import React from "react";
import VelavuButton from "../../elements/velavu-button";
import VelavuNotice from "../../elements/velavu-notice";
import styles from "./component-save-bar.module.scss";

interface ComponentSaveBarProps {
	disabled?: boolean;
	onClick?: VoidFunction;

	result: boolean | null;
	labelOK: string;
	labelError: string;
}

export default function ComponentSaveBar(props: ComponentSaveBarProps) {
	return (
		<div className={styles.footer}>
			<VelavuButton
				label="Save changes"
				disabled={props.disabled}
				onClick={props.onClick}
			/>
			{props.result === true && (
				<VelavuNotice
					className={styles.notice}
					type="confirm"
					body={props.labelOK}
				/>
			)}
			{props.result === false && (
				<VelavuNotice
					className={styles.notice}
					type="error"
					body={props.labelError}
				/>
			)}
		</div>
	);
}
