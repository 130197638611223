import React from "react";
import { deserializeAssetCategorization } from "../../data/asset-categorization";
import { GroupTag } from "../../elements/categorization-tag";
import Divider from "../../elements/divider";
import { VelavuMenuFilterItem } from "../../elements/velavu-menu-filter";
import { classArr } from "../../helper/style-helper";
import styles from "./analytics-sidebar-filter.module.scss";

export default function AnalyticsSidebarFilter(props: {
	expanded: boolean;

	allCategorizations: string[];
	selectedCategorizations: string[];
	onSetSelectedCategorizations: React.Dispatch<
		React.SetStateAction<string[]>
	>;

	filterStatus: boolean | undefined;
	setFilterStatus: (filter: boolean | undefined) => void;
}) {
	if (props.allCategorizations.length === 0 && !props.expanded) {
		return null;
	}

	return (
		<>
			<div
				className={classArr(
					styles.container,
					!props.expanded && styles.containerCollapsed,
				)}
			>
				{props.allCategorizations.map((categorization) => (
					<GroupTag
						key={categorization}
						className={styles.chip}
						categorization={
							deserializeAssetCategorization(categorization)!
						}
						selectable
						size="large"
						selected={props.selectedCategorizations.includes(
							categorization,
						)}
						onClick={() =>
							props.onSetSelectedCategorizations?.(
								(categorizations) => {
									const selectionIndex =
										categorizations.indexOf(categorization);
									if (selectionIndex === -1) {
										return categorizations.concat(
											categorization,
										);
									} else {
										const newSelection = [
											...categorizations,
										];
										newSelection.splice(selectionIndex, 1);
										return newSelection;
									}
								},
							)
						}
					/>
				))}

				<div className={styles.optionContainer}>
					<div className={styles.optionColumn}>
						<div className={styles.optionTitle}>Status</div>
						<VelavuMenuFilterItem
							checked={props.filterStatus === undefined}
							onClick={() => props.setFilterStatus(undefined)}
						>
							All
						</VelavuMenuFilterItem>
						<VelavuMenuFilterItem
							checked={props.filterStatus === true}
							onClick={() => props.setFilterStatus(true)}
						>
							Active
						</VelavuMenuFilterItem>
						<VelavuMenuFilterItem
							checked={props.filterStatus === false}
							onClick={() => props.setFilterStatus(false)}
						>
							Offline
						</VelavuMenuFilterItem>
					</div>
				</div>
			</div>
			<Divider />
		</>
	);
}
