import React, { useCallback } from "react";
import styles from "./floor-create-import-upload.module.scss";
import VelavuButton from "../../../../elements/velavu-button";
import VelavuNotice from "../../../../elements/velavu-notice";
import SiteModalUploadButton from "../../../../elements/site-modal-upload-button";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import { fileToPlaceableImage } from "../../../../helper/image-render-helper";
import PlaceableFloorImage from "../../../../data/placeable-floor-image";

export interface SiteModalFileUploadProps {
	onBack?: VoidFunction;
	onClose?: VoidFunction;
	onUpload?: (image: PlaceableFloorImage) => void;
}

export default function FloorCreateImportUpload(
	props: SiteModalFileUploadProps,
) {
	const propsOnUpload = props.onUpload;
	const handleFile = useCallback(
		async (file: File) => {
			try {
				const placeableImage = await fileToPlaceableImage(file);
				propsOnUpload?.(placeableImage);
			} catch (error) {
				alert(
					"Failed to load image. Please try again with a different file.",
				);
				console.warn("Failed to load image", error);
			}
		},
		[propsOnUpload],
	);

	return (
		<PinnedList>
			<PinnedListHeader
				label="Upload floor plan"
				onClose={props.onClose}
			/>

			<div className={styles.container}>
				<VelavuNotice
					type="info"
					body="Import the floor plan file you would like to use for this site"
				/>

				<SiteModalUploadButton
					className={styles.uploadButton}
					onReceive={handleFile}
				/>

				<div className={styles.buttonBar}>
					<VelavuButton
						className={styles.buttonBack}
						label="Back"
						outlined
						fullWidth
						onClick={props.onBack}
					/>
				</div>
			</div>
		</PinnedList>
	);
}
