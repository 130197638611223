import {
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	VelavuDevice,
	VelavuDevicePavo,
	VelavuDeviceVesta,
} from "velavu-js-api";

export function checkDeviceVesta(
	device: VelavuDevice,
): device is VelavuDeviceVesta {
	return (
		normalizeDeviceHardware(device.hardware) ===
		NormalizedDeviceHardware.Argo
	);
}

export function checkDeviceArda(
	device: VelavuDevice,
): device is VelavuDevicePavo {
	return (
		normalizeDeviceHardware(device.hardware) ===
		NormalizedDeviceHardware.Juno
	);
}

export function checkDevicePavo(
	device: VelavuDevice,
): device is VelavuDevicePavo {
	return (
		normalizeDeviceHardware(device.hardware) ===
		NormalizedDeviceHardware.Meridian
	);
}
