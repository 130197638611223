import React from "react";
import { VelavuDevice } from "velavu-js-api";
import TabTagCellular from "./tab-tag-cellular";
import TabTagDFU from "./tab-tag-dfu";
import TabTagEnvironment from "./tab-tag-environment";
import TabTagGeneral from "./tab-tag-general";
import TabTagLocation from "./tab-tag-location";
import styles from "./tab-tag.module.scss";
import { checkDeviceVesta } from "../../helper/hardware-helper";

export default function TabTag(props: {
	tag: VelavuDevice;
	onClickMap?: () => void;
}) {
	return (
		<div className={styles.container}>
			{/* General tag information */}
			<TabTagGeneral tag={props.tag} onClickMap={props.onClickMap} />

			{/* Cellular information */}
			<TabTagCellular className={styles.expandMenu} tag={props.tag} />

			{/* Location information */}
			<TabTagLocation className={styles.expandMenu} tag={props.tag} />

			{/* Environment information */}
			<TabTagEnvironment className={styles.expandMenu} tag={props.tag} />

			{/* Device firmware update information */}
			{checkDeviceVesta(props.tag) && props.tag.config && (
				<TabTagDFU className={styles.expandMenu} tag={props.tag} />
			)}
		</div>
	);
}
