import React, { CSSProperties } from "react";

export default function IconDownload(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M19 15a1 1 0 00-1 1v2H6v-2a1 1 0 10-2 0v2c0 1.1.9 2 2 2h12a2 2 0 002-2v-2c0-.6-.4-1-1-1zm-2.7-3.3a1 1 0 00-1.4-1.4L13 12.2V5a1 1 0 10-2 0v7.2l-1.9-2a1 1 0 00-1.4 1.5l3.6 3.6c.4.4 1 .4 1.4 0l3.6-3.6z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
