import React, { CSSProperties } from "react";

export default function IconGPSTag(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 8H10C8.89543 8 8 8.89543 8 10V14C8 15.1046 8.89543 16 10 16H14C15.1046 16 16 15.1046 16 14V10C16 8.89543 15.1046 8 14 8ZM10 6C7.79086 6 6 7.79086 6 10V14C6 16.2091 7.79086 18 10 18H14C16.2091 18 18 16.2091 18 14V10C18 7.79086 16.2091 6 14 6H10Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
