import React, { useCallback } from "react";
import styles from "./file-input-button.module.scss";
import { classArr } from "../helper/style-helper";
import InputButton from "./input-button";
import IconCircleAdd from "../dynamicicons/icon-circle-add";
import { makeStyleable } from "../helper/icon-helper";
import IconClose from "../dynamicicons/icon-close";
import globals from "../styles/global.scss";
import VelavuIconButton from "./velavu-icon-button";

export interface FileInputButtonProps {
	fileName?: string | undefined;
	inputLabel?: string;
	onInput?: (data: File | undefined) => void;
	titleText?: string;
	fullWidth?: boolean;
	accept?: string;
}

export default function FileInputButton(props: FileInputButtonProps) {
	const onInput = props.onInput;

	const onChangeFile = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const file = event.currentTarget.files![0];
			onInput?.(file);
		},
		[onInput],
	);

	const clearInput = useCallback(() => {
		onInput?.(undefined);
	}, [onInput]);

	return (
		<div
			className={classArr(
				styles.wrapper,
				props.fullWidth && styles.fullWidth,
			)}
		>
			{props.titleText && (
				<span className={styles.titleText}>{props.titleText}</span>
			)}

			{props.fileName === undefined ? (
				<label>
					<InputButton
						icon={makeStyleable(IconCircleAdd)}
						label={props.inputLabel ?? "Add"}
						fullWidth={props.fullWidth}
						nonInteractive
					/>

					<input
						className={styles.input}
						onChange={onChangeFile}
						type="file"
						accept={props.accept}
					/>
				</label>
			) : (
				<div
					className={classArr(
						styles.field,
						props.fullWidth && styles.fullWidth,
					)}
				>
					<span className="p2">{props.fileName}</span>
					<VelavuIconButton onClick={clearInput}>
						<IconClose color={globals.textPrimary} />
					</VelavuIconButton>
				</div>
			)}
		</div>
	);
}
