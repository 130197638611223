import React, { CSSProperties } from "react";

export default function IconManta(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	const color = props.color ?? "#00255D";
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 2C10.4477 2 10 2.44772 10 3V3.41604C8.2341 4.1876 7 5.94968 7 8V16C7 18.7614 9.23858 21 12 21C14.7614 21 17 18.7614 17 16V8C17 5.94968 15.7659 4.1876 14 3.41604V3C14 2.44772 13.5523 2 13 2H11ZM12 5C10.3431 5 9 6.34315 9 8V16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16V8C15 6.34315 13.6569 5 12 5Z"
				fill={color}
			/>
		</svg>
	);
}
