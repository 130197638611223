// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JZ1xebtYKwQuQjpKG0a0{display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:20px}`, "",{"version":3,"sources":["webpack://./src/elements/dropdown-panel-toolbar.module.scss"],"names":[],"mappings":"AAAA,sBACC,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,6BAAA,CACA,YAAA","sourcesContent":[".navigation {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tjustify-content: space-between;\n\tpadding: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": `JZ1xebtYKwQuQjpKG0a0`
};
export default ___CSS_LOADER_EXPORT___;
