/**
 * Adjusts a date to the very start of the day
 */
export function adjustDateStart(date: Date) {
	date.setHours(0, 0, 0, 0);
	return date;
}

/**
 * Adjusts a date to the very end of the day
 */
export function adjustDateEnd(date: Date) {
	date.setHours(23, 59, 59, 999);
	return date;
}
