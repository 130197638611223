import React, { CSSProperties } from "react";

export default function IconCloseLeft(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M20.01 10.947H9.38l2.835-3.143a1.11 1.11 0 000-1.496.945.945 0 00-1.397 0L6.29 11.252a1.056 1.056 0 00-.215.34 1.107 1.107 0 00.215 1.146l4.518 4.954c.092.098.2.175.32.228a.936.936 0 00.756 0 .987.987 0 00.32-.228c.093-.097.165-.213.215-.34a1.108 1.108 0 00-.214-1.145L9.38 13.054h10.628c.545 0 .991-.474.991-1.054 0-.58-.446-1.053-.99-1.053zM5 18a1 1 0 11-2 0V6a1 1 0 012 0v12z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
