import React, { CSSProperties } from "react";
import styles from "./velavu-simple-table.module.scss";

export default function VelavuSimpleTable(props: {
	style?: CSSProperties;
	className?: string;
	children?: React.ReactNode;
}) {
	return (
		<table
			style={props.style}
			className={
				(props.className ? props.className + " " : "") + styles.table
			}
		>
			{props.children}
		</table>
	);
}

export function CenteredTD(props: {
	style?: CSSProperties;
	className?: string;
	children?: React.ReactNode;
}) {
	return (
		<td style={props.style} className={props.className}>
			<div className={styles.centerCell}>{props.children}</div>
		</td>
	);
}

export function MultilineTD(props: {
	style?: CSSProperties;
	className?: string;
	children?: React.ReactNode;
}) {
	return (
		<td
			style={props.style}
			className={
				(props.className ? props.className + " " : "") +
				styles.multilineCell
			}
		>
			{props.children}
		</td>
	);
}

export function VerticalTD(props: {
	style?: CSSProperties;
	className?: string;
	children?: React.ReactNode;
}) {
	return (
		<td style={props.style} className={props.className}>
			<div className={styles.verticalCell}>{props.children}</div>
		</td>
	);
}
