import { MeasurementSystem } from "velavu-js-api";
import { normalizeSpeed } from "./unit-helper";

/**
 * Gets a user-readable value to display as the speeding threshold
 * @param index The user's selected index
 * @param unit The user's preferred unit
 */
export function getReadableSpeedingThreshold(
	index: number,
	unit: MeasurementSystem,
): string {
	if (unit === MeasurementSystem.Imperial) {
		return `${index * 3} mph`;
	} else {
		return `${index * 5} km/h`;
	}
}

/**
 * Gets the user's set speeding threshold in m/s
 * @param index The user's selected index
 * @param unit The user's preferred unit
 */
export function getSpeedingThreshold(
	index: number,
	unit: MeasurementSystem,
): number {
	if (unit === MeasurementSystem.Imperial) {
		return normalizeSpeed(index * 3, MeasurementSystem.Imperial);
	} else {
		return normalizeSpeed(index * 5, MeasurementSystem.Metric);
	}
}
