import React, {
	CSSProperties,
	useLayoutEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { mapboxStyleID, mapboxUsername } from "../../constants";
import { VelavuDevice } from "velavu-js-api";
import IconVelavuMapIndicator from "../../dynamicicons/icon-velavu-map-indicator";
import DynamicBattery from "../../elements/dynamic-battery";
import StatusIndicator from "../../elements/status-indicator";
import VelavuSimpleTable, {
	CenteredTD,
	MultilineTD,
} from "../../elements/velavu-simple-table";
import { useDisplayLocation, useMapboxAPIKey } from "../../helper/api-helper";
import { mapConnectionStatus } from "../../helper/language-helper";
import { getDeviceStatus } from "../../helper/status-helper";
import { classArr } from "../../helper/style-helper";
import NoLocation from "../../img/no-location.png";
import styles from "./tab-tag-general.module.scss";

const mapboxZoom = 16;

export default function TabTagGeneral(props: {
	style?: CSSProperties;
	className?: string;
	tag: VelavuDevice;
	onClickMap?: () => void;
}) {
	const coordinates = props.tag.location?.coordinates;
	const assetLocation = useDisplayLocation(props.tag);
	const mapboxKey = useMapboxAPIKey();
	const mapboxMapURL =
		coordinates === undefined
			? undefined
			: `https://api.mapbox.com/styles/v1/${mapboxUsername}/${mapboxStyleID}/static/${coordinates[0]},${coordinates[1]},${mapboxZoom},0,0/600x400?access_token=${mapboxKey}&@2x=true`;

	const [height, setHeight] = useState(0);
	const ref = useRef<HTMLDivElement>(null);

	const status = useMemo(() => {
		return getDeviceStatus(props.tag);
	}, [props.tag]);

	useLayoutEffect(() => {
		ref.current && setHeight(ref.current.clientHeight);
	}, [ref]);

	return (
		<div
			style={props.style}
			className={`${props.className ?? ""} ${styles.sectionGeneral}`}
		>
			<div className={`${styles.sectionGeneralItem}`} ref={ref}>
				<VelavuSimpleTable>
					<tbody>
						<tr>
							<td>Tag ID</td>
							<td>{props.tag.id}</td>
						</tr>
						<tr>
							<td>Location</td>
							<MultilineTD>{assetLocation}</MultilineTD>
						</tr>
						<tr>
							<td>Status</td>
							<CenteredTD>
								<StatusIndicator status={status} />
								<span>{mapConnectionStatus(status)}</span>
							</CenteredTD>
						</tr>
						{props.tag.state?.power && (
							<tr>
								<td>Battery</td>
								<CenteredTD>
									<span style={{ marginRight: 4 }}>
										{props.tag.state?.power
											?.battery_level !== undefined
											? `${props.tag.state.power.battery_level}%`
											: "N/A"}
									</span>
									<DynamicBattery
										resource={props.tag.state?.power}
									/>
								</CenteredTD>
							</tr>
						)}
						{props.tag.state && (
							<tr>
								<td>Firmware</td>
								<MultilineTD>
									{props.tag.state.app_version}
								</MultilineTD>
							</tr>
						)}
						<tr>
							<td>Serial Code</td>
							<td>{props.tag.serial_code}</td>
						</tr>
					</tbody>
				</VelavuSimpleTable>
			</div>

			<div className={styles.sectionGeneralSeparator} />
			{mapboxMapURL && (
				<div
					className={classArr(
						styles.sectionGeneralItem,
						styles.mapContainer,
						props.onClickMap && styles.clickableMapContainer,
					)}
					style={{ height: height }}
					onClick={props.onClickMap}
				>
					<IconVelavuMapIndicator className={styles.mapIndicator} />
					<img
						className={styles.mapImage}
						style={{ height: height }}
						src={mapboxMapURL}
						alt={assetLocation}
					/>
				</div>
			)}
			{!mapboxMapURL && (
				<div
					className={`${styles.sectionGeneralItem} ${styles.mapContainer}`}
					style={{ height: height }}
				>
					<div className={styles.textWrapper}>
						<span className={styles.noLocationText}>
							no location data
						</span>
					</div>
					<img
						className={`${styles.mapImage} ${styles.noLocation}`}
						style={{ height: height }}
						src={NoLocation}
						alt={"No location data"}
					/>
				</div>
			)}
		</div>
	);
}
