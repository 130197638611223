import React, { CSSProperties } from "react";
import styles from "./input-button.module.scss";
import { StyleableIcon } from "../helper/icon-helper";
import globals from "../styles/global.scss";
import { classArr } from "../helper/style-helper";

type InputButtonBaseProps = {
	style?: CSSProperties;
	className?: string;
	icon: StyleableIcon;
	label: string;
	fullWidth?: boolean;
};

type InputButtonInteractiveProps = {
	nonInteractive?: false;
	onClick?: () => void;
};

type InputButtonNonInteractiveProps = {
	nonInteractive: true;
};

export type InputButtonProps = InputButtonBaseProps &
	(InputButtonInteractiveProps | InputButtonNonInteractiveProps);

export default function InputButton(props: InputButtonProps) {
	const className = classArr(
		styles.button,
		props.fullWidth && styles.fullWidth,
		props.className,
	);
	const innerContent = (
		<>
			{props.icon(24, globals.accent)}
			{props.label}
		</>
	);

	if (props.nonInteractive) {
		return (
			<div style={props.style} className={className}>
				{innerContent}
			</div>
		);
	} else {
		return (
			<button
				style={props.style}
				className={className}
				onClick={props.onClick}
			>
				{innerContent}
			</button>
		);
	}
}
