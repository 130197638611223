import {
	AnyVelavuEvent,
	EventCategory,
	VelavuAPI,
	VelavuAsset,
} from "velavu-js-api";
import DatePicker from "./date-picker";
import Divider from "../../../../elements/divider";
import styles from "./asset-detail-events.module.scss";
import React, { useMemo, useState } from "react";
import { useDateRange } from "../../../../data/date-range";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import SelectableCard from "../../../../elements/selectable-card";
import AlertIcon from "../../../../elements/alert-icon";
import { buildEventDescription } from "../../../../helper/language-helper";
import { DateTime } from "luxon";

const eventCategory = EventCategory.Shock;

export default function AssetDetailEvents(props: { asset: VelavuAsset }) {
	//Last week -> Today
	const [dateRange, setDateRange] = useDateRange(7);
	const [sortNew, setSortNew] = useState(true);

	//Fetch events
	const [events, setEvents] = useState<AnyVelavuEvent[] | undefined>(
		undefined,
	);
	useCancellableEffect(
		(addPromise) => {
			setEvents(undefined);

			addPromise(
				VelavuAPI.events.getAllEvents({
					assetID: props.asset.id,
					category: eventCategory,
					since: dateRange.start,
					until: dateRange.end,
				}),
			)
				.then((result) => setEvents(result.data))
				.catch(console.warn);
		},
		[setEvents, dateRange, props.asset.id],
	);

	const sortedEvents = useMemo(() => {
		return events?.sort(sortNew ? compareEventDesc : compareEventAsc);
	}, [events, sortNew]);

	return (
		<>
			<DatePicker
				range={dateRange}
				onUpdate={setDateRange}
				sortNew={sortNew}
				setSortNew={setSortNew}
			/>

			<Divider />

			<div className={styles.listScroll}>
				{sortedEvents === undefined && (
					<div className={styles.empty}>
						<span>Checking events…</span>
					</div>
				)}

				{sortedEvents !== undefined && sortedEvents.length === 0 && (
					<div className={styles.empty}>
						<span>No events</span>
					</div>
				)}

				{sortedEvents !== undefined &&
					sortedEvents.length > 0 &&
					sortedEvents.map((event) => (
						<SelectableCard
							key={event.id}
							className={styles.eventCard}
						>
							<AlertIcon
								type={event.category}
								size={16}
								color="unset"
							/>

							<div className={styles.eventCardText}>
								<span className={styles.eventCardTitle}>
									{buildEventDescription(event)}
								</span>

								<span className={styles.eventCardSubtitle}>
									<DateText
										dateTime={DateTime.fromISO(
											event.timestamp,
										)}
									/>
								</span>
							</div>
						</SelectableCard>
					))}
			</div>
		</>
	);
}

function DateText(props: { dateTime: DateTime }) {
	const dateTime = props.dateTime;

	if (DateTime.now().year === dateTime.year) {
		return (
			<>
				<span>{dateTime.toFormat("MMM d")}</span>
				<span>{dateTime.toFormat("t")}</span>
			</>
		);
	} else {
		return <span>{dateTime.toLocaleString(DateTime.DATETIME_MED)}</span>;
	}
}

function compareEventAsc(event1: AnyVelavuEvent, event2: AnyVelavuEvent) {
	return (
		new Date(event1.timestamp).getTime() -
		new Date(event2.timestamp).getTime()
	);
}

function compareEventDesc(event1: AnyVelavuEvent, event2: AnyVelavuEvent) {
	return -compareEventAsc(event1, event2);
}
