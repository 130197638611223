import React, { CSSProperties } from "react";
import styles from "./velavu-avatar.module.scss";

export default function VelavuAvatar(props: {
	name: string;
	className?: string;
	image?: string;
	style?: CSSProperties;
}) {
	return (
		<div
			className={`${styles.content} ${!props.image && styles.initials} ${
				props.className ? props.className : ""
			}`}
			style={props.style}
		>
			{props.image ? (
				<img className={styles.image} src={props.image} alt="" />
			) : (
				<span>
					{props.name
						.split(" ")
						.slice(0, 2)
						.map((word) => word.substring(0, 1))
						.join("")}
				</span>
			)}
		</div>
	);
}
