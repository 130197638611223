import React from "react";
import { VelavuSite } from "velavu-js-api";
import Divider from "../../elements/divider";
import SelectorSite from "../shared/selector-site";
import SiteSwitcherList from "../shared/site-switcher-list";
import styles from "./analytics-sidebar-sites.module.scss";

export default function AnalyticsSidebarSites(props: {
	sites: VelavuSite[] | undefined;
	onSelectSite: (id: string | undefined) => void;
}) {
	return (
		<>
			<div className={styles.header}>
				<SelectorSite className={styles.selector} site="select" />
			</div>

			<Divider />

			<SiteSwitcherList
				className={styles.list}
				sites={props.sites ?? []}
				onSelectSite={props.onSelectSite}
				showGlobal
			/>
		</>
	);
}
