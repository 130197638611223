import { VelavuDevice } from "velavu-js-api";

enum AnalyticsStatType {
	Temperature,
	Humidity,
	Battery,
	Location,
}
export default AnalyticsStatType;

/**
 * Gets a numeric statistic from the provided device for a given statistic
 */
export function getNumericDeviceStat(
	stat: AnalyticsStatType,
	device: VelavuDevice | undefined,
): number | undefined {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			return device?.environment?.temperature_c;
		case AnalyticsStatType.Humidity:
			return device?.environment?.humidity;
		case AnalyticsStatType.Battery:
			return device?.state?.power?.battery_level;
		default:
			return undefined;
	}
}
