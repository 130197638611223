import React, { CSSProperties } from "react";
import styles from "./selectable-card.module.scss";

interface SelectableCardProps {
	style?: CSSProperties;
	className?: string;
	onClick?: VoidFunction;
	onMouseEnter?: VoidFunction;
	onMouseLeave?: VoidFunction;
	children?: React.ReactNode;
}

export default function SelectableCard(props: SelectableCardProps) {
	return (
		<div
			style={props.style}
			className={`${styles.container} ${props.className ?? ""}`}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			{props.children}
		</div>
	);
}
