import React, { CSSProperties } from "react";
import { VelavuDevice } from "velavu-js-api";
import {
	DynamicSignalLabel,
	mapStrengthRSRP,
} from "../../elements/dynamic-signal";
import VelavuExpandMenu from "../../elements/velavu-expand-menu";
import VelavuSimpleTable from "../../elements/velavu-simple-table";
import { mapCellRegistrationStatus } from "../../helper/language-helper";
import { checkDeviceVesta } from "../../helper/hardware-helper";

export default function TabTagCellular(props: {
	style?: CSSProperties;
	className?: string;
	tag: VelavuDevice;
}) {
	if (!checkDeviceVesta(props.tag) || props.tag.state?.cell === undefined)
		return null;
	const cell = props.tag.state.cell;

	return (
		<VelavuExpandMenu
			style={props.style}
			className={props.className}
			label="Cellular"
		>
			<VelavuSimpleTable>
				<tbody>
					<tr>
						<td>Cellular signal</td>
						<td>
							<DynamicSignalLabel
								strength={mapStrengthRSRP(cell.rsrp)}
							/>
						</td>
					</tr>
					<tr>
						<td>Operator</td>
						<td>{cell.operator_name}</td>
					</tr>
					<tr>
						<td>Status</td>
						<td>{mapCellRegistrationStatus(cell.reg_status)}</td>
					</tr>
					<tr>
						<td>Firmware</td>
						<td>{cell.fw_version}</td>
					</tr>
					<tr>
						<td>ICCID</td>
						<td>{cell.iccid}</td>
					</tr>
					<tr>
						<td>IMEI</td>
						<td>{cell.imei}</td>
					</tr>
				</tbody>
			</VelavuSimpleTable>
		</VelavuExpandMenu>
	);
}
