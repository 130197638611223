import React, { useState } from "react";
import globals from "../../styles/global.scss";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import { EventData, GeoJSONSource, MapEventType } from "mapbox-gl";
import { Feature, Point } from "geojson";

const mapboxIDGeofenceEditorPoints = "geofence-editor-points";
const mapboxIDGeofenceEditorLines = "geofence-editor-lines";

export type MapboxInjectableGeofenceEditorToolType = "draw" | "edit" | "review";

export interface MapboxInjectableGeofenceEditorParams {
	mapInstance: MapHelperInstance;
	disabled?: boolean;

	toolType: MapboxInjectableGeofenceEditorToolType;
	onChangeToolType: (
		toolType: MapboxInjectableGeofenceEditorToolType,
	) => void;

	points: [number, number][];
	onChangePoints: React.Dispatch<React.SetStateAction<[number, number][]>>;
}

interface EditorPointProperties {
	index: number;
}

export default function useMapboxInjectableGeofenceEditor(
	params: MapboxInjectableGeofenceEditorParams,
) {
	const [cursorCoords, setCursorCoords] = useState<
		[number, number] | undefined
	>(undefined);

	useStyleSetupMapEffect(params.mapInstance, (_, box) => {
		box.addSource(mapboxIDGeofenceEditorPoints, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer({
			id: mapboxIDGeofenceEditorPoints,
			type: "circle",
			source: mapboxIDGeofenceEditorPoints,
			paint: {
				"circle-color": "#FFFFFF",
				"circle-stroke-color": globals.accent,
				"circle-stroke-width": 2,
				"circle-radius": 4,
			},
		});

		box.addSource(mapboxIDGeofenceEditorLines, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer(
			{
				id: mapboxIDGeofenceEditorLines,
				type: "line",
				source: mapboxIDGeofenceEditorLines,
				layout: {
					"line-join": "round",
					"line-cap": "round",
				},
				paint: {
					"line-color": "#3957DF",
					"line-width": 3,
				},
			},
			mapboxIDGeofenceEditorPoints,
		);
	});

	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			const editorPointsSource = map.getSource(
				mapboxIDGeofenceEditorPoints,
			) as GeoJSONSource;
			const editorLinesSource = map.getSource(
				mapboxIDGeofenceEditorLines,
			) as GeoJSONSource;

			//Set blank data if we are disabled
			if (params.disabled) {
				editorPointsSource.setData(geoJSONBlankCollection);
				editorLinesSource.setData(geoJSONBlankCollection);
				return;
			}

			//Add the cursor coordinates
			const lineCoordinates = [...params.points];
			if (cursorCoords !== undefined && params.toolType === "draw") {
				lineCoordinates.push(cursorCoords);

				//Complete the shape
				if (lineCoordinates.length >= 2) {
					lineCoordinates.push(lineCoordinates[0]);
				}
			}

			//Update the editor points
			editorPointsSource.setData({
				type: "FeatureCollection",
				features: params.points.map(
					(point, index): Feature<Point, EditorPointProperties> => ({
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: point,
						},
						properties: {
							index: index,
						},
					}),
				),
			});

			//Update the editor line with the coordinates
			editorLinesSource.setData({
				type: "Feature",
				geometry: {
					type: "LineString",
					coordinates: lineCoordinates,
				},
				properties: {},
			});
		},
		[params.disabled, params.points, cursorCoords, params.toolType],
	);

	//Set the cursor to a crosshair while we are enabled
	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			if (!params.disabled && params.toolType !== "review") {
				map.getCanvas().style.cursor = "crosshair";
			} else {
				map.getCanvas().style.cursor = "";
			}
		},
		[params.disabled, params.toolType],
	);

	//Track the cursor coordinates state
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(_, box) => {
			if (!params.disabled && params.toolType === "draw") {
				box.on("mousemove", (event) => {
					setCursorCoords([event.lngLat.lng, event.lngLat.lat]);
				});
			}
		},
		[setCursorCoords, params.disabled, params.toolType],
	);

	//Add a new point on click
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(_, box) => {
			if (!params.disabled && params.toolType === "draw") {
				box.on("click", (event) => {
					//Extend the points array with the click point
					const clickPoint: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];
					params.onChangePoints((points) => {
						//Don't add the same point twice
						if (
							points.length > 1 &&
							points[points.length - 1][0] === clickPoint[0] &&
							points[points.length - 1][1] === clickPoint[1]
						) {
							return points;
						} else {
							return [...points, clickPoint];
						}
					});
				});
			}
		},
		[params.disabled, params.toolType],
	);

	//Complete the polygon on double click
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(_, box) => {
			const points = params.points;

			//Make sure that we can draw, and that we have at least 2 points
			if (
				!params.disabled &&
				params.toolType === "draw" &&
				points.length >= 2
			) {
				box.on("dblclick", (event) => {
					//Block zoom control
					event.preventDefault();

					//Complete the loop by bridging the last point to the first point
					params.onChangePoints([...points, points[0]]);

					//Enter edit mode
					params.onChangeToolType("edit");
				});
			}
		},
		[
			params.points,
			params.disabled,
			params.toolType,
			params.onChangeToolType,
		],
	);

	//Drag to move points in edit mode
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(map, box) => {
			//Make sure that we can edit
			if (!params.disabled && params.toolType === "edit") {
				const canvas = map.getCanvas();

				let moveTarget: EditorPointProperties | null = null;

				const updatePoint = (
					properties: EditorPointProperties,
					coords: [number, number],
				) => {
					params.onChangePoints((points) => {
						const newPoints = [...points];

						//If the first point was moved, move the last point as well
						if (
							newPoints.length >= 2 &&
							(properties.index === 0 ||
								properties.index === newPoints.length - 1)
						) {
							newPoints[0] = coords;
							newPoints[newPoints.length - 1] = coords;
						} else {
							newPoints[properties.index] = coords;
						}

						return newPoints;
					});
				};

				const onMove = (
					event: MapEventType["mousemove"] & EventData,
				) => {
					const clickCoords: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];

					//Update the location
					updatePoint(moveTarget!, clickCoords);
				};
				const onUp = (event: MapEventType["mouseup"] & EventData) => {
					const clickCoords: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];

					//Update the location
					updatePoint(moveTarget!, clickCoords);

					//Clean up
					canvas.style.cursor = "crosshair";
					map.off("mousemove", onMove);
					moveTarget = null;
				};
				const onDown = (
					event: MapEventType["mousedown"] & EventData,
				) => {
					//Prevent the default map drag behavior
					event.preventDefault();

					//Register the move target
					moveTarget = event.features![0]
						.properties as EditorPointProperties;

					//Set the cursor to grab
					canvas.style.cursor = "grabbing";

					//Register listeners for the lifecycle of this grab
					map.on("mousemove", onMove);
					map.once("mouseup", onUp);
				};
				map.on("mousedown", mapboxIDGeofenceEditorPoints, onDown);

				return () => {
					map.off("mousemove", onMove);
					map.off("mousedown", mapboxIDGeofenceEditorPoints, onDown);
				};
			}
		},
		[params.disabled, params.toolType, params.onChangePoints],
	);
}
