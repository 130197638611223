import React, { CSSProperties } from "react";

export default function IconVelavuDown(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M11.6 20.9L6.4 15.6C6 15.2 6.3 14.7 6.7 14.7H11L11 4.2C11 3.7 11.4 3.2 12 3.2 12.6 3.2 13 3.7 13 4.2L13 14.7H17.3C17.7 14.7 18 15.2 17.6 15.6L12.4 20.9C12.2 21 11.8 21 11.6 20.9Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
