import React, { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import IconArrowBack from "../dynamicicons/icon-arrow-back";
import IconArrowForward from "../dynamicicons/icon-arrow-forward";
import styles from "./list-pagination.module.scss";
import { classArr } from "../helper/style-helper";

export default function ListPagination({
	currentPage,
	totalPages,
	isLoading,
	hasMore,
	loadMore,
	setCurrentPage,
	setScrollToTop,
}: {
	currentPage: number;
	totalPages: number;
	isLoading: boolean;
	hasMore: boolean;
	loadMore: VoidFunction;
	setCurrentPage: Dispatch<SetStateAction<number>>;
	setScrollToTop: Dispatch<SetStateAction<boolean>>;
}) {
	const backButtonDisabled = useMemo(() => {
		return currentPage === 1;
	}, [currentPage]);

	const forwardButtonDisabled = useMemo(() => {
		return (currentPage === totalPages && !hasMore) || isLoading;
	}, [currentPage, hasMore, isLoading, totalPages]);

	const handleForward = useCallback(() => {
		if (currentPage < totalPages) {
			setCurrentPage((currentPage) => currentPage + 1);
		} else {
			loadMore();
		}
	}, [currentPage, loadMore, setCurrentPage, totalPages]);

	const handleBack = useCallback(() => {
		if (currentPage === 2) {
			setCurrentPage(1);
		} else {
			setCurrentPage((currentPage) => currentPage - 1);
		}

		setScrollToTop(true);
	}, [currentPage, setCurrentPage, setScrollToTop]);

	const availablePages = useMemo(() => {
		const maxPages = 3;
		const halfMaxPages = Math.floor(maxPages / 2);

		let startPage = Math.max(1, currentPage - halfMaxPages);
		const endPage = Math.min(totalPages, startPage + maxPages - 1);

		// Adjust startPage if endPage is at the upper limit
		if (endPage - startPage + 1 < maxPages) {
			startPage = Math.max(1, endPage - maxPages + 1);
		}

		const pagesShown: number[] = [];
		for (let i = startPage; i <= endPage; i++) {
			pagesShown.push(i);
		}

		return pagesShown;
	}, [currentPage, totalPages]);

	return (
		<div className={styles.paginationContainer}>
			<button
				className={classArr(
					styles.paginationTile,
					styles.selectablePaginationTile,
					styles.paginationNavigation,
				)}
				disabled={backButtonDisabled}
				onClick={handleBack}
			>
				<IconArrowBack color="currentColor" />
			</button>
			<div className={styles.paginationPages}>
				{availablePages.map((page) => (
					<button
						key={page}
						className={classArr(
							styles.paginationTile,
							styles.selectablePaginationTile,
							currentPage === page
								? styles.paginationPageSelected
								: styles.paginationPage,
						)}
						onClick={() => {
							setCurrentPage(page);
							setScrollToTop(true);
						}}
					>
						{page}
					</button>
				))}

				{!isLoading && hasMore && (
					<span
						className={classArr(
							styles.paginationTile,
							styles.paginationMoreIndicator,
						)}
					>
						...
					</span>
				)}
			</div>
			<button
				className={classArr(
					styles.paginationTile,
					styles.selectablePaginationTile,
					styles.paginationNavigation,
				)}
				disabled={forwardButtonDisabled}
				onClick={handleForward}
			>
				<IconArrowForward color="currentColor" size={24} />
			</button>
		</div>
	);
}
