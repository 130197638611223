import React, { CSSProperties } from "react";

export default function IconAdd(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M18 13h-5v5c0 .55-.45 1-1 1s-1-.45-1-1v-5H6c-.55 0-1-.45-1-1s.45-1 1-1h5V6c0-.55.45-1 1-1s1 .45 1 1v5h5c.55 0 1 .45 1 1s-.45 1-1 1z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
