import React from "react";

export type StyleableIcon = (
	size: number,
	color: string,
	className?: string,
) => React.ReactNode;

export function makeStyleable(
	Component: React.ComponentType<{
		size: number;
		color: string;
		className?: string;
	}>,
): StyleableIcon {
	return (size: number, color: string, className?: string) => (
		<Component size={size} color={color} className={className} />
	);
}
