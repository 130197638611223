import React, { CSSProperties } from "react";

export default function IconMute(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M17.086 18.5H4.996c-.89 0-1.33-1.08-.7-1.71l1.29-1.29v-5c0-1.059.193-2.056.563-2.937L3.293 4.707a1 1 0 011.414-1.414l16 16a1 1 0 01-1.414 1.414L17.086 18.5zM7.744 9.158a5.658 5.658 0 00-.158 1.342v6h7.5L7.744 9.158z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M17.587 13.88V10.5c0-3.07-1.64-5.64-4.5-6.32V3.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68a5.709 5.709 0 00-1.575.625l1.503 1.503A3.98 3.98 0 0111.586 6c2.49 0 4 2.02 4 4.5v1.38l2 2zM13.586 19.5a2 2 0 11-4 0h4z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
