// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nIgDK_21x_kea6zn_sdA{position:relative}.nIgDK_21x_kea6zn_sdA svg{display:block}.pBiMpQqQumC7j2DYJVD4{font-weight:normal;overflow-wrap:break-word;white-space:normal;display:block;width:168px}`, "",{"version":3,"sources":["webpack://./src/elements/info-tooltip.module.scss"],"names":[],"mappings":"AAAA,sBACC,iBAAA,CAEA,0BACC,aAAA,CAIF,sBACC,kBAAA,CACA,wBAAA,CACA,kBAAA,CACA,aAAA,CACA,WAAA","sourcesContent":[".container {\n\tposition: relative;\n\n\tsvg {\n\t\tdisplay: block;\n\t}\n}\n\n.text {\n\tfont-weight: normal;\n\toverflow-wrap: break-word;\n\twhite-space: normal;\n\tdisplay: block;\n\twidth: 168px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `nIgDK_21x_kea6zn_sdA`,
	"text": `pBiMpQqQumC7j2DYJVD4`
};
export default ___CSS_LOADER_EXPORT___;
