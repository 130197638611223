import { FeatureCollection } from "geojson";
import mapboxgl from "mapbox-gl";
import { FloorImageSize } from "src/data/floor-image-data";
import { Coordinate } from "velavu-js-api";
import { ImagePoint } from "../data/image-point";
import {
	calculateBounds,
	degToRad,
	inverseLerp,
	lerp,
	radToDeg,
} from "./math-helper";

const MERCATOR_RADIUS = 6378137;

//Converts from length in meters to an angle in degrees
export function projectToSphere(x: number, y: number): [number, number] {
	return [
		radToDeg(x / MERCATOR_RADIUS),
		radToDeg(Math.atan(Math.exp(y / MERCATOR_RADIUS)) * 2 - Math.PI / 2),
	];
}

//Converts from a longitude + latitude coordinate to a length in meters
export function projectToPlane(lng: number, lat: number): [number, number] {
	return [
		degToRad(lng) * MERCATOR_RADIUS,
		Math.log(Math.tan(Math.PI / 4 + degToRad(lat) / 2)) * MERCATOR_RADIUS,
	];
}

export class GeoTransformer {
	private readonly scale: number;
	private readonly rotSin: number;
	private readonly rotCos: number;
	private readonly offsetPlane: [number, number];

	constructor(scale: number, rotation: number, offset: [number, number]) {
		this.scale = scale;

		const rotRad = degToRad(rotation);
		this.rotSin = Math.sin(rotRad);
		this.rotCos = Math.cos(rotRad);

		this.offsetPlane = projectToPlane(offset[0], offset[1]);
	}

	/**
	 * Converts a single point from point space to world space, applying the given transformations
	 */
	public transform(point: ImagePoint) {
		const newPoint = [point.x, point.y];

		//Rotate points
		newPoint[0] = point.x * this.rotCos - point.y * this.rotSin;
		newPoint[1] = point.y * this.rotCos + point.x * this.rotSin;

		//Scale points
		newPoint[0] *= this.scale;
		newPoint[1] *= this.scale;

		//Offset points
		newPoint[0] += this.offsetPlane[0];
		newPoint[1] += this.offsetPlane[1];

		//Project to sphere
		return projectToSphere(newPoint[0], newPoint[1]);
	}

	/**
	 * Converts an array of lines from point space to world space, applying the given transformations
	 */
	public generateGeoJSON(lines: ImagePoint[][]): FeatureCollection {
		return {
			type: "FeatureCollection",
			features: lines.map((points) => ({
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: points.map((it) => this.transform(it)),
				},
			})),
		};
	}
}

/**
 * Lerps between two coordinate pairs
 */
export function lerpCoordinates(
	progress: number,
	start: [number, number],
	end: [number, number],
): [number, number] {
	return [lerp(progress, start[0], end[0]), lerp(progress, start[1], end[1])];
}

/**
 * Maps an array of lines from canvas space (top-left origin) to center (center origin)
 */
export function mapCanvasToCenterCoords(lines: ImagePoint[][]): Coordinate[][] {
	//Map all points to be around the center
	const { minX, minY, maxX, maxY } = calculateBounds(lines.flat());
	const centerX = (minX + maxX) / 2;
	const centerY = (minY + maxY) / 2;
	const adjustedLines = lines.map((line) =>
		line.map((point) => ({
			x: point.x - centerX,
			y: -(point.y - centerY),
		})),
	);
	return adjustedLines;
}

/**
 * Maps image coords to center based on the lines
 */
export function mapImageToCenterCoords(
	imageSize: FloorImageSize,
	lines: ImagePoint[][],
): Coordinate[] | undefined {
	if (!imageSize) return undefined;
	//Map all points to be around the center
	const { minX, minY, maxX, maxY } = calculateBounds(lines.flat());
	const centerX = (minX + maxX) / 2;
	const centerY = (minY + maxY) / 2;
	const imageCoords = [
		{ x: 0, y: 0 },
		{ x: imageSize.width, y: 0 },
		{ x: imageSize.width, y: imageSize.height },
		{ x: 0, y: imageSize.height },
	];
	const adjustedCoords = imageCoords.map((coord) => {
		return {
			x: coord.x - centerX,
			y: -(coord.y - centerY),
		};
	});
	return adjustedCoords;
}

export interface WeightedLinePoint {
	coordinates: [number, number];
	value: number;
}

type WeightedLinePointExtra<Extra> = WeightedLinePoint & {
	extra: Extra;
};

export enum WeightedLineInterpolationResultType {
	Exact,
	Interpolation,
}

export type WeightedLineInterpolationResult<Extra> = {
	coordinates: [number, number];
	value: number;
} & (
	| {
			type: WeightedLineInterpolationResultType.Exact;
			index: number;
			extra: Extra;
	  }
	| {
			type: WeightedLineInterpolationResultType.Interpolation;
			indexLower: number;
			extraLower: Extra;
			indexUpper: number;
			extraUpper: Extra;
			progress: number;
	  }
);

/**
 * Gets a point along a weighted line.
 * The points must be in ascending order.
 */
export function interpolateWeightedLine<T>(
	value: number,
	pointArray: WeightedLinePointExtra<T>[],
): WeightedLineInterpolationResult<T> {
	if (pointArray.length === 0) {
		throw new Error("Point array can't be empty!");
	}

	//Find the upper point
	const upperPointIndex = pointArray.findIndex(
		(point) => point.value > value,
	);

	//If we matched below the first point, clamp to first
	if (upperPointIndex === 0) {
		const index = 0;
		const exactPoint = pointArray[index];

		return {
			coordinates: exactPoint.coordinates,
			value: exactPoint.value,
			type: WeightedLineInterpolationResultType.Exact,
			index: index,
			extra: exactPoint.extra,
		};
	}
	//If we couldn't find any matches, clamp to last
	else if (upperPointIndex === -1) {
		const index = pointArray.length - 1;
		const exactPoint = pointArray[index];

		return {
			coordinates: exactPoint.coordinates,
			value: exactPoint.value,
			type: WeightedLineInterpolationResultType.Exact,
			index: index,
			extra: exactPoint.extra,
		};
	}

	//Interpolate between the points
	const upperPoint = pointArray[upperPointIndex];
	const lowerPointIndex = upperPointIndex - 1;
	const lowerPoint = pointArray[lowerPointIndex];

	const progress = inverseLerp(value, lowerPoint.value, upperPoint.value);
	const coordinates = lerpCoordinates(
		progress,
		lowerPoint.coordinates,
		upperPoint.coordinates,
	);

	return {
		coordinates: coordinates,
		value: value,
		type: WeightedLineInterpolationResultType.Interpolation,
		indexLower: lowerPointIndex,
		extraLower: lowerPoint.extra,
		indexUpper: upperPointIndex,
		extraUpper: upperPoint.extra,
		progress: progress,
	};
}

/**
 * Cuts a weighted line up to the end of an interpolation result
 */
export function cutWeightedLine<T>(
	pointArray: [number, number][],
	interpolationResult: WeightedLineInterpolationResult<T>,
): [number, number][] {
	if (
		interpolationResult.type === WeightedLineInterpolationResultType.Exact
	) {
		//Return a copy of the array up to the exact point
		return pointArray.slice(0, interpolationResult.index + 1);
	} else {
		//Return the original array up to the lower point, and insert
		//an interpolated point as the final point
		const pointLower = pointArray[interpolationResult.indexLower];
		const pointUpper = pointArray[interpolationResult.indexUpper];

		return [
			...pointArray.slice(0, interpolationResult.indexUpper),
			lerpCoordinates(
				interpolationResult.progress,
				pointLower,
				pointUpper,
			),
		];
	}
}

/**
 * Find angles in degree of one coordinates from another coordinates
 */
export function degsFromCoords(
	point0: [number, number],
	point1: [number, number],
) {
	const dy = point1[1] - point0[1];
	const dx = point1[0] - point0[0];
	return radToDeg(Math.atan2(dy, dx));
}

/**
 * Rotate bounds by degree
 */
export function rotateBounds(
	bounds: [number, number][],
	center: [number, number],
	deg: number,
): [number, number][] {
	const rad = degToRad(deg);
	const cos = Math.cos(rad);
	const sin = Math.sin(rad);
	return bounds.map((bound) => {
		const x =
			cos * (bound[0] - center[0]) +
			sin * (bound[1] - center[1]) +
			center[0];
		const y =
			cos * (bound[1] - center[1]) -
			sin * (bound[0] - center[0]) +
			center[1];
		return [x, y];
	});
}
