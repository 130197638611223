import React, { CSSProperties, useRef } from "react";
import ConnectionStatus from "../data/connection-status";
import { useDelayHover } from "../helper/hook-helper";
import { mapConnectionStatus } from "../helper/language-helper";
import BottomTooltip from "./bottom-tooltip";
import styles from "./status-indicator.module.scss";

const colors: { [key in ConnectionStatus]: string } = {
	active: "#9CE2BF",
	inactive: "#DA3E52",
	unpaired: "#E2E4E5",
	registered: "#FFD338",
};

//A simple active / inactive / unpaired status indicator component
export default function StatusIndicator(props: {
	status: ConnectionStatus;
	className?: string;
	viewBox?: string;
	style?: CSSProperties;
	size?: number;
}) {
	const { size, status, style, className, viewBox } = props;

	const color = colors[status];
	const [isHover, enableHover, disableHover] = useDelayHover();
	const ref = useRef<HTMLElement | null>(null);

	return (
		<>
			{isHover && (
				<BottomTooltip refPosition={ref.current!}>
					{mapConnectionStatus(status)}
				</BottomTooltip>
			)}
			<span ref={ref} className={styles.container}>
				<svg
					style={style}
					className={className}
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					width={size ?? 24}
					height={size ?? 24}
					viewBox={viewBox ?? "0 0 24 24"}
					onMouseEnter={enableHover}
					onMouseLeave={disableHover}
				>
					<circle
						cx="12"
						cy="12"
						r={size ?? 6}
						fill={color}
						opacity="0.6"
					/>
					<circle
						cx="12"
						cy="12"
						r={size ? (size / 3) * 2 : 4}
						fill={color}
					/>
				</svg>
			</span>
		</>
	);
}
