import React, {
	DependencyList,
	Dispatch,
	EffectCallback,
	SetStateAction,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { pushLIFOCapacityPure } from "./array-helper";

/**
 * Initializes a toggleable ON / OFF state with optional checks
 * @param initialState The initial state of this toggleable, defaults to false
 */
export function useToggleable(
	initialState?: boolean,
): [boolean, VoidFunction, VoidFunction] {
	const [isToggled, setToggled] = useState(initialState ?? false);
	const enableToggle = useCallback(() => setToggled(true), [setToggled]);
	const disableToggle = useCallback(() => setToggled(false), [setToggled]);
	return [isToggled, enableToggle, disableToggle];
}

/**
 * Initializes a toggleable ON / OFF state with optional checks
 * @param initialState The initial state of this toggleable, defaults to false
 * @param config Extra functions to validate state before allowing an enable or disable
 */
export function useToggleableChecks(
	initialState?: boolean,
	config?: { checkEnable?: () => boolean; checkDisable?: () => boolean },
) {
	const [isToggled, setToggled] = useState(initialState ?? false);

	const checkEnable = config?.checkEnable;
	const enableToggle = useCallback(
		() => (!checkEnable || checkEnable()) && setToggled(true),
		[setToggled, checkEnable],
	);

	const checkDisable = config?.checkDisable;
	const disableToggle = useCallback(
		() => (!checkDisable || checkDisable()) && setToggled(false),
		[setToggled, checkDisable],
	);
	return [isToggled, enableToggle, disableToggle];
}

/**
 * Initializes a numerical integer state that can be
 * incremented and decremented
 */
export function useCounter(
	initialValue: number = 0,
): [number, VoidFunction, VoidFunction] {
	const [counter, setCounter] = useState(initialValue);

	const increase = useCallback(() => {
		setCounter((value) => value + 1);
	}, [setCounter]);

	const decrease = useCallback(() => {
		setCounter((value) => value - 1);
	}, [setCounter]);

	return [counter, increase, decrease];
}

/**
 * Initializes a state hook that only activates after a delay
 * @param timeout The delay required after enabling to publish a state update
 */
export function useDelayHover(
	timeout = 1000,
): [boolean, VoidFunction, VoidFunction] {
	const [isHover, setHover] = useState(false);
	const tooltipActivationTimeout = useRef<undefined | any>(undefined);

	const enableHover = useCallback(() => {
		//Waiting a second before showing the tooltip
		if (!tooltipActivationTimeout.current) {
			tooltipActivationTimeout.current = setTimeout(
				() => setHover(true),
				timeout,
			);
		}
	}, [setHover, timeout]);

	const disableHover = useCallback(() => {
		//Clear the timeout and disable hover immediately
		if (tooltipActivationTimeout.current) {
			clearTimeout(tooltipActivationTimeout.current);
			tooltipActivationTimeout.current = undefined;
		}
		setHover(false);
	}, [setHover]);

	return [isHover, enableHover, disableHover];
}

/**
 * Initializes a state hook that syncs with a setting value
 * @param diskValue The value of the setting on disk
 * @param dirtyCheck A predicate to check if the value is dirty
 */
export function usePreference<T>(
	diskValue: T,
	dirtyCheck?: (current: T, original: T) => boolean,
): [T, Dispatch<SetStateAction<T>>, boolean] {
	const [currentValue, setCurrentValue] = useState(diskValue);
	const [originalValue, setOriginalValue] = useState(diskValue);

	//Update the values if the disk value changes
	useEffect(() => {
		setCurrentValue(diskValue);
		setOriginalValue(diskValue);
	}, [diskValue, setCurrentValue, setOriginalValue]);

	const isDirty = dirtyCheck
		? dirtyCheck(currentValue, originalValue)
		: currentValue !== originalValue;

	return [currentValue, setCurrentValue, isDirty];
}

/**
 * Initializes an effect that must have a delay of at least the specified value between its invocations
 * @param callback The callback to call
 * @param delay The minimum delay between invocations
 * @param runImmediately Whether to run the callback immediately on mount
 * @param deps Effect dependencies
 */
export function useDebouncedEffect(
	callback: EffectCallback,
	delay: number,
	runImmediately: boolean,
	deps: DependencyList,
) {
	const isFirstMount = useRef(false);
	useEffect(() => {
		if (runImmediately && !isFirstMount.current) {
			callback();
			isFirstMount.current = true;
		} else {
			let callbackDestructor: undefined | void | VoidFunction = undefined;
			const id = setTimeout(() => {
				callbackDestructor = callback();
			}, delay);

			return () => {
				clearTimeout(id);
				callbackDestructor?.();
			};
		}
	}, deps); //eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Initializes an effect that must have a delay of at least the specified value between its invocations,
 * and can be disabled with a flag
 * @param callback The callback to call
 * @param predicate Whether this effect is enabled. The callback is invoked immediately when this is true.
 * @param delay The minimum delay between invocations
 * @param deps Effect dependencies
 */
export function useDisableableDebouncedEffect(
	callback: EffectCallback,
	predicate: boolean,
	delay: number,
	deps: DependencyList,
) {
	const previouslyEnabled = useRef<boolean>(false);
	useEffect(() => {
		//Look up whether we should be enabled
		const enabled = predicate;

		//Load and store the previously enabled value
		const previouslyEnabledValue = previouslyEnabled.current;
		previouslyEnabled.current = enabled;

		if (enabled) {
			if (!previouslyEnabledValue) {
				//If we just became enabled, invoke the callback immediately
				return callback();
			} else {
				//Otherwise, we should debounce the callback
				let callbackDestructor: undefined | void | VoidFunction =
					undefined;
				const id = setTimeout(() => {
					callbackDestructor = callback();
				}, delay);

				return () => {
					clearTimeout(id);
					callbackDestructor?.();
				};
			}
		}
	}, deps); //eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Checks 2 arrays for equality by comparing each of their values
 * @param current The first array to compare
 * @param original The second array to compare
 */
export function dirtyCheckArray<T>(current: T[], original: T[]) {
	return (
		current.length !== original.length ||
		!current.every((value, index) => value === original[index])
	);
}

export function useUndoRedoState<S>(
	initialState: S | (() => S),
	capacity: number,
	saveFilterSource?: () => (state: S) => boolean,
): [
	S,
	Dispatch<SetStateAction<S>>,
	VoidFunction | undefined,
	VoidFunction | undefined,
] {
	const [state, setState] = useState<S>(initialState);
	const [statePast, setStatePast] = useState<S[]>([]);
	const [stateFuture, setStateFuture] = useState<S[]>([]);
	const saveFilter = useMemo(
		() => (saveFilterSource ? saveFilterSource() : undefined),
		[saveFilterSource],
	);

	const pushState = useCallback(
		(newState: SetStateAction<S>) => {
			//Save the past state
			if (saveFilter === undefined || saveFilter(state)) {
				setStatePast((statePast) =>
					pushLIFOCapacityPure(statePast, state, capacity),
				);
			}

			//Erase any future state
			setStateFuture([]);

			//Apply the new state
			setState(newState);
		},
		[state, setState, setStatePast, setStateFuture, saveFilter, capacity],
	);

	const undo = useCallback(() => {
		//Ignore if there is nothing to undo
		if (statePast.length === 0) return;

		//Move the current state to future state
		if (saveFilter === undefined || saveFilter(state)) {
			setStateFuture((stateFuture) =>
				pushLIFOCapacityPure(stateFuture, state, capacity),
			);
		}

		//Move the latest past state to current state
		const newStatePast = [...statePast];
		setState(newStatePast.pop()!);
		setStatePast(newStatePast);
	}, [
		state,
		setState,
		statePast,
		setStatePast,
		setStateFuture,
		saveFilter,
		capacity,
	]);

	const redo = useCallback(() => {
		//Ignore if there is nothing to redo
		if (stateFuture.length === 0) return;

		//Move the current state to past state
		if (saveFilter === undefined || saveFilter(state)) {
			setStatePast((statePast) =>
				pushLIFOCapacityPure(statePast, state, capacity),
			);
		}

		//Move the latest future state to current state
		const newStateFuture = [...stateFuture];
		setState(newStateFuture.pop()!);
		setStateFuture(newStateFuture);
	}, [
		state,
		setState,
		stateFuture,
		setStatePast,
		setStateFuture,
		saveFilter,
		capacity,
	]);

	return [
		state,
		pushState,
		statePast.length > 0 ? undo : undefined,
		stateFuture.length > 0 ? redo : undefined,
	];
}

/**
 * Runs an effect that can queue promises won't invoke callbacks once the effect is cleaned up
 */
export function useCancellableEffect(
	effect: (
		addPromise: <T>(promise: Promise<T>) => Promise<T>,
	) => void | VoidFunction,
	deps?: DependencyList,
) {
	useEffect(() => {
		let isCancelled = false;

		const cleanup = effect(<T>(promise: Promise<T>): Promise<T> => {
			return new Promise<T>((resolve, reject) => {
				promise
					.then((val) => !isCancelled && resolve(val))
					.catch((error) => !isCancelled && reject(error));
			});
		});

		return () => {
			isCancelled = true;
			cleanup?.();
		};
	}, deps); //eslint-disable-line react-hooks/exhaustive-deps
}

/**
 * Monitors and exposes the size of an HTML element
 */
export function useElementSize(
	elementRef: React.RefObject<HTMLElement>,
): [number, number] {
	const [size, setSize] = useState<[number, number]>([0, 0]);

	useEffect(() => {
		const element = elementRef.current;
		if (element === null) return;

		const resizeObserver = new ResizeObserver((entries) => {
			for (const entry of entries) {
				const { width, height } = entry.contentRect;
				setSize([width, height]);
			}
		});

		resizeObserver.observe(element);

		return () => {
			resizeObserver.unobserve(element);
		};
	}, [elementRef, setSize]);

	return size;
}
