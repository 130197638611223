import React from "react";
import styles from "./map-list-selector-option.module.scss";
import {
	MapListDisplayType,
	mapListDisplayTypeToIcon,
} from "./map-list-display-type";
import { mapMapListDisplayType } from "../../../../helper/language-helper";

export interface MapListSelectorOptionProps {
	option: MapListDisplayType;
	onClick?: () => void;
}

/**
 * A selectable option in the map list header dropdown
 */
export default function MapListSelectorOption(
	props: MapListSelectorOptionProps,
) {
	return (
		<button className={styles.button} onClick={props.onClick}>
			<div className={styles.iconWrapper}>
				{mapListDisplayTypeToIcon(props.option)(24, "currentColor")}
			</div>

			<span className={styles.label}>
				{mapMapListDisplayType(props.option)}
			</span>
		</button>
	);
}
