import PlaceableFloorImage from "../data/placeable-floor-image";
import { getDocument } from "pdfjs-dist";

/**
 * Converts a File object to a PlaceableFloorImage object.
 *
 * @param {File} file - The File object to convert.
 * @returns {Promise<PlaceableFloorImage>} A Promise that resolves to the converted PlaceableFloorImage object.
 * @throws {Error} If the file type is unsupported.
 */
export async function fileToPlaceableImage(
	file: File,
): Promise<PlaceableFloorImage> {
	if (file.type === "application/pdf") {
		//Parse the file as a PDF
		const arraybuffer = await file.arrayBuffer();
		const pdfDoc = await getDocument(arraybuffer).promise;
		const page = await pdfDoc.getPage(1);

		//Create the render target
		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d")!;

		//Set the canvas size
		const pageViewport = page.getViewport({ scale: 1 });
		canvas.width = pageViewport.width;
		canvas.height = pageViewport.height;

		//Render the PDF to the canvas
		await page.render({
			canvasContext: context,
			viewport: pageViewport,
		}).promise;

		//Convert the canvas to an image blob
		const blob = await new Promise<Blob>((resolve, reject) => {
			canvas.toBlob((blob) => {
				if (blob === null) {
					reject(new Error("Failed to convert canvas to blob"));
				} else {
					resolve(blob);
				}
			});
		});

		return {
			name: file.name,
			renderable: blob,
			dimensions: {
				width: pageViewport.width,
				height: pageViewport.height,
			},
		};
	} else if (file.type.startsWith("image/")) {
		//Read the file to a blob
		const arrayBuffer = await file.arrayBuffer();
		const blob = new Blob([new Uint8Array(arrayBuffer)]);

		//Get the image dimensions
		const imageURL = URL.createObjectURL(blob);

		const image = new Image();
		await new Promise((resolve, reject) => {
			image.onload = () => resolve(undefined);
			image.onerror = (event) => reject(event);
			image.src = imageURL;
		});
		const width = image.naturalWidth;
		const height = image.naturalHeight;
		URL.revokeObjectURL(imageURL);

		return {
			name: file.name,
			renderable: blob,
			dimensions: {
				width: width,
				height: height,
			},
		};
	} else {
		throw new Error(`Unsupported file type: ${file.type}`);
	}
}
