import React, { CSSProperties, useCallback, useState } from "react";
import IconSearch from "../dynamicicons/icon-search";
import stylesDivider from "./velavu-autocomplete-divider.module.scss";
import stylesSuggestion from "./velavu-autocomplete-suggestion.module.scss";
import styles from "./velavu-autocomplete.module.scss";

interface VelavuAutocompleteProps {
	containerProps?: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>;
	inputProps?: React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>;
	children?: React.ReactNode;
	contentOverride?: React.ReactNode;
	disableSuggestions?: boolean;
}

const VelavuAutocomplete = React.forwardRef<
	HTMLInputElement,
	VelavuAutocompleteProps
>((props, ref) => {
	const [isFocused, setFocused] = useState(false);
	const [isActive, setActive] = useState(false);

	const handleBlur = useCallback(() => {
		if (isActive) return;
		setFocused(false);
	}, [isActive, setFocused]);

	return (
		<div
			className={`${styles.wrapper} ${
				props.containerProps?.className ?? ""
			}`}
			style={props.containerProps?.style}
			{...props.containerProps}
		>
			<div
				className={`${styles.container} ${
					isFocused && !props.disableSuggestions ? styles.focus : ""
				}`}
				onMouseDown={() => setActive(true)}
				onMouseUp={() => setActive(false)}
				onMouseOut={() => setActive(false)}
				onFocus={() => setFocused(true)}
				onBlur={handleBlur}
			>
				<div className={styles.search}>
					{isFocused && <IconSearch color="#00255D" size={16} />}
					<div style={{ width: 10 }} />
					{props.contentOverride ? (
						props.contentOverride
					) : (
						<input ref={ref} {...props.inputProps} />
					)}
					<div style={{ width: 10 }} />
				</div>

				{isFocused && !props.disableSuggestions && props.children}
			</div>
		</div>
	);
});
export default VelavuAutocomplete;

export function AutocompleteDivider(props: {
	style?: CSSProperties;
	className?: string;
}) {
	return (
		<hr
			style={props.style}
			className={
				(props.className ? props.className + " " : "") +
				stylesDivider.divider
			}
		/>
	);
}

export function AutocompleteEntry(props: {
	icon: React.ReactNode;
	children?: React.ReactNode;
	hint?: string;
	onClick?: () => void;
}) {
	return (
		<div className={stylesSuggestion.container} onClick={props.onClick}>
			{props.icon}
			<div className={stylesSuggestion.iconSpacer} />
			{props.children}
			{props.hint && (
				<>
					<div className={stylesSuggestion.hintSpacer} />
					<span className={stylesSuggestion.hint}>{props.hint}</span>
				</>
			)}
		</div>
	);
}
