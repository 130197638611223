import React, { CSSProperties } from "react";

export default function IconGeofenceExited(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 18}
			height={props.size ?? 18}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5 7.73244C4.4022 7.38663 4 6.74028 4 6C4 4.89543 4.89543 4 6 4C6.74028 4 7.38663 4.4022 7.73244 5L11 5C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7L7.73244 7C7.5568 7.30363 7.30363 7.5568 7 7.73244V16.2676C7.30363 16.4432 7.5568 16.6964 7.73244 17L16.2676 17C16.4432 16.6964 16.6964 16.4432 17 16.2676V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V16.2676C19.5978 16.6134 20 17.2597 20 18C20 19.1046 19.1046 20 18 20C17.2597 20 16.6134 19.5978 16.2676 19L7.73244 19C7.38663 19.5978 6.74028 20 6 20C4.89543 20 4 19.1046 4 18C4 17.2597 4.4022 16.6134 5 16.2676L5 7.73244Z"
				fill={props.color ?? "#00255D"}
			/>
			<path
				d="M9.82843 12.6066C9.4379 12.9971 9.4379 13.6303 9.82843 14.0208C10.219 14.4113 10.8521 14.4113 11.2426 14.0208L16.6172 8.64626L18.7385 10.7676L19.3076 5.64597C19.3784 5.00906 18.8402 4.47089 18.2033 4.54166L13.0817 5.11073L15.203 7.23204L9.82843 12.6066Z"
				fill={props.color ?? "#00255D"}
			/>
		</svg>
	);
}
