import React, { CSSProperties } from "react";

export default function IconCircleDeleteFilled(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 16 16"
			fill="none"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 16C3.59 16 0 12.41 0 8C0 3.59 3.59 0 8 0C12.41 0 16 3.59 16 8C16 12.41 12.41 16 8 16ZM10.295 4.29503L8 6.59003L5.705 4.29503C5.31564 3.90567 4.68436 3.90567 4.295 4.29503C3.90564 4.68439 3.90564 5.31567 4.295 5.70503L6.59 8.00003L4.295 10.295C3.90564 10.6844 3.90564 11.3157 4.295 11.705C4.68436 12.0944 5.31564 12.0944 5.705 11.705L8 9.41003L10.295 11.705C10.6844 12.0944 11.3156 12.0944 11.705 11.705C12.0944 11.3157 12.0944 10.6844 11.705 10.295L9.41 8.00003L11.705 5.70503C12.0944 5.31567 12.0944 4.68439 11.705 4.29503C11.3156 3.90567 10.6844 3.90567 10.295 4.29503Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
