import React, { CSSProperties, useCallback, useEffect, useState } from "react";
import { classArr } from "../helper/style-helper";
import styles from "./velavu-input.module.scss";

interface ReactProps {
	className?: string;
	value?: string;
	titleText?: string;
	placeholder?: string;
	helperText?: string;
	errorText?: string;
	autoComplete?: string;
	noAutofill?: boolean;

	italicPlaceholder?: boolean;
	fullWidth?: boolean;
	autoFocus?: boolean;
	round?: boolean;
	disabled?: boolean;
	error?: boolean;
	bottomPadding?: boolean;

	maxLength?: number;
	height?: number;

	style?: CSSProperties;

	leadingIcon?: React.ReactNode;
	trailingIcon?: React.ReactNode;

	onChange?: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
}

interface InputProps extends ReactProps {
	inputProps?: React.DetailedHTMLProps<
		React.InputHTMLAttributes<HTMLInputElement>,
		HTMLInputElement
	>;
	multiline?: false | undefined;
	inputRef?: React.RefObject<HTMLInputElement>;
	type?: "text" | "password" | "search";
}

interface TextareaProps extends ReactProps {
	inputProps?: React.DetailedHTMLProps<
		React.TextareaHTMLAttributes<HTMLTextAreaElement>,
		HTMLTextAreaElement
	>;
	multiline: true;
	inputRef?: React.RefObject<HTMLTextAreaElement>;
	rows?: number;
}

//A reusable flexible input field that conforms to Velavu's design language
export default function VelavuInput(props: InputProps | TextareaProps) {
	const [isFocused, setFocused] = useState(false);

	const {
		disabled: propsDisabled,
		onFocus: propsOnFocus,
		onBlur: propsOnBlur,
	} = props;

	const enableFocus = useCallback(() => {
		if (!propsDisabled) setFocused(true);
		if (propsOnFocus) propsOnFocus();
	}, [propsDisabled, setFocused, propsOnFocus]);
	const disableFocus = useCallback(() => {
		setFocused(false);
		if (propsOnBlur) propsOnBlur();
	}, [setFocused, propsOnBlur]);

	useEffect(() => {
		//If we're disabled, disable focus
		if (props.disabled) setFocused(false);
	}, [props.disabled, setFocused]);

	return (
		<div
			style={props.style}
			className={[
				styles.container,
				props.className,
				props.bottomPadding ? styles.bottomPadding : undefined,
			]
				.filter((value) => value)
				.join(" ")}
		>
			{props.titleText && (
				<span className={styles.titleText}>{props.titleText}</span>
			)}

			<div
				className={[
					styles.content,
					props.leadingIcon
						? styles.contentLeadingIcon
						: styles.contentLeadingText,
					props.trailingIcon
						? styles.contentTrailingIcon
						: styles.contentTrailingText,
					isFocused ? styles.focus : undefined,
					props.round
						? styles.containerCircular
						: styles.containerRound,
					props.height ? styles.contentNoVerticalPadding : undefined,
					props.fullWidth ? styles.fullWidth : undefined,
					props.error ? styles.borderError : styles.borderNormal,
					props.disabled ? styles.inputDisabled : styles.inputEnabled,
				]
					.filter((value) => value)
					.join(" ")}
				onFocus={enableFocus}
				onBlur={disableFocus}
				style={{ height: props.height }}
			>
				{props.leadingIcon && (
					<div className={styles.leadingIcon}>
						{props.leadingIcon}
					</div>
				)}
				{props.multiline ? (
					<textarea
						ref={props.inputRef}
						{...props.inputProps}
						className={styles.textArea}
						value={props.value}
						onChange={(event) =>
							props.onChange && props.onChange(event.target.value)
						}
						data-lpignore={props.noAutofill ? "true" : undefined}
						autoFocus={props.autoFocus}
						placeholder={props.placeholder}
						rows={props.rows}
						autoComplete={props.autoComplete}
						disabled={props.disabled}
					/>
				) : (
					<input
						ref={props.inputRef}
						{...props.inputProps}
						className={classArr(
							styles.input,
							props.italicPlaceholder && styles.italicPlaceholder,
						)}
						value={props.value}
						onChange={(event) =>
							props.onChange && props.onChange(event.target.value)
						}
						data-lpignore={props.noAutofill ? "true" : undefined}
						autoFocus={props.autoFocus}
						placeholder={props.placeholder}
						type={props.type}
						autoComplete={props.autoComplete}
						disabled={props.disabled}
						maxLength={props.maxLength}
						style={{ height: props.height }}
					/>
				)}
				{props.trailingIcon && (
					<div className={styles.trailingIcon}>
						{props.trailingIcon}
					</div>
				)}
			</div>

			{(!props.error || !props.errorText) && props.helperText && (
				<span className={`${styles.bottomText} ${styles.helperText}`}>
					{props.helperText}
				</span>
			)}
			{props.error && props.errorText && (
				<span className={`${styles.bottomText} ${styles.errorText}`}>
					{props.errorText}
				</span>
			)}
		</div>
	);
}
