import React, { useCallback, useMemo, useState } from "react";
import VelavuAssetDevicePair from "src/data/asset-device-pair";
import {
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	VelavuGeofence,
} from "velavu-js-api";
import IconClose from "../../../../dynamicicons/icon-close";
import IconDelete from "../../../../dynamicicons/icon-delete";
import IconEdit from "../../../../dynamicicons/icon-edit";
import IconFilterList from "../../../../dynamicicons/icon-filter-list";
import IconSearch from "../../../../dynamicicons/icon-search";
import Divider from "../../../../elements/divider";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import VelavuInput from "../../../../elements/velavu-input";
import VelavuMenu, {
	VelavuMenuItem,
	VelavuOverflowButton,
} from "../../../../elements/velavu-menu";
import {
	VelavuModalPrompt,
	useVelavuModal,
} from "../../../../elements/velavu-modal";
import { useGeocoder } from "../../../../helper/api-helper";
import useAssetFiltering from "../../../../helper/assets-filter-hook";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import AssetListFilter from "../asset-list/asset-list-filter";
import AssetListItem from "../asset-list/asset-listitem";
import PinnedList from "../pinned-list";
import styles from "./geofence-detail.module.scss";

export interface GeofenceDetailProps {
	geofence: VelavuGeofence;
	onBack?: () => void;
	onClose?: () => void;

	onEdit?: () => void;
	onDelete?: () => void;

	onSelectAsset?: (assetID: string) => void;
	onHoverAsset?: (asset: VelavuAsset | undefined) => void;
}

export default function GeofenceDetail(props: GeofenceDetailProps) {
	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const openMenu = useCallback(
		(event: React.MouseEvent<HTMLElement>) =>
			setMenuAnchor(event.currentTarget),
		[setMenuAnchor],
	);
	const closeMenu = useCallback(() => setMenuAnchor(null), [setMenuAnchor]);

	const pushModal = useVelavuModal();
	const propsGeofence = props.geofence;
	const propsOnEdit = props.onEdit;
	const handleEditGeofence = useCallback(() => {
		closeMenu();
		if (propsOnEdit !== undefined) propsOnEdit();
	}, [closeMenu, propsOnEdit]);
	const propsOnDelete = props.onDelete;
	const handleDeleteGeofence = useCallback(() => {
		closeMenu();
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={"Delete the following geofence?"}
				object={propsGeofence.name}
				labelConfirm="Delete"
				confirmDanger
				onSelect={resolve}
			>
				The site and all associated data will be permanently deleted.
			</VelavuModalPrompt>
		)).then((result) => {
			if (result) {
				VelavuAPI.geofences
					.deleteExistingGeofence(propsGeofence.id)
					.catch(() =>
						alert(`Failed to delete geofence ${propsGeofence.id}`),
					);
				if (propsOnDelete !== undefined) propsOnDelete();
			}
		});
	}, [closeMenu, pushModal, propsGeofence, propsOnDelete]);

	const address = useGeocoder(propsGeofence.center);

	const [geofenceDevices, setGeofenceDevices] = useState<
		undefined | VelavuDevice[]
	>(undefined);
	useCancellableEffect(
		(addPromise) => {
			addPromise(
				VelavuAPI.devices.getAllDevices({
					geofenceID: propsGeofence.id,
					paired: true,
				}),
			).then((result) => setGeofenceDevices(result.data));
		},
		[propsGeofence.id],
	);

	const geofenceAssetDevicePairs = useMemo(() => {
		return geofenceDevices
			?.map((device) => {
				return { asset: device.asset, device };
			})
			.filter((it) => it.asset !== undefined) as VelavuAssetDevicePair[];
	}, [geofenceDevices]);

	const {
		assets: filteredAssets,
		filterCategory,
		filterStatus,
		isFiltering,
		searchText,
		setFilterCategory,
		setFilterStatus,
		setSearchText,
		showFilterMenu,
		toggleFilterMenu,
	} = useAssetFiltering(geofenceAssetDevicePairs);

	const {
		onSelectAsset: propsOnSelectAsset,
		onHoverAsset: propsOnHoverAsset,
	} = props;

	const filterColor = isFiltering ? "#3A58E2" : "#5F718C";

	return (
		<React.Fragment>
			<VelavuMenu
				open={!!menuAnchor}
				onClose={closeMenu}
				refPosition={menuAnchor}
			>
				<VelavuMenuItem
					icon={(size, color) => (
						<IconEdit size={size} color={color} />
					)}
					label="Edit geofence"
					onClick={handleEditGeofence}
				/>
				<VelavuMenuItem
					icon={(size, color) => (
						<IconDelete size={size} color={color} />
					)}
					label="Delete geofence"
					onClick={handleDeleteGeofence}
					danger
				/>
			</VelavuMenu>

			<PinnedList>
				<DropdownPanelToolbar
					onBack={props.onBack}
					onClose={props.onClose}
				/>

				<div className={styles.titleHeader}>
					<div className={styles.titleText}>
						<span className={styles.titleHeaderTitle}>
							{propsGeofence.name}
						</span>
						<span className={styles.titleHeaderSubtitle}>
							{address}
						</span>
					</div>

					<VelavuOverflowButton
						onClick={openMenu}
						open={!!menuAnchor}
					/>
				</div>

				<div className={styles.assetCountCard}>
					{geofenceAssetDevicePairs === undefined ? (
						<span>Loading devices...</span>
					) : (
						<React.Fragment>
							<span className={styles.assetCountCardNumber}>
								{geofenceAssetDevicePairs.length}
							</span>
							<span className={styles.assetCountCardLabel}>
								Assets in geofence
							</span>
						</React.Fragment>
					)}
				</div>

				<div className={styles.filterBar}>
					<VelavuInput
						className={styles.search}
						leadingIcon={<IconSearch size={16} color="#00255D" />}
						placeholder="Search assets"
						value={searchText}
						onChange={setSearchText}
						fullWidth
					/>

					<VelavuIconButton
						highlight={isFiltering}
						onClick={toggleFilterMenu}
					>
						{showFilterMenu ? (
							<IconClose color={filterColor} />
						) : (
							<IconFilterList color={filterColor} />
						)}
					</VelavuIconButton>
				</div>

				<Divider />

				{showFilterMenu && (
					<>
						<AssetListFilter
							filterCategory={filterCategory}
							selectCategory={setFilterCategory}
							filterStatus={filterStatus}
							selectStatus={setFilterStatus}
						/>
						<Divider />
					</>
				)}

				<div className={styles.list}>
					{(filteredAssets ?? []).map((asset) => (
						<AssetListItem
							key={asset.id}
							className={styles.item}
							asset={asset}
							onClick={() => propsOnSelectAsset?.(asset.id)}
							onMouseEnter={() => propsOnHoverAsset?.(asset)}
							onMouseLeave={() => propsOnHoverAsset?.(undefined)}
						/>
					))}
				</div>
			</PinnedList>
		</React.Fragment>
	);
}
