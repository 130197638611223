import React, { Dispatch, SetStateAction } from "react";
import { AssetCategory } from "velavu-js-api";
import AssetCategorization, {
	mapCategoryName,
	serializeAssetCategorization,
} from "../data/asset-categorization";
import IconDelete from "../dynamicicons/icon-delete";
import IconUnlink from "../dynamicicons/icon-unlink";
import styles from "./asset-filter-menu.module.scss";
import ChipFilterDivider from "./chip-filter-divider";
import ChipFilterMenu from "./chip-filter-menu";
import VelavuButton from "./velavu-button";
import VelavuSwitch from "./velavu-switch";
import ConnectionStatus from "../data/connection-status";
import { mapConnectionStatus } from "../helper/language-helper";
import StatusIndicator from "./status-indicator";

export default function AssetFilterMenu(props: {
	filterCategory: AssetCategory | null;
	setFilterCategory: Dispatch<SetStateAction<AssetCategory | null>>;
	categorizations: AssetCategorization[];
	filterCategorization: AssetCategorization | null;
	setFilterCategorization: Dispatch<
		SetStateAction<AssetCategorization | null>
	>;
	filterStatus: ConnectionStatus | null;
	setFilterStatus: Dispatch<SetStateAction<ConnectionStatus | null>>;
	filterUnpaired: boolean;
	setFilterUnpaired: Dispatch<SetStateAction<boolean>>;
	selectedAssets: string[];
	isDirty: boolean;
	onApply: VoidFunction;
	onUnpair: VoidFunction;
	onDelete: VoidFunction;
}) {
	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				{props.selectedAssets.length > 0 ? (
					<>
						<div className={styles.selectedAssets}>
							<label>Selected: </label>
							<div className={styles.selectedAssetsTally}>
								<label>{props.selectedAssets.length}</label>
							</div>
						</div>
						<ChipFilterDivider />
						<div className={styles.actionButtons}>
							<button
								className={styles.unpairButton}
								onClick={props.onUnpair}
							>
								<IconUnlink color="#00255D" size={16} />
								<span className={styles.buttonText}>
									Unpair
								</span>
							</button>
							<button
								className={styles.deleteButton}
								onClick={props.onDelete}
							>
								<IconDelete color="#da3e52" size={16} />
								<span>Delete</span>
							</button>
						</div>
					</>
				) : (
					<>
						<ChipFilterMenu
							title="Category"
							options={[null, ...Object.values(AssetCategory)]}
							selected={props.filterCategory}
							setSelected={props.setFilterCategory}
							label={(category) =>
								category === null
									? "All"
									: mapCategoryName(category)
							}
							category={(category) => category ?? undefined}
						/>
						<ChipFilterDivider />
						<ChipFilterMenu
							title="Group"
							options={[null, ...props.categorizations]}
							selected={props.filterCategorization}
							setSelected={props.setFilterCategorization}
							label={(item) =>
								item === null ? "All" : item.group
							}
							category={(item) =>
								item === null ? undefined : item.category
							}
							useDropdown
							keyExtractor={(item) =>
								item === null
									? null
									: serializeAssetCategorization(item)
							}
						/>
						<ChipFilterDivider />
						<ChipFilterMenu
							title={"Status"}
							options={[null, "active", "inactive", "registered"]}
							selected={props.filterStatus}
							setSelected={props.setFilterStatus}
							label={(status) =>
								status === null
									? "All"
									: mapConnectionStatus(status)
							}
							useDropdown
							dropdownMode="column"
							renderDropdownItem={(status) => (
								<div className={styles.assetFilterItem}>
									<StatusIndicator
										status={status!}
										size={12}
									/>
									<span style={{ marginLeft: 8 }}>
										{mapConnectionStatus(status!)}
									</span>
								</div>
							)}
						/>
						<ChipFilterDivider />
						<VelavuSwitch
							className={styles.assetPairedSwitch}
							label="Unpaired"
							toggled={props.filterUnpaired}
							onChange={() =>
								props.setFilterUnpaired(!props.filterUnpaired)
							}
						/>
						{props.isDirty && (
							<VelavuButton
								outlined
								label="Apply"
								onClick={props.onApply}
								className={styles.applyFiltersButton}
							/>
						)}
					</>
				)}
			</div>
		</div>
	);
}
