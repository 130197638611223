import React, { useCallback, useState } from "react";
import { DeviceCategory, VelavuAPI, VelavuDevice } from "velavu-js-api";
import { VelavuTitledModal } from "../../../elements/velavu-modal";
import ModalRegisterTagDetails from "./modal-register-tag-details";
import ModalRegisterTagID from "./modal-register-tag-id";
import styles from "./modal-register-tag.module.scss";

export default function ModalRegisterTag(props: {
	onClose: VoidFunction;
	onRegister: (tag: VelavuDevice) => void;
}) {
	const [idInput, setIDInput] = useState("");
	const [tagDetails, setTagDetails] = useState<VelavuDevice>();
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const propsOnRegister = props.onRegister;
	const registerTag = useCallback(
		(tagID: string) => {
			//Updating the state
			setLoading(true);

			VelavuAPI.devices
				.getSpecificDevice(idInput)
				.catch((err) => {
					// if (err?.response?.status === 404) {
					// 	setError("");
					// } else {
					// 	setError("An error occured");
					// }
				})
				.then((resp) => {
					// If the request succeeded, then a registered device
					// already exists
					if (resp) {
						setError("Already registered");
						throw "Already registered";
					}
					// Submitting the device ID
					return VelavuAPI.devices.registerNewDevice({
						category: DeviceCategory.Tag,
						id: tagID,
					});
				})
				.then((tag: VelavuDevice) => {
					propsOnRegister(tag);

					setTagDetails(tag);
					setError("");
				})
				.catch((err) => {
					if (!error)
						setError("The provided code couldn't be registered.");
				})
				.finally(() => {
					setLoading(false);
				});
		},
		[error, propsOnRegister, idInput],
	);

	const resetRegistration = useCallback(() => {
		//Clear the ID input and tag details
		setIDInput("");
		setTagDetails(undefined);
	}, []);

	return (
		<VelavuTitledModal title="Register tag" onClose={props.onClose}>
			<div className={styles.container}>
				<div
					style={{
						transform: tagDetails
							? "translateX(-100%)"
							: "translateX(0)",
					}}
					className={styles.contentContainer}
				>
					<div className={styles.contentSlide}>
						<ModalRegisterTagID
							id={idInput}
							setID={setIDInput}
							onSubmit={registerTag}
							loading={isLoading}
							error={error}
						/>
					</div>
					<div className={styles.contentSlide}>
						{tagDetails && (
							<ModalRegisterTagDetails
								tag={tagDetails}
								onRepeat={resetRegistration}
								onClose={props.onClose}
							/>
						)}
					</div>
				</div>
			</div>
		</VelavuTitledModal>
	);
}
