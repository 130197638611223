import React, { useState } from "react";
import styles from "./geofence-list.module.scss";
import { VelavuGeofence } from "velavu-js-api";
import VelavuInput from "../../../../elements/velavu-input";
import IconSearch from "../../../../dynamicicons/icon-search";
import VelavuButton from "../../../../elements/velavu-button";
import IconAdd from "../../../../dynamicicons/icon-add";
import Divider from "../../../../elements/divider";
import GeofenceListItem from "./geofence-list-item";

interface GeofenceListProps {
	geofences: VelavuGeofence[];
	onSelectGeofence: (id: string) => void;
	onCreateGeofence: () => void;
}

export default function GeofenceList(props: GeofenceListProps) {
	const [searchText, setSearchText] = useState("");

	return (
		<React.Fragment>
			<div className={styles.header}>
				<VelavuInput
					className={styles.search}
					leadingIcon={<IconSearch size={16} color="#00255D" />}
					placeholder="Search"
					value={searchText}
					onChange={setSearchText}
					fullWidth
				/>

				<VelavuButton
					icon={(size, color, className) => (
						<IconAdd
							size={size}
							color={color}
							className={className}
						/>
					)}
					label="New geofence"
					onClick={props.onCreateGeofence}
				/>
			</div>

			<Divider />

			<div className={styles.list}>
				{props.geofences.map((geofence) => (
					<GeofenceListItem
						key={geofence.id}
						geofence={geofence}
						onClick={() => props.onSelectGeofence(geofence.id)}
					/>
				))}
			</div>
		</React.Fragment>
	);
}
