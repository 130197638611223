import { useCallback, useMemo, useState } from "react";
import VelavuAssetDevicePair from "src/data/asset-device-pair";
import { AssetCategory, VelavuAsset } from "velavu-js-api";
import FloorFilter from "../data/floor-filter";
import { getLocationFloorID } from "./floor-helper";
import { compoundSearchAssets } from "./search-helper";

export interface AssetFilteringState<T> {
	assets: T;

	searchText: string;
	showFilterMenu: boolean;
	filterCategory: AssetCategory | undefined;
	filterStatus: boolean | undefined;
	isFiltering: boolean;
	floorFilter: FloorFilter;

	setSearchText: (text: string) => void;
	toggleFilterMenu: () => void;
	setFilterCategory: (category: AssetCategory | undefined) => void;
	setFilterStatus: (status: boolean | undefined) => void;
	setFloorFilter: (filter: FloorFilter) => void;
}

export default function useAssetFiltering(
	assetDevicePairs: VelavuAssetDevicePair[] | undefined,
	activeFloorID?: string,
): AssetFilteringState<VelavuAsset[]>;
export default function useAssetFiltering(
	assetDevicePairs: VelavuAssetDevicePair[] | undefined,
	activeFloorID?: string,
): AssetFilteringState<VelavuAsset[] | undefined>;
export default function useAssetFiltering(
	assetDevicePairs: VelavuAssetDevicePair[] | undefined,
	activeFloorID?: string,
): AssetFilteringState<VelavuAsset[] | undefined> {
	const [searchText, setSearchText] = useState("");

	const [floorFilter, setFloorFilter] = useState(FloorFilter.All);

	const [showFilterMenu, setShowFilterMenu] = useState(false);
	const toggleFilterMenu = useCallback(
		() => setShowFilterMenu((it) => !it),
		[setShowFilterMenu],
	);
	const [filterCategory, setFilterCategory] = useState<
		AssetCategory | undefined
	>(undefined);
	const [filterStatus, setFilterStatus] = useState<boolean | undefined>(
		undefined,
	);

	const filteredAssets = useMemo(() => {
		if (assetDevicePairs === undefined) {
			return undefined;
		}

		let copy = [
			...assetDevicePairs.map((it) => {
				return {
					...it.asset,
					device_id: it.device?.id,
					online: it.device?.online,
				};
			}),
		];

		//Filtering the assets by category
		if (filterCategory !== undefined) {
			copy = copy.filter((asset) => asset.category === filterCategory);
		}

		//Filtering the assets by status
		if (filterStatus !== undefined) {
			copy = copy.filter((asset) => asset.online === filterStatus);
		}

		//Filtering the assets by search query
		copy = compoundSearchAssets(copy, searchText);

		//Filtering the assets by floor
		if (floorFilter === FloorFilter.Current) {
			const deviceFloorIDs = assetDevicePairs
				.map((it) => it.device?.location)
				.map(getLocationFloorID);
			copy = copy.filter((asset) => {
				const matchingAssetDevicePair = assetDevicePairs.find(
					(it) => it.asset.id === asset.id,
				);
				const assetFloorID = getLocationFloorID(
					matchingAssetDevicePair?.device?.location,
				);
				return deviceFloorIDs.includes(assetFloorID);
			});
		}

		return copy;
	}, [
		assetDevicePairs,
		filterCategory,
		filterStatus,
		searchText,
		floorFilter,
	]);

	return useMemo(
		() => ({
			assets: filteredAssets,

			searchText,
			showFilterMenu,
			filterCategory,
			filterStatus,
			isFiltering:
				filterCategory !== undefined || filterStatus !== undefined,
			floorFilter,

			setSearchText,
			toggleFilterMenu,
			setFilterCategory,
			setFilterStatus,
			setFloorFilter,
		}),
		[
			filteredAssets,

			searchText,
			showFilterMenu,
			filterCategory,
			filterStatus,
			floorFilter,

			setSearchText,
			toggleFilterMenu,
			setFilterCategory,
			setFilterStatus,
			setFloorFilter,
		],
	);
}
