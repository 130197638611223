import {
	defaultFloorCreateState,
	FloorCreateInteractiveState,
	floorCreateStageToInteractiveState,
	FloorCreateState,
	FloorCreateStep,
} from "../components/dashboard/dropdown/floor-create/floor-create";
import FloorDrawToolState, {
	defaultFloorDrawToolState,
} from "../data/floor-draw-tool-state";
import React, { useCallback, useMemo, useState } from "react";
import { VelavuSite, VelavuFloor } from "velavu-js-api";
import PlaceImageState, {
	defaultPlaceImageState,
} from "../data/place-image-state";
import PlaceableFloorImage from "../data/placeable-floor-image";
import { getBase64ImageSize } from "./image-helper";

export interface FloorEditState {
	isOpen: boolean;
	editFloorID: string | null;
	createState: FloorCreateState;
	createStep: FloorCreateStep;
	createInteractiveState: FloorCreateInteractiveState | null;
	imagePlaceState: PlaceImageState;
	imageCoordinatesOverride:
		| [
				[number, number],
				[number, number],
				[number, number],
				[number, number],
		  ]
		| null;
	drawToolState: FloorDrawToolState;
	drawWalls: [number, number][][];
}

export interface FloorEditActions {
	open(site: VelavuSite): void;
	edit(floor: VelavuFloor): void;
	close(): void;
	setCreateState: React.Dispatch<React.SetStateAction<FloorCreateState>>;
	setCreateStep: React.Dispatch<React.SetStateAction<FloorCreateStep>>;
	setImagePlaceState: React.Dispatch<React.SetStateAction<PlaceImageState>>;
	setDrawToolState: React.Dispatch<React.SetStateAction<FloorDrawToolState>>;
	setDrawWalls: React.Dispatch<React.SetStateAction<[number, number][][]>>;
}

export default function useFloorEditState(): [
	FloorEditState,
	FloorEditActions,
] {
	//Floor editor
	const [isOpen, setOpen] = useState(false);
	const [editFloorID, setEditFloorID] = useState<string | null>(null);
	const [floorCreateState, setFloorCreateState] = useState<FloorCreateState>(
		defaultFloorCreateState,
	);
	const [floorCreateStep, setFloorCreateStep] = useState<FloorCreateStep>(
		FloorCreateStep.Intro,
	);

	const floorCreateInteractiveState = useMemo(() => {
		return floorCreateStageToInteractiveState(floorCreateStep);
	}, [floorCreateStep]);

	const [floorImagePlaceState, setFloorImagePlaceState] =
		useState<PlaceImageState>(defaultPlaceImageState);
	const [imageCoordinatesOverride, setImageCoordinatesOverride] = useState<
		| [
				[number, number],
				[number, number],
				[number, number],
				[number, number],
		  ]
		| null
	>(null);
	const [floorDrawState, setFloorDrawState] = useState<FloorDrawToolState>(
		defaultFloorDrawToolState,
	);

	const [floorDrawWalls, setFloorDrawWalls] = useState<[number, number][][]>(
		[],
	);

	const reset = useCallback(() => {
		setOpen(false);
		setEditFloorID(null);
		setFloorCreateState(defaultFloorCreateState);
		setFloorCreateStep(FloorCreateStep.Intro);
		setFloorImagePlaceState(defaultPlaceImageState);
		setImageCoordinatesOverride(null);
		setFloorDrawState(defaultFloorDrawToolState);
		setFloorDrawWalls([]);
	}, []);

	const open = useCallback((site: VelavuSite) => {
		setFloorImagePlaceState({
			center: site.coordinates,
			scale: 1,
			rotation: 0,
		});
		setOpen(true);
	}, []);

	const editFloor = useCallback(async (floor: VelavuFloor) => {
		setEditFloorID(floor.id);

		let floorImage: PlaceableFloorImage | null = null;
		if (floor.image && floor.image_coordinates) {
			const imageSize = await getBase64ImageSize(floor.image);

			floorImage = {
				name: floor.floorplan_data?.file_name ?? "Image",
				dimensions: {
					width: imageSize.width,
					height: imageSize.height,
				},
				renderable: floor.image,
			};
			setImageCoordinatesOverride(floor.image_coordinates!);
		}
		setFloorCreateState({
			floorName: floor.name,
			floorNum: floor.level,
			floorImage: floorImage,
		});

		const walls = (floor.floorplan_data?.lines ?? []).map((line) => {
			return line.map((coord): [number, number] => [coord.x, coord.y]);
		});
		setFloorDrawWalls(walls);
		setFloorCreateStep(FloorCreateStep.Draw);
		setOpen(true);
	}, []);

	const state: FloorEditState = {
		isOpen: isOpen,
		editFloorID: editFloorID,
		createState: floorCreateState,
		createStep: floorCreateStep,
		createInteractiveState: floorCreateInteractiveState,
		imagePlaceState: floorImagePlaceState,
		imageCoordinatesOverride: imageCoordinatesOverride,
		drawToolState: floorDrawState,
		drawWalls: floorDrawWalls,
	};

	const actions: FloorEditActions = {
		open: open,
		edit: editFloor,
		close: reset,
		setCreateState: setFloorCreateState,
		setCreateStep: setFloorCreateStep,
		setImagePlaceState: setFloorImagePlaceState,
		setDrawToolState: setFloorDrawState,
		setDrawWalls: setFloorDrawWalls,
	};

	return [state, actions];
}
