// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eDJ_SsXGwSniciffeVor{width:1px;height:24px;background-color:#d9d9d9}`, "",{"version":3,"sources":["webpack://./src/elements/chip-filter-divider.module.scss"],"names":[],"mappings":"AAAA,sBACC,SAAA,CACA,WAAA,CACA,wBAAA","sourcesContent":[".divider {\n\twidth: 1px;\n\theight: 24px;\n\tbackground-color: #d9d9d9;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider": `eDJ_SsXGwSniciffeVor`
};
export default ___CSS_LOADER_EXPORT___;
