import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Route } from "react-router";
import { VelavuAPI, VelavuUser, VelavuAlert } from "velavu-js-api";
import { useVelavuModal, VelavuTitledModal } from "../../elements/velavu-modal";
import { useCancellableEffect } from "../../helper/hook-helper";
import SimpleObservable from "../../helper/simple-observable";
import * as storageHelper from "../../helper/storage-helper";
import SignInContext from "../../sign-in-context";
import Alerts from "../alerts/alerts";
import Analytics from "../analytics/analytics";
import Assets from "../assets/assets";
import AssetNew from "../assets/create/asset-new";
import AssetNewConfirm from "../assets/create/asset-new-confirm";
import AssetNewTag from "../assets/create/asset-new-tag";
import Dashboard from "../dashboard/dashboard";
import Page from "../page/page";
import SettingsModal, { SettingsTab } from "../settings/settings-modal";
import Sidebar from "../sidebar/sidebar";
import Devices from "../devices/devices";
import styles from "./content.module.scss";
import { MQTTContext, MQTTListener } from "./mqtt-provider";
import OnboardingDialog from "../onboarding/onboarding-dialog";

export default function Content() {
	const signInDetails = useContext(SignInContext);

	const [isExpanded, setExpanded] = useState(true);
	const expandedObservable = useMemo(() => new SimpleObservable<void>(), []);
	const updateExpanded = useCallback(
		(updater: (isExpanded: boolean) => boolean) => {
			setExpanded(updater);
			expandedObservable.emit(undefined);
		},
		[setExpanded, expandedObservable],
	);

	const [alerts, setAlerts] = useState<undefined | VelavuAlert[]>(undefined);
	const [showAlerts, setShowAlerts] = useState(false);
	const [readAlertIDs, setReadAlertIDs] = useState<string[]>([]);
	const alertCount = useMemo(() => {
		if (!alerts || !readAlertIDs) return 0;
		else return alerts.length - readAlertIDs.length;
	}, [alerts, readAlertIDs]);

	useCancellableEffect(
		(addPromise) => {
			//Waiting for the user to be signed in and have their preferences loaded
			if (signInDetails.velavuUser === undefined) return;

			//Fetching alerts
			addPromise(
				VelavuAPI.alerts
					.getAllAlerts({
						eventCategories:
							signInDetails.preferences.alertsEventCategoryFilter,
					})
					.then((result) => result.data),
			)
				.then((alerts: VelavuAlert[]) => {
					setAlerts(alerts);
					setReadAlertIDs(storageHelper.getReadAlertIDs(alerts));
				})
				.catch(console.log);
		},
		[
			signInDetails.velavuUser,
			signInDetails.preferences.alertsEventCategoryFilter,
		],
	);

	const mqttContext = useContext(MQTTContext);
	useEffect(() => {
		const listener: MQTTListener = {
			onAlertReceive(alert) {
				//Adding the alert
				if (
					signInDetails.preferences.alertsEventCategoryFilter.includes(
						alert.event_category,
					)
				) {
					setAlerts((alerts) =>
						alerts ? [alert, ...alerts] : undefined,
					);
				}
			},
		};

		mqttContext.addListener(listener);
		return () => mqttContext.removeListener(listener);
	}, [
		setAlerts,
		mqttContext,
		signInDetails.preferences.alertsEventCategoryFilter,
	]);

	const dismissAlert = useCallback(
		(alert: VelavuAlert) => {
			//Updating the state in memory
			setReadAlertIDs((readAlertIDs) => readAlertIDs.concat(alert.id));

			//Updating the state on disk
			storageHelper.markAlertIDRead(alert.id);
		},
		[setReadAlertIDs],
	);

	const deleteAlert = useCallback(
		(alert: VelavuAlert) => {
			//Updating the state in memory
			setReadAlertIDs((array) =>
				array.filter((alertID) => alertID !== alert.id),
			);
			setAlerts(
				(array) =>
					array?.filter((allAlerts) => allAlerts.id !== alert.id),
			);

			//Updating the state on disk
			storageHelper.markAlertIDDeleted(alert.id);

			//Updating the state on the backend
			VelavuAPI.alerts.deleteAlert(alert.id).catch((error) => {
				console.log(error);
				window.alert("Failed to delete alert");
			});
		},
		[setReadAlertIDs, setAlerts],
	);

	const dismissAllAlerts = useCallback(() => {
		if (!alerts) return;

		//Updating the state in memory
		setReadAlertIDs(alerts.map((alert) => alert.id) ?? []);

		//Updating the state on disk
		storageHelper.setReadAlertIDs(alerts);
	}, [alerts, setReadAlertIDs]);

	const deleteAllAlerts = useCallback(() => {
		if (!alerts) return;

		//Updating the state in memory
		setAlerts([]);
		setReadAlertIDs([]);

		//Updating the state on disk
		storageHelper.setReadAlertIDs([]);

		//Updating the state on the backend
		VelavuAPI.alerts.deleteAllAlerts().catch((error) => {
			console.log(error);
			window.alert("Failed to delete all alerts");
		});
	}, [alerts, setAlerts, setReadAlertIDs]);

	const pushModal = useVelavuModal();

	const openSettings = useCallback(
		(initialTabKey?: SettingsTab) => {
			pushModal(
				(resolve, onTryResolve) => (
					<VelavuTitledModal title="Settings" onClose={resolve}>
						<SettingsModal
							onTryResolve={onTryResolve}
							initialTabKey={initialTabKey}
						/>
					</VelavuTitledModal>
				),
				{ pinToTop: true },
			);
		},
		[pushModal],
	);

	const onboardingOpened = useRef(false);
	const updateVelavuUser = signInDetails.updateVelavuUser;
	const openOnboarding = useCallback(async () => {
		//Make sure we only open a single dialog
		if (onboardingOpened.current) return;
		onboardingOpened.current = true;

		//Check if this is the first user
		const users: VelavuUser[] = await VelavuAPI.users.getAllUsers();
		const isFirstUser = users.length === 1;

		//Show the onboarding dialog
		await pushModal<boolean>((resolve, onTryResolve) => {
			//Fail resolutions by default
			onTryResolve.subscribe((data) => {
				if (!data.resolve) {
					data.cancel();
				}
			});

			return (
				<OnboardingDialog
					resolve={() => resolve(true)}
					isFirstUser={isFirstUser}
				/>
			);
		});

		//Record that the user has seen the welcome screen
		updateVelavuUser({
			settings: {
				showWelcomeScreen: false,
			},
		});
	}, [updateVelavuUser, pushModal]);
	useEffect(() => {
		//Show the welcome screen to the user the first time
		if (
			signInDetails.velavuUser !== undefined &&
			signInDetails.preferences.showWelcomeScreen
		) {
			openOnboarding();
		}
	}, [
		signInDetails.velavuUser,
		signInDetails.preferences.showWelcomeScreen,
		openOnboarding,
	]);

	return (
		<div className={styles.container}>
			<Sidebar
				isExpanded={isExpanded}
				setExpanded={updateExpanded}
				showAlerts={showAlerts}
				setShowAlerts={setShowAlerts}
				alertCount={alertCount}
				onOpenSettings={openSettings}
			/>

			<Page>
				<Alerts
					show={showAlerts}
					onClose={() => setShowAlerts(false)}
					alerts={alerts}
					alertCount={alertCount}
					readAlertIDs={readAlertIDs}
					onDismiss={dismissAlert}
					onDelete={deleteAlert}
					onDismissAll={dismissAllAlerts}
					onDeleteAll={deleteAllAlerts}
					onOpenAlertsSettings={() =>
						openSettings(SettingsTab.Alerts)
					}
				/>
				<Route exact={true} path="/">
					<Dashboard resizeObservable={expandedObservable} />
				</Route>
				<Route exact={true} path="/assets" component={Assets} />
				<Route
					exact={true}
					path="/assets/create"
					component={AssetNew}
				/>
				<Route
					exact={true}
					path="/assets/create/tag"
					component={AssetNewTag}
				/>
				<Route
					exact={true}
					path="/assets/create/confirm"
					component={AssetNewConfirm}
				/>
				<Route exact={true} path="/devices" component={Devices} />
				<Route exact={true} path="/analytics" component={Analytics} />
			</Page>
		</div>
	);
}
