import styles from "./map-context-menu.module.scss";
import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import iconGeofence from "./geofence.svg";
import iconSite from "./site.svg";

const newSiteButtonClass = "map-context-menu-new-site-button";
const newGeofenceButtonClass = "map-context-menu-new-geofence-button";

export default function MapContextMenu(props: {
	map: mapboxgl.Map;
	lngLat: mapboxgl.LngLat;
	onCreateSite: () => void;
	onCreateGeofence: () => void;
}) {
	useEffect(() => {
		const el = document.createElement("div");
		el.innerHTML = `
			<div class="${styles.list}">
				<button class="${styles.button} ${newSiteButtonClass}">
					<img class="${styles.icon}" src="${iconSite}" alt="" />
					<span>New site</span>
				</button>
				<button class="${styles.button} ${newGeofenceButtonClass}">
					<img class="${styles.icon}" src="${iconGeofence}" alt="" />
					<span>New geofence</span>
				</button>
			</div>
		`;

		el.getElementsByClassName(newSiteButtonClass)[0].addEventListener(
			"click",
			() => {
				props.onCreateSite();
			},
		);
		el.getElementsByClassName(newGeofenceButtonClass)[0].addEventListener(
			"click",
			() => {
				props.onCreateGeofence();
			},
		);

		const marker = new mapboxgl.Marker(el)
			.setLngLat(props.lngLat)
			.addTo(props.map);

		return () => {
			marker.remove();
		};
	}, [props]);

	return null;
}
