import React, { useCallback, useContext } from "react";
import { signOut } from "aws-amplify/auth";
import { Link, useLocation } from "react-router-dom";
import IconChevronLeft from "../../dynamicicons/icon-chevron-left";
import IconChevronRight from "../../dynamicicons/icon-chevron-right";
import IconHelp from "../../dynamicicons/icon-help";
import IconVelavuAlerts from "../../dynamicicons/icon-velavu-alerts";
import IconVelavuAnalytics from "../../dynamicicons/icon-velavu-analytics";
import IconVelavuAssets from "../../dynamicicons/icon-velavu-assets";
import IconVelavuDevices from "../../dynamicicons/icon-velavu-devices";
import IconVelavuMap from "../../dynamicicons/icon-velavu-map";
import AlertCounter from "../../elements/alert-counter";
import VelavuAvatar from "../../elements/velavu-avatar";
import VelavuButton from "../../elements/velavu-button";
import { useVelavuModal, VelavuModalPrompt } from "../../elements/velavu-modal";
import { useToggleable } from "../../helper/hook-helper";
import velavuLogoSquare from "../../img/logo-square.svg";
import velavuLogo from "../../img/logo.svg";
import SignInContext from "../../sign-in-context";
import SidebarIcon from "./sidebar-icon";
import SidebarTooltip from "./sidebar-tooltip";
import styles from "./sidebar.module.scss";

interface SidebarProps {
	isExpanded: boolean;
	setExpanded: (updater: (isExpanded: boolean) => boolean) => void;

	showAlerts: boolean;
	setShowAlerts: (showAlerts: boolean) => void;
	alertCount: number;
	onOpenSettings: VoidFunction;
}

function MapItem(props: { expanded: boolean }) {
	const [isHover, enableHover, disableHover] = useToggleable();
	const loc = useLocation();

	const isSelected = loc.pathname === "/";

	return (
		<div className={`${styles.navItem}`}>
			{isSelected && <div className={styles.selectionIndicator} />}
			<Link className={styles.link} to={{ pathname: "/" }}>
				<button
					className={
						props.expanded
							? styles.selectable
							: styles.selectableSquare
					}
					onMouseEnter={enableHover}
					onMouseLeave={disableHover}
				>
					<div
						className={`${styles.icon} ${
							isSelected ? styles.iconSelected : ""
						}`}
					>
						<IconVelavuMap
							size={24}
							color={isSelected ? "white" : "#5F718C"}
						/>
					</div>
					{props.expanded && (
						<span
							className={`${styles.label} ${
								isSelected ? styles.labelSelected : ""
							}`}
						>
							Map
						</span>
					)}

					{isHover && !props.expanded && (
						<SidebarTooltip>Map</SidebarTooltip>
					)}
				</button>
			</Link>
		</div>
	);
}

function NavItem(props: {
	route: string;
	icon: React.ReactNode;
	label: string;
	expanded: boolean;
}) {
	const [isHover, enableHover, disableHover] = useToggleable();
	const loc = useLocation();

	const isSelected = loc.pathname === props.route;

	return (
		<div className={styles.navItem}>
			{isSelected && <div className={styles.selectionIndicator} />}
			<Link className={styles.link} to={props.route}>
				<button
					className={
						props.expanded
							? styles.selectable
							: styles.selectableSquare
					}
					onMouseEnter={enableHover}
					onMouseLeave={disableHover}
				>
					<div
						className={`${styles.icon} ${
							isSelected ? styles.iconSelected : ""
						}`}
					>
						{props.icon}
					</div>
					{props.expanded && (
						<span
							className={`${styles.label} ${
								isSelected ? styles.labelSelected : ""
							}`}
						>
							{props.label}
						</span>
					)}

					{isHover && !props.expanded && (
						<SidebarTooltip>{props.label}</SidebarTooltip>
					)}
				</button>
			</Link>
		</div>
	);
}

function NavItems(props: { isExpanded: boolean }) {
	const loc = useLocation();

	return (
		<>
			<MapItem expanded={props.isExpanded} />
			<NavItem
				route={"/assets"}
				icon={
					<IconVelavuAssets
						size={24}
						color={loc.pathname === "/assets" ? "white" : "#5F718C"}
					/>
				}
				label={"Assets"}
				expanded={props.isExpanded}
			/>
			<NavItem
				route={"/devices"}
				icon={
					<IconVelavuDevices
						size={24}
						color={
							loc.pathname === "/devices" ? "white" : "#5F718C"
						}
					/>
				}
				label={"Devices"}
				expanded={props.isExpanded}
			/>
			<NavItem
				route={"/analytics"}
				icon={
					<IconVelavuAnalytics
						size={24}
						color={
							loc.pathname === "/analytics" ? "white" : "#5F718C"
						}
					/>
				}
				label={"Analytics"}
				expanded={props.isExpanded}
			/>
		</>
	);
}

export default function Sidebar(props: SidebarProps) {
	const location = useLocation();
	const userContext = useContext(SignInContext);
	const userName = userContext.velavuUser?.name ?? "";
	const userOrganization = userContext.organization?.name ?? "Organization";
	const userImage = userContext.velavuUser?.profile_img_url;
	const propsSetExpanded = props.setExpanded;
	const toggleCollapse = useCallback(
		() => propsSetExpanded((isExpanded) => !isExpanded),
		[propsSetExpanded],
	);

	const signIn = useContext(SignInContext);

	const pushModal = useVelavuModal();

	const openLogOut = useCallback(() => {
		pushModal((resolve) => (
			<VelavuModalPrompt
				onSelect={resolve}
				title="Do you want to log out of velavu?"
				labelConfirm="Log out"
			>
				You will have to log in again to view your assets.
			</VelavuModalPrompt>
		)).then((result) => {
			if (!result) return;

			//Sign out of AWS Amplify
			signOut().catch(console.log);
		});
	}, [pushModal]);

	const redirectToSupport = useCallback(() => {
		window.open("https://support.velavu.com");
	}, []);

	return (
		<div
			className={`${styles.container} ${
				location.pathname === "/" && !props.showAlerts
					? styles.elevated
					: ""
			} ${props.isExpanded ? styles.expanded : styles.collapsed} ${
				props.showAlerts ? styles.attached : ""
			}`}
		>
			<div className={styles.header}>
				{props.isExpanded ? (
					<img src={velavuLogo} style={{ width: 100 }} alt="velavu" />
				) : (
					<img src={velavuLogoSquare} alt="v" />
				)}
			</div>

			<NavItems isExpanded={props.isExpanded} />

			<div className={styles.spacer} />

			<SidebarIcon
				className={styles.iconButtonSpacing}
				tooltip={
					<>
						<span>Alerts</span>
						{props.alertCount > 0 && (
							<AlertCounter
								style={{ marginLeft: 12 }}
								count={props.alertCount}
							/>
						)}
					</>
				}
				onClick={() => props.setShowAlerts(!props.showAlerts)}
				badge={props.alertCount > 0}
			>
				<IconVelavuAlerts color="#5F718C" />
			</SidebarIcon>
			<SidebarIcon
				className={styles.iconButtonSpacing}
				tooltip={props.isExpanded ? "Collapse" : "Expand"}
				onClick={toggleCollapse}
				clickCausesLayout
			>
				{props.isExpanded ? (
					<IconChevronLeft color="#5F718C" />
				) : (
					<IconChevronRight color="#5F718C" />
				)}
			</SidebarIcon>
			<SidebarIcon
				className={styles.iconButtonSpacing}
				tooltip={
					<AccountMenu
						name={userName}
						organization={userOrganization}
						openSettings={() => props.onOpenSettings()}
						logOut={openLogOut}
					/>
				}
			>
				<VelavuAvatar name={userName} image={userImage} />
			</SidebarIcon>
			<SidebarIcon tooltip="Support" onClick={redirectToSupport}>
				<IconHelp color="#5F718C" />
			</SidebarIcon>
		</div>
	);
}

function AccountMenu(props: {
	name: string;
	organization: string;
	openSettings: VoidFunction;
	logOut: VoidFunction;
}) {
	return (
		<div className={styles.accountContainer}>
			<span className={styles.accountTitle}>{props.name}</span>
			<span className={styles.accountSubtitle}>{props.organization}</span>
			<div className={styles.accountButtons}>
				<VelavuButton
					style={{ marginRight: 8 }}
					label="Settings"
					size="small"
					onClick={props.openSettings}
				/>
				<VelavuButton
					label="Log out"
					size="small"
					onClick={props.logOut}
				/>
			</div>
		</div>
	);
}
