import React, { CSSProperties } from "react";
import styles from "./icon-velavu-map-indicator.module.scss";

export default function IconVelavuMapIndicator(props: {
	className?: string;
	style?: CSSProperties;
}) {
	return (
		<div
			className={
				styles.main + (props.className ? ` ${props.className}` : "")
			}
			style={props.style}
		/>
	);
}
