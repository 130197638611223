/**
 * Compares 2 arrays, returning elements only in either array, and elements in both arrays
 * @param arr1 The first array to compare
 * @param arr2 The second array to compare
 */
export function findArrayDiff<T>(
	arr1: T[],
	arr2: T[],
): { only1: T[]; only2: T[]; both: T[] } {
	return {
		only1: arr1.filter((val1) => !arr2.includes(val1)),
		only2: arr2.filter((val2) => !arr1.includes(val2)),
		both: arr1.filter((val1) => arr2.includes(val1)),
	};
}

/**
 * Compares 2 arrays, returning a set of elements only in an array 1, elements in both arrays, and then elements only in array 2
 * @param arr1 The first array to compare
 * @param arr2 The second array to compare
 * @param predicate A function to compare if 2 items are equal
 * @return [items only in array 1, items found in both arrays, items only in array 2]
 */
export function findArrayDiffPair<T, U>(
	arr1: T[],
	arr2: U[],
	predicate: (a: T, b: U) => boolean,
): [T[], [T, U][], U[]] {
	return [
		arr1.filter((val1) => !arr2.find((val2) => predicate(val1, val2))),
		arr1
			.map((val1) => {
				const val2 = arr2.find((val2) => predicate(val1, val2));
				if (val2) return [val1, val2];
				else return undefined;
			})
			.filter((value) => value) as [T, U][],
		arr2.filter((val1) => !arr1.find((val2) => predicate(val2, val1))),
	];
}

/**
 * Patches an array by adding or removing a value in it while keeping it sorted
 * @param set A function to set an updated array
 * @param value The value to add or remove
 * @param add True to add, false to remove
 */
export function patchArray<T>(
	set: (updater: (array: T[]) => T[]) => void,
	value: T,
	add: boolean,
) {
	if (add) {
		set((array: T[]) => array.concat(value).sort());
	} else {
		set((array: T[]) => array.filter((item) => item !== value));
	}
}

/**
 * Pushes a new value onto the end of an array, removing the element at the start if the array exceeds the capacity.
 * This function does not modify the original array.
 * @param array The array to push to
 * @param value The value to push to the array
 * @param capacity The size limit of the array
 */
export function pushLIFOCapacityPure<T>(
	array: T[],
	value: T,
	capacity: number,
): T[] {
	const newArray = [...array];
	newArray.push(value);
	if (newArray.length >= capacity) newArray.shift();
	return newArray;
}

/**
 * Performs a shallow compare of 2 arrays
 */
export function compareArrays(arr1: unknown[], arr2: unknown[]): boolean {
	if (arr1.length !== arr2.length) {
		return false;
	}

	for (let i = 0; i < arr1.length; i++) {
		if (arr1[i] !== arr2[i]) {
			return false;
		}
	}

	return true;
}
