import React from "react";
import styles from "./authentication-button-bar.module.scss";

export default function AuthenticationButtonBar(props: {
	primary?: React.ReactNode;
	secondary?: React.ReactNode;
}) {
	return (
		<div className={styles.container}>
			<div className={styles.button}>{props.primary}</div>
			<div className={styles.spacer} />
			<div className={styles.button}>{props.secondary}</div>
		</div>
	);
}
