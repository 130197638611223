import React, { useCallback, useMemo, useState } from "react";
import styles from "./floor-create-details.module.scss";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import VelavuSelect from "../../../../elements/velavu-select";
import {
	getAvailableSiteFloors,
	getDefaultFloor,
} from "../../../../helper/floor-helper";
import {
	getDefaultFloorName,
	mapFloorNumber,
} from "../../../../helper/language-helper";
import VelavuInput from "../../../../elements/velavu-input";
import { VelavuFloor } from "velavu-js-api";
import VelavuButton from "../../../../elements/velavu-button";

export interface FloorCreateDetailsProps {
	existingFloors: VelavuFloor[];
	onClose?: () => void;
	onSubmit?: (floorNum: number, floorName: string) => void;
}

export function FloorCreateDetails(props: FloorCreateDetailsProps) {
	const [floorNum, setFloorNum] = useState<number>(() =>
		getDefaultFloor(getAvailableSiteFloors(props.existingFloors)),
	);

	const [floorName, setFloorName] = useState("");
	const cleanFloorName = useMemo(() => floorName.trim(), [floorName]);

	const propsOnSubmit = props.onSubmit;
	const onSubmit = useCallback(() => {
		propsOnSubmit?.(floorNum, cleanFloorName);
	}, [floorNum, cleanFloorName, propsOnSubmit]);

	return (
		<PinnedList>
			<PinnedListHeader
				label="Upload floor plan"
				onClose={props.onClose}
			/>

			<div className={styles.content}>
				<h1 className={styles.label}>What floor will this be?</h1>

				<div className={styles.inputBar}>
					<VelavuSelect
						className={styles.inputFloorNum}
						titleText="Floor"
						value={floorNum}
						onChange={setFloorNum}
						fullWidth
					>
						{getAvailableSiteFloors(props.existingFloors).map(
							(floorNum) => (
								<option key={floorNum} value={floorNum}>
									{mapFloorNumber(floorNum)}
								</option>
							),
						)}
					</VelavuSelect>
					<VelavuInput
						className={styles.inputFloorName}
						titleText="Name"
						placeholder={getDefaultFloorName(floorNum)}
						value={floorName}
						onChange={setFloorName}
						fullWidth
					/>
				</div>

				<VelavuButton
					label="Next"
					disabled={cleanFloorName.length === 0}
					fullWidth
					onClick={onSubmit}
				/>
			</div>
		</PinnedList>
	);
}
