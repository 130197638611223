/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2020.07.15          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

import React from "react";
import styles from "./page.module.scss";

export default function Page(props: { children: React.ReactNode }) {
	return <div className={styles.container}>{props.children}</div>;
}
