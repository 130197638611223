// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icxJYhpdrdKerQneg3Y2{margin-top:24px;display:flex;flex-direction:row;height:32px;align-items:center}.f_TvqtKGYoY9xEOotpuR{flex-grow:1;flex-basis:0}.SjdNeTc4Kc31qirs7xjh{width:16px;flex-shrink:0}`, "",{"version":3,"sources":["webpack://./src/components/authentication/authentication-button-bar.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAA,CAEA,YAAA,CACA,kBAAA,CAEA,WAAA,CACA,kBAAA,CAGD,sBACC,WAAA,CACA,YAAA,CAGD,sBACC,UAAA,CACA,aAAA","sourcesContent":[".container {\n\tmargin-top: 24px;\n\n\tdisplay: flex;\n\tflex-direction: row;\n\n\theight: 32px;\n\talign-items: center;\n}\n\n.button {\n\tflex-grow: 1;\n\tflex-basis: 0;\n}\n\n.spacer {\n\twidth: 16px;\n\tflex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `icxJYhpdrdKerQneg3Y2`,
	"button": `f_TvqtKGYoY9xEOotpuR`,
	"spacer": `SjdNeTc4Kc31qirs7xjh`
};
export default ___CSS_LOADER_EXPORT___;
