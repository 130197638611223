import React, { CSSProperties, useCallback, useRef } from "react";
import { VelavuSite } from "velavu-js-api";
import IconFloors from "../../dynamicicons/icon-floors";
import IconPlace from "../../dynamicicons/icon-place";
import Divider from "../../elements/divider";
import VelavuMenu from "../../elements/velavu-menu";
import { useToggleable } from "../../helper/hook-helper";
import { mapFloorNumber } from "../../helper/language-helper";
import styles from "./selector-site.module.scss";

interface SelectorSiteProps {
	style?: CSSProperties;
	className?: string;

	site: VelavuSite | undefined | "select";
	onClick?: VoidFunction;

	floorNum?: number | undefined;
	onClickFloor?: (floorNum: number | undefined) => void;
}

function FloorMenuItem(props: { label: string; onClick?: VoidFunction }) {
	return (
		<button className={styles.menuItem} onClick={props.onClick}>
			<span className={styles.menuItemLabel}>{props.label}</span>
		</button>
	);
}

export default function SelectorSite(props: SelectorSiteProps) {
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [isFloorMenu, openFloorMenu, closeFloorMenu] = useToggleable();

	const propsOnClickFloor = props.onClickFloor;
	const selectFloor = useCallback(
		(floorNum: number | undefined) => {
			closeFloorMenu();
			if (propsOnClickFloor) propsOnClickFloor(floorNum);
		},
		[propsOnClickFloor, closeFloorMenu],
	);

	return (
		<div
			style={props.style}
			className={`${props.className} ${styles.container}`}
		>
			<button className={styles.siteButton} onClick={props.onClick}>
				<IconPlace className={styles.icon} size={16} color="unset" />
				<span className={styles.label}>
					{props.site === "select"
						? "Select site to filter"
						: props.site?.name ?? "Global"}
				</span>
			</button>

			{typeof props.site === "object" && (
				<>
					<Divider orientation="vertical" />

					<button
						ref={buttonRef}
						className={styles.floorButton}
						onClick={openFloorMenu}
					>
						<IconFloors color="#3A58E2" />
						<span>{mapFloorNumber(props.floorNum)}</span>
					</button>

					<VelavuMenu
						className={styles.floorMenu}
						open={isFloorMenu}
						onClose={closeFloorMenu}
						refPosition={buttonRef.current}
					>
						<FloorMenuItem
							label={mapFloorNumber(undefined)}
							onClick={() => selectFloor(undefined)}
						/>
						{props.site.floors !== undefined &&
							props.site.floors.length > 0 && (
								<>
									<Divider edgePadding />
									{props.site.floors.map((floor) => (
										<FloorMenuItem
											key={floor.id}
											label={mapFloorNumber(floor.level)}
											onClick={() =>
												selectFloor(floor.level)
											}
										/>
									))}
								</>
							)}
					</VelavuMenu>
				</>
			)}
		</div>
	);
}
