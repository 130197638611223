import React, { CSSProperties } from "react";

export default function IconVelavuAnalytics(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.99999 11C8.54999 11 8.99999 11.45 8.99999 12V16C8.99999 16.55 8.54999 17 7.99999 17C7.44999 17 6.99999 16.55 6.99999 16V12C6.99999 11.45 7.44999 11 7.99999 11ZM12 8C12.55 8 13 8.45 13 9V16C13 16.55 12.55 17 12 17C11.45 17 11 16.55 11 16V9C11 8.45 11.45 8 12 8ZM16 12.7143C16.55 12.7143 17 13.1643 17 13.7143V16C17 16.55 16.55 17 16 17C15.45 17 15 16.55 15 16V13.7143C15 13.1643 15.45 12.7143 16 12.7143Z"
				fill={props.color ?? "black"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 5H9C6.79086 5 5 6.79086 5 9V15C5 17.2091 6.79086 19 9 19H15C17.2091 19 19 17.2091 19 15V9C19 6.79086 17.2091 5 15 5ZM9 3C5.68629 3 3 5.68629 3 9V15C3 18.3137 5.68629 21 9 21H15C18.3137 21 21 18.3137 21 15V9C21 5.68629 18.3137 3 15 3H9Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
