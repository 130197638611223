import { VelavuAsset, VelavuDevice } from "velavu-js-api";
import globals from "../styles/global.scss";

export enum StatusType {
	green,
	blue,
	red,
}

/**
 * Gets the colors to use for a given status type
 */
export function getStatusColors(type: StatusType): {
	foreground: string;
	background: string;
} {
	switch (type) {
		case StatusType.green:
			return {
				foreground: globals.active,
				background: globals.activeBackground,
			};
		case StatusType.blue:
			return {
				foreground: globals.accent,
				background: globals.accentBackground,
			};
		case StatusType.red:
			return {
				foreground: globals.error,
				background: globals.errorBackground,
			};
	}
}

export function getAssetStatus(asset: VelavuAsset) {
	if (!asset.device_id) return "unpaired";

	switch (asset.online) {
		case true:
			return "active";
		case false:
			return "inactive";
		default:
			return "registered";
	}
}

export function getDeviceStatus(device?: VelavuDevice) {
	if (!device) return "unpaired";

	switch (device.online) {
		case true:
			return "active";
		case false:
			return "inactive";
		default:
			return "registered";
	}
}
