import React, { CSSProperties } from "react";
import { VelavuDevice } from "velavu-js-api";
import {
	DynamicSignalLabel,
	mapStrengthSNR,
} from "../../elements/dynamic-signal";
import VelavuExpandMenu from "../../elements/velavu-expand-menu";
import VelavuSimpleTable from "../../elements/velavu-simple-table";
import { mapLocationType } from "../../helper/language-helper";
import { lastUpdated } from "../../helper/time-helper";
import { checkDeviceVesta } from "../../helper/hardware-helper";

export default function TabTagLocation(props: {
	style?: CSSProperties;
	className?: string;
	tag: VelavuDevice;
}) {
	if (!props.tag.location) return null;

	return (
		<VelavuExpandMenu
			style={props.style}
			className={props.className}
			label="Location"
		>
			<VelavuSimpleTable>
				<tbody>
					{props.tag.location !== undefined && (
						<tr>
							<td>Location type</td>
							<td>
								{mapLocationType(
									props.tag.location.location_type,
								)}
							</td>
						</tr>
					)}

					{checkDeviceVesta(props.tag) &&
						props.tag.state?.gnss?.snr !== undefined && (
							<tr>
								<td>GPS signal</td>
								<td>
									<DynamicSignalLabel
										strength={mapStrengthSNR(
											props.tag.state.gnss.snr,
										)}
									/>
								</td>
							</tr>
						)}
					{checkDeviceVesta(props.tag) &&
						props.tag.state?.gnss?.sats_in_view !== undefined && (
							<tr>
								<td>Satellites</td>
								<td>{props.tag.state.gnss.sats_in_view}</td>
							</tr>
						)}
					{props.tag.location !== undefined && (
						<>
							<tr>
								<td>Last coordinates</td>
								<td>
									{props.tag.location.coordinates.join(
										", ",
									) ?? "Unknown"}
								</td>
							</tr>
							<tr>
								<td>Last updated</td>
								<td>
									{lastUpdated(props.tag.location.timestamp)}
								</td>
							</tr>
							{props.tag.location.location_type ===
								"PROXIMITY" && (
								<tr>
									<td>Accuracy</td>
									<td>{`${props.tag.location.distance}m`}</td>
								</tr>
							)}
						</>
					)}
				</tbody>
			</VelavuSimpleTable>
		</VelavuExpandMenu>
	);
}
