import { GeoTransformer } from "../helper/geo-helper";

export default interface PlaceImageState {
	center: [number, number];
	scale: number;
	rotation: number;
}

export const defaultPlaceImageState: PlaceImageState = {
	center: [0, 0],
	scale: 1,
	rotation: 0,
};

export function placeImageStateToTransformer(
	state: PlaceImageState,
): GeoTransformer {
	return new GeoTransformer(state.scale, state.rotation, state.center);
}

export function placeImageStateToEdgeCoords(
	state: PlaceImageState,
	width: number,
	height: number,
): [[number, number], [number, number], [number, number], [number, number]] {
	const transformer = placeImageStateToTransformer(state);

	const halfX = width / 2;
	const halfY = height / 2;

	return [
		{ x: -halfX, y: halfY },
		{ x: halfX, y: halfY },
		{ x: halfX, y: -halfY },
		{ x: -halfX, y: -halfY },
	].map((it) => transformer.transform(it)) as [
		[number, number],
		[number, number],
		[number, number],
		[number, number],
	];
}
