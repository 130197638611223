import React, { useState } from "react";
import {
	normalizeDeviceHardware,
	VelavuDevice,
	VelavuSite,
} from "velavu-js-api";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuItemIcon from "../../../../elements/velavu-item-icon";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import styles from "./gateway-detail.module.scss";
import GatewayProperties from "./gateway-properties";

enum GatewayDetailTab {
	Info,
}

interface GatewayDetailProps {
	site: VelavuSite;
	gateway: VelavuDevice;
	onBack: () => void;
	onClose: () => void;
}

export default function GatewayDetail(props: GatewayDetailProps) {
	const [selectedTab, setSelectedTab] = useState(GatewayDetailTab.Info);

	return (
		<div className={styles.container}>
			<DropdownPanelToolbar
				onBack={props.onBack}
				onClose={props.onClose}
			/>
			<div className={styles.header}>
				<VelavuItemIcon
					data={normalizeDeviceHardware(props.gateway.hardware)}
				/>
				<div className={styles.headerText}>
					<span className={styles.headerID}>{props.gateway.id}</span>
					<span className={styles.headerLocation}>
						{props.site.name}
					</span>
				</div>
			</div>
			<VelavuTabSwitcher
				selectedKey={selectedTab}
				onSelectKey={setSelectedTab}
				labels={{
					[GatewayDetailTab.Info]: "Info",
				}}
			>
				<div className={styles.tables}>
					<GatewayProperties
						gateway={props.gateway}
						site={props.site}
					/>
				</div>
			</VelavuTabSwitcher>
		</div>
	);
}
