import { Feature, GeoJsonProperties, Geometry } from "geojson";
import mapboxgl, { GeoJSONSource, MapLayerMouseEvent } from "mapbox-gl";
import { useMemo, useRef, useState } from "react";
import {
	degsFromCoords,
	lerpCoordinates,
	rotateBounds,
} from "../../helper/geo-helper";
import { VelavuDevice } from "velavu-js-api";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import styles from "./mapbox-injectable-anchors-routes.module.scss";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import {
	buildImageName,
	DeviceSymbolType,
	DeviceSymbolVariant,
} from "../../helper/map-device-symbols";
import { createVelavuMapboxPopup } from "../../mapbox-component/velavu-mapbox-popup";

const mapboxIDAnchorRouteLineSource = "anchor-route-lines-source";
const mapboxIDAnchorRouteArrowSource = "anchor-route-arrow-source";
const mapboxIDAnchorRouteArrowHoverSource = "anchor-route-arrow-hover-source";
const mapboxIDAnchorRouteLine = "anchor-route-line";
const mapboxIDAnchorRouteLineHover = "anchor-route-line-hover";
const mapboxIDAnchorRouteArrow = "anchor-route-arrow";
const mapboxIDAnchorRouteArrowHover = "anchor-route-arrow-hover";
const mapboxIDAnchorRouteHoverArea = "anchor-route-hover-area";
const mapboxIDAnchorRouteHoverAreaSource = "anchor-route-hover-area-source";

const ARROW_HALF_SIZE = 0.000000009;
const arrowBase64 =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKRSURBVHgB7ZpBbtswEEVnJKprHcE5QR2gXXSnLLsI6h4gqHOC5gaJT5D0BIpTdG00PkC9K1CniHuC5ghe15JY0vaiKJyIFDmUjMxbGpQJfn3NJ0cCYBiGYRjmuYIQmHw6zwTgQM38DiT0tj8vEHGxiv+MTt++eYCABBMgn9yncVydI8qzp8ahxKtVGY1O3x8uIQBBBNgsvpipu/zS8JJFUcRHIUSIIACbO2+8eE0/UddAAMgdkE++94RIfkMj5NHJ8esZEELuAJEk36AxSO4CUgFubn8M/6n0TcjW/0EI2SOgC59IyntHATRLVRAPqAoimQNeiOqjh8Vr0kgUZ0AEiQPcCt9OloVYHVJskkgcoBZ/AX5JRZHkQIB3B+TTu4EAmAAJ/mPRuwMEwiWQgd7/26sAHmKvjv7N7dxrQfT2CKwLn9700Aqg8RqL3hywLnz0i9ekPs8JXhxAEHu1qFg88BGLXhxAEHv1c3qKRWcBtnv1DxCe7LPqLoEjzgJgFAU5tz8yubMLnAT4Mv15Hqjw7UbK3ng6vwAHGhfBgLFXh1MsNnZAwNirwykWGzmgjdirp9k5oZED1OKJDjsuNGufWQuwjb0+dA8Vi3cDsMRagFZjrw7ES92Ks7nESoDWY68OFYu27TPjItjNwrcTq1g0dkAcxxnsB2mcFEPTwcYCRBi1sd9vRgWZ6VBjASR2+Nn/D4zM30MGeTkaHAnG22JzB4D8BfuDfwEUM9gTpKzGpmONBShX4hoslG2TMilnpmONBdC5KlGOoONUIK2+M7I+Dar99jW00wIzYXxy/Gpoc4F1Cmwm6KQTPtkuXuPWEdp0g3XmtnM6RHhQ8fRVtcivQn9exzAMwzAMwzAMwzDM/vIXfLTWXxcgzdIAAAAASUVORK5CYII=";
const arrowHoverBase64 =
	"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAKESURBVHgB7Zo/ctNAFMa/FTPO0PkIMhcIdE4q5QS4AJMO3wBugH0CwglIunTACVCZjnAAULiBS+wZtLz1qE3271tJk/er7JmV5P307fv2yQIEQRAEQXisKGRm/qqpiiftQqN4SV/Lw4/QuKVfctsqbG6uZ3fISDYBqkUz3R3hA318bxl6cbTDpv462yIDWQQwk99PdK2VOnYZbxwx2eMshwgFMmDuvOvkDVrheecWdtgdMD9vSrqjDQIgIc6oJtRghN0BNPnvCISOZXcBqwB091foKn0g1enrwznYYFsCXdX/gTgBDFtKhRlXQWRzwH5SvEP85A3Tv5PWFp3BsDggpvDdw5YK4guOTRKLAxT0GmmZkqCfwUByB5wsmwWd9QsY4IjF9A5Q+Agmijb9uZMKkCD2HsTsEE/eNEkLYrIl0BU+s+kpwUvSWEzmgK7wleBnmrJPSOIAhtizQsthliIWkziAIfbs10wUi9ECHAqfVm+Rn4quXSGSaAFydGwPXDvaBVECnC7/mMmX6I9yvvy1RgTBRTBj7NmIisVgB2SMPRtRsRjkgD5iz0ZonxDkANqTszQ7MYQWY28BTOyZPTmGR3XoRD3xFqDP2LNCnah5FOdziJcAA4g9G6Xv4zPnIjjEwncPXrHo44AK48DE4sp1sLMAqp/9fhDa42a5O0DpEiOB1rXz/5BZ/hztAedtsccS0D8xEjSHAP+gaoyEQuHKeazrwKc7XMJD2T5pgdp1rLMAXa5uMHC0ar3eM/LuBufnvy+HGola6aub62crn2O8U8BcgJqhATqh/eQ7eUPUEyHSfK1adYyeukOq9ncK7Tetiovcr9cJgiAIgiAIgiAIgjBe/gOk9ddD3dpdFwAAAABJRU5ErkJggg==";

export default function useMapboxInjectableAnchorsRoutes(
	mapInstance: MapHelperInstance,
	anchors?: VelavuDevice[],
) {
	const [arrows, setArrows] = useState<
		{
			id: string;
			bounds: [number, number][];
		}[]
	>([]);

	const [hoverID, setHoverID] = useState<string | undefined>(undefined);
	const arrowIds = useRef<string[]>([]);

	useStyleSetupMapEffect(mapInstance, (_, box) => {
		box.addSource(mapboxIDAnchorRouteHoverAreaSource, {
			type: "geojson",
			data: geoJSONBlankCollection,
			generateId: true,
		});

		box.addSource(mapboxIDAnchorRouteLineSource, {
			type: "geojson",
			data: geoJSONBlankCollection,
			generateId: true,
		});

		box.addLayer(
			{
				id: mapboxIDAnchorRouteHoverArea,
				type: "symbol",
				source: mapboxIDAnchorRouteHoverAreaSource,
				layout: {
					"icon-image": buildImageName(
						DeviceSymbolType.Anchor,
						DeviceSymbolVariant.Mobile,
					),
					"icon-allow-overlap": true,
					"icon-size": 0.7,
				},
				paint: {
					"icon-opacity": 0,
				},
			},
			"asset-marker",
		);

		box.addLayer(
			{
				id: mapboxIDAnchorRouteLineHover,
				type: "line",
				source: mapboxIDAnchorRouteLineSource,
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-width": 18,
					"line-opacity": 0,
				},
			},
			mapboxIDAnchorRouteHoverArea,
		);

		box.addLayer(
			{
				id: mapboxIDAnchorRouteLine,
				type: "line",
				source: mapboxIDAnchorRouteLineSource,
				layout: {
					"line-cap": "round",
					"line-join": "round",
				},
				paint: {
					"line-color": "#A3B3CC",
					"line-width": ["step", ["zoom"], 0, 18, 1, 19, 2, 20, 3],
					"line-dasharray": [
						"step",
						["zoom"],
						["literal", [0, 2]],
						18,
						["literal", [0, 2]],
						18.5,
						["literal", [0, 2.5]],
						19,
						["literal", [0, 3]],
						19.5,
						["literal", [0, 3.5]],
						20,
						["literal", [0, 4]],
						20.5,
						["literal", [0, 4.5]],
						21,
						["literal", [0, 5]],
						21.5,
						["literal", [0, 5.5]],
						22,
						["literal", [0, 6]],
					],
					"line-opacity": ["step", ["zoom"], 0, 17, 0, 18, 1],
				},
			},
			mapboxIDAnchorRouteLineHover,
		);
	});

	const routePopup = useMemo(
		() =>
			createVelavuMapboxPopup({
				className: styles.tooltipContainer,
				offset: [0, -10],
				anchor: "bottom",
				maxWidth: "400px",
			}),
		[],
	);

	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			const linesOnMouseMove = (event: MapLayerMouseEvent) => {
				const feature = event.features?.[0];
				if (!feature) return;
				setHoverID(feature.properties?.id);
			};

			const linesOnMouseLeave = () => {
				setHoverID(undefined);
			};

			const onMouseMove = (event: MapLayerMouseEvent) => {
				const feature = event.features?.[0];
				if (!feature) return;

				const anchorSvg = `
				<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6665 4.66732H5.33317C3.49222 4.66732 1.99984 6.1597 1.99984 8.00065C1.99984 9.8416 3.49222 11.334 5.33317 11.334H10.6665C12.5075 11.334 13.9998 9.8416 13.9998 8.00065C13.9998 6.1597 12.5075 4.66732 10.6665 4.66732ZM5.33317 3.33398C2.75584 3.33398 0.666504 5.42332 0.666504 8.00065C0.666504 10.578 2.75584 12.6673 5.33317 12.6673H10.6665C13.2438 12.6673 15.3332 10.578 15.3332 8.00065C15.3332 5.42332 13.2438 3.33398 10.6665 3.33398H5.33317Z" fill="#3A58E2"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6665 7.33398H5.33317C4.96498 7.33398 4.6665 7.63246 4.6665 8.00065C4.6665 8.36884 4.96498 8.66732 5.33317 8.66732H10.6665C11.0347 8.66732 11.3332 8.36884 11.3332 8.00065C11.3332 7.63246 11.0347 7.33398 10.6665 7.33398ZM5.33317 6.00065C4.2286 6.00065 3.33317 6.89608 3.33317 8.00065C3.33317 9.10522 4.2286 10.0007 5.33317 10.0007H10.6665C11.7711 10.0007 12.6665 9.10522 12.6665 8.00065C12.6665 6.89608 11.7711 6.00065 10.6665 6.00065H5.33317Z" fill="#3A58E2"/>
				</svg>
			`;

				const arrowSvg = `
				<svg width="26" height="16" viewBox="0 0 26 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect x="3" y="7" width="15" height="2" rx="1" fill="#A3B3CC"/>
					<path d="M23 7.13397C23.6667 7.51887 23.6667 8.48113 23 8.86603L17 12.3301C16.3333 12.715 15.5 12.2339 15.5 11.4641L15.5 4.5359C15.5 3.7661 16.3333 3.28497 17 3.66987L23 7.13397Z" fill="#A3B3CC"/>
				</svg>
			`;

				const descriptionHTML = `
				<div>
					${anchorSvg}
					${feature.properties?.id}
					${arrowSvg}
					${anchorSvg}
					${feature.properties?.routing_device_id}
				</div>
				${
					feature.properties?.install_quality !== undefined
						? `
						<div>
							<div>${feature.properties.install_quality}</div>
							Install quality
						</div>
					`
						: ""
				}
				<div>
					<div>${feature.properties?.rssi}</div>
					Signal strength
					<span>(dBm)</span>
				</div>
				<div>
					<div>${feature.properties?.tx_power}</div>
					TX power
					<span>(dBm)</span>
				</div>
			`;
				const coordinates = JSON.parse(feature.properties?.center);
				routePopup
					.setLngLat({
						lng: coordinates[0],
						lat: coordinates[1],
					})
					.setHTML(descriptionHTML);
				if (!routePopup.isOpen()) routePopup.addTo(map);

				setHoverID(feature.properties?.id);
			};

			const onMouseLeave = () => {
				setHoverID(undefined);
				routePopup.remove();
			};

			box.on("mousemove", mapboxIDAnchorRouteLineHover, linesOnMouseMove);
			box.on(
				"mouseleave",
				mapboxIDAnchorRouteLineHover,
				linesOnMouseLeave,
			);
			box.on("mousemove", mapboxIDAnchorRouteHoverArea, onMouseMove);
			box.on("mouseleave", mapboxIDAnchorRouteHoverArea, onMouseLeave);
		},
		[routePopup, setHoverID],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(_, box) => {
			arrowIds.current = arrows.map((arrow) => arrow.id);

			for (const { id, bounds } of arrows) {
				const source = `${mapboxIDAnchorRouteArrowSource}_${id}`;
				const hoverSource = `${mapboxIDAnchorRouteArrowHoverSource}_${id}`;
				const layer = `${mapboxIDAnchorRouteArrow}_${id}`;
				const hoverLayer = `${mapboxIDAnchorRouteArrowHover}_${id}`;

				box.addSource(source, {
					type: "image",
					url: arrowBase64,
					coordinates: bounds,
				});

				box.addSource(hoverSource, {
					type: "image",
					url: arrowHoverBase64,
					coordinates: bounds,
				});

				box.addLayer(
					{
						id: layer,
						source: source,
						type: "raster",
						paint: {
							"raster-fade-duration": 0,
						},
					},
					mapboxIDAnchorRouteHoverArea,
				);

				box.addLayer(
					{
						id: hoverLayer,
						source: hoverSource,
						type: "raster",
						paint: {
							"raster-opacity": 1,
						},
					},
					mapboxIDAnchorRouteHoverArea,
				);
			}
		},
		[arrows],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			for (const { id } of arrows) {
				map.setPaintProperty(
					`${mapboxIDAnchorRouteArrowHover}_${id}`,
					"raster-opacity",
					id === hoverID ? 1 : 0,
				);
			}

			map.setPaintProperty(mapboxIDAnchorRouteLine, "line-color", [
				"match",
				["get", "id"],
				hoverID === undefined ? "" : hoverID,
				"#3957df",
				"#A3B3CC",
			]);
		},
		[arrows, hoverID],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			const lines: Feature<Geometry, GeoJsonProperties>[] = [];
			const hoverAreas: Feature<Geometry, GeoJsonProperties>[] = [];
			const arrows: {
				id: string;
				bounds: [number, number][];
			}[] = [];

			if (anchors) {
				for (const anchor of anchors) {
					//Ignore offline anchors
					if (anchor.online === false) {
						continue;
					}

					const routing =
						anchor.state && "routing" in anchor.state
							? anchor.state.routing
							: undefined;
					if (routing) {
						const startCoords = anchor.location?.coordinates;
						const endCoords = anchors?.find(
							(x) => x.id === routing?.device_id,
						)?.location?.coordinates;

						if (startCoords && endCoords) {
							lines.push({
								type: "Feature",
								id: anchor.id,
								geometry: {
									type: "LineString",
									coordinates: [startCoords, endCoords],
								},
								properties: {
									id: anchor.id,
								},
							});

							// get mercator coordinate at center
							const start =
								mapboxgl.MercatorCoordinate.fromLngLat({
									lng: startCoords[0],
									lat: startCoords[1],
								});
							const end = mapboxgl.MercatorCoordinate.fromLngLat({
								lng: endCoords[0],
								lat: endCoords[1],
							});
							const center = lerpCoordinates(
								0.5,
								[start.x, start.y],
								[end.x, end.y],
							);
							// calculate rotation
							const rot = degsFromCoords(
								[start.x, start.y],
								[end.x, end.y],
							);
							// calculate bounds
							let bounds = rotateBounds(
								[
									[
										center[0] + ARROW_HALF_SIZE,
										center[1] - ARROW_HALF_SIZE,
									],
									[
										center[0] + ARROW_HALF_SIZE,
										center[1] + ARROW_HALF_SIZE,
									],
									[
										center[0] - ARROW_HALF_SIZE,
										center[1] + ARROW_HALF_SIZE,
									],
									[
										center[0] - ARROW_HALF_SIZE,
										center[1] - ARROW_HALF_SIZE,
									],
								],
								center,
								-rot,
							);
							// convert back to lngLat
							bounds = bounds.map(([x, y]) => {
								const coor = new mapboxgl.MercatorCoordinate(
									x,
									y,
								);
								const lngLat = coor.toLngLat();
								return [lngLat.lng, lngLat.lat];
							});

							arrows.push({
								id: anchor.id,
								bounds,
							});

							const lngLatCenter = lerpCoordinates(
								0.5,
								startCoords,
								endCoords,
							);

							hoverAreas.push({
								type: "Feature",
								geometry: {
									type: "Point",
									coordinates: lngLatCenter,
								},
								properties: {
									id: anchor.id,
									routing_device_id: routing.device_id,
									install_quality: routing.install_quality,
									rssi: routing.rssi,
									tx_power: routing.tx_power,
									center: lngLatCenter,
								},
							});
						}
					}
				}
			}

			const lineSource = map.getSource(
				mapboxIDAnchorRouteLineSource,
			) as GeoJSONSource;
			lineSource.setData({
				type: "FeatureCollection",
				features: lines,
			});

			const hoverAreaSource = map.getSource(
				mapboxIDAnchorRouteHoverAreaSource,
			) as GeoJSONSource;
			hoverAreaSource.setData({
				type: "FeatureCollection",
				features: hoverAreas,
			});

			setArrows(arrows);
		},
		[anchors],
	);
}
