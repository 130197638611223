import React, { useState } from "react";
import styles from "./channel-topic-row.module.scss";
import DiscreteInput from "../../../elements/discrete-input";
import EditableChannelTopic from "./editable-channel-topic";
import { classArr } from "../../../helper/style-helper";
import IconClose from "../../../dynamicicons/icon-close";
import globals from "../../../styles/global.scss";
import VelavuIconButton from "../../../elements/velavu-icon-button";
import BottomTooltip from "../../../elements/bottom-tooltip";
import { useCounter } from "../../../helper/hook-helper";

interface ChannelTopicRowProps {
	channelTopic: EditableChannelTopic;
	errorMessage?: string | undefined;
	onChange?: (channelTopic: EditableChannelTopic) => void;
	onRemove?: () => void;
}

export default function ChannelTopicRow(props: ChannelTopicRowProps) {
	const [
		interactionCounter,
		increaseInteractionCounter,
		decreaseInteractionCounter,
	] = useCounter();
	const isPronounced = interactionCounter > 0;

	const [isHoverChannelInput, setHoverChannelInput] = useState(false);

	return (
		<tr
			className={classArr(
				styles.row,
				isPronounced && styles.rowPronounced,
			)}
			onMouseEnter={increaseInteractionCounter}
			onMouseLeave={decreaseInteractionCounter}
		>
			<td className={styles.channelCell}>
				<span className={styles.channelInputWrapper}>
					<DiscreteInput
						className={styles.channelInput}
						value={props.channelTopic.wirepas_channel}
						onChange={(value) => {
							props.onChange?.({
								...props.channelTopic,
								wirepas_channel: value,
							});
						}}
						placeholder="1"
						type="text"
						reveal={isPronounced}
						error={props.errorMessage !== undefined}
						onMouseEnter={() => setHoverChannelInput(true)}
						onMouseLeave={() => setHoverChannelInput(false)}
						onFocus={increaseInteractionCounter}
						onBlur={decreaseInteractionCounter}
					/>

					{props.errorMessage !== undefined &&
						isHoverChannelInput && (
							<BottomTooltip edge="right">
								{props.errorMessage}
							</BottomTooltip>
						)}
				</span>
			</td>
			<td
				className={classArr(
					styles.endpointCell,
					isPronounced && styles.endpointCellBorder,
				)}
			>
				<div className={styles.endpointCellLayout}>
					<DiscreteInput
						className={styles.endpointInput}
						value={props.channelTopic.mqtt_topic}
						onChange={(value) => {
							props.onChange?.({
								...props.channelTopic,
								mqtt_topic: value,
							});
						}}
						placeholder="Enter topic string"
						reveal={isPronounced}
						onFocus={increaseInteractionCounter}
						onBlur={decreaseInteractionCounter}
					/>

					<VelavuIconButton
						className={classArr(
							styles.removeButton,
							isPronounced && styles.removeButtonShown,
						)}
						onClick={props.onRemove}
					>
						<IconClose size={16} color={globals.textTertiary} />
					</VelavuIconButton>
				</div>
			</td>
		</tr>
	);
}
