/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2020.08.26          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

import polyline from "@mapbox/polyline";
import buffer from "@turf/buffer";
import { FeatureCollection, LineString } from "geojson";
import mapboxgl from "mapbox-gl";
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
	DeviceCategory,
	mapboxToInternalBounds,
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
	PairedLocatedVelavuDevice,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	VelavuFloor,
	VelavuGeofence,
	VelavuRoute,
	VelavuRouteDetailed,
} from "velavu-js-api";
import { mapboxStyle, mapboxStyleSatellite } from "../../constants";
import { default as VelavuAssetDevicePair } from "../../data/asset-device-pair";
import {
	DeviceMarkerType,
	resolveSiteBoundaryViewport,
} from "../../data/device-marker";
import MapStyle from "../../data/map-style";
import { placeImageStateToEdgeCoords } from "../../data/place-image-state";
import { placeableFloorImageToBase64 } from "../../data/placeable-floor-image";
import UnpairAssetTag from "../../data/unpair-asset-tag";
import { VelavuModalProvider } from "../../elements/velavu-modal";
import { useGeofences, useSites } from "../../helper/api-helper";
import { compareArrays } from "../../helper/array-helper";
import useFloorEditState from "../../helper/draw-state-hook";
import { getFloorTarget, getLocationFloorID } from "../../helper/floor-helper";
import {
	GeoTransformer,
	mapCanvasToCenterCoords,
} from "../../helper/geo-helper";
import {
	useCancellableEffect,
	useElementSize,
	useToggleable,
} from "../../helper/hook-helper";
import {
	addImagesToMapboxStyle,
	useMapReadyEmitter,
	useStyleDependantMapEffect,
} from "../../helper/map-helper";
import useMultiRepo, {
	useSyncMultiRepoSource,
} from "../../helper/multi-repo-hook-helper";
import SimpleObservable from "../../helper/simple-observable";
import { classArr } from "../../helper/style-helper";
import useDeviceMarkers from "../../hook/use-device-markers";
import useMapboxInjectableAnchorsRoutes from "../../injectable/mapbox/mapbox-injectable-anchors-routes";
import useMapboxInjectableDevices from "../../injectable/mapbox/mapbox-injectable-devices";
import useMapboxInjectableDrawWalls from "../../injectable/mapbox/mapbox-injectable-draw-walls";
import useMapboxInjectableGeofenceEditor, {
	MapboxInjectableGeofenceEditorToolType,
} from "../../injectable/mapbox/mapbox-injectable-geofence-editor";
import useMapboxInjectableGeofences from "../../injectable/mapbox/mapbox-injectable-geofences";
import useMapboxInjectablePlaceImage from "../../injectable/mapbox/mapbox-injectable-place-image";
import useMapboxInjectableRoute, {
	RouteRenderType,
} from "../../injectable/mapbox/mapbox-injectable-route";
import useMapboxInjectableSiteBoundaries from "../../injectable/mapbox/mapbox-injectable-site-boundaries";
import useMapboxInjectableSiteFloorplans from "../../injectable/mapbox/mapbox-injectable-site-floorplans";
import useMapboxInjectableSiteIndicators from "../../injectable/mapbox/mapbox-injectable-site-indicators";
import SignInContext from "../../sign-in-context";
import { useMapDevicesState } from "../../state/map-devices-state";
import FloorSelector from "../../widgets/floor-selector";
import MapControls from "../../widgets/map-controls";
import { MQTTContext } from "../main/mqtt-provider";
import MapContextMenu from "../map-context-menu/map-context-menu";
import useDashboardMarkersRepo, {
	useSyncDashboardMarkersDevicesSource,
	useSyncDashboardMarkersSingleDevice,
} from "./dashboard-devices-repo";
import styles from "./dashboard.module.scss";
import AnchorDetail from "./dropdown/anchor-detail/anchor-detail";
import AssetDetail, {
	AssetDetailTab,
} from "./dropdown/asset-detail/asset-detail";
import ButtonAssets from "./dropdown/button-assets";
import FloorCreate, {
	FloorCreateInteractiveState,
	FloorCreateStep,
} from "./dropdown/floor-create/floor-create";
import GatewayDetail from "./dropdown/gateway-detail/gateway-detail";
import GeofenceCreate from "./dropdown/geofence-create/geofence-create";
import GeofenceDetail from "./dropdown/geofence-detail/geofence-detail";
import { MapList } from "./dropdown/map-list/map-list";
import { MapListDisplayType } from "./dropdown/map-list/map-list-display-type";
import SiteCreate from "./dropdown/site-create/site-create";
import SiteDetail from "./dropdown/site-detail/site-detail";
import Layers from "./layers/layers";
import PaginationToast from "./toast/pagination-toast";
import { doBoundsIntersect } from "../../helper/math-helper";
import useMapboxInjectableFloorplanImage from "../../injectable/mapbox/mapbox-injectable-floorplan-image";

interface MapVelavuAsset extends VelavuAsset {
	anim?: {
		startCoords: [number, number];
		startDate: number;
		endCoords: [number, number];
		endDate: number;
	};
}

interface DashboardHistoryState {
	assetID?: string;
	routeID?: string;
}

export default function Dashboard(props: {
	resizeObservable?: SimpleObservable<void>;
}) {
	useEffect(() => {
		//Update the document title
		document.title = "Velavu | Map";
	}, []);

	const location = useLocation<DashboardHistoryState>();

	//Map object
	const [map, setMap] = useState<mapboxgl.Map | undefined>(undefined);
	const mapContainer = useRef<HTMLDivElement>(null);
	const mapInstance = useMapReadyEmitter(map, async (map) => {
		await addImagesToMapboxStyle(map);
	});

	const mapContainerDimensions = useElementSize(mapContainer);

	//Map style
	const [mapStyle, setMapStyle] = useState(MapStyle.Graphic);
	const toggleMapStyle = useCallback(() => {
		setMapStyle((mapStyle) => {
			if (mapStyle === MapStyle.Graphic) {
				return MapStyle.Satellite;
			} else {
				return MapStyle.Graphic;
			}
		});
	}, [setMapStyle]);

	//Map properties
	const [mapBounds, setMapBounds] = useState<mapboxgl.LngLatBounds | null>(
		null,
	);

	//Devices
	const [pairedDevicesState, pairedDevicesActions] = useMapDevicesState();
	const pairedDevicesActionsAddDevice = pairedDevicesActions.addDevice;
	const pairedDevicesActionsRemoveDevice = pairedDevicesActions.removeDevice;
	const pairedDevicesActionsLoadRegion = pairedDevicesActions.loadRegion;
	const pairedDevicesActionsLoadMore = pairedDevicesActions.loadMore;

	const mapMovedSinceLastFetch = useMemo((): boolean => {
		if (mapBounds === null) {
			return false;
		}

		if (pairedDevicesState.activeRegion === null) {
			return true;
		}

		return !compareArrays(
			mapboxToInternalBounds(mapBounds),
			pairedDevicesState.activeRegion,
		);
	}, [mapBounds, pairedDevicesState.activeRegion]);
	const loadMoreDevices = useCallback(() => {
		//Refuse to load if we are not in a state to do so
		if (pairedDevicesState.isLoading) {
			return;
		}

		if (mapMovedSinceLastFetch) {
			//Load devices in the new region
			if (mapBounds !== null) {
				pairedDevicesActionsLoadRegion(
					mapboxToInternalBounds(mapBounds),
				);
			}
		} else {
			//Load more devices in the current region
			if (pairedDevicesState.hasMore) {
				pairedDevicesActionsLoadMore();
			}
		}
	}, [
		pairedDevicesState.hasMore,
		pairedDevicesState.isLoading,
		pairedDevicesActionsLoadMore,
		pairedDevicesActionsLoadRegion,
		mapMovedSinceLastFetch,
		mapBounds,
	]);

	const assets = useMemo(() => {
		return pairedDevicesState.devices.map((device) => device.asset);
	}, [pairedDevicesState.devices]);

	const [dropdownExpanded, setDropdownExpanded, setDropdownCollapsed] =
		useToggleable(false);

	const [initialSiteSelectedTab, setInitialSiteSelectedTab] = useState(0);

	const [hoveredAnchor, setHoveredAnchor] = useState<
		VelavuDevice | undefined
	>(undefined);
	const [selectedAnchor, setSelectedAnchor] = useState<
		VelavuDevice | undefined
	>(undefined);
	const [siteAnchors, setSiteAnchors] = useState<VelavuDevice[] | undefined>(
		undefined,
	);
	const [isSiteAnchorsFocused, setIsSiteAnchorsFocused] = useState(false);

	const [selectedGateway, setSelectedGateway] = useState<
		VelavuDevice | undefined
	>(undefined);

	const [selectedAsset, setSelectedAsset] = useState<VelavuAsset | undefined>(
		undefined,
	);

	const [hoveredAsset, setHoveredAsset] = useState<VelavuAsset | undefined>(
		undefined,
	);
	const [assetDetailTab, setAssetDetailTab] = useState<
		AssetDetailTab | undefined
	>(AssetDetailTab.Info);

	const [hoverRoute, setHoverRoute] = useState<VelavuRoute | undefined>(
		undefined,
	);
	const [selectedRoute, setSelectedRoute] = useState<VelavuRoute | undefined>(
		undefined,
	);
	const [selectedRouteDetails, setSelectedRouteDetails] = useState<
		VelavuRouteDetailed | undefined
	>(undefined);

	const [sites, setSites] = useSites();
	const [selectedSiteIndex, setSelectedSiteIndex] = useState<number>();

	const [selectedFloor, setSelectedFloor] = useState<number>();
	const [editingFloor, setEditingFloor] = useState<
		{ siteId?: string; floorId?: string } | undefined
	>(undefined);
	const [sitesFloorOverride, setSitesFloorOverride] = useState<{
		[key: string]: string | undefined;
	}>({});
	const [isSiteCreator, openSiteCreator, closeSiteCreator] = useToggleable();
	const [siteCreatorLngLat, setSiteCreatorLngLat] = useState<
		mapboxgl.LngLat | undefined
	>();
	const [isSiteEditor, openSiteEditor, closeSiteEditor] = useToggleable();
	const [inspectedSiteIndex, setInspectedSiteIndex] = useState<
		number | undefined
	>(undefined);
	const [clickMenu, setClickMenu] = useState<React.ReactNode>();

	const deleteFloorPlan = useCallback(
		(floor: VelavuFloor) => {
			if (sites) {
				const sitesUpdated = [...sites];
				const site = sites![inspectedSiteIndex!];

				const floorsUpdated = site.floors?.filter((predicateFloor) => {
					return predicateFloor.id !== floor.id;
				});

				sitesUpdated![inspectedSiteIndex!] = {
					...sitesUpdated![inspectedSiteIndex!],
					floors: floorsUpdated,
				};

				setSites(sitesUpdated);
			}
		},
		[sites, inspectedSiteIndex, setSites],
	);

	//Geofences
	const [geofences, setGeofences] = useGeofences();
	const [selectedGeofenceID, setSelectedGeofenceID] = useState<
		string | undefined
	>(undefined);
	const selectedGeofence = useMemo((): VelavuGeofence | undefined => {
		if (selectedGeofenceID === undefined) {
			return undefined;
		} else {
			return geofences?.find(
				(geofence) => geofence.id === selectedGeofenceID,
			);
		}
	}, [geofences, selectedGeofenceID]);
	const selectGeofence = useCallback(
		(geofenceID) => {
			//Set the selected geofence ID
			setSelectedGeofenceID(geofenceID);

			//Locate the geofence
			const targetGeofence = geofences?.find(
				(geofence) => geofence.id === geofenceID,
			);

			//Fly to the geofence
			if (targetGeofence !== undefined && map !== undefined) {
				const bounds = new mapboxgl.LngLatBounds();
				for (const point of targetGeofence.boundary.coordinates[0] as [
					number,
					number,
				][]) {
					bounds.extend(point);
				}

				map.fitBounds(bounds, { padding: 256 });
			}
		},
		[setSelectedGeofenceID, geofences, map],
	);

	const [geofenceEditorExistingTarget, setGeofenceEditorExistingTarget] =
		useState<string | undefined>(undefined);
	const [isGeofenceEditor, openGeofenceEditor, closeGeofenceEditor] =
		useToggleable();
	const [geofenceEditorToolType, setGeofenceEditorToolType] =
		useState<MapboxInjectableGeofenceEditorToolType>("draw");
	const [geofenceEditorName, setGeofenceEditorName] = useState<string>("");
	const [geofenceEditorPoints, setGeofenceEditorPoints] = useState<
		[number, number][]
	>([]);

	//Cleans up and starts a new geofence editor session
	const startGeofenceEditor = useCallback(() => {
		openGeofenceEditor();
		setGeofenceEditorToolType("draw");
		setGeofenceEditorName("");
		setGeofenceEditorPoints([]);
		setGeofenceEditorExistingTarget(undefined);
	}, [
		openGeofenceEditor,
		setGeofenceEditorToolType,
		setGeofenceEditorPoints,
	]);

	const startGeofenceEditorFromExisting = useCallback(
		(geofence: VelavuGeofence) => {
			openGeofenceEditor();
			setGeofenceEditorToolType("edit");
			setGeofenceEditorName(geofence.name);
			setGeofenceEditorPoints(
				geofence.boundary.coordinates[0] as [number, number][],
			);
			setGeofenceEditorExistingTarget(geofence.id);
		},
		[
			openGeofenceEditor,
			setGeofenceEditorToolType,
			setGeofenceEditorPoints,
		],
	);

	//Submits a new geofence for creation or patching
	const [isGeofenceSubmissionLoading, setGeofenceSubmissionLoading] =
		useState(false);
	const submitGeofence = useCallback(async () => {
		setGeofenceSubmissionLoading(true);

		//Submit the geofence to the backend
		try {
			if (geofenceEditorExistingTarget === undefined) {
				const newGeofence = await VelavuAPI.geofences.createNewGeofence(
					{
						name: geofenceEditorName,
						boundary: {
							type: "Polygon",
							coordinates: [geofenceEditorPoints],
						},
					},
				);

				//Add the geofence to the list
				setGeofences((geofences) => {
					return [...(geofences ?? []), newGeofence];
				});
			} else {
				const updatedGeofence =
					await VelavuAPI.geofences.modifyExistingGeofence(
						geofenceEditorExistingTarget,
						{
							name: geofenceEditorName,
							boundary: {
								type: "Polygon",
								coordinates: [geofenceEditorPoints],
							},
						},
					);

				//Update the geofence
				setGeofences((geofences) => {
					if (geofences === undefined) {
						return undefined;
					}

					const index = geofences.findIndex(
						(geofence) =>
							geofence.id === geofenceEditorExistingTarget,
					);
					if (index !== -1) {
						const newGeofences = [...geofences];
						newGeofences[index] = updatedGeofence;
						return newGeofences;
					} else {
						return geofences;
					}
				});
			}
		} catch (error) {
			console.warn(error);
			return;
		} finally {
			setGeofenceSubmissionLoading(false);
		}
		closeGeofenceEditor();
	}, [
		closeGeofenceEditor,
		geofenceEditorPoints,
		setGeofenceSubmissionLoading,
		setGeofences,
		geofenceEditorExistingTarget,
		geofenceEditorName,
	]);

	//Floor editor
	const [floorEditState, floorEditActions] = useFloorEditState();
	const [floorEditLoading, setFloorEditLoading] = useState(false);

	const launchFloorPlanEditor = useCallback(() => {
		const targetSite = sites![inspectedSiteIndex!];
		floorEditActions.open(targetSite);
	}, [sites, inspectedSiteIndex, floorEditActions]);

	//Layers
	const [showLayerEvents, setShowLayerEvents] = useState(true);
	const [showLayerSpeeding, setShowLayerSpeeding] = useState(true);

	//Cognito user
	const userContext = useContext(SignInContext);

	//MQTT updates
	const mqttContext = useContext(MQTTContext);

	//Create repos to consolidate devices
	const dashboardSelectableDevicesRepo = useMultiRepo<VelavuDevice>(
		useCallback((device: VelavuDevice) => {
			return device.id;
		}, []),
	);
	const dashboardMarkersRepo = useDashboardMarkersRepo();

	/*
	 * The dashboard used to work with VelavuAsset instances, where VelavuAsset.device
	 * could be used to look up the device.
	 * We've since switched to fetching VelavuDevice instances.
	 * Since VelavuDevice.asset.device will always be undefined,
	 * this function can be used to get a complete VelavuAsset instance with a
	 * possibly valid VelavuAsset.device field.
	 */
	const lookUpCompleteAsset = useCallback(
		(asset: VelavuAsset): VelavuAssetDevicePair => {
			const device = dashboardSelectableDevicesRepo.items.find(
				(device) => device.asset?.id === asset.id,
			);

			//TODO: Refactor this with AssetTagPair to properly keep track of both instances
			//For now this hack will work to keep the asset's information in sync
			let effectiveAsset: VelavuAsset;
			if (device !== undefined) {
				//Make sure the asset has all the relevant properties from its device
				effectiveAsset = {
					...asset,
					device_id: device.id,
					online: device.online,
				};
			} else {
				effectiveAsset = asset;
			}

			return {
				asset: effectiveAsset,
				device,
			};
		},
		[dashboardSelectableDevicesRepo.items],
	);

	const getAssetLocation = useCallback(
		(assetDevicePair: VelavuAssetDevicePair) => {
			if (
				assetDevicePair.device === undefined ||
				assetDevicePair.device.location === undefined
			) {
				return undefined;
			} else {
				return assetDevicePair.device.location.coordinates;
			}
		},
		[],
	);

	//Initialize the map
	useEffect(() => {
		if (map || assets === undefined || !userContext.isMapboxRegistered)
			return;

		const deltaDistance = 100;
		const deltaDegrees = 25;

		const mapbox = new mapboxgl.Map({
			container: mapContainer.current!,
			style: mapboxStyle,
			zoom: 2,
		});

		const easing = (t: number) => {
			return t * (2 - t);
		};

		mapbox.on("load", () => {
			//Make sure the map fits its frame
			mapbox.resize();

			//Expand the dropdown to show all assets
			setDropdownExpanded();
		});

		mapbox.getCanvas().addEventListener(
			"keydown",
			function (e) {
				e.preventDefault();
				if (e.key === "ArrowUp") {
					mapbox.panBy([0, -deltaDistance], {
						easing: easing,
					});
				} else if (e.key === "ArrowDown") {
					mapbox.panBy([0, deltaDistance], {
						easing: easing,
					});
				} else if (e.key === "ArrowLeft") {
					mapbox.easeTo({
						bearing: mapbox.getBearing() - deltaDegrees,
						easing: easing,
					});
				} else if (e.key === "ArrowRight") {
					mapbox.easeTo({
						bearing: mapbox.getBearing() + deltaDegrees,
						easing: easing,
					});
				}
			},
			true,
		);

		mapbox.on("move", () => {
			setMapBounds(mapbox.getBounds());
		});

		setMap(mapbox);
	}, [
		assets,
		map,
		setMap,
		userContext.isMapboxRegistered,
		clickMenu,
		setClickMenu,
		setDropdownExpanded,
		setMapBounds,
	]);

	//Zoom in to focus on assets on initial load
	const hasDoneInitialZoom = useRef<boolean>(false);
	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			if (
				!hasDoneInitialZoom.current &&
				pairedDevicesState.activeRegion === null &&
				pairedDevicesState.devices.length !== 0 &&
				!pairedDevicesState.hasMore
			) {
				//Bring all assets into view
				const bounds = new mapboxgl.LngLatBounds();
				for (const device of pairedDevicesState.devices) {
					bounds.extend(device.location.coordinates);
				}
				map.fitBounds(bounds, { maxZoom: 16, padding: 100 });

				hasDoneInitialZoom.current = true;
			}
		},
		[pairedDevicesState],
	);

	useEffect(() => {
		if (map) {
			map.on("contextmenu", (e) => {
				setClickMenu(
					<MapContextMenu
						key={`${e.lngLat.lng}${e.lngLat.lat}`}
						map={map}
						lngLat={e.lngLat}
						onCreateSite={() => {
							setDropdownExpanded();
							setSiteCreatorLngLat(e.lngLat);
							openSiteCreator();
							setClickMenu(undefined);
						}}
						onCreateGeofence={() => {
							setDropdownExpanded();
							setClickMenu(undefined);

							setTimeout(() => {
								startGeofenceEditor();
							});
						}}
					/>,
				);
			});

			map.on("click", (e) => {
				setClickMenu(undefined);
			});
		}
	}, [map, openSiteCreator, setDropdownExpanded, startGeofenceEditor]);

	//Navigation updates
	useCancellableEffect(
		(addPromise) => {
			if (location.state === undefined) return;

			const { assetID, routeID } = location.state;
			if (
				assetID === undefined ||
				assets === undefined ||
				map === undefined
			)
				return;

			//Fetch the selected asset from the backend
			addPromise(VelavuAPI.assets.getSpecificAsset(assetID)).then(
				(targetAsset) => {
					// Get the asset's device
					const assetDevicePair = lookUpCompleteAsset(targetAsset);

					//Get the selected asset's coordinates
					const coordinates = getAssetLocation(assetDevicePair);
					if (coordinates === undefined) return;

					//Focus the selected asset
					setSelectedAsset(targetAsset);
					setSelectedRoute(undefined);
					setSelectedRouteDetails(undefined);

					const flyToAsset = () =>
						map.flyTo({ center: coordinates, zoom: 18 });

					//If there's a route, find it and select it
					if (routeID !== undefined) {
						VelavuAPI.assetRoutes
							.getSpecificRoute(assetID, routeID)
							.then(setSelectedRoute)
							.catch((error) => {
								console.warn(error);
								//Just fly to the asset as fallback
								flyToAsset();
							});
					} else {
						flyToAsset();
					}
				},
			);

			//eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[location.state?.assetID, location.state?.routeID, !!assets, !!map],
	);

	//Subscribe to resize updates
	useEffect(() => {
		if (!props.resizeObservable || !map) return;

		const subscriber = () => {
			map.resize();
			requestAnimationFrame(() => {
				map.resize();
			});
		};
		props.resizeObservable.subscribe(subscriber);
		return () => props.resizeObservable!.unsubscribe(subscriber);
	}, [props.resizeObservable, map]);

	//Fly to the provided coordinates
	const flyTo = useCallback(
		(coordinates: [number, number]) => {
			if (!map) return;

			const defaultZoom = 20;
			if (
				map.getBounds().contains(coordinates) &&
				map.getZoom() > defaultZoom
			) {
				map.flyTo({ center: coordinates });
			} else {
				map.flyTo({ center: coordinates, zoom: defaultZoom });
			}
		},
		[map],
	);

	//Fly to the currently selected asset
	const flyToAsset = useCallback(() => {
		if (!selectedAsset) return;
		for (const asset of assets!) {
			if (asset.id !== selectedAsset.id) continue;

			const assetDevicePair = lookUpCompleteAsset(asset);
			const location = getAssetLocation(assetDevicePair);
			if (location !== undefined) flyTo(location);
			break;
		}
	}, [selectedAsset, assets, lookUpCompleteAsset, getAssetLocation, flyTo]);

	const selectAsset = useCallback(
		(asset: VelavuAsset | undefined) => {
			let assetDevicePair: VelavuAssetDevicePair | undefined;
			if (asset) {
				assetDevicePair = lookUpCompleteAsset(asset);
			}

			setSelectedAsset(assetDevicePair?.asset);
			setSelectedRoute(undefined);
			setSelectedRouteDetails(undefined);
			setHoveredAsset(undefined);
			setSiteAnchors(undefined);
			setSelectedAnchor(undefined);
			setSelectedGateway(undefined);
			if (assetDevicePair?.device) {
				const location = assetDevicePair.device?.location;
				if (location !== undefined) flyTo(location.coordinates);
			}
		},
		[lookUpCompleteAsset, setSelectedAsset, flyTo],
	);

	interface PreviouslySelectedAssetState {
		assetID: string;
		tab?: AssetDetailTab;
	}
	const previouslySelectedAssetData =
		useRef<PreviouslySelectedAssetState | null>(null);

	// Called when selecting a parent asset from details
	// or when selecting a child asset from inventory
	const selectNewAsset = useCallback(
		(assetID: string) => {
			if (selectedAsset !== undefined) {
				previouslySelectedAssetData.current = {
					assetID: selectedAsset.id,
					tab: assetDetailTab,
				};
			}

			VelavuAPI.assets
				.getSpecificAsset(assetID)
				.then((response: VelavuAsset) => {
					selectAsset(response);
					setAssetDetailTab(undefined);
					setHoveredAsset(undefined);
				})
				.catch((error) => console.log(error));
		},
		[selectAsset, selectedAsset, assetDetailTab],
	);

	const selectAssetByAssetId = useCallback(
		(id: string, tab?: AssetDetailTab) => {
			selectAsset(assets?.find((asset) => asset.id === id));
			setAssetDetailTab(tab);
		},
		[selectAsset, assets],
	);

	const repositionAsset = useCallback(() => {
		//Fly to the asset and enable focus
		flyToAsset();
	}, [flyToAsset]);

	const hoverAnchor = (anchor: VelavuDevice | undefined) => {
		setHoveredAnchor(anchor);
	};

	const selectAnchor = useCallback(
		(anchor: VelavuDevice | undefined) => {
			setSelectedAnchor(anchor);
			anchor?.location && flyTo(anchor?.location?.coordinates);
		},
		[flyTo],
	);

	const selectSiteAnchors = useCallback(
		(anchors: VelavuDevice[] | undefined) => {
			setSiteAnchors(anchors);
		},
		[setSiteAnchors],
	);

	const selectGateway = useCallback(
		(gateway: VelavuDevice | undefined) => {
			setSelectedGateway(gateway);
			gateway?.location && flyTo(gateway?.location?.coordinates);
		},
		[flyTo],
	);

	//Fit all loaded assets within the bounds of the map
	const fitToMap = useCallback(() => {
		if (!map) return;

		const bounds = new mapboxgl.LngLatBounds();
		for (const device of pairedDevicesState.devices) {
			bounds.extend(device.location.coordinates);
		}
		map.fitBounds(bounds, { maxZoom: 16, padding: 100 });

		//Deselect the currently selected asset
		selectAsset(undefined);
		setSelectedRoute(undefined);
		setSelectedRouteDetails(undefined);
	}, [pairedDevicesState.devices, map, selectAsset]);

	//Zoom the map in by 1 zoom level
	const zoomInMap = useCallback(() => {
		if (!map) return;
		map.zoomTo(map.getZoom() + 1);
	}, [map]);

	//Zoom the map out by 1 zoom level
	const zoomOutMap = useCallback(() => {
		if (!map) return;
		map.zoomTo(map.getZoom() - 1);
	}, [map]);

	const selectSite = useCallback(
		(index: number | undefined) => {
			setSelectedSiteIndex(index);
			setSelectedFloor(undefined);

			setInitialSiteSelectedTab(0);
			setInspectedSiteIndex(index);

			if (
				sites !== undefined &&
				index !== undefined &&
				map !== undefined
			) {
				const site = sites[index];

				const siteViewport = resolveSiteBoundaryViewport(
					site,
					mapContainerDimensions,
				);
				if (siteViewport !== undefined) {
					map.flyTo(siteViewport);
				} else {
					flyTo(site.coordinates);
				}
			}
		},
		[
			setSelectedSiteIndex,
			setSelectedFloor,
			map,
			flyTo,
			sites,
			mapContainerDimensions,
		],
	);

	const updateSiteFloorSelection = useCallback(
		(floorID: string | undefined) => {
			if (
				!sites ||
				(inspectedSiteIndex === undefined &&
					selectedSiteIndex === undefined)
			)
				return;

			if (inspectedSiteIndex !== undefined) {
				setSitesFloorOverride((sitesFloorOverride) => ({
					...sitesFloorOverride,
					[sites[inspectedSiteIndex].id]: floorID,
				}));
			} else if (selectedSiteIndex !== undefined) {
				setSitesFloorOverride((sitesFloorOverride) => ({
					...sitesFloorOverride,
					[sites[selectedSiteIndex].id]: floorID,
				}));
			}
		},
		[sites, inspectedSiteIndex, selectedSiteIndex, setSitesFloorOverride],
	);

	//Update the map style
	const initialMapStyleSet = useRef(false);
	const mapInstanceInvalidate = mapInstance.invalidate;
	useEffect(() => {
		//Ignore if we don't have a map
		if (map === undefined) {
			return;
		}

		//Don't set the map style on the first round
		if (!initialMapStyleSet.current) {
			initialMapStyleSet.current = true;
			return;
		}

		//Update the map style
		let mapStyleValue: string;
		switch (mapStyle) {
			case MapStyle.Graphic:
				mapStyleValue = mapboxStyle;
				break;
			case MapStyle.Satellite:
				mapStyleValue = mapboxStyleSatellite;
				break;
		}
		mapInstanceInvalidate();
		map.setStyle(mapStyleValue);
	}, [map, mapStyle, mapInstanceInvalidate]);

	const displayAnchors = useMemo(() => {
		return (
			siteAnchors ??
			(selectedAnchor
				? [selectedAnchor]
				: selectedGateway
				? [selectedGateway]
				: [])
		);
	}, [siteAnchors, selectedAnchor, selectedGateway]);

	const selectedAssetDevicePair = useMemo(() => {
		if (selectedAsset === undefined) {
			return undefined;
		}

		return lookUpCompleteAsset(selectedAsset);
	}, [selectedAsset, lookUpCompleteAsset]);

	//Consolidate selectable devices
	useSyncMultiRepoSource(
		dashboardSelectableDevicesRepo,
		pairedDevicesState.devices,
	);
	useSyncMultiRepoSource(dashboardSelectableDevicesRepo, siteAnchors);

	//Consolidate device markers
	useSyncDashboardMarkersDevicesSource(
		dashboardMarkersRepo,
		pairedDevicesState.devices,
	);
	useSyncDashboardMarkersDevicesSource(dashboardMarkersRepo, displayAnchors);
	useSyncDashboardMarkersSingleDevice(
		dashboardMarkersRepo,
		selectedAssetDevicePair?.device,
	);
	const visibleDeviceMarkers = useDeviceMarkers(
		dashboardMarkersRepo.items,
		sites,
		mapContainerDimensions,
	);

	const selectAnchorById = useCallback(
		(id: string) => {
			const anchor = dashboardSelectableDevicesRepo.items.find(
				(anchor) => anchor.id === id,
			);
			if (anchor?.category === DeviceCategory.Anchor) {
				selectAnchor(anchor);
			} else if (
				anchor?.category === DeviceCategory.Tag &&
				normalizeDeviceHardware(anchor?.hardware) ===
					NormalizedDeviceHardware.Argo
			) {
				selectGateway(anchor);
			}
		},
		[selectAnchor, selectGateway, dashboardSelectableDevicesRepo.items],
	);

	const selectAssetByDeviceId = useCallback(
		(deviceID: string, tab?: AssetDetailTab) => {
			selectAsset(
				dashboardSelectableDevicesRepo.items.find(
					(device) => device.id === deviceID,
				)?.asset,
			);
			setAssetDetailTab(tab);
		},
		[selectAsset, dashboardSelectableDevicesRepo.items],
	);

	//Fetch detailed route information for the selected route
	useCancellableEffect(
		(addPromise) => {
			if (selectedRoute !== undefined) {
				addPromise(
					VelavuAPI.assetRoutes.getSpecificRoute(
						selectedAsset!.id,
						selectedRoute.id,
					),
				)
					.then(setSelectedRouteDetails)
					.catch((error) => console.log(error.response));
			}
		},
		[selectedRoute, setSelectedRouteDetails],
	);

	useEffect(() => {
		//Ignore if there is no map or selected route
		if (mapInstance.map === undefined || selectedRoute === undefined)
			return;

		//Decode the selected route to GeoJSON
		const lineString = polyline.toGeoJSON(selectedRoute.data.geometry!);

		//Fit the map to show the path
		const bounds = new mapboxgl.LngLatBounds();
		for (const position of lineString.coordinates) {
			bounds.extend(position as [number, number]);
		}

		//Shift the map to contain the selected route
		mapInstance.map.fitBounds(bounds, {
			padding: {
				top: 100,
				right: 100,
				bottom: 100,
				left: 450,
			},
		});
	}, [mapInstance.map, selectedRoute]);

	useMapboxInjectableGeofenceEditor({
		mapInstance: mapInstance,
		disabled: !isGeofenceEditor,
		toolType: geofenceEditorToolType,
		onChangeToolType: setGeofenceEditorToolType,
		points: geofenceEditorPoints,
		onChangePoints: setGeofenceEditorPoints,
	});

	const previewGeofences = useMemo<VelavuGeofence[]>(() => {
		if (geofences === undefined) {
			return [];
		}

		if (!isGeofenceEditor || geofenceEditorExistingTarget === undefined) {
			return geofences;
		}

		return geofences.filter(
			(geofence) => geofence.id !== geofenceEditorExistingTarget,
		);
	}, [geofences, isGeofenceEditor, geofenceEditorExistingTarget]);
	useMapboxInjectableGeofences({
		mapInstance: mapInstance,
		geofences: previewGeofences,
	});

	const floorImageCoords = useMemo(() => {
		const image = floorEditState.createState.floorImage;
		if (image === null) {
			return undefined;
		}

		return placeImageStateToEdgeCoords(
			floorEditState.imagePlaceState,
			image.dimensions.width,
			image.dimensions.height,
		);
	}, [floorEditState.imagePlaceState, floorEditState.createState.floorImage]);

	useMapboxInjectableFloorplanImage({
		mapInstance: mapInstance,
		referenceImage:
			(floorEditState.createStep === FloorCreateStep.DrawPrompt ||
				(floorEditState.createStep === FloorCreateStep.Draw &&
					!floorEditState.drawToolState.hideReferenceImage)) &&
			floorEditState.createState.floorImage !== null
				? {
						coords:
							floorEditState.imageCoordinatesOverride ??
							floorImageCoords!,
						image: floorEditState.createState.floorImage,
				  }
				: undefined,
	});

	useMapboxInjectableDrawWalls({
		mapInstance: mapInstance,
		disabled:
			floorEditState.createInteractiveState !==
			FloorCreateInteractiveState.DrawWalls,
		toolType: floorEditState.drawToolState.toolType,
		walls: floorEditState.drawWalls,
		onChangeWalls: floorEditActions.setDrawWalls,
	});

	const isHoverRoute =
		hoverRoute !== undefined && hoverRoute.id !== selectedRoute?.id;
	useMapboxInjectableRoute({
		mapInstance: mapInstance,
		showLayerSpeeding: showLayerSpeeding,
		showLayerEvents: showLayerEvents,
		route: isHoverRoute ? hoverRoute : selectedRoute,
		routeDetails: isHoverRoute ? undefined : selectedRouteDetails,
		renderType: isHoverRoute
			? RouteRenderType.Hover
			: RouteRenderType.Normal,
	});

	const displaySites = useMemo(() => {
		if (sites === undefined) {
			return undefined;
		}

		//Don't show the active editing site
		const newSites = [...sites];
		if (floorEditState.isOpen && inspectedSiteIndex !== undefined) {
			newSites.splice(inspectedSiteIndex, 1);
		}
		return newSites;
	}, [sites, floorEditState.isOpen, inspectedSiteIndex]);

	//Show sites
	const selectSiteByID = useCallback(
		(id: string) => {
			if (sites !== undefined) {
				const index = sites.findIndex((site) => site.id === id);
				if (index !== -1) {
					selectSite(index);
					setDropdownExpanded();
				}
			}
		},
		[sites, selectSite, setDropdownExpanded],
	);

	const mapboxLayerIDSiteIndicators = useMapboxInjectableSiteIndicators(
		mapInstance,
		{
			sites: displaySites,
			onSelectSite: selectSiteByID,
			mapDimensions: mapContainerDimensions,
		},
	);

	const mapboxLayerIDDevices = useMapboxInjectableDevices({
		mapInstance: mapInstance,
		devicesSource: visibleDeviceMarkers,
		hoveredDeviceID: hoveredAnchor?.id ?? hoveredAsset?.device_id,
		selectedDeviceID:
			selectedAsset?.device_id ??
			selectedGateway?.id ??
			selectedAnchor?.id,
		onSelectAsset: selectAssetByDeviceId,
		onSelectAnchor: selectAnchorById,
		animateMovement: true,
		prioritizedDeviceIDs: visibleDeviceMarkers
			.filter(
				(device) =>
					device.markerType === DeviceMarkerType.Anchor &&
					siteAnchors &&
					isSiteAnchorsFocused,
			)
			.map((device) => device.deviceID),
		belowLayer: mapboxLayerIDSiteIndicators,
	});

	const mapboxLayerIDSiteBoundaries = useMapboxInjectableSiteBoundaries(
		mapInstance,
		{
			sites: displaySites,
			belowLayer: mapboxLayerIDDevices,
		},
	);

	useMapboxInjectableSiteFloorplans(mapInstance, {
		sites: displaySites,
		floorOverride: sitesFloorOverride,
		editingSiteId: editingFloor?.siteId,
		belowLayer: mapboxLayerIDSiteBoundaries,
	});

	useMapboxInjectableAnchorsRoutes(mapInstance, siteAnchors);

	const unpairAsset = useCallback(
		(data: UnpairAssetTag) => {
			//Removing the associated device from the list
			pairedDevicesActionsRemoveDevice(data.tag.id);

			//Deselecting the currently selected asset
			setSelectedAsset(undefined);
			setSelectedRoute(undefined);
			setSelectedRouteDetails(undefined);
		},
		[pairedDevicesActionsRemoveDevice, setSelectedAsset, setSelectedRoute],
	);

	const pairAsset = useCallback(
		(data: UnpairAssetTag) => {
			//Updating the local data
			const location = data.tag.location;
			if (location !== undefined) {
				pairedDevicesActionsAddDevice({
					...data.tag,
					location: location,
					asset: data.asset,
				});
			}
		},
		[pairedDevicesActionsAddDevice],
	);

	//Show floor plan if editing
	useMapboxInjectablePlaceImage({
		mapInstance: mapInstance,
		state: floorEditState.imagePlaceState,
		onChangeState: floorEditActions.setImagePlaceState,
		image:
			floorEditState.createInteractiveState ===
			FloorCreateInteractiveState.PlaceImage
				? floorEditState.createState.floorImage
				: undefined,
	});

	let dropdownComponent: React.ReactNode;

	const [mapListDisplayType, setMapListDisplayType] = useState(
		MapListDisplayType.Asset,
	);

	const listAssets = useMemo((): VelavuAssetDevicePair[] => {
		let listDevices: PairedLocatedVelavuDevice[];
		//Resolve which assets to show in the list
		if (!assets) {
			listDevices = [];
		} else if (selectedFloor == undefined) {
			listDevices = pairedDevicesState.devices;
		} else if (sites && selectedSiteIndex) {
			listDevices = pairedDevicesState.devices.filter(
				(device) =>
					sites[selectedSiteIndex].floors?.find(
						(f) => f.level === selectedFloor,
					)?.id == getLocationFloorID(device.location),
			);
		} else {
			listDevices = [];
		}
		//Filter out any devices that aren't within the map bounds
		const visibleDevices = listDevices.filter((device) => {
			if (mapBounds === null) {
				return true;
			}
			return mapBounds.contains(device.location.coordinates);
		});
		// Return array of {asset, device} pairs
		return visibleDevices.map((device) => {
			const asset = assets.find((asset) => asset.id === device.asset?.id);
			return {
				asset: asset!,
				device: device,
			};
		});
	}, [
		assets,
		selectedFloor,
		sites,
		selectedSiteIndex,
		pairedDevicesState.devices,
		mapBounds,
	]);

	if (selectedAsset) {
		dropdownComponent = (
			<AssetDetail
				asset={selectedAsset}
				device={selectedAssetDevicePair?.device}
				onBack={() => {
					if (previouslySelectedAssetData.current !== null) {
						selectAssetByAssetId(
							previouslySelectedAssetData.current.assetID,
							previouslySelectedAssetData.current.tab,
						);
						previouslySelectedAssetData.current = null;
					} else {
						setSelectedAsset(undefined);
						setSelectedRoute(undefined);
						setSelectedRouteDetails(undefined);
						setDropdownExpanded();
						setAssetDetailTab(undefined);
					}
				}}
				onClose={() => {
					setSelectedAsset(undefined);
					setSelectedRoute(undefined);
					setSelectedRouteDetails(undefined);
					setDropdownCollapsed();
				}}
				onPair={pairAsset}
				onUnpair={unpairAsset}
				onUpdateAsset={(assetID, name, category, group, notes) => {
					//Updating the asset values
					const deviceID = selectedAsset?.device_id;
					if (deviceID !== undefined) {
						pairedDevicesActions.updateDevice(
							deviceID,
							(device) => {
								return {
									...device,
									asset: {
										...device.asset,
										name: name,
										category: category,
										group: group,
										notes: notes,
									},
								};
							},
						);
					}

					//Updating the currently selected asset
					if (assetID === selectedAsset?.id) {
						setSelectedAsset({
							...selectedAsset,
							name: name,
							category: category,
							group: group,
							notes: notes,
						});
					}
				}}
				onRemoveParentAsset={() => {
					setSelectedAsset({
						...selectedAsset,
						parent_id: undefined,
					});
				}}
				onRepositionAsset={repositionAsset}
				onSelectAsset={selectNewAsset}
				onHoverAsset={setHoveredAsset}
				hoverRoute={hoverRoute}
				setHoverRoute={setHoverRoute}
				selectedRoute={selectedRoute}
				setSelectedRoute={setSelectedRoute}
				onSelectRoute={() => setSelectedRouteDetails(undefined)}
				onDeleteRoute={() => {
					setSelectedRoute(undefined);
					setSelectedRouteDetails(undefined);
				}}
				tab={assetDetailTab}
				setTab={setAssetDetailTab}
			/>
		);
	} else if (isGeofenceEditor) {
		dropdownComponent = (
			<GeofenceCreate
				name={geofenceEditorName}
				onChangeName={setGeofenceEditorName}
				onClose={closeGeofenceEditor}
				onSubmit={submitGeofence}
				disabled={
					geofenceEditorToolType === "draw" ||
					isGeofenceSubmissionLoading
				}
				isEditing={geofenceEditorExistingTarget !== undefined}
			/>
		);
	} else if (floorEditState.isOpen) {
		dropdownComponent = (
			<FloorCreate
				site={sites![inspectedSiteIndex!]}
				onClose={floorEditActions.close}
				onFinish={async () => {
					const floorImage = floorEditState.createState.floorImage;
					let walls = floorEditState.drawWalls;

					//User selects Place 2d image on map
					if (walls.length === 0 && floorImage !== null) {
						const transformer = new GeoTransformer(
							floorEditState.imagePlaceState.scale,
							floorEditState.imagePlaceState.rotation,
							floorEditState.imagePlaceState.center,
						);

						//Create walls from image edges
						walls = mapCanvasToCenterCoords([
							[
								{ x: 0, y: 0 },
								{ x: 0, y: floorImage.dimensions.height },
								{
									x: floorImage.dimensions.width,
									y: floorImage.dimensions.height,
								},
								{ x: floorImage.dimensions.width, y: 0 },
								{ x: 0, y: 0 },
							],
						]).map((line) =>
							line.map((point) => transformer.transform(point)),
						);
					}

					//Convert the walls to GeoJSON
					const wallsLines: FeatureCollection<
						LineString,
						Record<string, never>
					> = {
						type: "FeatureCollection",
						features: walls.map((points) => ({
							type: "Feature",
							properties: {},
							geometry: {
								type: "LineString",
								coordinates: points,
							},
						})),
					};

					//Add thickness to the walls
					const wallsPolygons = buffer(wallsLines, 5, {
						units: "centimeters",
					});

					const coordinateLines = walls.map((wall) => {
						return wall.map((point) => ({
							x: point[0],
							y: point[1],
						}));
					});

					//Create the request
					const request: Omit<VelavuFloor, "id"> = {
						name: floorEditState.createState.floorName,
						level: floorEditState.createState.floorNum,
						floorplan: wallsPolygons,
						floorplan_data: {
							file_name:
								floorEditState.createState.floorImage?.name,
							rotation: floorEditState.imagePlaceState.rotation,
							scale: floorEditState.imagePlaceState.scale,
							lines: coordinateLines,
							width: floorImage?.dimensions.width,
							height: floorImage?.dimensions.height,
						},
					};

					//Don't change image for existing floors
					if (
						floorEditState.editFloorID === null &&
						floorImage !== null
					) {
						request.image = await placeableFloorImageToBase64(
							floorImage.renderable,
						);
						request.image_coordinates =
							floorEditState.imageCoordinatesOverride ??
							placeImageStateToEdgeCoords(
								floorEditState.imagePlaceState,
								floorImage.dimensions.width,
								floorImage.dimensions.height,
							);
					}

					//Enable loading
					setFloorEditLoading(true);

					//Submit to backend
					try {
						let newFloor: VelavuFloor;

						const siteID = sites![inspectedSiteIndex!].id;

						//Edit existing floorplan
						if (floorEditState.editFloorID !== null) {
							newFloor =
								await VelavuAPI.siteFloors.updateSiteFloor(
									siteID,
									floorEditState.editFloorID,
									request,
								);
						} else {
							//Create new floor
							newFloor =
								await VelavuAPI.siteFloors.createNewSiteFloor(
									siteID,
									request,
								);
						}

						//Refresh the site data
						const newSite =
							await VelavuAPI.sites.getSpecificSite(siteID);

						setSites((sites) => {
							if (!sites) return undefined;

							//Get the site index
							const index = sites.findIndex(
								(site) => site.id === siteID,
							);
							if (index === -1) return sites;

							//Update the site
							const newSites = [...sites];
							newSites[index] = newSite;
							return newSites;
						});

						//Select the floor
						updateSiteFloorSelection(newFloor.id);

						//Close the menu
						floorEditActions.close();
					} catch (error) {
						console.warn("Failed to update floor", error);
					} finally {
						setFloorEditLoading(false);
					}
				}}
				disableNext={floorEditLoading}
				state={floorEditState.createState}
				onChangeState={floorEditActions.setCreateState}
				step={floorEditState.createStep}
				onChangeStep={floorEditActions.setCreateStep}
				floorDrawState={floorEditState.drawToolState}
				onChangeFloorDrawState={floorEditActions.setDrawToolState}
			/>
		);
	} else if (!dropdownExpanded) {
		dropdownComponent = <ButtonAssets onClick={setDropdownExpanded} />;
	} else if (selectedGeofence !== undefined) {
		const localSelectedGeofence = selectedGeofence;

		dropdownComponent = (
			<GeofenceDetail
				geofence={localSelectedGeofence}
				onBack={() => setSelectedGeofenceID(undefined)}
				onClose={setDropdownCollapsed}
				onEdit={() => {
					startGeofenceEditorFromExisting(localSelectedGeofence);
				}}
				onDelete={() => {
					setSelectedGeofenceID(undefined);
					setGeofences((geofences) => {
						return geofences?.filter(
							(geofence) =>
								geofence.id !== localSelectedGeofence.id,
						);
					});
				}}
				onSelectAsset={selectNewAsset}
				onHoverAsset={setHoveredAsset}
			/>
		);
	} else if (isSiteCreator) {
		dropdownComponent = (
			<SiteCreate
				key={
					siteCreatorLngLat
						? `${siteCreatorLngLat.lng},${siteCreatorLngLat.lat}`
						: ""
				} // trigger rerender
				from={`${map?.getCenter().lng},${map?.getCenter().lat}`}
				initialCoords={
					siteCreatorLngLat
						? [siteCreatorLngLat.lng, siteCreatorLngLat.lat]
						: undefined
				}
				onClose={() => {
					closeSiteCreator();
					setSiteCreatorLngLat(undefined);
				}}
				onPreview={flyTo}
				onResult={(site) => {
					if (!sites) return;

					//Close panel
					closeSiteCreator();

					//Add the new site to the list
					const newSites = sites.concat(site);
					setSites(newSites);

					//Automatically inspect the new site
					const newIndex = newSites.length - 1;
					selectSite(newIndex);
				}}
			/>
		);
	} else if (inspectedSiteIndex !== undefined) {
		if (isSiteEditor) {
			dropdownComponent = (
				<SiteCreate
					from={`${map?.getCenter().lng},${map?.getCenter().lat}`}
					initialEdit={sites![inspectedSiteIndex]}
					onClose={closeSiteEditor}
					onPreview={flyTo}
					onResult={(site) => {
						if (
							sites === undefined ||
							inspectedSiteIndex === undefined
						)
							return;

						//Close panel
						closeSiteEditor();

						//Update the site listing
						const newSites = [...sites];
						newSites[inspectedSiteIndex] = site;
						setSites(newSites);
					}}
				/>
			);
		} else {
			if (selectedAnchor) {
				dropdownComponent = (
					<AnchorDetail
						anchor={selectedAnchor}
						site={sites![inspectedSiteIndex]}
						onBack={() => {
							setInitialSiteSelectedTab(3);
							setSelectedAnchor(undefined);
						}}
						onClose={() => {
							setInitialSiteSelectedTab(0);
							setHoveredAnchor(undefined);
							setSelectedAnchor(undefined);
							setSiteAnchors(undefined);
							setDropdownCollapsed();
						}}
					/>
				);
			} else if (selectedGateway) {
				dropdownComponent = (
					<GatewayDetail
						gateway={selectedGateway}
						site={sites![inspectedSiteIndex]}
						onBack={() => {
							setInitialSiteSelectedTab(2);
							setSelectedGateway(undefined);
						}}
						onClose={() => {
							setInitialSiteSelectedTab(0);
							setSelectedGateway(undefined);
							setDropdownCollapsed();
						}}
					/>
				);
			} else {
				dropdownComponent = (
					<SiteDetail
						onBack={() => {
							setInitialSiteSelectedTab(0);
							setInspectedSiteIndex(undefined);
							setSiteAnchors(undefined);
						}}
						onClose={() => {
							setInitialSiteSelectedTab(0);
							setDropdownCollapsed();
							setSiteAnchors(undefined);
						}}
						onDeletePlan={deleteFloorPlan}
						onEditFloor={(floor) => {
							floorEditActions.edit(floor);
						}}
						onCreateFloor={launchFloorPlanEditor}
						selectedFloor={
							getFloorTarget(
								sites![selectedSiteIndex!]?.floors,
								sitesFloorOverride[
									sites![selectedSiteIndex!]?.id
								],
							)?.id
						}
						onSelectFloor={(f) => {
							updateSiteFloorSelection(f);
							const idx = sites![
								selectedSiteIndex!
							]?.floors?.findIndex((floor) => floor.id === f);
							if (idx && idx >= 0) setSelectedFloor(idx);
						}}
						onHoverAnchor={hoverAnchor}
						onSelectAnchor={selectAnchor}
						onSelectSiteAnchors={selectSiteAnchors}
						setIsSiteAnchorsFocused={setIsSiteAnchorsFocused}
						onSelectGateway={selectGateway}
						onEdit={openSiteEditor}
						onDelete={() => {
							if (
								sites === undefined ||
								inspectedSiteIndex === undefined
							)
								return;

							//Adjusting the inspected site index
							setInspectedSiteIndex(undefined);
							if (selectedSiteIndex !== undefined) {
								if (selectedSiteIndex === inspectedSiteIndex) {
									setSelectedSiteIndex(undefined);
								} else if (
									selectedSiteIndex > inspectedSiteIndex
								) {
									setSelectedSiteIndex((i) =>
										i === undefined ? undefined : i - 1,
									);
								}
							}

							//Removing the site
							const newSites = [...sites];
							newSites.splice(inspectedSiteIndex, 1);
							setSites(newSites);
						}}
						site={sites![inspectedSiteIndex]}
						initialSelectedTab={initialSiteSelectedTab}
						selectableDevicesRepo={dashboardSelectableDevicesRepo}
						markersRepo={dashboardMarkersRepo}
						onSelectAsset={selectNewAsset}
						onHoverAsset={setHoveredAsset}
					/>
				);
			}
		}
	} else {
		dropdownComponent = (
			<MapList
				displayType={mapListDisplayType}
				onChangeDisplayType={setMapListDisplayType}
				onClose={setDropdownCollapsed}
				assetDevicePairs={listAssets}
				onSelectAsset={selectAsset}
				onHoverAsset={setHoveredAsset}
				sites={sites ?? []}
				onSelectSite={selectSiteByID}
				onCreateSite={openSiteCreator}
				geofences={geofences ?? []}
				onSelectGeofence={selectGeofence}
				onCreateGeofence={startGeofenceEditor}
			/>
		);
	}

	const floorSelector = useMemo(() => {
		if (sites && selectedSiteIndex !== undefined) {
			return (
				<FloorSelector
					className={styles.floorSelector}
					site={sites[selectedSiteIndex]}
					onFloorClick={() => {
						setDropdownExpanded();
						setInspectedSiteIndex(selectedSiteIndex);
					}}
					onSelectFloor={updateSiteFloorSelection}
					selectedFloor={getFloorTarget(
						sites[selectedSiteIndex].floors,
						sitesFloorOverride[sites[selectedSiteIndex].id],
					)}
				/>
			);
		}

		return null;
	}, [
		sites,
		selectedSiteIndex,
		sitesFloorOverride,
		setDropdownExpanded,
		updateSiteFloorSelection,
	]);

	// Hide floor selection widget if site not in view
	useStyleDependantMapEffect(
		mapInstance,
		(map, box) => {
			if (sites === undefined) return;

			box.on("move", () => {
				const mapZoom = map.getZoom();

				const mapBounds = map.getBounds();
				const mapBoundsArray: [number, number, number, number] = [
					mapBounds.getWest(),
					mapBounds.getSouth(),
					mapBounds.getEast(),
					mapBounds.getNorth(),
				];

				const targetSiteIndex = sites.findIndex((site) => {
					//Resolve the desired zoom level for the site
					const siteViewport = resolveSiteBoundaryViewport(
						site,
						mapContainerDimensions,
					);
					if (siteViewport === undefined) {
						//This can happen if the site has no floors.
						//In this case, we should never select the site.
						return false;
					}

					//Check if we are at an appropriate zoom level for this site
					if (mapZoom < siteViewport.zoom) {
						return false;
					}

					//Check if the viewport is visible on the map
					if (
						!doBoundsIntersect(mapBoundsArray, siteViewport.bounds)
					) {
						return false;
					}

					//The site is visible and the map is zoomed in enough,
					//select it!
					return true;
				});

				//No site selection
				if (targetSiteIndex !== -1) {
					setSelectedSiteIndex(targetSiteIndex);
				} else {
					setSelectedSiteIndex(undefined);
				}
			});
		},
		[sites, mapContainerDimensions],
	);

	return (
		<div className={styles.container}>
			<div className={styles.map} ref={mapContainer} />

			<div
				id="border"
				className={classArr(
					styles.mapEditorHighlight,
					(isGeofenceEditor || floorEditState.isOpen) &&
						styles.mapEditorHighlightActive,
				)}
			/>

			<PaginationToast
				show={
					!pairedDevicesState.isComplete &&
					(pairedDevicesState.hasMore ||
						pairedDevicesState.isLoading ||
						mapMovedSinceLastFetch)
				}
				isLoading={pairedDevicesState.isLoading}
				type={mapMovedSinceLastFetch ? "loadArea" : "loadMore"}
				onClickLoad={loadMoreDevices}
			/>

			{floorSelector}
			{clickMenu}

			<MapControls
				className={styles.controls}
				labelFit="Show all assets"
				onFit={fitToMap}
				labelMapStyle={
					mapStyle === MapStyle.Graphic ? "Satellite" : "Graphic"
				}
				onChangeMapStyle={toggleMapStyle}
				onZoomIn={zoomInMap}
				onZoomOut={zoomOutMap}
			/>

			<VelavuModalProvider zIndex={60}>
				<div className={styles.dropdownWrapper}>
					{dropdownComponent}

					{selectedRoute && (
						<div className={styles.layers}>
							<Layers
								showEvents={showLayerEvents}
								setShowEvents={setShowLayerEvents}
								showSpeeding={showLayerSpeeding}
								setShowSpeeding={setShowLayerSpeeding}
							/>
						</div>
					)}
				</div>
			</VelavuModalProvider>
		</div>
	);
}
