// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.W7KhCoVXrQP_HuQBCfsL{width:8px;height:8px;border-radius:4px}`, "",{"version":3,"sources":["webpack://./src/elements/velavu-indicator.module.scss"],"names":[],"mappings":"AAAA,sBACC,SAAA,CACA,UAAA,CACA,iBAAA","sourcesContent":[".indicator {\n\twidth: 8px;\n\theight: 8px;\n\tborder-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicator": `W7KhCoVXrQP_HuQBCfsL`
};
export default ___CSS_LOADER_EXPORT___;
