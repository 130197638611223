import { useCallback, useState } from "react";
import { fileToBase64 } from "../helper/data-helper";

export default interface Base64FileData {
	base64: string;
	name: string;
}

export function useBase64FileState(
	initialFileData?: Base64FileData,
): [
	Base64FileData | undefined,
	(data: Base64FileData | File | undefined) => void,
] {
	const [fileData, setFileData] = useState<Base64FileData | undefined>(
		initialFileData,
	);

	const updateFileData = useCallback(
		(file: File | Base64FileData | undefined) => {
			if (file === undefined) {
				//Clear the selected file
				setFileData(undefined);
			} else {
				if (file instanceof File) {
					//Read the file to base64 and assign state
					fileToBase64(file).then((base64) => {
						setFileData({
							base64: base64,
							name: file.name,
						});
					});
				} else {
					setFileData(file);
				}
			}
		},
		[setFileData],
	);

	return [fileData, updateFileData];
}
