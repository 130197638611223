import { DateTime } from "luxon";
import React, { useCallback, useMemo } from "react";
import {
	DeviceCategory,
	VelavuAlert,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
} from "velavu-js-api";
import IconMoreHorizontal from "../../dynamicicons/icon-more-horizontal";
import AlertIcon from "../../elements/alert-icon";
import Divider from "../../elements/divider";
import VelavuIconButton from "../../elements/velavu-icon-button";
import { VelavuTitledModal, useVelavuModal } from "../../elements/velavu-modal";
import { buildEventDescription } from "../../helper/language-helper";
import DetailModal from "../asset-tag-detail/detail-modal";
import styles from "./alert-item.module.scss";

interface AlertItemProps {
	alert: VelavuAlert;
	unread: boolean;
	baseTime: DateTime;

	onOpenMenu?: (event: AlertsMenu) => void;
	menuOpen?: boolean;
	onDismiss?: () => void;
}

export interface AlertsMenu {
	alert: VelavuAlert;
	anchor: HTMLElement;
	unread: boolean;
}

const AlertItem = React.memo(
	({
		alert,
		baseTime,
		unread,
		onOpenMenu,
		menuOpen,
		onDismiss,
	}: AlertItemProps) => {
		const date: string = useMemo(() => {
			const eventDate = DateTime.fromISO(alert.timestamp).set({
				millisecond: 0,
				second: 0,
			});

			return eventDate.toMillis() === baseTime.toMillis()
				? "Just now"
				: eventDate
						.minus({ minutes: 1 })
						.toRelative({ base: baseTime })!;
		}, [alert.timestamp, baseTime]);

		const pushModal = useVelavuModal();
		const inspectAlert = useCallback(
			async (device?: VelavuDevice, asset?: VelavuAsset) => {
				try {
					pushModal(
						(close) => (
							<VelavuTitledModal
								title={
									asset ? "Asset Details" : "Device Details"
								}
								onClose={close}
							>
								<DetailModal
									asset={asset}
									device={device}
									deviceOnly={
										device?.category ===
										DeviceCategory.Anchor
									}
									defaultTab={asset ? "asset" : "device"}
								/>
							</VelavuTitledModal>
						),
						{ pinToTop: true },
					);
				} catch (error) {
					console.log(error);
				}
			},
			[pushModal],
		);

		const deviceID = alert.device.id;
		const handleAlertItemClick = useCallback(() => {
			//Fetch the device and asset information
			VelavuAPI.devices.getSpecificDevice(deviceID).then((device) => {
				inspectAlert(device, device.asset);
			});
		}, [onDismiss, inspectAlert, deviceID]);

		return (
			<div
				className={`${styles.wrapper} ${
					menuOpen ? styles.selected : ""
				}`}
			>
				<div className={`${styles.twoLevel} ${styles.twoLevelRight}`}>
					<VelavuIconButton
						className={styles.moreButton}
						onClick={(event) =>
							onOpenMenu?.({
								alert: alert,
								anchor: event.currentTarget,
								unread: unread,
							})
						}
						focus={menuOpen}
						small
					>
						<IconMoreHorizontal color="#5F718C" />
					</VelavuIconButton>
					<span
						className={`${styles.date} ${
							unread ? styles.unread : ""
						}`}
					>
						{date}
					</span>
				</div>
				<button
					className={`${styles.container} ${
						unread ? styles.unread : ""
					}`}
					onClick={handleAlertItemClick}
				>
					<div
						className={`${styles.alertIndicator} ${
							unread ? styles.alertIndicatorActive : ""
						}`}
					/>
					<div
						className={`${styles.twoLevel} ${styles.twoLevelLeft}`}
					>
						<span className={styles.title}>
							{alert.asset?.name ?? alert.device.id}
						</span>
						<div className={styles.subtitleContainer}>
							<AlertIcon
								type={alert.event_category}
								size={16}
								color="unset"
							/>
							<span className={styles.subtitle}>
								{buildEventDescription(alert)}
							</span>
						</div>
					</div>
				</button>
				<Divider />
			</div>
		);
	},
);

export default AlertItem;
