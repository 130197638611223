import React, { useMemo } from "react";
import VelavuAssetDevicePair from "src/data/asset-device-pair";
import { VelavuAsset } from "velavu-js-api";
import Divider from "../../../../elements/divider";
import useAssetFiltering from "../../../../helper/assets-filter-hook";
import { NoAssetsIcon } from "../../../../img/icons/icons";
import AssetsNavigationHeader from "../../../shared/assets-navigation-header";
import AssetListFilter from "./asset-list-filter";
import styles from "./asset-list.module.scss";
import AssetListItem from "./asset-listitem";

interface AssetListProps {
	assetDevicePairs: VelavuAssetDevicePair[];
	onSelectAsset: (asset: VelavuAsset) => void;
	onHoverAsset: (asset: VelavuAsset | undefined) => void;
}

function NoResults() {
	return (
		<div className={styles.emptyContent}>
			<NoAssetsIcon />
			<span className={styles.heading}>No results</span>
			<span className={styles.info}>
				Try a different keyword or filter.
			</span>
		</div>
	);
}

export default function AssetList(props: AssetListProps) {
	const {
		assets: filteredAssets,
		filterCategory,
		filterStatus,
		isFiltering,
		searchText,
		setFilterCategory,
		setFilterStatus,
		setSearchText,
		showFilterMenu,
		toggleFilterMenu,
	} = useAssetFiltering(props.assetDevicePairs);

	const {
		onSelectAsset: propsOnSelectAsset,
		onHoverAsset: propsOnHoverAsset,
	} = props;

	const result = useMemo(() => {
		if (filteredAssets && filteredAssets.length === 0) {
			return <NoResults />;
		}

		return filteredAssets.map((asset) => (
			<AssetListItem
				key={asset.id}
				className={styles.item}
				asset={asset}
				onClick={() => propsOnSelectAsset(asset)}
				onMouseEnter={() => propsOnHoverAsset(asset)}
				onMouseLeave={() => propsOnHoverAsset(undefined)}
			/>
		));
	}, [filteredAssets, propsOnHoverAsset, propsOnSelectAsset]);

	return (
		<React.Fragment>
			<AssetsNavigationHeader
				isSearching={true}
				searchText={searchText}
				setSearchText={setSearchText}
				isFiltering={isFiltering}
				isShowFilterMenu={showFilterMenu}
				onToggleFilterMenu={toggleFilterMenu}
			/>
			<Divider />
			{showFilterMenu && (
				<>
					<AssetListFilter
						filterCategory={filterCategory}
						selectCategory={setFilterCategory}
						filterStatus={filterStatus}
						selectStatus={setFilterStatus}
					/>
					<Divider />
				</>
			)}
			<div className={styles.list}>{result}</div>
		</React.Fragment>
	);
}
