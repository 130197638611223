import React from "react";
import styles from "./sidebar-tooltip.module.scss";

export default function SidebarTooltip(props: {
	children?: string | React.ReactNode;
}) {
	return (
		<div className={styles.tooltip}>
			<svg width="4" height="8" viewBox="0 0 1 2">
				<polygon points="1,0 1,2 0,1" fill="#00255D" />
			</svg>
			<span className={styles.tooltipContainer}>{props.children}</span>
		</div>
	);
}
