/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2020.10.20          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

import React from "react";
import { Redirect, Route } from "react-router";
import { RouteProps } from "react-router-dom";

interface AuthRouteProps extends RouteProps {
	isAuthUser: boolean;
	type: "guest" | "private";
}

export default function AuthRoute(props: AuthRouteProps) {
	if (props.type === "guest" && props.isAuthUser) return <Redirect to="/" />;
	else if (props.type === "private" && !props.isAuthUser)
		return <Redirect to="/login" />;

	return <Route {...props} />;
}
