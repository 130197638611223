import React from "react";
import UnpairAssetTag from "../../data/unpair-asset-tag";
import IconUnlink from "../../dynamicicons/icon-unlink";
import Divider from "../../elements/divider";
import VelavuButton from "../../elements/velavu-button";
import styles from "./unpair-modal.module.scss";

export default function UnpairModal(props: {
	data: UnpairAssetTag;
	onCancel: () => void;
	onConfirm: () => void;
}) {
	const labelAsset = props.data.asset.name;
	const labelTag = props.data.tag.id;

	const pairLabels =
		props.data.type === "asset"
			? [labelAsset, labelTag]
			: [labelTag, labelAsset];

	return (
		<div className={styles.container}>
			<span className={styles.title}>
				{props.data.type === "asset"
					? "Do you want to unpair this asset?"
					: "Do you want to unpair this tag?"}
			</span>
			<Divider />
			<div className={styles.innerContainer}>
				<span>{pairLabels[0]}</span>
				<IconUnlink color="#A3B3CC" />
				<span>{pairLabels[1]}</span>
			</div>
			<VelavuButton
				style={{ marginBottom: 8 }}
				label={
					props.data.type === "asset" ? "Unpair asset" : "Unpair tag"
				}
				fullWidth
				onClick={props.onConfirm}
			/>
			<VelavuButton
				label="Cancel"
				outlined
				fullWidth
				onClick={props.onCancel}
			/>
		</div>
	);
}
