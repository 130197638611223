import { useEffect, useMemo } from "react";
import {
	isDeviceLocated,
	LocatedVelavuDevice,
	VelavuDevice,
} from "velavu-js-api";
import useMultiRepo, { MultiRepo } from "../../helper/multi-repo-hook-helper";

export type DashboardMarkersRepo = MultiRepo<LocatedVelavuDevice>;

const getDeviceID = (device: LocatedVelavuDevice) => device.id;

// Manages different sources of device markers and consolidates
// then into a single array
export default function useDashboardMarkersRepo(): DashboardMarkersRepo {
	return useMultiRepo<LocatedVelavuDevice>(getDeviceID);
}

// Syncs the provided device array into the repo,
// automatically updating the repo with any changes,
// and removing the devices on unmount.
export function useSyncDashboardMarkersDevicesSource(
	repo: DashboardMarkersRepo,
	devices: VelavuDevice[],
) {
	//Filter out devices without a location
	const locatedDevices = useMemo(() => {
		return devices.filter(isDeviceLocated);
	}, [devices]);

	//Add the located devices to the repo
	const addDevices = repo.addRepo;
	useEffect(() => {
		if (locatedDevices.length === 0) {
			return;
		}

		return addDevices(locatedDevices);
	}, [addDevices, locatedDevices]);
}

// Syncs the single provided device into the repo
export function useSyncDashboardMarkersSingleDevice(
	repo: DashboardMarkersRepo,
	device: VelavuDevice | undefined,
) {
	//Needs to be memoized to avoid triggering updates
	const array = useMemo(() => {
		if (device === undefined) {
			return [];
		} else {
			return [device];
		}
	}, [device]);

	useSyncDashboardMarkersDevicesSource(repo, array);
}
