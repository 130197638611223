import React, { useMemo, useState } from "react";
import {
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	VelavuInventory,
	VelavuInventoryItem,
	normalizeDeviceHardware,
} from "velavu-js-api";
import IconCircleDeleteFilled from "../../../../dynamicicons/icon-circle-delete-filled";
import IconParentAsset from "../../../../dynamicicons/icon-parent-asset";
import { CategoryTag, GroupTag } from "../../../../elements/categorization-tag";
import DynamicBattery from "../../../../elements/dynamic-battery";
import StatusIndicator from "../../../../elements/status-indicator";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import VelavuSimpleTable, {
	CenteredTD,
	MultilineTD,
	VerticalTD,
} from "../../../../elements/velavu-simple-table";
import { useCancellableEffect } from "../../../../helper/hook-helper";
import {
	mapConnectionStatus,
	mapDeviceHardware,
} from "../../../../helper/language-helper";
import { getAssetStatus } from "../../../../helper/status-helper";
import { lastUpdated } from "../../../../helper/time-helper";
import styles from "./asset-properties.module.scss";

function AssetStatus(props: { asset: VelavuAsset }) {
	const status = useMemo(() => {
		return getAssetStatus(props.asset);
	}, [props.asset]);

	return (
		<>
			<StatusIndicator status={status} />
			<span>{mapConnectionStatus(status)}</span>
		</>
	);
}

export default function AssetProperties(props: {
	asset: VelavuAsset;
	device?: VelavuDevice;
	removeParentAsset: VoidFunction;
	onSelectAsset: (assetID: string) => void;
}) {
	//Find the parent asset object
	const parentAssetID = props.asset.parent_id;
	const [parentAsset, setParentAsset] = useState<
		VelavuAsset | undefined | null
	>(undefined);
	useCancellableEffect(
		(addPromise) => {
			if (parentAssetID === undefined) {
				setParentAsset(null);
				return;
			}

			setParentAsset(undefined);

			addPromise(VelavuAPI.assets.getSpecificAsset(parentAssetID)).then(
				setParentAsset,
			);
		},
		[parentAssetID, setParentAsset],
	);

	const [inventoryItem, setInventoryItem] = useState<
		VelavuInventoryItem | undefined
	>(undefined);
	useCancellableEffect(
		(addPromise) => {
			//Reset state
			setInventoryItem(undefined);

			//Get the asset information
			const assetID = props.asset.id;
			const parentID = props.asset.parent_id;

			//Ignore if this asset has no parent
			if (parentID === undefined) {
				return undefined;
			}

			//Find the asset's inventory item
			addPromise(
				VelavuAPI.assetInventory.getAssetInventory(parentID),
			).then((inventory: VelavuInventory) => {
				const item = inventory?.items?.find(
					(item) => item.asset.id === assetID,
				);

				if (item !== undefined) {
					setInventoryItem(item);
				}
			});
		},
		[props.asset.id, props.asset.parent_id, setInventoryItem],
	);

	return (
		<div>
			<VelavuSimpleTable>
				<tbody>
					<tr>
						<td>Category</td>
						<td>
							<CategoryTag category={props.asset.category} />
						</td>
					</tr>
					<tr>
						<td>Group</td>
						<td>
							<GroupTag
								categorization={{
									category: props.asset.category,
									group: props.asset.group,
								}}
							/>
						</td>
					</tr>
					{parentAsset !== null && (
						<tr>
							<td>Parent</td>
							<td>
								<div className={styles.parent}>
									<IconParentAsset color="#5F718C" />
									<div className={styles.parentInfo}>
										<button
											className={styles.parentName}
											onClick={() =>
												props.asset.parent_id &&
												props.onSelectAsset(
													props.asset.parent_id,
												)
											}
										>
											{parentAsset?.name ?? "Checking..."}
										</button>
										{inventoryItem?.proximity !==
											undefined && (
											<span>
												{` `}
												{`(${inventoryItem.proximity.distance}m)`}
											</span>
										)}
									</div>
									<VelavuIconButton
										className={styles.removeParent}
										tooltip="Remove"
										reallySmall={true}
										onClick={props.removeParentAsset}
									>
										<IconCircleDeleteFilled
											size={16}
											color="#5F718C"
										/>
									</VelavuIconButton>
								</div>
							</td>
						</tr>
					)}
					<tr>
						<td>Notes</td>
						<MultilineTD>
							{props.asset.notes ? props.asset.notes : "None"}
						</MultilineTD>
					</tr>
				</tbody>
			</VelavuSimpleTable>
			{props.device && (
				<VelavuSimpleTable style={{ marginTop: 8 }}>
					<tbody>
						<tr>
							<td>Device</td>
							<td>
								{mapDeviceHardware(
									normalizeDeviceHardware(
										props.device.hardware,
									),
								)}
							</td>
						</tr>
						<tr>
							<td>Tag ID</td>
							<td>{props.device.id}</td>
						</tr>
						<tr>
							<td>Status</td>
							{props.device.online ? (
								<CenteredTD>
									<AssetStatus asset={props.asset} />
								</CenteredTD>
							) : (
								<VerticalTD>
									<div className={styles.centerRow}>
										<AssetStatus asset={props.asset} />
									</div>

									{props.device.location?.timestamp && (
										<span className={styles.statusText}>
											{`Last seen: ${lastUpdated(
												props.device.location.timestamp,
											)}`}
										</span>
									)}
								</VerticalTD>
							)}
						</tr>
						<tr>
							<td>Battery</td>
							<CenteredTD>
								<span style={{ marginRight: 4 }}>
									{props.device.state?.power
										?.battery_level !== undefined
										? `${props.device.state.power.battery_level}%`
										: "N/A"}
								</span>
								<DynamicBattery
									resource={props.device.state?.power}
								/>
							</CenteredTD>
						</tr>
					</tbody>
				</VelavuSimpleTable>
			)}
		</div>
	);
}
