import { get } from "aws-amplify/api";
import {
	Dispatch,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	LocationType,
	operationToJSON,
	VelavuAPI,
	VelavuDevice,
	VelavuGeofence,
	VelavuSite,
} from "velavu-js-api";
import { API_NAME_MAPBOX } from "../dao/amplify-utils";
import AssetCategorization, {
	convertCategoryGroupsToAssetCategorizations,
} from "../data/asset-categorization";
import SignInContext from "../sign-in-context";
import { findArrayDiff } from "./array-helper";
import { useCancellableEffect } from "./hook-helper";

//https://docs.mapbox.com/api/search/geocoding/#geocoding-response-object
type GeocodingFeaturePlaceType =
	| "country"
	| "region"
	| "postcode"
	| "district"
	| "place"
	| "locality"
	| "neighborhood"
	| "address"
	| "poi";

interface GeocodingFeatureResult {
	id: string;
	type: "Feature";
	place_type: GeocodingFeaturePlaceType[];
	relevance: number;
	address?: string;
	text: string;
	place_name: string;
}

interface GeocodingResult {
	type: "FeatureCollection";
	query: string[] | number[];
	features: GeocodingFeatureResult[];
	attribution: string;
}

//Converts a set of coordinates to a human-readable address
export async function requestGeocoding(
	mapboxToken: string,
	coordinates: [number, number],
): Promise<string> {
	//Perform a reverse-geocoding lookup
	const result = await operationToJSON<GeocodingResult>(
		get({
			apiName: API_NAME_MAPBOX,
			path: `/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json`,
			options: {
				queryParams: {
					access_token: mapboxToken,
				},
			},
		}),
	);

	//Get the address
	const placeName = result.features[0].place_name.split(",");
	const address = placeName[0];
	const region = placeName[1];
	return `${address}, ${region}`;
}

export async function requestCityGeocoding(
	mapboxToken: string,
	coordinates: [number, number],
): Promise<string | undefined> {
	//Perform a reverse-geocoding lookup
	const response = await operationToJSON<GeocodingResult>(
		get({
			apiName: API_NAME_MAPBOX,
			path: `/geocoding/v5/mapbox.places/${coordinates[0]},${coordinates[1]}.json`,
			options: {
				queryParams: {
					access_token: mapboxToken,
				},
			},
		}),
	);

	//Get the place (which is usually a city)
	return response.features.find((feature) => {
		return feature.place_type.includes("place");
	})?.text;
}

export function useGeocoder(coordinates?: [number, number]): string {
	const [location, setLocation] = useState<string>("Checking location…");
	const user = useContext(SignInContext);

	useCancellableEffect(
		(addPromise) => {
			//Ignoring if there's no coordinates
			if (!coordinates) return;

			//Getting the mapbox token
			const mapboxToken = user.velavuUser?.mapbox_token;
			if (mapboxToken === undefined) {
				console.warn("Geocoder failed; no Mapbox token");
				return;
			}

			//Fetching the asset location
			addPromise(requestGeocoding(mapboxToken!, coordinates))
				.then(setLocation)
				.catch((error) => {
					//Default to geo coordinates if address couldn't be loaded
					setLocation(coordinates.join(", "));
					console.log(error);
				});
		},
		[coordinates, user.velavuUser],
	);

	return location;
}

const displayLocationPending = "Checking location...";
const displayNoLocationAvailable = "No location available";

/**
 * Gets a human-readable display location for a device.
 * The display location of the device may be either the device's
 * site name, its address, or its coordinates.
 * @param device The device to get the display location of
 * @return A human-readable display location of the device
 */
export function useDisplayLocation(device: VelavuDevice | undefined) {
	const [display, setDisplay] = useState<string>(displayLocationPending);
	const user = useContext(SignInContext);

	useCancellableEffect(
		(addPromise) => {
			setDisplay(displayLocationPending);

			addPromise(
				(async (): Promise<string> => {
					//Set a fallback string if there is no device
					if (device === undefined) {
						return displayNoLocationAvailable;
					}

					//Cellular isn't very accurate, so only look up
					//the approximate location
					const approximate =
						device?.location?.location_type ===
						LocationType.Cellular;

					//Attempt to look up the device's site
					if (!approximate && device.site_id !== undefined) {
						try {
							const targetSite =
								await VelavuAPI.sites.getSpecificSite(
									device.site_id,
								);
							return targetSite.name;
						} catch (error) {
							console.warn(
								`Failed to fetch site ${device.site_id}`,
								error,
							);
							//Fall through and try device address
						}
					}

					//Attempt to look up the device's address
					if (device.location !== undefined) {
						//Get the Mapbox token
						const mapboxToken = user.velavuUser?.mapbox_token;
						if (mapboxToken === undefined) {
							console.warn("Geocoder failed; no Mapbox token");
							return displayNoLocationAvailable;
						}

						const coordinates = device.location.coordinates;

						//Fetch the asset location
						try {
							if (approximate) {
								const cityResult = await requestCityGeocoding(
									mapboxToken,
									coordinates,
								);
								if (cityResult === undefined) {
									return "Unknown location";
								} else {
									return `${cityResult} (approximate)`;
								}
							} else {
								return await requestGeocoding(
									mapboxToken,
									coordinates,
								);
							}
						} catch (error) {
							console.log(error);

							//Default to geo coordinates if address couldn't be loaded
							return coordinates.join(", ");
						}
					}

					//No way to determine the device's location
					return displayNoLocationAvailable;
				})(),
			)
				.then(setDisplay)
				.catch(console.warn);
		},
		[device],
	);

	return display;
}

//Loads assets and compiles them into a categorization list for autocomplete
export function useCategorizationSuggestions(): AssetCategorization[] {
	//Load asset metadata from global organization state
	const organizationMetaAssets =
		useContext(SignInContext).organization?.meta?.assets;

	return useMemo(() => {
		if (organizationMetaAssets === undefined) {
			return [];
		} else {
			return convertCategoryGroupsToAssetCategorizations(
				organizationMetaAssets.category_groups,
			);
		}
	}, [organizationMetaAssets]);
}

type PendingDownload = { key: string; id: number };

/**
 * Syncs a selection of string IDs with remote data
 * @param selection The selection of IDs to sync
 * @param downloadParam A parameter that affects the downloaded data
 * @param download A function that returns a promise of the downloaded data
 * @return data An array of the currently available data
 */
export function useRemoteSync<R, P>(
	selection: string[],
	downloadParam: P,
	download: (id: string, param: P) => Promise<R>,
): { [key: string]: R } {
	const [data, setData] = useState<{ [key: string]: R }>({});

	const prevSelection = useRef<string[]>([]);
	const prevDownloadParam = useRef<P>(downloadParam);
	const pendingDownloadArray = useRef<PendingDownload[]>([]);
	const pendingDownloadID = useRef(0);

	const startDownload = useCallback(
		(key: string) => {
			//Adding a new pending download
			const pendingDownload: PendingDownload = {
				key: key,
				id: pendingDownloadID.current++,
			};
			pendingDownloadArray.current.push(pendingDownload);

			//Downloading the entry
			download(key, downloadParam).then((result) => {
				//Check if this download hasn't been cancelled
				if (!pendingDownloadArray.current.includes(pendingDownload)) {
					return;
				}

				//Adding the item
				setData((data) => ({ ...data, [key]: result }));
			});
		},
		[download, downloadParam],
	);

	useEffect(() => {
		// If the download parameter changed, remove all data and re-download
		if (downloadParam !== prevDownloadParam.current) {
			//Clear all pending downloads
			pendingDownloadArray.current = [];

			//Download all entries
			for (const addedKey of selection) {
				startDownload(addedKey);
			}
		} else {
			//Compare to previous
			const { only1: removed, only2: added } = findArrayDiff(
				prevSelection.current,
				selection,
			);

			if (removed.length > 0) {
				//Delete removed entries
				setData((data) => {
					const newData = { ...data };
					for (const removedKey of removed) {
						delete newData[removedKey];
					}

					return newData;
				});

				//Cancel pending downloads that have been removed
				pendingDownloadArray.current =
					pendingDownloadArray.current.filter((download) =>
						removed.includes(download.key),
					);
			}

			//Download new entries
			for (const addedKey of added) {
				startDownload(addedKey);
			}
		}

		//Update the previous values
		prevDownloadParam.current = downloadParam;
		prevSelection.current = selection;
	}, [selection, downloadParam, download, startDownload]);

	return data;
}

//Gets the Mapbox API key from the current user
export function useMapboxAPIKey() {
	return useContext(SignInContext).velavuUser?.mapbox_token;
}

export function useSites(): [
	VelavuSite[] | undefined,
	Dispatch<SetStateAction<VelavuSite[] | undefined>>,
] {
	const [sites, setSites] = useState<VelavuSite[] | undefined>(undefined);

	useCancellableEffect((addPromise) => {
		addPromise(VelavuAPI.sites.getAllSites())
			.then(setSites)
			.catch((error) =>
				console.log("There was a sites get error %O", error.response),
			);
	}, []);

	return [sites, setSites];
}

export function useGeofences(): [
	VelavuGeofence[] | undefined,
	Dispatch<SetStateAction<VelavuGeofence[] | undefined>>,
] {
	const [geofences, setGeofences] = useState<VelavuGeofence[] | undefined>(
		undefined,
	);

	useCancellableEffect((addPromise) => {
		addPromise(VelavuAPI.geofences.getAllGeofences())
			.then(setGeofences)
			.catch((error) =>
				console.log(
					"There was a geofences get error %O",
					error.response,
				),
			);
	}, []);

	return [geofences, setGeofences];
}
