import React, { CSSProperties } from "react";

export default function IconCrop(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				d="M18 13.5C18.6 13.5 19 13.9 19 14.5V15.5C19 16.4 18.6 17.3 18 18 17.3 18.6 16.4 19 15.5 19H14.5C13.9 19 13.5 18.6 13.5 18 13.5 17.4 13.9 17 14.5 17H15.5C15.9 17 16.3 16.8 16.6 16.6 16.8 16.3 17 15.9 17 15.5V14.5C17 13.9 17.4 13.5 18 13.5Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M13.5 6C13.5 5.4 13.9 5 14.5 5H15.5C16.4 5 17.3 5.4 18 6 18.6 6.7 19 7.6 19 8.5V9.5C19 10.1 18.6 10.5 18 10.5 17.4 10.5 17 10.1 17 9.5V8.5C17 8.1 16.8 7.7 16.6 7.4 16.3 7.2 15.9 7 15.5 7H14.5C13.9 7 13.5 6.6 13.5 6Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M6 13.5C6.6 13.5 7 13.9 7 14.5V15.5C7 15.9 7.2 16.3 7.4 16.6 7.7 16.8 8.1 17 8.5 17H9.5C10.1 17 10.5 17.4 10.5 18 10.5 18.6 10.1 19 9.5 19H8.5C7.6 19 6.7 18.6 6 18 5.4 17.3 5 16.4 5 15.5V14.5C5 13.9 5.4 13.5 6 13.5Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M8.5 7C8.1 7 7.7 7.2 7.4 7.4 7.2 7.7 7 8.1 7 8.5V9.5C7 10.1 6.6 10.5 6 10.5 5.4 10.5 5 10.1 5 9.5V8.5C5 7.6 5.4 6.7 6 6 6.7 5.4 7.6 5 8.5 5H9.5C10.1 5 10.5 5.4 10.5 6 10.5 6.6 10.1 7 9.5 7H8.5Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
