import React from "react";
import {
	DevicePowerUnion,
	useDevicePowerUnion,
} from "../helper/resource-helper";

const batteryBackground = "#E2E5E5";
const batteryColors = ["#DA3E52", "#FFD338", "#9CE2BF"];

//A battery icon that can dynamically generate a vector drawable for its power level and charging state
export default function DynamicBattery(props: DevicePowerUnion) {
	const { level, charging } = useDevicePowerUnion(props);

	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g
				clipPath={
					charging
						? "path('M24,0 L24,24 L0,24 L0,0 L24,0 Z M9.5,9 L9.5,11 L4,11 L11.5,15 L11.5,13 L17,13 L9.5,9 Z')"
						: undefined
				}
			>
				<path d="M0 0h24v24H0V0z" fill="none" />
				<path
					d="M20 15.67L20 14 22 14 22 10 20 10 20 8.33C20 7.6 19.4 7 18.67 7L3.34 7C2.6 7 2 7.6 2 8.33L2 15.66C2 16.4 2.6 17 3.33 17L18.67 17C19.4 17 20 16.4 20 15.67Z"
					fill={batteryBackground}
				/>
				<path
					d="M20 15.67L20 14 22 14 22 10 20 10 20 8.33C20 7.6 19.4 7 18.67 7L3.34 7C2.6 7 2 7.6 2 8.33L2 15.66C2 16.4 2.6 17 3.33 17L18.67 17C19.4 17 20 16.4 20 15.67Z"
					fill={getBatteryColor(level)}
					clipPath={`inset(0 ${(1 - level) * 20}px 0 0)`}
				/>
			</g>
		</svg>
	);
}

//Gets the battery color to display depending on the battery level
function getBatteryColor(level: number) {
	if (level < 1 / 3) return batteryColors[0];
	else if (level < 2 / 3) return batteryColors[1];
	else return batteryColors[2];
}
