import React, { CSSProperties } from "react";
import styles from "./pinned-list.module.scss";
import { classArr } from "../../../helper/style-helper";

export interface PinnedListProps {
	style?: CSSProperties;
	className?: string;
	children: React.ReactNode;
}

export default function PinnedList(props: PinnedListProps) {
	return (
		<div
			style={props.style}
			className={classArr(styles.container, props.className)}
		>
			{props.children}
		</div>
	);
}
