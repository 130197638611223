import React, { CSSProperties } from "react";
import { VelavuSite } from "velavu-js-api";
import IconInfo from "../../dynamicicons/icon-info";
import SelectableCard from "../../elements/selectable-card";
import StatusIndicator from "../../elements/status-indicator";
import VelavuIconButton from "../../elements/velavu-icon-button";
import { useGeocoder } from "../../helper/api-helper";
import styles from "./site-switcher-list-item.module.scss";

interface SiteListItemProps {
	style?: CSSProperties;
	className?: string;

	site: VelavuSite;
	onClick?: VoidFunction;
	onClickDetails?: VoidFunction;
}

export default function SiteSwitcherListItem(props: SiteListItemProps) {
	const location = useGeocoder(props.site.coordinates);

	const status = props.site.online ? "active" : "inactive";

	return (
		<SelectableCard
			style={props.style}
			className={`${styles.container} ${props.className ?? ""}`}
			onClick={props.onClick}
		>
			<StatusIndicator className={styles.status} status={status} />
			<div className={styles.text}>
				<span className={styles.title}>{props.site.name}</span>
				<span className={styles.subtitle}>{location}</span>
			</div>
			{props.onClickDetails !== undefined && (
				<VelavuIconButton onClick={props.onClickDetails}>
					<IconInfo color="#A3B3CC" />
				</VelavuIconButton>
			)}
		</SelectableCard>
	);
}
