import React, { CSSProperties } from "react";

export default function IconCircleAdd(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 22}
			height={props.size ?? 22}
			viewBox="0 0 22 22"
		>
			<path
				d="M11 5.76023C10.4494 5.76023 10.003 6.20661 10.003 6.75725V10.0029H6.75737C6.20673 10.0029 5.76035 10.4492 5.76035 10.9999C5.76035 11.5505 6.20673 11.9969 6.75737 11.9969H10.003V15.2425C10.003 15.7932 10.4494 16.2395 11 16.2395C11.5506 16.2395 11.997 15.7932 11.997 15.2425V11.9969H15.2426C15.7933 11.9969 16.2397 11.5505 16.2397 10.9999C16.2397 10.4492 15.7933 10.0029 15.2426 10.0029H11.997V6.75725C11.997 6.20661 11.5506 5.76023 11 5.76023ZM3.92894 3.92882C0.0186408 7.83912 0.0186408 14.1607 3.92894 18.071C7.83924 21.9813 14.1608 21.9813 18.0711 18.071C21.9814 14.1607 21.9814 7.83912 18.0711 3.92882C14.1608 0.0185187 7.83924 0.0185187 3.92894 3.92882ZM16.6569 16.6567C13.5385 19.7751 8.4615 19.7751 5.34315 16.6567C2.22481 13.5384 2.22481 8.46137 5.34315 5.34303C8.4615 2.22469 13.5385 2.22469 16.6569 5.34303C19.7752 8.46137 19.7752 13.5384 16.6569 16.6567Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
