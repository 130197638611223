import React, { CSSProperties } from "react";
import { NormalizedDeviceHardware } from "velavu-js-api";
import IconArda from "../dynamicicons/icon-arda";
import IconManta from "../dynamicicons/icon-manta";
import IconPavo from "../dynamicicons/icon-pavo";
import IconVesta from "../dynamicicons/icon-vesta";
import { classArr } from "../helper/style-helper";

export default function DeviceIcon(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
	hardware?: NormalizedDeviceHardware;
}) {
	const color = props.color;
	const size = props.size ?? 16;

	const iconProps = {
		style: props.style,
		className: classArr(props.className),
		color,
		size,
	};

	switch (props.hardware) {
		case NormalizedDeviceHardware.Argo:
			return <IconVesta {...iconProps} />;
		case NormalizedDeviceHardware.Juno:
			return <IconArda {...iconProps} />;
		case NormalizedDeviceHardware.Meridian:
			return <IconPavo {...iconProps} />;
		case NormalizedDeviceHardware.Pisces:
			return <IconManta {...iconProps} />;
		default:
			return null;
	}
}
