import React, { CSSProperties } from "react";

export default function IconVelavuAlerts(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M18.8765 16.79L17.5865 15.5V10.5C17.5865 7.43 15.9465 4.86 13.0865 4.18V3.5C13.0865 2.67 12.4165 2 11.5865 2C10.7565 2 10.0865 2.67 10.0865 3.5V4.18C7.21648 4.86 5.58648 7.42 5.58648 10.5V15.5L4.29648 16.79C3.66648 17.42 4.10648 18.5 4.99648 18.5H18.1665C19.0665 18.5 19.5065 17.42 18.8765 16.79ZM15.5865 16.5H7.58648V10.5C7.58648 8.02 9.09648 6 11.5865 6C14.0765 6 15.5865 8.02 15.5865 10.5V16.5ZM11.5865 21.5C12.6865 21.5 13.5865 20.6 13.5865 19.5H9.58648C9.58648 20.0304 9.79719 20.5391 10.1723 20.9142C10.5473 21.2893 11.056 21.5 11.5865 21.5Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
