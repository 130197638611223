import React, { useMemo } from "react";
import styles from "./velavu-segmented-controls.module.scss";

/**
 * A velavu implementation of Apple's segmented controls
 *
 * From Apple's Human Interface Guidelines:
 *
 * A segmented control is a horizontal set of two or more segments, each of which functions as a button — usually a toggle button.
 * Segmented controls provide closely related choices that affect an object, state, or view. Like buttons, segments can contain text or glyphs.
 *
 * https://developer.apple.com/design/human-interface-guidelines/macos/selectors/segmented-controls/
 */
export default function VelavuSegmentedControls(props: {
	selectedIndex: number;
	onSelectIndex: (index: number) => void;
	labels: string[];
}) {
	const radioGroup = useMemo(() => "segmented-" + Math.random(), []);

	return (
		<div className={styles.container}>
			{props.labels.map((label, i) => (
				<label key={i} className={styles.button}>
					<input
						type="radio"
						name={radioGroup}
						value={i}
						checked={props.selectedIndex === i}
						onChange={(event) =>
							props.onSelectIndex(
								parseInt(
									(event.target as HTMLInputElement).value,
								),
							)
						}
					/>
					<span>{label}</span>
				</label>
			))}

			<div
				className={styles.selectionBubble}
				style={{ left: 4 + props.selectedIndex * (100 + 4) }}
			/>
		</div>
	);
}
