import React, { CSSProperties } from "react";
import { VelavuDevice } from "velavu-js-api";
import VelavuExpandMenu from "../../elements/velavu-expand-menu";
import VelavuSimpleTable from "../../elements/velavu-simple-table";
import { lastUpdated } from "../../helper/time-helper";

export default function TabTagEnvironment(props: {
	style?: CSSProperties;
	className?: string;
	tag: VelavuDevice;
}) {
	if (!props.tag.environment) return null;

	return (
		<VelavuExpandMenu
			style={props.style}
			className={props.className}
			label="Environment"
		>
			<VelavuSimpleTable>
				<tbody>
					{props.tag.environment?.temperature_c && (
						<tr>
							<td>Temperature</td>
							<td>{props.tag.environment.temperature_c} °C</td>
						</tr>
					)}
					{props.tag.environment?.humidity && (
						<tr>
							<td>Humidity</td>
							<td>{props.tag.environment.humidity}%</td>
						</tr>
					)}
					{props.tag.environment?.timestamp && (
						<tr>
							<td>Last updated</td>
							<td>
								{lastUpdated(props.tag.environment.timestamp)}
							</td>
						</tr>
					)}
				</tbody>
			</VelavuSimpleTable>
		</VelavuExpandMenu>
	);
}
