import {
	AssetCategory,
	RawDeviceHardware,
	VelavuInventoryItem,
} from "velavu-js-api";

const wordSplitter = / +/;

export function compoundSearchAssets<
	T extends { name: string; category: AssetCategory; group: string },
>(assets: T[], query: string): T[] {
	const preparedQuery = query.trim().toLowerCase();
	if (preparedQuery.length === 0) return assets;

	const querySplit = preparedQuery.split(wordSplitter);
	return assets.filter((asset) =>
		checkMatchStringSplits(
			querySplit,
			asset.name,
			asset.category,
			asset.group,
		),
	);
}

export function compoundSearchTags<
	T extends {
		id: string;
		hardware?: RawDeviceHardware;
		asset?: { name: string };
	},
>(tags: T[], query: string) {
	const preparedQuery = query.trim().toLowerCase();
	if (preparedQuery.length === 0) return tags;

	const querySplit = preparedQuery.split(wordSplitter);
	return tags.filter((tag) =>
		checkMatchStringSplits(
			querySplit,
			...([tag.id, tag.hardware, tag.asset?.name].filter(
				(value) => value,
			) as string[]),
		),
	);
}

export function compoundSearchInventory(
	inventory: VelavuInventoryItem[],
	query: string,
) {
	const preparedQuery = query.trim().toLowerCase();
	if (preparedQuery.length === 0) return inventory;

	const querySplit = preparedQuery.split(wordSplitter);
	return inventory.filter((item) =>
		checkMatchStringSplits(
			querySplit,
			item.asset.name,
			item.asset.category,
			item.asset.group,
		),
	);
}

/**
 * Compares a list of properties against a query split for a full consume match
 */
function checkMatchStringSplits(
	querySplit: string[],
	...propertyStrings: string[]
): boolean {
	//Copying the query split
	const localQuery = [...querySplit];

	//Comparing each item
	for (const property of propertyStrings) {
		//Comparing the property split against the query split
		const compareResult = compareWordSplits(
			property.toLowerCase().split(wordSplitter),
			localQuery,
		);

		//Checking if the result was successful
		if (compareResult) {
			//Splicing the consumed portion out of the local query
			localQuery.splice(
				compareResult[0],
				compareResult[1] - compareResult[0],
			);

			//Confirming the match if we have completed the local query
			if (localQuery.length === 0) return true;
		}
	}

	//We reached the end without completing the local query, fail search
	return false;
}

/**
 * Compares a source and contains word split array, returning the matching range (upper exclusive), or undefined if a match couldn't be found
 */
function compareWordSplits(
	sourceArray: string[],
	containsArray: string[],
): [number, number] | undefined {
	//Try to find an initial match between the arrays
	let initialMatch: [number, number] | undefined = undefined;
	master: for (let iSource = 0; iSource < sourceArray.length; iSource++) {
		for (let iContains = 0; iContains < containsArray.length; iContains++) {
			if (sourceArray[iSource].startsWith(containsArray[iContains])) {
				initialMatch = [iSource, iContains];
				break master;
			}
		}
	}

	//No match, fail
	if (!initialMatch) return undefined;

	//Continue scanning forwards at offset +1, consuming as many matches as possible
	const offsetLimit = Math.min(
		sourceArray.length - initialMatch[0],
		containsArray.length - initialMatch[1],
	);
	for (let i = 1; i < offsetLimit; i++) {
		const source = sourceArray[initialMatch[0] + i];
		const contains = containsArray[initialMatch[1] + i];

		//Consuming as many more splits as possible
		if (source.startsWith(contains)) continue;

		//This is the end of our match section
		return [initialMatch[1], initialMatch[1] + i];
	}

	//We matched all the way to the end!
	return [initialMatch[1], initialMatch[1] + offsetLimit];
}
