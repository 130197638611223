import React, { useCallback } from "react";
import {
	DeviceCategory,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	normalizeDeviceHardware,
} from "velavu-js-api";
import UnpairAssetTag from "../../../data/unpair-asset-tag";
import IconLink from "../../../dynamicicons/icon-link";
import CategorizationTag from "../../../elements/categorization-tag";
import StatusIndicator from "../../../elements/status-indicator";
import VelavuItemIcon from "../../../elements/velavu-item-icon";
import { mapDeviceHardware } from "../../../helper/language-helper";
import styles from "./asset-link.module.scss";
import LinkCard from "./link-card";

interface AssetLinkProps {
	asset?: VelavuAsset;
	device?: VelavuDevice;
	deviceOnly?: boolean;
	onUnpair: (type: UnpairAssetTag) => void;
	onPair: (data: UnpairAssetTag) => void;

	defaultOpenPair?: undefined | "asset" | "tag";

	onClickAssetName?: () => void;
}

//The maximum number of search suggestions to fetch from the backend
const SUGGESTION_LIMIT = 8;

export default function AssetLink(props: AssetLinkProps) {
	const pairLinked = props.asset && props.device;

	const fetchAssets = useCallback(async (filter: string | undefined) => {
		const response = await VelavuAPI.assets.getAllAssets(
			{
				//We only want unpaired assets
				paired: false,
				//Filter by name
				name: filter,
			},
			{ limit: SUGGESTION_LIMIT },
		);

		return response.data;
	}, []);

	const fetchTags = useCallback(async (filter: string | undefined) => {
		const response = await VelavuAPI.devices.getAllDevices(
			{
				//We only want tags
				category: DeviceCategory.Tag,
				//We only want unpaired devices
				paired: false,
				//Filter by ID
				id: filter,
			},
			{ limit: SUGGESTION_LIMIT },
		);

		return response.data;
	}, []);

	const getStatus = (tag: VelavuDevice) => {
		switch (tag.online) {
			case true:
				return "active";
			case false:
				return "inactive";
			default:
				return "registered";
		}
	};

	return (
		<div className={styles.container}>
			{!props.deviceOnly && (
				<LinkCard
					title="Asset"
					disableUnpair={!pairLinked}
					onPair={(asset) =>
						props.onPair({
							type: "asset",
							asset: asset,
							tag: props.device!,
						})
					}
					onUnpair={() =>
						props.onUnpair({
							type: "asset",
							asset: props.asset!,
							tag: props.device!,
						})
					}
					onClickName={props.onClickAssetName}
					linkDetails={
						props.asset
							? {
									icon: (
										<VelavuItemIcon
											data={
												props.asset.profile_img_url
													? {
															url: props.asset
																.profile_img_url,
													  }
													: props.asset.category
											}
											status={
												props.device
													? props.device.online
														? "active"
														: "inactive"
													: "unpaired"
											}
										/>
									),
									name: props.asset.name,
									subtitle: (
										<CategorizationTag
											categorization={{
												category: props.asset.category,
												group: props.asset.group,
											}}
										/>
									),
							  }
							: undefined
					}
					blankLabel="No Asset"
					unpairLabel="Unpair Asset"
					fetchData={fetchAssets}
					mapDataID={(asset) => asset.id}
					renderData={(asset) => (
						<div className={styles.assetAutocompleteItem}>
							<span className={styles.assetAutocompleteTitle}>
								{asset.name}
							</span>
							<CategorizationTag
								categorization={{
									category: asset.category,
									group: asset.group,
								}}
							/>
						</div>
					)}
					addData={{ label: "New Asset", link: "/assets/create" }}
					defaultOpen={props.defaultOpenPair === "asset"}
				/>
			)}

			{!props.deviceOnly && (
				<div className={styles.linkIndicator}>
					<IconLink color="#A3B3CC" />
				</div>
			)}

			<LinkCard
				title="Device"
				disableUnpair={!pairLinked}
				hideUnpair={props.device?.category === DeviceCategory.Anchor}
				onPair={(tag) =>
					props.onPair({ type: "tag", asset: props.asset!, tag: tag })
				}
				onUnpair={() =>
					props.onUnpair({
						type: "tag",
						asset: props.asset!,
						tag: props.device!,
					})
				}
				linkDetails={
					props.device
						? {
								icon: (
									<VelavuItemIcon
										data={normalizeDeviceHardware(
											props.device?.hardware,
										)}
									/>
								),
								name: props.device.id,
								subtitle: mapDeviceHardware(
									normalizeDeviceHardware(
										props.device.hardware,
									),
								),
						  }
						: undefined
				}
				blankLabel="No Device"
				unpairLabel="Unpair Device"
				fetchData={fetchTags}
				mapDataID={(tag) => tag.id}
				renderData={(tag) => (
					<div key={tag.id} className={styles.tagAutocompleteItem}>
						<div className={styles.tagAutocompleteText}>
							<span className={styles.tagAutocompleteTitle}>
								{tag.id}
							</span>
							<span className={styles.tagAutocompleteSubtitle}>
								{mapDeviceHardware(
									normalizeDeviceHardware(tag.hardware),
								)}
							</span>
						</div>
						<StatusIndicator status={getStatus(tag)} />
					</div>
				)}
				defaultOpen={props.defaultOpenPair === "tag"}
			/>
		</div>
	);
}
