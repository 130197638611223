import React, { useCallback, useRef, useState } from "react";
import { VelavuAPI, VelavuSite, VelavuFloor } from "velavu-js-api";
import IconDelete from "../../../../dynamicicons/icon-delete";
import IconEdit from "../../../../dynamicicons/icon-edit";
import IconMoreHorizontal from "../../../../dynamicicons/icon-more-horizontal";
import SelectableCard from "../../../../elements/selectable-card";
import VelavuButton from "../../../../elements/velavu-button";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import VelavuMenu, { VelavuMenuItem } from "../../../../elements/velavu-menu";
import {
	useVelavuModal,
	VelavuModalPrompt,
} from "../../../../elements/velavu-modal";
import { mapFloorNumber } from "../../../../helper/language-helper";
import styles from "./site-detail-floors.module.scss";
import { NoFloorsIcon } from "../../../../img/icons/icons";

interface SiteDetailFloorsProps {
	site: VelavuSite;

	selectedFloor: string | undefined;
	onSelectFloor?: (id: string | undefined) => void;

	onDeletePlan?: (floor: VelavuFloor) => void;
	onEditFloor?: (floor: VelavuFloor) => void;
	onCreateFloor?: () => void;
}

function NoFloors() {
	return (
		<div className={styles.emptyContent}>
			<NoFloorsIcon />
			<span className={styles.heading}>No floorplan</span>
			<span className={styles.info}>
				Add a floor plan to complete your site setup.
			</span>
		</div>
	);
}

export default function SiteDetailFloors(props: SiteDetailFloorsProps) {
	const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
	const pushModal = useVelavuModal();

	const {
		site: propsSite,
		onSelectFloor: propsOnSelectFloor,
		onCreateFloor: propsOnCreateFloor,
		onEditFloor: propsOnEditFloor,
		onDeletePlan: propsOnDeletePlan,
	} = props;

	const activeMenuFloorID = useRef<string>("");

	const openMenu = useCallback(
		(event: React.MouseEvent<HTMLElement>, floorID: string) => {
			setMenuAnchor(event.currentTarget);
			activeMenuFloorID.current = floorID;
		},
		[setMenuAnchor],
	);

	const closeMenu = useCallback(() => {
		setMenuAnchor(null);
	}, [setMenuAnchor]);

	const editFloorPlan = useCallback(() => {
		closeMenu();

		const floor = propsSite.floors!.find(
			(floor) => floor.id === activeMenuFloorID.current,
		)!;

		propsOnEditFloor?.(floor);
	}, [closeMenu, propsOnEditFloor, propsSite]);

	const deleteFloorPlan = useCallback(() => {
		closeMenu();

		const floorID = activeMenuFloorID.current;

		const floor = propsSite.floors!.find((floor) => floor.id === floorID)!;
		pushModal((resolve) => (
			<VelavuModalPrompt
				title={"Delete the following floor plan?"}
				object={floor.name}
				labelConfirm="Delete"
				confirmDanger
				onSelect={resolve}
			>
				The floor plan and all associated data will be permanently
				deleted.
			</VelavuModalPrompt>
		)).then((result) => {
			if (result) {
				VelavuAPI.siteFloors
					.deleteSiteFloor(propsSite.id, floorID)
					.then(() => {
						propsOnSelectFloor?.(undefined);
						propsOnDeletePlan?.(floor);
					})
					.catch(() => alert(`Failed to delete ${floor.name}`));
			}
		});
	}, [
		closeMenu,
		pushModal,
		propsOnDeletePlan,
		propsSite,
		propsOnSelectFloor,
	]);

	let result;
	if (!propsSite?.floors?.length) {
		result = <NoFloors />;
	} else {
		result = (props.site.floors ?? [])
			.sort((a, b) => b.level - a.level)
			.map((floor) => (
				<SelectableCard
					key={floor.id}
					className={`${styles.floorCard} ${
						props.selectedFloor === floor.id &&
						styles.floorCardSelected
					}`}
					onClick={() => {
						props.selectedFloor !== floor.id &&
							props.onSelectFloor?.(floor.id);
					}}
				>
					<span className={styles.floorNumber}>
						{mapFloorNumber(floor.level)}
					</span>
					<span className={styles.floorName}>{floor.name}</span>
					<VelavuIconButton
						className={styles.moreButton}
						onClick={(event) => openMenu(event, floor.id)}
						small
					>
						<IconMoreHorizontal color="#5F718C" />
					</VelavuIconButton>
				</SelectableCard>
			));
	}

	return (
		<>
			<VelavuMenu
				open={!!menuAnchor}
				onClose={closeMenu}
				refPosition={menuAnchor}
			>
				<VelavuMenuItem
					onClick={editFloorPlan}
					icon={(size, color) => (
						<IconEdit size={size} color={color} />
					)}
					label="Edit floor plan"
				/>
				<VelavuMenuItem
					onClick={deleteFloorPlan}
					icon={(size, color) => (
						<IconDelete size={size} color={color} />
					)}
					label="Delete floor plan"
					danger
				/>
			</VelavuMenu>

			<div className={styles.content}>
				{result}
				<div className={styles.button}>
					<VelavuButton
						outlined={false}
						fullWidth
						label={"Add floor plan"}
						onClick={propsOnCreateFloor}
					/>
				</div>
			</div>
		</>
	);
}
