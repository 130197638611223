import React, { CSSProperties } from "react";

export default function IconLink(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M18,8 C20.2091,8 22,9.79086 22,12 C22,14.2091 20.2091,16 18,16 L13,16 C10.7909,16 9,14.2091 9,12 C9,11.4477 9.44772,11 10,11 C10.5523,11 11,11.4477 11,12 C11,13.1046 11.8954,14 13,14 L18,14 C19.1046,14 20,13.1046 20,12 C20,10.8954 19.1046,10 18,10 L16,10 C15.4477,10 15,9.55228 15,9 C15,8.44772 15.4477,8 16,8 L18,8 Z M11,8 C13.2091,8 15,9.79086 15,12 C15,12.5523 14.5523,13 14,13 C13.4477,13 13,12.5523 13,12 C13,10.8954 12.1046,10 11,10 L6,10 C4.89543,10 4,10.8954 4,12 C4,13.1046 4.89543,14 6,14 L8,14 C8.55228,14 9,14.4477 9,15 C9,15.5523 8.55228,16 8,16 L6,16 C3.79086,16 2,14.2091 2,12 C2,9.79086 3.79086,8 6,8 L11,8 Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
