import React from "react";
import { VelavuAsset, VelavuGeofence, VelavuSite } from "velavu-js-api";
import VelavuAssetDevicePair from "../../../../data/asset-device-pair";
import AssetList from "../asset-list/asset-list";
import GeofenceList from "../geofence-list/geofence-list";
import PinnedList from "../pinned-list";
import SiteList from "../site-list/site-list";
import { MapListDisplayType } from "./map-list-display-type";
import MapListSelector from "./map-list-selector";

export interface MapListProps {
	displayType: MapListDisplayType;
	onChangeDisplayType: (displayType: MapListDisplayType) => void;

	onClose?: () => void;

	assetDevicePairs: VelavuAssetDevicePair[];
	onSelectAsset: (asset: VelavuAsset) => void;
	onHoverAsset: (asset: VelavuAsset | undefined) => void;

	sites: VelavuSite[];
	onSelectSite: (siteID: string) => void;
	onCreateSite: () => void;

	geofences: VelavuGeofence[];
	onSelectGeofence: (geofenceID: string) => void;
	onCreateGeofence: () => void;
}

export function MapList(props: MapListProps) {
	return (
		<PinnedList>
			<MapListSelector
				displayType={props.displayType}
				onChangeDisplayType={props.onChangeDisplayType}
				onClose={props.onClose}
			/>

			{props.displayType === MapListDisplayType.Asset && (
				<AssetList
					assetDevicePairs={props.assetDevicePairs}
					onSelectAsset={props.onSelectAsset}
					onHoverAsset={props.onHoverAsset}
				/>
			)}

			{props.displayType === MapListDisplayType.Site && (
				<SiteList
					sites={props.sites}
					onSelectSite={props.onSelectSite}
					onCreateSite={props.onCreateSite}
				/>
			)}

			{props.displayType === MapListDisplayType.Geofence && (
				<GeofenceList
					geofences={props.geofences}
					onSelectGeofence={props.onSelectGeofence}
					onCreateGeofence={props.onCreateGeofence}
				/>
			)}
		</PinnedList>
	);
}
