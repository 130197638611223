import { VelavuSite } from "velavu-js-api";
import {
	MapHelperInstance,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { Feature } from "geojson";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import { GeoJSONSource } from "mapbox-gl";

const mapboxIDSiteBoundary: string = "site-boundary";

export interface MapboxInjectableSiteBoundariesProps {
	sites?: VelavuSite[] | undefined;
	belowLayer?: string;
}

export default function useMapboxInjectableSiteBoundaries(
	mapInstance: MapHelperInstance,
	props: MapboxInjectableSiteBoundariesProps,
): string {
	//Initialize layers
	useStyleSetupMapEffect(mapInstance, (_, box) => {
		box.addSource(mapboxIDSiteBoundary, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer(
			{
				id: mapboxIDSiteBoundary,
				source: mapboxIDSiteBoundary,
				type: "line",
				layout: {
					"line-cap": "round",
				},
				paint: {
					"line-width": 2,
					"line-dasharray": [0, 2],
					"line-color": "#A3B3CC",
				},
			},
			props.belowLayer,
		);
	});

	//Update sites data
	useStyleDependantMapEffect(
		mapInstance,
		(map, box) => {
			if (props.sites === undefined) return;

			const source = map.getSource(mapboxIDSiteBoundary) as GeoJSONSource;
			source.setData({
				type: "FeatureCollection",
				features: props.sites
					.map((site): Feature | undefined => {
						if (site.boundary === undefined) {
							return undefined;
						}

						return {
							type: "Feature",
							id: site.id,
							geometry: site.boundary,
							properties: {},
						};
					})
					.filter((it): it is Feature => it !== undefined),
			});
		},
		[props.sites],
	);

	return mapboxIDSiteBoundary;
}
