import PlanToolType from "./plan-tool-type";

///FloorDrawState represents the state of a floor plan-drawing tool
export default interface FloorDrawToolState {
	toolType: PlanToolType;
	hideReferenceImage: boolean;
}

export const defaultFloorDrawToolState: FloorDrawToolState = {
	toolType: "draw",
	hideReferenceImage: false,
};
