import React from "react";
import { normalizeDeviceHardware, VelavuInvoiceUsage } from "velavu-js-api";
import DeviceIcon from "../../../elements/device-icon";
import {
	VelavuTableBody,
	VelavuTableColumn,
	VelavuTableDivider,
	VelavuTableHeader,
	VelavuTableHeaderLabel,
	VelavuTableRoundContainer,
	VelavuTableRow,
} from "../../../elements/velavu-header-table";
import { mapDeviceHardware } from "../../../helper/language-helper";
import { formatBillingPrice } from "./settings-billing-format";
import styles from "./settings-billing-usage-table.module.scss";

export interface SettingsBillingUsageTableProps {
	usage: VelavuInvoiceUsage[];
}

function InvoiceUsageRow(props: { usage: VelavuInvoiceUsage }) {
	const hardware = normalizeDeviceHardware(props.usage.hardware);

	return (
		<VelavuTableRow>
			<VelavuTableColumn flex={3}>
				<DeviceIcon hardware={hardware} />
				<span style={{ marginLeft: 8 }}>
					{mapDeviceHardware(hardware)}
				</span>
			</VelavuTableColumn>
			<VelavuTableColumn type="center" flex={4}>
				{props.usage.quantity}
			</VelavuTableColumn>
			<VelavuTableColumn type="center" flex={4}>
				{formatBillingPrice(props.usage.unit_price)}
			</VelavuTableColumn>
			<VelavuTableColumn type="edge" width={100}>
				<span className={styles.lineTotal}>
					{formatBillingPrice(props.usage.line_total)}
				</span>
			</VelavuTableColumn>
		</VelavuTableRow>
	);
}

export default function SettingsBillingUsageTable(
	props: SettingsBillingUsageTableProps,
) {
	return (
		<VelavuTableRoundContainer>
			<VelavuTableHeader>
				<VelavuTableHeaderLabel flex={3}>
					Device type
				</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel type="center" flex={4}>
					Device qty.
				</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel type="center" flex={4}>
					Monthly rate
				</VelavuTableHeaderLabel>
				<VelavuTableHeaderLabel type="edge" width={100}>
					Line total
				</VelavuTableHeaderLabel>
			</VelavuTableHeader>
			<VelavuTableDivider />
			<VelavuTableBody className={styles.tableBody}>
				{props.usage.map((usage) => (
					<InvoiceUsageRow key={usage.hardware} usage={usage} />
				))}
			</VelavuTableBody>
		</VelavuTableRoundContainer>
	);
}
