import React, {
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import { MeasurementSystem } from "velavu-js-api";
import VelavuAvatar from "../../elements/velavu-avatar";
import VelavuButton from "../../elements/velavu-button";
import VelavuInput from "../../elements/velavu-input";
import VelavuSelect from "../../elements/velavu-select";
import { getBase64FromUrl } from "../../helper/image-helper";
import SignInContext from "../../sign-in-context";
import ComponentSaveBar from "./component-save-bar";
import styles from "./settings-user.module.scss";
import { VelavuUser } from "velavu-js-api";
import { VelavuOrganization } from "velavu-js-api";

export default function SettingsUser(props: {
	changePassword: VoidFunction;
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
}) {
	const userContext = useContext(SignInContext);
	const userAttrName = userContext.velavuUser?.name;
	const userAttrEmail = userContext.velavuUser?.email;
	const userProfileImage = userContext.velavuUser?.profile_img_url;

	const userOrganization = userContext.organization?.name;
	const userPrefUnits = userContext.velavuUser?.settings?.measurementSystem;

	const resolvedPreferences = userContext.preferences;

	const [username, setUsername] = useState<string | undefined>(undefined);
	const [organization, setOrganization] = useState<string | undefined>(
		undefined,
	);
	const [units, setUnits] = useState<MeasurementSystem>(
		resolvedPreferences.measurementSystem,
	);
	const [image, setImage] = useState<string | undefined>(undefined);
	const imageInput = useRef<HTMLInputElement>(null);

	const [originalUsername, setOriginalUsername] = useState<string>("");
	const [originalOrganization, setOriginalOrganization] =
		useState<string>("");
	const [originalUnits, setOriginalUnits] = useState<MeasurementSystem>(
		resolvedPreferences.measurementSystem,
	);

	const updateUser = userContext.updateVelavuUser;
	const updateOrganization = userContext.updateOrganization;
	const updateProfileImage = userContext.updateVelavuUserImage;

	const [isLoading, setLoading] = useState(false);
	const [lastSaveResult, setLastSaveResult] = useState<boolean | null>(null);

	const onChangeImage = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			//TODO: This creates a memory leak. Call revokeObjectURL() to release.
			const imageURL = URL.createObjectURL(event.currentTarget.files![0]);
			getBase64FromUrl(imageURL!).then(updateProfileImage);
		},
		[updateProfileImage],
	);

	const saveChanges = useCallback(async () => {
		//Create the updated user
		const updatedUser: Partial<VelavuUser> = {};

		const trimmedUsername = username!.trim();
		if (trimmedUsername !== originalUsername) {
			updatedUser.name = trimmedUsername;
		}

		if (units !== originalUnits) {
			updatedUser.settings = { measurementSystem: units };
		}

		//Create the updated organization
		const updatedOrganization: Partial<VelavuOrganization> = {};

		const trimmedOrganization = organization!.trim();
		if (trimmedOrganization !== originalOrganization)
			updatedOrganization.name = trimmedOrganization;

		//Create the requests
		const promiseArray: Promise<unknown>[] = [];
		if (Object.keys(updatedUser).length > 0) {
			promiseArray.push(updateUser(updatedUser));
		}
		if (Object.keys(updatedOrganization).length > 0) {
			promiseArray.push(updateOrganization(updatedOrganization));
		}

		//Wait for all promises to resolve
		Promise.all(promiseArray)
			.then(() => {
				setLastSaveResult(true);
			})
			.catch((error) => {
				console.log(error);
				setLastSaveResult(false);
			})
			.finally(() => setLoading(false));
	}, [
		username,
		originalUsername,
		units,
		originalUnits,
		organization,
		originalOrganization,
		updateUser,
		updateOrganization,
	]);

	//Update the name if the attribute changes
	useEffect(() => {
		if (!userAttrName) return;

		setUsername(userAttrName);
		setOriginalUsername(userAttrName);
	}, [setUsername, setOriginalUsername, userAttrName]);

	//Update the profile image if it changes within the sign-in context
	useEffect(() => {
		setImage(userProfileImage);
	}, [setImage, userProfileImage]);

	//Update the organization if the attribute changes
	useEffect(() => {
		if (!userOrganization) return;

		setOrganization(userOrganization);
		setOriginalOrganization(userOrganization);
	}, [setOrganization, setOriginalOrganization, userOrganization]);

	//Update the measurement system if the attribute changes
	useEffect(() => {
		if (!userPrefUnits) return;

		setUnits(userPrefUnits);
		setOriginalUnits(userPrefUnits);
	}, [setUnits, setOriginalUnits, userPrefUnits]);

	//Update the dirty state
	const propsSetDirty = props.setDirty;
	useEffect(() => {
		propsSetDirty(
			(username !== undefined && username.trim() !== originalUsername) ||
				(organization !== undefined &&
					organization.trim() !== originalOrganization) ||
				(units !== undefined && units !== originalUnits),
		);
	}, [
		username,
		originalUsername,
		organization,
		originalOrganization,
		units,
		originalUnits,
		propsSetDirty,
	]);

	return (
		<div className={styles.container}>
			<div className={styles.profileHeader}>
				<VelavuAvatar
					className={styles.profileAvatar}
					name={userAttrName ?? ""}
					image={image}
				/>
				<button
					className={styles.imageButton}
					onClick={() => imageInput.current?.click()}
				>
					{image ? "Upload new photo" : "Upload photo"}
				</button>
				{image && (
					<div>
						<span className={styles.bulletPoint} />
						<button
							className={styles.imageButton}
							onClick={() => updateProfileImage(undefined)}
						>
							Remove photo
						</button>
					</div>
				)}
				<input
					className={styles.inputImage}
					ref={imageInput}
					onChange={onChangeImage}
					type="file"
					name="image"
					accept="image/png, image/jpeg"
				/>
			</div>

			<div className={styles.inputSection}>
				<div className={styles.inputColumn}>
					<VelavuInput
						value={username ?? ""}
						onChange={setUsername}
						titleText="Name"
						disabled={username === undefined}
						fullWidth
						bottomPadding
					/>
					<VelavuInput
						value={organization ?? ""}
						onChange={setOrganization}
						titleText="Organization"
						disabled={organization === undefined}
						fullWidth
						bottomPadding
					/>
					<VelavuSelect
						value={units ?? "imperial"}
						onChange={setUnits}
						titleText="Display unit"
						fullWidth
						disabled={units === undefined}
					>
						<option value="imperial">Imperial</option>
						<option value="metric">Metric</option>
					</VelavuSelect>
				</div>
				<div className={styles.inputSpacer} />
				<div className={styles.inputColumn}>
					<VelavuInput
						value={userAttrEmail ?? ""}
						titleText="Email"
						fullWidth
						disabled
						bottomPadding
						noAutofill
					/>
					<VelavuInput
						value={"----------"}
						titleText="Password"
						type="password"
						autoComplete="new-password"
						fullWidth
						disabled
						bottomPadding
						noAutofill
					/>
					<VelavuButton
						onClick={props.changePassword}
						label="Change password"
						size="small"
						outlined
					/>
				</div>
			</div>

			<ComponentSaveBar
				disabled={!props.isDirty || isLoading}
				onClick={saveChanges}
				labelOK="User settings saved"
				labelError="Failed to save user settings"
				result={lastSaveResult}
			/>
		</div>
	);
}
