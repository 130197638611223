import React, { useCallback } from "react";
import FloorCreateIntro from "./floor-create-intro";
import { FloorCreateDetails } from "./floor-create-details";
import { VelavuSite } from "velavu-js-api";
import FloorCreateImportPrompt from "./floor-create-import-prompt";
import FloorCreateImportUpload from "./floor-create-import-upload";
import { FloorCreateImportReview } from "./floor-create-import-review";
import FloorCreatePlaceImage from "./floor-create-place-image";
import FloorCreateDrawPrompt from "./floor-create-draw-prompt";
import FloorDrawToolState from "../../../../data/floor-draw-tool-state";
import FloorCreateDrawControls from "./floor-create-draw-controls";
import PlaceableFloorImage from "../../../../data/placeable-floor-image";

// FloorCreateStage represents the current step of the floor creation flow
export enum FloorCreateStep {
	Intro,
	Details,
	ImportPrompt,
	ImportUpload,
	ImportReview,
	Place,
	DrawPrompt,
	Draw,
}

/// FloorCreateInteractiveState is used to notify the parent component of when the map needs to be interactive
export enum FloorCreateInteractiveState {
	PlaceImage,
	DrawWalls,
}

/// floorCreateStageToInteractiveState maps a FloorCreateStage to a FloorCreateInteractiveState
export function floorCreateStageToInteractiveState(
	stage: FloorCreateStep,
): FloorCreateInteractiveState | null {
	switch (stage) {
		case FloorCreateStep.Place:
			return FloorCreateInteractiveState.PlaceImage;
		case FloorCreateStep.Draw:
			return FloorCreateInteractiveState.DrawWalls;
		default:
			return null;
	}
}

export interface FloorCreateState {
	floorNum: number;
	floorName: string;
	floorImage: PlaceableFloorImage | null;
}
export const defaultFloorCreateState: FloorCreateState = {
	floorNum: 0,
	floorName: "",
	floorImage: null,
};

export interface FloorCreateProps {
	site: VelavuSite;
	onClose?: () => void;
	onFinish?: () => void;

	disableNext?: boolean;

	step: FloorCreateStep;
	onChangeStep: (stage: FloorCreateStep) => void;

	state: FloorCreateState;
	onChangeState: React.Dispatch<React.SetStateAction<FloorCreateState>>;

	floorDrawState: FloorDrawToolState;
	onChangeFloorDrawState: React.Dispatch<
		React.SetStateAction<FloorDrawToolState>
	>;
}

export default function FloorCreate(props: FloorCreateProps) {
	const step = props.step;
	const setStep = props.onChangeStep;
	const onFinish = props.onFinish;

	const { floorImage } = props.state;

	const propsOnChangeState = props.onChangeState;
	const setState = useCallback(
		(newState: Partial<FloorCreateState>) => {
			propsOnChangeState((existingState) => ({
				...existingState,
				...newState,
			}));
		},
		[propsOnChangeState],
	);

	switch (step) {
		case FloorCreateStep.Intro:
			return (
				<FloorCreateIntro
					onClose={props.onClose}
					onNext={() => setStep(FloorCreateStep.Details)}
				/>
			);
		case FloorCreateStep.Details:
			return (
				<FloorCreateDetails
					existingFloors={props.site.floors ?? []}
					onClose={props.onClose}
					onSubmit={(floorNum: number, floorName: string) => {
						setState({
							floorNum,
							floorName,
						});
						setStep(FloorCreateStep.ImportPrompt);
					}}
				/>
			);
		case FloorCreateStep.ImportPrompt:
			return (
				<FloorCreateImportPrompt
					onClose={props.onClose}
					onSelectUpload={() => setStep(FloorCreateStep.ImportUpload)}
					onSelectSkip={() => setStep(FloorCreateStep.Draw)}
				/>
			);
		case FloorCreateStep.ImportUpload:
			return (
				<FloorCreateImportUpload
					onClose={props.onClose}
					onBack={() => setStep(FloorCreateStep.ImportPrompt)}
					onUpload={(floorImage) => {
						setState({ floorImage: floorImage });
						setStep(FloorCreateStep.ImportReview);
					}}
				/>
			);
		case FloorCreateStep.ImportReview:
			return (
				<FloorCreateImportReview
					image={floorImage!}
					onClose={props.onClose}
					onBack={() => {
						setState({ floorImage: null });
						setStep(FloorCreateStep.ImportUpload);
					}}
					onNext={() => {
						setStep(FloorCreateStep.Place);
					}}
				/>
			);
		case FloorCreateStep.Place:
			return (
				<FloorCreatePlaceImage
					onClose={props.onClose}
					onNext={() => {
						setStep(FloorCreateStep.DrawPrompt);
					}}
				/>
			);
		case FloorCreateStep.DrawPrompt:
			return (
				<FloorCreateDrawPrompt
					onClose={props.onClose}
					onNext={(selection) => {
						if (selection === "image") {
							onFinish?.();
						} else if (selection === "draw") {
							setStep(FloorCreateStep.Draw);
						}
					}}
					disableNext={props.disableNext ?? false}
				/>
			);
		case FloorCreateStep.Draw:
			return (
				<FloorCreateDrawControls
					floorDrawState={props.floorDrawState}
					onChangeFloorDrawState={props.onChangeFloorDrawState}
					onClose={props.onClose}
					onFinish={onFinish}
					disableHideReferenceImage={false}
					disableFinish={props.disableNext ?? false}
				/>
			);
	}
}
