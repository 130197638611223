import React, { useEffect, useMemo, useState } from "react";
import {
	NormalizedDeviceHardware,
	VelavuAPI,
	VelavuAsset,
	VelavuDevice,
	VelavuInventory,
	VelavuInventoryItem,
	normalizeDeviceHardware,
} from "velavu-js-api";
import IconCloseLeft from "../../../../dynamicicons/icon-close-left";
import IconSearch from "../../../../dynamicicons/icon-search";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import VelavuInput from "../../../../elements/velavu-input";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import { compoundSearchInventory } from "../../../../helper/search-helper";
import AssetInventoryItem from "./asset-inventory-item";
import styles from "./asset-inventory-management.module.scss";

interface AssetInventoryManageProps {
	show: boolean;
	asset: VelavuAsset;
	device?: VelavuDevice;
	onClose: VoidFunction;
	inventory?: VelavuInventory;
	addInventory?: (item: VelavuInventoryItem) => void;
	onSelectAsset: (assetID: string) => void;
	onHoverAsset: (asset: VelavuAsset | undefined) => void;
}

function initializeAssets(
	selectedAsset: VelavuAsset,
	devices: VelavuDevice[],
	inventory: VelavuInventory | undefined,
) {
	const inventoryItems: VelavuInventoryItem[] = [];

	// Filter out selected asset, assets with parent, current inventory, GPS tags
	const filteredDevices = devices.filter(
		(device) =>
			device.asset?.id !== selectedAsset.id &&
			!device.asset?.parent_id &&
			[...(inventory?.items ?? []), ...(inventory?.others ?? [])].find(
				(item) => item.asset.id === device.asset?.id,
			) === undefined &&
			normalizeDeviceHardware(device?.hardware) !==
				NormalizedDeviceHardware.Argo,
	);

	// Append all assets in proximity without parent to assets array
	inventory?.others?.forEach((item) => {
		!item.asset.parent_id && inventoryItems.push(item);
	});

	// Append all non-inventory to assets array
	filteredDevices.forEach((device) => {
		const newInventoryItem: VelavuInventoryItem = {
			asset: device.asset!,
		};

		inventoryItems.push(newInventoryItem);
	});

	return inventoryItems;
}

export enum AssetInventoryManagementTab {
	All,
	Nearby,
}

export default function AssetInventoryManagement(
	props: AssetInventoryManageProps,
) {
	const [selectedTab, setSelectedTab] = useState(
		AssetInventoryManagementTab.All,
	);
	const [searchText, setSearchText] = useState("");
	const [devices, setDevices] = useState<VelavuDevice[]>([]);

	const options = useMemo(
		() => initializeAssets(props.asset, devices, props.inventory),
		[props.asset, props.inventory, devices],
	);

	useEffect(() => {
		VelavuAPI.devices
			.getAllDevices({ paired: true })
			.then((response) => setDevices(response.data))
			.catch((error) => console.log(error.response));
	}, [props.inventory]);

	const filteredOptions = useMemo(() => {
		return compoundSearchInventory(options, searchText).filter((item) => {
			const device = devices.find(
				(device) => device.asset?.id === item.asset.id,
			);
			const hardware = normalizeDeviceHardware(device?.hardware);
			return (hardware === NormalizedDeviceHardware.Juno ||
				hardware === NormalizedDeviceHardware.Meridian) &&
				selectedTab === AssetInventoryManagementTab.All
				? true
				: item.proximity;
		});
	}, [devices, options, searchText, selectedTab]);

	return (
		<div
			className={`${styles.container} ${
				props.show ? styles.containerExpand : styles.containerCollapse
			}`}
		>
			<div className={styles.header}>
				<div className={styles.titleRow}>
					<span>Inventory Manager</span>
					<VelavuIconButton
						tooltip="Close"
						className={styles.closeButton}
						onClick={props.onClose}
					>
						<IconCloseLeft color="#5F718C" />
					</VelavuIconButton>
				</div>
				<div className={styles.filterRow}>
					<VelavuInput
						className={styles.search}
						value={searchText}
						onChange={setSearchText}
						leadingIcon={<IconSearch size={16} color="#00255D" />}
						placeholder="Search assets"
						fullWidth
					/>
				</div>
			</div>

			<div className={styles.tabBarContainer}>
				<VelavuTabSwitcher
					selectedKey={selectedTab}
					onSelectKey={setSelectedTab}
					labels={{
						[AssetInventoryManagementTab.All]: "ALL ASSETS",
						[AssetInventoryManagementTab.Nearby]: "NEARBY ASSETS",
					}}
					className={styles.tabBar}
				/>
			</div>

			<div className={styles.assets}>
				{filteredOptions.map((item) =>
					item.proximity ? (
						<AssetInventoryItem
							item={item}
							key={item.asset.id}
							action={"add"}
							className={styles.assetItem}
							fill={"#FFE588"}
							stroke={"#FFD338"}
							addInventory={props.addInventory}
							onClick={() => props.onSelectAsset(item.asset.id)}
							onMouseEnter={() => props.onHoverAsset(item.asset)}
							onMouseLeave={() => props.onHoverAsset(undefined)}
							isOther
						/>
					) : (
						<AssetInventoryItem
							item={item}
							key={item.asset.id}
							action={"add"}
							className={styles.assetItem}
							fill={"#D5DCE5"}
							stroke={"#A3B3CC"}
							addInventory={props.addInventory}
							onClick={() => props.onSelectAsset(item.asset.id)}
							onMouseEnter={() => props.onHoverAsset(item.asset)}
							onMouseLeave={() => props.onHoverAsset(undefined)}
							isOther
						/>
					),
				)}
			</div>
		</div>
	);
}
