import {
	NormalizedDeviceHardware,
	normalizeDeviceHardware,
} from "velavu-js-api";
import {
	VelavuDevice,
	LocatedVelavuDevice,
	LocationResource,
} from "velavu-js-api";
import { DeviceCategory } from "velavu-js-api";
import { VelavuSite } from "velavu-js-api";
import { GeoViewport, viewport as geoViewport } from "@mapbox/geo-viewport";
import { Polygon } from "geojson";
import mapboxgl from "mapbox-gl";

export enum DeviceMarkerType {
	Asset,
	Anchor,
}

export interface DevicePositionAnimation {
	startCoords: [number, number];
	startDate: number;
	endCoords: [number, number];
	endDate: number;
}

/**
 * DeviceMarker is a custom type that represents the
 * consolidated state of a renderable asset / device on a map
 */
export default interface DeviceMarker {
	markerType: DeviceMarkerType;
	deviceID: string;
	assetID?: string;
	name?: string;
	online?: boolean;
	hardware: NormalizedDeviceHardware | undefined;
	location: LocationResource;
	anim?: DevicePositionAnimation;
	inSite: boolean;
	displayZoomThreshold: number | undefined;
	hasInventoryActions: boolean;
}

export function deviceToDeviceMarker(
	device: LocatedVelavuDevice,
	mapDimensions: [number, number],
	site?: VelavuSite,
): DeviceMarker;
export function deviceToDeviceMarker(
	device: VelavuDevice,
	mapDimensions: [number, number],
	site?: VelavuSite,
): DeviceMarker | undefined;
export function deviceToDeviceMarker(
	device: VelavuDevice,
	mapDimensions: [number, number],
	site?: VelavuSite,
): DeviceMarker | undefined {
	//Device markers must have a location
	if (device.location === undefined) {
		return undefined;
	}

	//Map the marker type
	let markerType: DeviceMarkerType;
	switch (device.category) {
		case DeviceCategory.Tag:
			markerType = DeviceMarkerType.Asset;
			break;
		case DeviceCategory.Anchor:
			markerType = DeviceMarkerType.Anchor;
			break;
		default:
			throw new Error(`Unknown device category: ${device.category}`);
	}

	//A device has inventory actions if it contains inventory,
	//or there are devices that may be added to inventory.
	//Checking the 'nearby' property is an approximation,
	//so it may be wrong in some cases, but for the most part
	//it should be good enough.
	const hasInventoryActions = "nearby" in device && device.nearby.length > 0;

	return {
		markerType: markerType,
		deviceID: device.id,
		assetID: device.asset?.id,
		name: device.asset?.name,
		online: device.online,
		hardware: normalizeDeviceHardware(device.hardware),
		location: device.location,
		inSite: device.site_id !== undefined,
		displayZoomThreshold: resolveSiteZoomLevel(site, mapDimensions),
		hasInventoryActions: hasInventoryActions,
	};
}

/// Resolves the coordinates of a device marker at a given time
export function resolveDeviceMarkerCoordinates(
	deviceMarker: DeviceMarker,
	time: number,
): [number, number] {
	//If the device has no animation, return the current coordinates
	if (deviceMarker.anim === undefined) {
		return deviceMarker.location.coordinates;
	}

	//Interpolate the coordinates based on the animation
	const timeDelta = time - deviceMarker.anim.startDate;
	const timeTotal = deviceMarker.anim.endDate - deviceMarker.anim.startDate;
	const progress = Math.min(1, Math.max(0, timeDelta / timeTotal));

	const xDelta =
		deviceMarker.anim.endCoords[0] - deviceMarker.anim.startCoords[0];
	const yDelta =
		deviceMarker.anim.endCoords[1] - deviceMarker.anim.startCoords[1];

	return [
		deviceMarker.anim.startCoords[0] + xDelta * progress,
		deviceMarker.anim.startCoords[1] + yDelta * progress,
	];
}

export interface SiteBoundaryViewport {
	bounds: [number, number, number, number];
	zoom: number;
	center: [number, number];
}

// Resolves the appropriate coordinates and zoom level
// for inspecting a site
export function resolveSiteBoundaryViewport(
	site: VelavuSite | undefined,
	mapDimensions: [number, number],
): SiteBoundaryViewport | undefined {
	//Make sure the site has floors we can check
	if (site === undefined || site.floors === undefined) {
		return undefined;
	}

	//Collect the coordinates of all floor points
	const coordinateArray = site.floors.flatMap((floor) => {
		return floor.floorplan.features.flatMap((feature) => {
			return feature.geometry.coordinates.flat();
		});
	});
	if (coordinateArray.length === 0) {
		return undefined;
	}

	//Resolve the min and max coords of the site floors
	const bounds = new mapboxgl.LngLatBounds();
	for (const coords of coordinateArray) {
		bounds.extend(coords as [number, number]);
	}
	const boundsArray: [number, number, number, number] = [
		bounds.getWest(),
		bounds.getSouth(),
		bounds.getEast(),
		bounds.getNorth(),
	];

	const viewport = geoViewport(
		boundsArray,
		mapDimensions,
		undefined,
		undefined,
		512,
	);
	return {
		bounds: boundsArray,
		zoom: viewport.zoom,
		center: viewport.center,
	};
}

export const fallbackSiteZoomThreshold = 17;

// Resolves the appropriate zoom level for a given site
// at which to reveal its assets
export function resolveSiteZoomLevel(
	site: VelavuSite | undefined,
	mapDimensions: [number, number],
): number | undefined {
	const viewport = resolveSiteBoundaryViewport(site, mapDimensions);
	return viewport?.zoom;
}
