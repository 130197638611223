import React, { CSSProperties } from "react";

export default function IconFound(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 12}
			height={props.size ?? 12}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.33335 4.33337C4.33335 3.96518 4.03488 3.66671 3.66669 3.66671H3.33335C1.86059 3.66671 0.666687 4.86062 0.666687 6.33337V9.00004C0.666687 10.4728 1.86059 11.6667 3.33335 11.6667H8.66669C10.1394 11.6667 11.3334 10.4728 11.3334 9.00004V6.33337C11.3334 4.86062 10.1394 3.66671 8.66669 3.66671H8.33335C7.96516 3.66671 7.66669 3.96518 7.66669 4.33337C7.66669 4.70156 7.96516 5.00004 8.33335 5.00004H8.66669C9.40307 5.00004 10 5.59699 10 6.33337V9.00004C10 9.73642 9.40307 10.3334 8.66669 10.3334H3.33335C2.59697 10.3334 2.00002 9.73642 2.00002 9.00004L2.00002 6.33337C2.00002 5.597 2.59697 5.00004 3.33335 5.00004H3.66669C4.03488 5.00004 4.33335 4.70156 4.33335 4.33337Z"
				fill={props.color ?? "#00255D"}
			/>
			<path
				d="M6.66669 1.00004C6.66669 0.631851 6.36821 0.333374 6.00002 0.333374C5.63183 0.333374 5.33335 0.631851 5.33335 1.00004L5.33335 6.06723H3.33335L5.47944 8.74984C5.74632 9.08344 6.25372 9.08344 6.5206 8.74984L8.66669 6.06723H6.66669L6.66669 1.00004Z"
				fill={props.color ?? "#00255D"}
			/>
		</svg>
	);
}
