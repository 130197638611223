import React, { useCallback, useState } from "react";
import styles from "./floor-create-draw-prompt.module.scss";
import PinnedListHeader from "../pinned-list-header";
import PinnedList from "../pinned-list";
import { classArr } from "../../../../helper/style-helper";
import IconFloorplan2D from "../../../../dynamicicons/icon-floorplan-2d";
import IconFloorplan3D from "../../../../dynamicicons/icon-floorplan-3d";
import VelavuButton from "../../../../elements/velavu-button";

export type FloorCreateDrawPromptSelection = "image" | "draw";

export interface FloorCreateDrawPromptProps {
	onClose?: () => void;
	onNext?: (selection: FloorCreateDrawPromptSelection) => void;
	disableNext?: boolean;
}

export default function FloorCreateDrawPrompt(
	props: FloorCreateDrawPromptProps,
) {
	const [selection, setSelection] =
		useState<FloorCreateDrawPromptSelection | null>(null);

	const propsOnNext = props.onNext;
	const handleSubmit = useCallback(() => {
		if (selection === null) {
			return;
		}

		propsOnNext?.(selection);
	}, [selection, propsOnNext]);

	return (
		<PinnedList>
			<PinnedListHeader label="Draw floor plan" onClose={props.onClose} />

			<div className={styles.content}>
				<span className={styles.message}>Choose a display mode:</span>
				<div className={styles.buttonRow}>
					<button
						className={classArr(
							styles.buttonBox,
							selection === "image" && styles.buttonBoxSelected,
						)}
						onClick={() => setSelection("image")}
					>
						<IconFloorplan2D />
						<span>Place 2D image on map</span>
					</button>
					<div className={styles.buttonSpacer} />
					<button
						className={classArr(
							styles.buttonBox,
							selection === "draw" && styles.buttonBoxSelected,
						)}
						onClick={() => setSelection("draw")}
					>
						<IconFloorplan3D />
						<span>Trace floor plan for 3D walls</span>
					</button>
				</div>

				<VelavuButton
					label="Next"
					onClick={handleSubmit}
					disabled={props.disableNext || selection === null}
					fullWidth
				/>
			</div>
		</PinnedList>
	);
}
