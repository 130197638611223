import { VelavuAlert } from "velavu-js-api";

const lsKeyReadAlerts = "readAlerts";

function readReadAlertIDs(): string[] | undefined {
	//Reading the raw value from local storage
	const lsItem = localStorage.getItem(lsKeyReadAlerts);

	//Type-checking the value
	if (lsItem) {
		const parsed = JSON.parse(lsItem);
		if (
			Array.isArray(parsed) &&
			parsed.every((it) => typeof it === "string")
		) {
			return parsed;
		}
	}

	return undefined;
}

function writeReadAlertIDs(readAlertIDs: string[]) {
	localStorage.setItem(lsKeyReadAlerts, JSON.stringify(readAlertIDs));
}

//getReadAlerts gets a list of all read alert IDs, automatically filtering out invalid entries
export function getReadAlertIDs(alerts: VelavuAlert[]): string[] {
	const readAlertIDs = readReadAlertIDs();
	if (readAlertIDs) {
		//Filter nonexistent alerts out of the array
		const filteredAlertIDs = readAlertIDs.filter((id) =>
			alerts.find((alert) => alert.id === id),
		);

		//Updating local storage if any changes were made
		if (filteredAlertIDs.length !== readAlertIDs.length)
			writeReadAlertIDs(filteredAlertIDs);

		return filteredAlertIDs;
	} else {
		return [];
	}
}

//setReadAlerts sets a list of all read alert IDs
export function setReadAlertIDs(alerts: VelavuAlert[]) {
	writeReadAlertIDs(alerts.map((alert) => alert.id));
}

//markAlertRead marks an alert as read
export function markAlertIDRead(alertID: string) {
	const readAlertIDs = readReadAlertIDs() ?? [];
	writeReadAlertIDs(readAlertIDs.concat(alertID));
}

//markAlertDeleted removes an alert's read entry from disk
export function markAlertIDDeleted(alertID: string) {
	const readAlertIDs = readReadAlertIDs() ?? [];
	writeReadAlertIDs(readAlertIDs.filter((alert) => alert !== alertID));
}
