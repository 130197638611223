import React from "react";
import {
	defaultUserPreferences,
	UserPreferences,
	VelavuOrganization,
	VelavuUser,
} from "velavu-js-api";

interface SignInContextType {
	//The current logged in velavu user
	velavuUser?: VelavuUser;
	updateVelavuUser: (user: Partial<VelavuUser>) => Promise<VelavuUser>;
	updateVelavuUserImage: (imageData: string | undefined) => void;

	//Preferences resolved against defaults
	preferences: UserPreferences;

	//The user's organization
	organization?: VelavuOrganization;
	updateOrganization: (
		organization: Partial<VelavuOrganization>,
	) => Promise<VelavuOrganization>;

	//Load flags
	isMapboxRegistered: boolean;
	isMQTTRegistered: boolean;
}

const SignInContext = React.createContext<SignInContextType>({
	updateVelavuUser() {
		throw new Error("Velavu user handler not assigned");
	},
	updateVelavuUserImage() {
		throw new Error("Velavu user image handler not assigned");
	},
	updateOrganization() {
		throw new Error("Organization handler not assigned");
	},
	preferences: defaultUserPreferences,
	isMapboxRegistered: false,
	isMQTTRegistered: false,
});

export default SignInContext;
