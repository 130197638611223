import React, { useCallback } from "react";
import styles from "./floor-create-draw-controls.module.scss";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";
import EditorTool from "../../../../elements/editor-tool";
import IconWall from "../../../../dynamicicons/icon-wall";
import IconEdit from "../../../../dynamicicons/icon-edit";
import IconErase from "../../../../dynamicicons/icon-erase";
import { makeStyleable } from "../../../../helper/icon-helper";
import VelavuNotice from "../../../../elements/velavu-notice";
import VelavuSwitch from "../../../../elements/velavu-switch";
import VelavuButton from "../../../../elements/velavu-button";
import FloorDrawToolState from "../../../../data/floor-draw-tool-state";

export interface FloorCreateDrawControlsProps {
	onClose?: () => void;
	onFinish?: () => void;

	floorDrawState: FloorDrawToolState;
	onChangeFloorDrawState: React.Dispatch<
		React.SetStateAction<FloorDrawToolState>
	>;

	disableHideReferenceImage: boolean;
	disableFinish: boolean;
}

export default function FloorCreateDrawControls(
	props: FloorCreateDrawControlsProps,
) {
	const { hideReferenceImage, toolType } = props.floorDrawState;
	const disableFinish = props.disableFinish;

	const setState = props.onChangeFloorDrawState;
	const setToolDraw = useCallback(
		() => setState((state) => ({ ...state, toolType: "draw" })),
		[setState],
	);
	const setToolEdit = useCallback(
		() => setState((state) => ({ ...state, toolType: "edit" })),
		[setState],
	);
	const setToolErase = useCallback(
		() => setState((state) => ({ ...state, toolType: "erase" })),
		[setState],
	);

	const changeHideReferenceImage = useCallback(
		(value: boolean) => {
			setState((state) => ({ ...state, hideReferenceImage: value }));
		},
		[setState],
	);

	return (
		<PinnedList>
			<PinnedListHeader label="Draw floor plan" onClose={props.onClose} />
			<div className={styles.content}>
				<div className={styles.toolbar}>
					<EditorTool
						icon={makeStyleable(IconWall)}
						label="Draw"
						size="medium"
						active={toolType === "draw"}
						onClick={setToolDraw}
					/>
					<EditorTool
						icon={makeStyleable(IconEdit)}
						label="Edit"
						size="medium"
						active={toolType === "edit"}
						onClick={setToolEdit}
					/>
					<EditorTool
						icon={makeStyleable(IconErase)}
						label="Erase"
						size="medium"
						active={toolType === "erase"}
						onClick={setToolErase}
					/>
				</div>

				<VelavuNotice
					type="info"
					body="Draw on the map to create 3D walls for this floor"
				/>

				{!props.disableHideReferenceImage && (
					<VelavuSwitch
						toggled={hideReferenceImage}
						onChange={changeHideReferenceImage}
						label="Hide reference image"
					/>
				)}

				<VelavuButton
					label="Finish"
					disabled={disableFinish}
					onClick={props.onFinish}
					fullWidth
				/>
			</div>
		</PinnedList>
	);
}
