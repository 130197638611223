import React, { CSSProperties } from "react";

export default function IconGeofenceEntered(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 18}
			height={props.size ?? 18}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4 6C4 6.74028 4.4022 7.38663 5 7.73244L5 16.2676C4.4022 16.6134 4 17.2597 4 18C4 19.1046 4.89543 20 6 20C6.74028 20 7.38663 19.5978 7.73244 19L16.2676 19C16.6134 19.5978 17.2597 20 18 20C19.1046 20 20 19.1046 20 18C20 17.2597 19.5978 16.6134 19 16.2676V11C19 10.4477 18.5523 10 18 10C17.4477 10 17 10.4477 17 11V16.2676C16.6964 16.4432 16.4432 16.6964 16.2676 17L7.73244 17C7.5568 16.6964 7.30363 16.4432 7 16.2676V7.73244C7.30363 7.5568 7.5568 7.30363 7.73244 7L13 7C13.5523 7 14 6.55228 14 6C14 5.44772 13.5523 5 13 5H7.73244C7.38663 4.4022 6.74028 4 6 4C4.89543 4 4 4.89543 4 6Z"
				fill={props.color ?? "#00255D"}
			/>
			<path
				d="M19.0208 4.82842C19.4113 5.21895 19.4113 5.85211 19.0208 6.24264L13.6463 11.6172L15.7676 13.7385L10.646 14.3076C10.0091 14.3784 9.47088 13.8402 9.54165 13.2033L10.1107 8.08167L12.232 10.203L17.6066 4.82842C17.9971 4.4379 18.6303 4.4379 19.0208 4.82842Z"
				fill={props.color ?? "#00255D"}
			/>
		</svg>
	);
}
