import React, {
	CSSProperties,
	MouseEventHandler,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import styles from "./toggleable-fade.module.scss";

interface ToggleableFadeProps {
	className?: string;
	style?: CSSProperties;

	visible?: boolean;
	children?: React.ReactNode;

	shift?: boolean;

	onFadeIn?: VoidFunction;
	onFadeOut?: VoidFunction;

	onClick?: MouseEventHandler<HTMLDivElement>;
}

const transitionDuration = 250;

export default function ToggleableFade(props: ToggleableFadeProps) {
	const [isVisible, setVisible] = useState(false);
	const [isTransition, setTransition] = useState(false);
	const timeoutID = useRef<any>(undefined);

	const propsVisible: boolean = !!props.visible;
	const {
		onFadeIn: propsOnFadeIn,
		onFadeOut: propsOnFadeOut,
		onClick: propsOnClick,
	} = props;

	useEffect(() => {
		if (propsVisible !== isVisible) {
			setTimeout(() => {
				setVisible(propsVisible);
				setTransition(true);

				if (timeoutID.current !== undefined)
					clearTimeout(timeoutID.current);
				timeoutID.current = setTimeout(() => {
					setTransition(false);
					if (propsVisible) propsOnFadeIn && propsOnFadeIn();
					else propsOnFadeOut && propsOnFadeOut();
				}, transitionDuration);
			}, 2);
		}
	}, [propsVisible, isVisible, propsOnFadeIn, propsOnFadeOut]);

	const handleClick = useCallback(
		(event: React.MouseEvent<HTMLDivElement>) => {
			//Prevent children from receiving mouse events while transitioning
			if (isTransition) {
				event.stopPropagation();
				event.preventDefault();
			} else {
				if (propsOnClick) propsOnClick(event);
			}
		},
		[isTransition, propsOnClick],
	);

	return (
		<div
			style={props.style}
			className={`${styles.container} ${
				isVisible ? styles.containerVisible : styles.containerHidden
			} ${props.shift ? styles.shift : ""} ${
				props.className ? props.className : ""
			}`}
			onClick={handleClick}
		>
			{props.children}
		</div>
	);
}
