import React from "react";
import ReactCodeInput from "react-verification-code-input";
import styles from "./velavu-verification-input.module.scss";

interface VelavuVerificationInputProps {
	disabled?: boolean;
	onChange?: (value: string) => void;
	value?: string;
	onComplete?: (value: string) => void;
}

export default function VelavuVerificationInput(
	props: VelavuVerificationInputProps,
) {
	return (
		<ReactCodeInput
			className={styles.verification}
			type="number"
			fields={6}
			fieldWidth={40}
			fieldHeight={40}
			disabled={props.disabled}
			onChange={props.onChange}
			values={props.value?.split("")}
			onComplete={props.onComplete}
		/>
	);
}
