import React from "react";
import IconChevronLeft from "../dynamicicons/icon-chevron-left";
import IconClose from "../dynamicicons/icon-close";
import styles from "./dropdown-panel-toolbar.module.scss";
import VelavuButton from "./velavu-button";
import VelavuIconButton from "./velavu-icon-button";

interface PanelToolbarProps {
	onBack?: VoidFunction;
	onClose?: VoidFunction;
}

export default function DropdownPanelToolbar(props: PanelToolbarProps) {
	return (
		<div className={styles.navigation}>
			<VelavuButton
				label="Back"
				outlined
				icon={(size, color, className) => (
					<IconChevronLeft
						size={size}
						color={color}
						className={className}
					/>
				)}
				onClick={props.onBack}
			/>
			<VelavuIconButton onClick={props.onClose}>
				<IconClose color="#A3B3CC" />
			</VelavuIconButton>
		</div>
	);
}
