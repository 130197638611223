import React, { CSSProperties } from "react";
import globals from "../../src/styles/global.scss";
import { StyleableIcon } from "../helper/icon-helper";
import {
	DevicePowerUnion,
	useDevicePowerUnion,
} from "../helper/resource-helper";
import { classArr } from "../helper/style-helper";
import DynamicBattery from "./dynamic-battery";
import styles from "./icon-label-attribute.module.scss";

/**
 * A component that displays an attribute label next to an icon
 */
export default function IconLabelAttribute(props: {
	style?: CSSProperties;
	className?: string;
	icon: StyleableIcon;
	label: string;
}) {
	return (
		<div
			style={props.style}
			className={classArr(styles.container, props.className)}
		>
			{props.icon(24, globals.textTertiary)}
			<span className={styles.label}>{props.label}</span>
		</div>
	);
}

/**
 * A convenience component for creating a battery icon with a label
 */
export function BatteryIconLabelAttribute(
	props: {
		style?: CSSProperties;
		className?: string;
	} & DevicePowerUnion,
) {
	const { level, charging } = useDevicePowerUnion(props);

	return (
		<IconLabelAttribute
			style={props.style}
			className={props.className}
			icon={() => <DynamicBattery level={level} charging={charging} />}
			label={`${Math.round(level * 100)}%`}
		/>
	);
}
