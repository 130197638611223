import React from "react";
import { AssetCategory, NormalizedDeviceHardware } from "velavu-js-api";
import ConnectionStatus from "../data/connection-status";
import iconAnchor from "../img/icons/icon-anchor.png";
import iconBLE from "../img/icons/icon-ble.png";
import iconEquipment from "../img/icons/icon-equipment.png";
import iconGPS from "../img/icons/icon-gps.png";
import iconManta from "../img/icons/icon-manta.png";
import iconVehicle from "../img/icons/icon-vehicle.png";
import StatusIndicator from "./status-indicator";
import styles from "./velavu-item-icon.module.scss";

/**
 * An element that is used to represent an asset, tag, or anchor
 * @param props.data The type of icon to display, or a URL to display a custom image
 * @param props.status The connection status indicator to display, or UNDEFINED to hide
 */
export default function VelavuItemIcon(props: {
	data: NormalizedDeviceHardware | AssetCategory | { url: string };
	status?: ConnectionStatus;
}) {
	const data = props.data;
	let imgSrc: string, className: string;

	switch (data) {
		case NormalizedDeviceHardware.Juno:
			imgSrc = iconBLE;
			className = styles.iconDevice;
			break;
		case NormalizedDeviceHardware.Meridian:
			imgSrc = iconAnchor;
			className = styles.iconDevice;
			break;
		case NormalizedDeviceHardware.Argo:
			imgSrc = iconGPS;
			className = styles.iconDevice;
			break;
		case NormalizedDeviceHardware.Pisces:
			imgSrc = iconManta;
			className = styles.iconDevice;
			break;
		case AssetCategory.Vehicle:
			imgSrc = iconVehicle;
			className = styles.iconAsset;
			break;
		case AssetCategory.Equipment:
			imgSrc = iconEquipment;
			className = styles.iconAsset;
			break;
		default:
			imgSrc = data.url;
			className = "";
			break;
	}

	return (
		<div className={`${styles.main} ${className}`}>
			<img className={styles.size} src={imgSrc} alt="" />

			{props.status && (
				<span className={styles.indicator}>
					<StatusIndicator status={props.status} />
				</span>
			)}
		</div>
	);
}
