import React, { CSSProperties } from "react";
import { VelavuAsset } from "velavu-js-api";
import CategorizationTag from "../../../../elements/categorization-tag";
import SelectableCard from "../../../../elements/selectable-card";
import StatusIndicator from "../../../../elements/status-indicator";
import { getAssetStatus } from "../../../../helper/status-helper";
import styles from "./asset-listitem.module.scss";

interface AssetListItemProps {
	style?: CSSProperties;
	className?: string;

	asset: VelavuAsset;
	onClick?: VoidFunction;
	onMouseEnter: VoidFunction;
	onMouseLeave: VoidFunction;
}

export default function AssetListItem(props: AssetListItemProps) {
	return (
		<SelectableCard
			style={props.style}
			className={`${styles.container} ${props.className ?? ""}`}
			onClick={props.onClick}
			onMouseEnter={props.onMouseEnter}
			onMouseLeave={props.onMouseLeave}
		>
			<div className={styles.info}>
				<div className={styles.titleContainer}>
					<StatusIndicator
						status={getAssetStatus(props.asset)}
						viewBox={"6 0 24 24"}
					/>
					<span className={styles.title}>{props.asset.name}</span>
				</div>
				<CategorizationTag
					categorization={{
						category: props.asset.category,
						group: props.asset.group,
					}}
				/>
			</div>
		</SelectableCard>
	);
}
