import React, { CSSProperties } from "react";
import { classArr } from "../helper/style-helper";
import LoadingDots from "./loading-dots";
import styles from "./velavu-button.module.scss";

type ButtonSize = "small" | "medium" | "large";

interface VelavuButtonProps {
	label?: string; //The text to display on the button
	icon?: (size: number, color: string, className: string) => React.ReactNode; //An icon to be applied with the text
	size?: ButtonSize; //The button's size
	outlined?: boolean; //Whether this button should be outlined or filled
	danger?: boolean; //Whether this button should be red
	fullWidth?: boolean; //Whether this button should expand to fill horizontal space
	disabled?: boolean; //Whether this button should be disabled
	onClick?: (event?: any) => void; //A callback when this button is clicked
	submit?: boolean; //Append type=submit to enable this button to submit forms
	className?: string;
	style?: CSSProperties;
	loadingIndicator?: boolean; //Whether this button's text content should be replaced with a loading indicator
}

//A reusable button component that conforms to Velavu's design system
export default function VelavuButton(props: VelavuButtonProps) {
	const styleList: string[] = [styles.main];
	let iconSize: number;

	//Set the button's size style, defaulting to medium
	switch (props.size ?? "medium") {
		case "small":
			styleList.push(styles.shapeSmall);
			iconSize = 16;
			break;
		case "medium":
			styleList.push(styles.shapeMedium);
			iconSize = 24;
			break;
		case "large":
			styleList.push(styles.shapeLarge);
			iconSize = 28;
			break;
	}

	//Set the button's normal or danger color
	if (props.danger) {
		styleList.push(styles.colorDanger);
	} else {
		styleList.push(styles.colorNormal);
	}

	//Set the button's outlined or filled appearance
	if (props.outlined) {
		styleList.push(styles.colorOutline);
	} else {
		styleList.push(styles.colorFill);
	}

	//Set the button's full width appearance
	if (props.fullWidth) {
		styleList.push(styles.wide);
	}

	//Set the button's disabled appearance
	if (props.disabled) {
		styleList.push(styles.disabled);
	} else {
		styleList.push(styles.hover);
	}

	if (props.icon && !props.label) {
		styleList.push(styles.onlyIcon);
	}

	let type: "submit" | "reset" | "button" | undefined;
	if (props.submit === true) type = "submit";
	else if (props.submit === false) type = "button";
	else type = undefined;

	return (
		<button
			style={props.style}
			className={classArr(...styleList, props.className)}
			disabled={props.disabled}
			onClick={props.onClick}
			type={type}
		>
			{props.icon &&
				props.icon(
					iconSize,
					props.loadingIndicator ? "transparent" : "unset",
					styles.icon,
				)}
			{props.label && (
				<span
					className={classArr(
						styles.labelMargin,
						props.icon && styles.iconLabelMargin,
						props.loadingIndicator && styles.loadingHidden,
					)}
				>
					{props.label}
				</span>
			)}
			{props.loadingIndicator && (
				<LoadingDots
					className={styles.loadingIndicator}
					color="currentColor"
				/>
			)}
		</button>
	);
}
