import { DateTime } from "luxon";
import { VelavuInvoiceStatus } from "velavu-js-api";

/**
 * Formats the billing date to a format suitable for billing period terms.
 *
 * @param {string} date - The date to format in ISO-8601 string format.
 * @return {string} The formatted date as a human-readable string.
 */
export function formatBillingDate(date: string): string {
	return DateTime.fromISO(date).toLocaleString(DateTime.DATE_FULL);
}

/**
 * Formats the billing period by concatenating the formatted start and end dates.
 *
 * @param {string} start - The start date of the billing period.
 * @param {string} end - The end date of the billing period.
 * @return {string} - The formatted billing period in the format "start - end".
 */
export function formatBillingPeriod(start: string, end: string): string {
	return `${formatBillingDate(start)} - ${formatBillingDate(end)}`;
}

/**
 * Format the billing price in cents to a currency string in USD.
 *
 * @param {number} cents - The amount in cents to be formatted.
 * @return {string} The human-readable formatted currency string.
 */
export function formatBillingPrice(cents: number): string {
	return `$${(cents / 100).toFixed(2)}`;
}

/**
 * Format a given price in cents into a currency string in USD, discarding cents.
 *
 * @param {number} cents - The price in cents.
 * @return {string} The human-readable formatted currency string in dollars.
 */
export function formatCompactBillingPrice(cents: number): string {
	return `$${Math.floor(cents / 100)}`;
}

/**
 * Maps the invoice status to a human-readable string.
 *
 * @param {VelavuInvoiceStatus} status - The invoice status to be mapped.
 * @returns {string} - The text representation of the invoice status.
 */
export function mapInvoiceStatusText(status: VelavuInvoiceStatus): string {
	switch (status) {
		case VelavuInvoiceStatus.Open:
			return "Unpaid";
		case VelavuInvoiceStatus.Paid:
			return "Paid";
		case VelavuInvoiceStatus.Draft:
			return "Draft";
		case VelavuInvoiceStatus.Void:
			return "Void";
		case VelavuInvoiceStatus.Uncollectible:
			return "Uncollectible";
		default:
			return "Unknown";
	}
}

/**
 * Maps the given invoice status to corresponding colors.
 *
 * @param {VelavuInvoiceStatus} status - The invoice status to map colors for.
 *
 * @return {[string, string]} - An array of two strings representing the foreground color and background color respectively.
 */
export function mapInvoiceStatusColors(
	status: VelavuInvoiceStatus,
): [string, string] {
	switch (status) {
		case VelavuInvoiceStatus.Open:
		case VelavuInvoiceStatus.Uncollectible:
			return ["#da3e52", "#fceff1"];
		case VelavuInvoiceStatus.Paid:
			return ["#00b283", "#f0fcf8"];
		default:
		case VelavuInvoiceStatus.Draft:
		case VelavuInvoiceStatus.Void:
			return ["#3a58e2", "#f0f5fd"];
	}
}
