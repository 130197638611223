// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QPl67w2SzZo_47m4w4p5{width:100%;height:100%;display:flex;flex-direction:row}`, "",{"version":3,"sources":["webpack://./src/components/main/content.module.scss"],"names":[],"mappings":"AAAA,sBACC,UAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA","sourcesContent":[".container {\n\twidth: 100%;\n\theight: 100%;\n\tdisplay: flex;\n\tflex-direction: row;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `QPl67w2SzZo_47m4w4p5`
};
export default ___CSS_LOADER_EXPORT___;
