import React, { CSSProperties } from "react";

export default function IconHumidity(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.25 4.61002L12 3.77002L12.75 4.61002C12.75 4.61002 14.03 6.06002 15.32 7.94002C16.61 9.82002 18 12.07 18 14.23C18 15.8213 17.3679 17.3474 16.2426 18.4727C15.1174 19.5979 13.5913 20.23 12 20.23C10.4087 20.23 8.88258 19.5979 7.75736 18.4727C6.63214 17.3474 6 15.8213 6 14.23C6 12.07 7.39 9.82002 8.68 7.94002C9.97 6.06002 11.25 4.61002 11.25 4.61002ZM16 14.23C16 16.45 14.22 18.23 12 18.23C11.3166 18.23 10.675 18.0614 10.1141 17.7631C11.7427 17.5253 15 15.8947 15 11.2748C15.5915 12.4252 16 13.5263 16 14.23Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
