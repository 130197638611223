/**
 * Converts an image from a URL string to a Blob object
 * @param url The url to the image
 * @returns Promise with Blob object
 */

export async function getBlobFromUrl(url: string): Promise<Blob> {
	const data = await fetch(url);
	const blob = await data.blob();

	return new Promise((resolve) => resolve(blob));
}

/**
 * Converts an image from a URL string to a base64 string
 * @param url The URL to the image
 * @returns Promise with base64 string
 */
export async function getBase64FromUrl(
	url: string,
): Promise<string | undefined> {
	const blob = await getBlobFromUrl(url);

	return new Promise((resolve) => {
		const reader = new FileReader();

		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			const data = reader.result;
			const base64 = data
				?.toString()
				.replace(/^data:image\/(png|jpg|jpeg);base64,/, "");

			resolve(base64);
		};
	});
}

export async function getBase64(
	file: Blob | File,
): Promise<string | undefined> {
	return new Promise((resolve) => {
		const reader = new FileReader();

		reader.readAsDataURL(file);
		reader.onloadend = () => {
			const data = reader.result;
			const dataURL = data?.toString();
			const base64 = dataURL?.split(",")[1];

			resolve(base64);
		};
	});
}

export function getBase64ImageSize(
	base64: string,
): Promise<{ width: number; height: number }> {
	return new Promise((resolve, reject) => {
		const img = new Image();
		img.onload = () => {
			resolve({ width: img.width, height: img.height });
		};
		img.onerror = reject;
		img.src = base64;
	});
}
