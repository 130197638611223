import React, { CSSProperties } from "react";

export default function IconTemperature(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 16C7 14.3642 7.78555 12.9118 9 11.9996V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V11.9996C16.2144 12.9118 17 14.3642 17 16C17 18.7614 14.7614 21 12 21C9.23858 21 7 18.7614 7 16ZM13 6V10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10V6C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
