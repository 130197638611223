import React, { Key, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import IconAdd from "../../../dynamicicons/icon-add";
import IconSearch from "../../../dynamicicons/icon-search";
import IconUnlink from "../../../dynamicicons/icon-unlink";
import Divider from "../../../elements/divider";
import VelavuButton from "../../../elements/velavu-button";
import VelavuIconButton from "../../../elements/velavu-icon-button";
import styles from "./link-card.module.scss";
import { classArr } from "../../../helper/style-helper";
import { useDisableableDebouncedEffect } from "../../../helper/hook-helper";

interface LinkCardProps<T> {
	//Render details
	title: string;
	disableUnpair?: boolean;
	hideUnpair?: boolean;
	onUnpair: () => void;
	linkDetails?: {
		icon: React.ReactNode;
		name: string;
		subtitle: React.ReactNode | string;
	};
	blankLabel: string;
	unpairLabel: string;
	onClickName?: () => void;

	//Autocomplete details
	fetchData: (filter: string | undefined) => Promise<T[]>;
	mapDataID: (value: T) => Key;
	renderData: (value: T) => React.ReactNode;
	addData?: {
		label: string;
		link: string;
	};
	onPair: (data: T) => void;

	defaultOpen?: boolean;
}

export default function LinkCard<T>(props: LinkCardProps<T>) {
	const [isEditMode, setEditMode] = useState(
		!props.linkDetails && props.defaultOpen,
	);
	const [searchText, setSearchText] = useState("");
	const inputRef = useRef<HTMLInputElement>(null);

	const [suggestionsArray, setSuggestionsArray] = useState<T[]>([]);

	const fetchRequestID = useRef(0);
	const fetchData = props.fetchData;
	const loadSuggestions = useCallback(
		(searchText: string) => {
			//Sanitize the search text
			const trimmedSearchText = searchText.trim();
			let effectiveSearchText: string | undefined;
			if (trimmedSearchText.length === 0) {
				effectiveSearchText = undefined;
			} else {
				effectiveSearchText = trimmedSearchText;
			}

			//Get a new request ID
			fetchRequestID.current += 1;
			const currentRequestID = fetchRequestID.current;

			//Fetch the data
			fetchData(effectiveSearchText)
				.then((searchData) => {
					//Make sure that the data is still relevant
					if (fetchRequestID.current !== currentRequestID) return;

					//Set the search data
					setSuggestionsArray(searchData);
				})
				.catch(console.log);
		},
		[fetchData, setSuggestionsArray],
	);

	const [isFocused, setFocused] = useState(false);
	const [isActive, setActive] = useState(false);

	const enableEditMode = useCallback(() => {
		setEditMode(true);
	}, [setEditMode]);

	useDisableableDebouncedEffect(
		() => {
			loadSuggestions(searchText);
		},
		isEditMode ?? false,
		400,
		[isEditMode, setEditMode, loadSuggestions, searchText],
	);

	useEffect(() => {
		if (isEditMode) inputRef.current?.focus();
	}, [isEditMode, inputRef]);

	const disableEditMode = useCallback(() => {
		//Disable focus and edit mode
		setFocused(false);
		setEditMode(false);
	}, [setFocused, setEditMode]);

	const handleBlur = useCallback(() => {
		if (isActive) return;
		if (searchText === "") disableEditMode();
	}, [isActive, searchText, disableEditMode]);

	useEffect(() => {
		//If we're assigned link details, disable edit mode
		if (props.linkDetails && isEditMode) disableEditMode();
	}, [props.linkDetails, isEditMode, disableEditMode]);

	const { defaultOpen: propsDefaultOpen } = props;
	useEffect(() => {
		if (propsDefaultOpen) {
			//Enabling edit mode
			enableEditMode();
		}
	}, [propsDefaultOpen, enableEditMode]);

	return (
		<div className={styles.container}>
			<div className={styles.header}>
				<span className={styles.title}>{props.title}</span>
				{!props.hideUnpair && (
					<VelavuIconButton
						tooltip={props.unpairLabel}
						onClick={props.onUnpair}
						disabled={props.disableUnpair}
						light
					>
						<IconUnlink color="white" />
					</VelavuIconButton>
				)}
			</div>

			<div
				className={styles.cardContent}
				onMouseDown={() => setActive(true)}
				onMouseUp={() => setActive(false)}
				onMouseOut={() => setActive(false)}
				onFocus={() => setFocused(true)}
				onBlur={handleBlur}
			>
				{props.linkDetails ? (
					<div className={styles.cardContainer}>
						{props.linkDetails.icon}
						<div className={styles.contentText}>
							<span
								className={classArr(
									styles.contentName,
									props.onClickName !== undefined &&
										styles.contentTextSelectable,
								)}
								onClick={props.onClickName}
							>
								{props.linkDetails.name}
							</span>
							{typeof props.linkDetails.subtitle === "string" ? (
								<span className={styles.contentSubtitle}>
									{props.linkDetails.subtitle}
								</span>
							) : (
								props.linkDetails.subtitle
							)}
						</div>
					</div>
				) : (
					<div
						className={`${styles.cardContainer} ${
							!isEditMode ? styles.containerSelectable : ""
						}`}
						onClick={enableEditMode}
					>
						{!isEditMode ? (
							<>
								<div className={styles.addCircle}>
									<IconAdd color="#A3B3CC" />
								</div>
								<div className={styles.contentText}>
									<span className={styles.contentBlankText}>
										{props.blankLabel}
									</span>
								</div>
							</>
						) : (
							<>
								<div className={styles.addCircle}>
									<IconSearch color="#A3B3CC" />
								</div>
								<div className={styles.contentText}>
									<input
										id="testing"
										ref={inputRef}
										className={styles.searchInput}
										value={searchText}
										onChange={(event) =>
											setSearchText(event.target.value)
										}
									/>
								</div>
							</>
						)}
					</div>
				)}
				{isFocused && (
					<div className={styles.searchSuggestionsSheet}>
						{suggestionsArray.map((data, index) => (
							<React.Fragment key={props.mapDataID(data)}>
								{index > 0 && <Divider />}
								<div
									className={styles.searchSuggestion}
									onClick={() => props.onPair(data)}
								>
									{props.renderData(data)}
								</div>
							</React.Fragment>
						))}
						{props.addData && (
							<div className={styles.searchCreate}>
								<Link
									to={props.addData.link}
									style={{ textDecoration: "none" }}
								>
									<VelavuButton
										label={props.addData.label}
										icon={(size, color, className) => (
											<IconAdd
												size={size}
												color={color}
												className={className}
											/>
										)}
										size="small"
									/>
								</Link>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}
