import React, { CSSProperties } from "react";

export default function IconSiteEntered(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 18}
			height={props.size ?? 18}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.35575 3.03424C8.58985 2.32192 7.41015 2.32192 6.64425 3.03424L2.64425 6.75441C2.23432 7.13566 2 7.67308 2 8.23748V13.4827C2 14.6025 2.90108 15.5 4 15.5H12C13.0989 15.5 14 14.6025 14 13.4827C14 12.94 14.44 12.5 14.9827 12.5H15.0173C15.56 12.5 16 12.94 16 13.4827C16 15.6965 14.214 17.5 12 17.5H4C1.78603 17.5 0 15.6965 0 13.4827V8.23748C0 7.11944 0.464002 6.05085 1.28219 5.2899L5.28219 1.56973C6.81577 0.143427 9.18422 0.143423 10.7178 1.56972L14.7178 5.28989C15.043 5.59236 15.3123 5.94342 15.5186 6.32681C15.7804 6.81311 15.4447 7.36572 14.909 7.5C14.3733 7.63428 13.8435 7.27867 13.4711 6.87084C13.4344 6.83061 13.3959 6.79176 13.3557 6.75441L9.35575 3.03424Z"
				fill={props.color ?? "#00255D"}
			/>
			<path
				d="M17 11C17.5523 11 18 10.5523 18 10C18 9.44771 17.5523 9 17 9H9.39922V6L5.37531 9.21913C4.8749 9.61946 4.8749 10.3805 5.37531 10.7809L9.39922 14V11H17Z"
				fill={props.color ?? "#00255D"}
			/>
		</svg>
	);
}
