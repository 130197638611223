/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2020.09.23          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import logo from "../../img/logo.svg";
import velavuCity from "../../img/velavu-city.png";
import styles from "./authentication.module.scss";
import Challenge from "./challenge/challenge";
import LogIn from "./log-in/log-in";
import Recover from "./recover/recover";
import RecoverReset from "./recover/recover-reset";

export default function Authentication() {
	useEffect(() => {
		document.title = `Velavu`;
	}, []);

	return (
		<Router>
			<div className={styles.verticalSplit}>
				<div className={styles.interactSplit}>
					<div className={styles.interactColumn}>
						<img className={styles.logo} src={logo} alt="Velavu" />
						<Switch>
							<Route
								path="/login/recover/reset"
								component={RecoverReset}
							/>
							<Route path="/login/recover" component={Recover} />
							<Route
								path="/login/challenge"
								component={Challenge}
							/>
							<Route path="/login" component={LogIn} />
						</Switch>
					</div>
				</div>
				<div className={styles.imageSplit}>
					<img
						className={styles.velavuCity}
						src={velavuCity}
						alt="Velavu City"
					/>
				</div>
			</div>
		</Router>
	);
}
