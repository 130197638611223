import React, { useCallback, useContext, useEffect, useState } from "react";
import VelavuInput from "../../elements/velavu-input";
import OnboardingFrame from "./onboarding-frame";
import { OnboardingFrameProps } from "./onboarding-shared-data";
import SignInContext from "../../sign-in-context";

interface OnboardingStepWelcomeProps extends OnboardingFrameProps {
	organization: string | undefined;
	setOrganization: (organization: string) => void;
}

export default function OnboardingStepOrganization(
	props: OnboardingStepWelcomeProps,
) {
	const {
		navigateBackwards,
		navigateForwards,
		organization: propsOrganization,
		setOrganization: propsSetOrganization,
	} = props;

	const [organizationName, setOrganizationName] = useState("");
	useEffect(() => {
		setOrganizationName(propsOrganization ?? "");
	}, [propsOrganization, setOrganizationName]);

	const [isLoading, setIsLoading] = useState(false);
	const updateOrganization = useContext(SignInContext).updateOrganization;

	const onSubmit = useCallback(async () => {
		const cleanOrganizationName = organizationName.trim();
		try {
			setIsLoading(true);
			await updateOrganization({ name: cleanOrganizationName });
			propsSetOrganization(cleanOrganizationName);
			navigateForwards();
		} catch {
			setIsLoading(false);
		}
	}, [
		setIsLoading,
		organizationName,
		updateOrganization,
		propsSetOrganization,
		navigateForwards,
	]);

	return (
		<OnboardingFrame
			title="Organization"
			description="Please enter the name of your organization"
			buttonSecondary={{
				label: "Back",
				onClick: navigateBackwards,
			}}
			buttonPrimary={{
				label: "Next",
				onClick: onSubmit,
				disabled: isLoading || organizationName.trim().length === 0,
			}}
		>
			<VelavuInput
				value={organizationName}
				onChange={setOrganizationName}
				placeholder="Organization"
				fullWidth
			/>
		</OnboardingFrame>
	);
}
