import React, { useContext, useState } from "react";
import styles from "./settings-billing.module.scss";
import SignInContext from "../../../sign-in-context";
import { useCancellableEffect } from "../../../helper/hook-helper";
import {
	HistoricalVelavuInvoice,
	VelavuAPI,
	VelavuInvoice,
	VelavuSubscription,
	VelavuSubscriptionStatus,
} from "velavu-js-api";
import SettingsBillingStatusText from "./settings-billing-status-text";
import SettingsBillingSubscription from "./settings-billing-subscription";
import SettingsBillingCurrent from "./settings-billing-current";
import SettingsBillingHistory from "./settings-billing-history";

function useBillingState(): [
	//Whether we are loading the subscription.
	//If this is false, it is unknown whether the user has a subscription.
	boolean,
	//The organization's active subscription. Null if there is none.
	VelavuSubscription | null,
	//The upcoming invoice
	VelavuInvoice | "error" | null,
	//All past invoices
	HistoricalVelavuInvoice[] | "error" | null,
] {
	//Get the user's organization
	const userContext = useContext(SignInContext);
	const organization = userContext.organization;

	//Get the invoices
	const [upcomingInvoice, setUpcomingInvoice] = useState<
		VelavuInvoice | "error" | null
	>(null);
	const [allInvoices, setAllInvoices] = useState<
		HistoricalVelavuInvoice[] | "error" | null
	>(null);

	useCancellableEffect(
		(addPromise) => {
			setUpcomingInvoice(null);

			//Don't do anything if there is no subscription
			if (organization?.subscription === undefined) return;

			//Get the upcoming invoice
			addPromise(VelavuAPI.invoices.getUpcomingInvoice())
				.then(setUpcomingInvoice)
				.catch((error) => {
					console.warn(error);
					setUpcomingInvoice("error");
				});

			//Get all invoices
			addPromise(VelavuAPI.invoices.getAllInvoices())
				.then(setAllInvoices)
				.catch((error) => {
					console.warn(error);
					setAllInvoices("error");
				});
		},
		[organization?.subscription, setUpcomingInvoice, setAllInvoices],
	);

	return [
		organization === undefined,
		organization?.subscription ?? null,
		upcomingInvoice,
		allInvoices,
	];
}

export default function SettingsBilling() {
	const [isOrganizationLoading, subscription, upcomingInvoice, allInvoices] =
		useBillingState();

	if (isOrganizationLoading) {
		return (
			<div className={styles.container}>
				<SettingsBillingStatusText>
					Checking your organization status...
				</SettingsBillingStatusText>
			</div>
		);
	} else {
		return (
			<div className={styles.container}>
				<SettingsBillingSubscription subscription={subscription} />
				{subscription !== null &&
					(subscription.status === VelavuSubscriptionStatus.Active ||
						subscription.status ===
							VelavuSubscriptionStatus.Paused) && (
						<>
							<div style={{ height: 20 }} />
							<SettingsBillingCurrent invoice={upcomingInvoice} />
							<div style={{ height: 40 }} />
							<SettingsBillingHistory invoices={allInvoices} />
						</>
					)}
			</div>
		);
	}
}
