/**
 * Maps a cell registration status to its human-readable form
 */
import { isSameDay } from "date-fns";
import { DateTime } from "luxon";
import { MapListDisplayType } from "../components/dashboard/dropdown/map-list/map-list-display-type";
import {
	AnyVelavuEvent,
	DeviceCategory,
	DFURequestStatus,
	EventCategory,
	EventCategoryMap,
	LocationType,
	NormalizedDeviceHardware,
	VelavuAlert,
	VelavuEvent,
} from "velavu-js-api";
import AnalyticsStatType from "../data/analytics-stat-type";
import ConnectionStatus from "../data/connection-status";
import { DateRange } from "../data/date-range";
import FloorFilter from "../data/floor-filter";

export function mapCellRegistrationStatus(value: CellStatus | undefined) {
	switch (value) {
		case "REGISTERED_HOME":
			return "Registered home";
		case "REGISTERED_ROAMING":
			return "Registered roaming";
		default:
			return value ?? "Unknown";
	}
}

/**
 * Maps an internal user registration type to its human-readable form
 */
export function mapUserRegistrationStatus(type: string) {
	switch (type) {
		case "FORCE_CHANGE_PASSWORD":
			return "Pending";
		case "CONFIRMED":
			return "Active";
		default:
			return type;
	}
}

/**
 * Builds human-readable strings from a Velavu route event
 * @param event The event to process
 * @return The title and subtitle strings to display in a tuple
 */
export function buildRouteEventStrings(
	event: VelavuEvent<EventCategory.DrivingEvent>,
): [string, string] {
	let title: string;
	switch (event.data.direction) {
		case "ACCELERATION":
			title = "Harsh acceleration";
			break;
		case "BRAKING":
			title = "Harsh braking";
			break;
		case "TURNING":
			title = "Harsh turning";
			break;
	}

	const subtitle = "Max g-force " + event.data.max_g + " G";

	return [title, subtitle];
}

/**
 * Maps an event category to a human-readable short description
 */
export function mapEventCategoryDescription(category: EventCategory): string {
	switch (category) {
		case EventCategory.Alert:
			return "Alerts";
		case EventCategory.BatteryLevel:
			return "Device battery level";
		case EventCategory.DFURequest:
			return "Firmware update requested";
		case EventCategory.DeviceBoot:
			return "Device powered on";
		case EventCategory.DeviceConnect:
			return "Connection restored";
		case EventCategory.DeviceDisconnect:
			return "Connection lost";
		case EventCategory.DeviceError:
			return "Device error";
		case EventCategory.DeviceProvision:
			return "Device registered";
		case EventCategory.DrivingEvent:
			return "Driving events";
		case EventCategory.DrivingRoute:
			return "Route updates";
		case EventCategory.Environment:
			return "Environment updates";
		case EventCategory.EnvironmentThreshold:
			return "Environment event trigger";
		case EventCategory.GeofenceEntered:
			return "Geofence entered";
		case EventCategory.GeofenceExited:
			return "Geofence exited";
		case EventCategory.Location:
			return "Location updated";
		case EventCategory.LowBattery:
			return "Device battery low";
		case EventCategory.Motion:
			return "Motion detected";
		case EventCategory.OTAPRequest:
			return "OTAP requested";
		case EventCategory.Shock:
			return "Shock detected";
		case EventCategory.SiteEntered:
			return "Site entered";
		case EventCategory.SiteExited:
			return "Site exited";
		case EventCategory.InventoryAdded:
			return "Inventory added";
		case EventCategory.InventoryRemoved:
			return "Inventory removed";
		case EventCategory.WearableAlert:
			return "Button pressed";
		case EventCategory.CustomEvent:
			return "Custom event";
		default:
			return `Unknown (${category})`;
	}
}

type NormalizedEvent = {
	[K in keyof EventCategoryMap]: {
		category: K;
		data: EventCategoryMap[K];
	};
}[keyof EventCategoryMap];

/**
 * Builds a human-readable summary string from a Velavu alert
 * @param item The event or alert to process
 * @return The summary string of the alert
 */
export function buildEventDescription(
	item: AnyVelavuEvent | VelavuAlert,
): string {
	const event = {
		category:
			"event_category" in item ? item.event_category : item.category,
		data: item.data,
	} as NormalizedEvent;

	switch (event.category) {
		case EventCategory.DeviceConnect:
			return "Reconnected to network";
		case EventCategory.DeviceDisconnect:
			return "Lost connection to network";
		case EventCategory.DeviceProvision:
			return "Registered with network";
		case EventCategory.DeviceBoot:
			return "Powered on";
		case EventCategory.LowBattery:
			return `${event.data.battery_level}% battery remaining`;
		case EventCategory.DrivingEvent: {
			switch (event.data.direction) {
				case "ACCELERATION":
					return "Harsh acceleration detected";
				case "BRAKING":
					return "Harsh braking detected";
				case "TURNING":
					return "Harsh turning detected";
			}
			break;
		}
		case EventCategory.Shock:
			return `Shock detected - ${event.data.gforce.toFixed(2)}G`;
		case EventCategory.Motion:
			return `Significant motion detected`;
		case EventCategory.SiteEntered:
			return `Entered site ${event.data.site.name}`;
		case EventCategory.SiteExited:
			return `Left site ${event.data.site.name}`;
		case EventCategory.GeofenceEntered:
			return `Entered geofence ${event.data.geofence.name}`;
		case EventCategory.GeofenceExited:
			return `Left geofence ${event.data.geofence.name}`;
		case EventCategory.DeviceError:
			return `Encountered error ${event.data.level}-${event.data.type}`;
		case EventCategory.InventoryAdded:
			return `Found ${event.data.inventory_asset.name}`;
		case EventCategory.InventoryRemoved:
			return `Missing ${event.data.inventory_asset.name}`;
		case EventCategory.WearableAlert:
			return "Requested assistance";
		default:
			return `Unknown event (${event.category})`;
	}
}

/**
 * Maps a connection status to a human-readable string
 * @param status The connection status to map
 */
export function mapConnectionStatus(status: ConnectionStatus): string {
	switch (status) {
		case "active":
			return "Active";
		case "inactive":
			return "Offline";
		case "registered":
			return "Registered";
		case "unpaired":
			return "Unpaired";
	}
}

/**
 * Maps a floor number to a human-readable string
 */
export function mapFloorNumber(floor: number | undefined): string {
	if (floor === undefined) return "All";
	else if (floor === 1) return "G";
	else return floor.toString();
}

/**
 * Maps a floor number to a suggestion of what this floor could be called
 */
export function getDefaultFloorName(floor: number): string {
	if (floor > 1) {
		return `Floor ${floor}`;
	} else if (floor < 1) {
		return `Basement ${-floor}`;
	} else {
		return "Ground floor";
	}
}

/**
 * Maps an ISO8601 date string to a human-readable string
 * @param date The ISO8601 date string
 * @return The date in dd/mm/yy format
 */
export function formatDate(date: string): string {
	const date_obj: Date = new Date(date);

	return `${date_obj.getDate()}/${date_obj.getMonth() + 1}/${date_obj
		.getFullYear()
		.toString()
		.substr(-2)}`;
}

/**
 * Maps a location type
 * @param type The location type
 */
export function mapLocationType(type: LocationType): string {
	switch (type) {
		case LocationType.GPS:
			return "GPS";
		case LocationType.Fixed:
			return "Fixed";
		case LocationType.Mesh:
			return "Mesh";
		case LocationType.Proximity:
			return "Proximity";
		case LocationType.Cellular:
			return "Cellular";
		case LocationType.Internal:
			return "Internal";
	}
}

/**
 * Formats a date range to a human-readable format, rounding to the nearest day
 */
export function formatDateRange(range: DateRange): string {
	const diff = range.end.getTime() - range.start.getTime();

	if (diff <= 1000 * 60 * 15) {
		// 15 minutes
		return "Last 15 minutes";
	} else if (diff <= 1000 * 60 * 60) {
		// 60 minutes
		return "Last hour";
	} else if (isSameDay(range.start, range.end)) {
		return DateTime.fromJSDate(range.start).toLocaleString(
			DateTime.DATE_FULL,
		);
	} else {
		return `${DateTime.fromJSDate(range.start).toLocaleString(
			DateTime.DATE_MED,
		)} to ${DateTime.fromJSDate(range.end).toLocaleString(
			DateTime.DATE_MED,
		)}`;
	}
}

/**
 * Formats a unit from a statistic to a human-readable string
 */
export function formatUnit(
	stat: AnalyticsStatType,
	value: number,
): string | undefined {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			return Math.round(value) + "º";
		case AnalyticsStatType.Humidity:
		case AnalyticsStatType.Battery:
			return Math.round(value) + "%";
		default:
			return undefined;
	}
}

/**
 * Maps a DFU request status to a human-readable string
 */
export function mapDFURequestStatus(status: DFURequestStatus): string {
	switch (status) {
		case DFURequestStatus.Pending:
			return "Installation pending…";
		case DFURequestStatus.Started:
			return "Installation started…";
		case DFURequestStatus.Complete:
			return "Installed to device";
		case DFURequestStatus.Failed:
			return "Installation failed";
	}
}

/**
 * Maps a normalized device hardware to a human-readable string
 */
export function mapDeviceHardware(
	hardware: NormalizedDeviceHardware | undefined,
): string {
	switch (hardware) {
		case NormalizedDeviceHardware.Argo:
			return "Vesta";
		case NormalizedDeviceHardware.Juno:
			return "Arda";
		case NormalizedDeviceHardware.Meridian:
			return "Pavo";
		case NormalizedDeviceHardware.Pisces:
			return "Manta";
		case undefined:
			return "Unknown";
	}
}

export function mapDeviceCategory(
	category: DeviceCategory | undefined,
): string {
	switch (category) {
		case DeviceCategory.Tag:
			return "Tag";
		case DeviceCategory.Anchor:
			return "Anchor";
		default:
			return category ?? "Unknown";
	}
}

/**
 * Maps a map list display type to a human-readable string
 */
export function mapMapListDisplayType(type: MapListDisplayType): string {
	switch (type) {
		case MapListDisplayType.Asset:
			return "Assets";
		case MapListDisplayType.Site:
			return "Sites";
		case MapListDisplayType.Geofence:
			return "Geofences";
	}
}

/**
 * Maps a FloorFilter to a human-readable string
 * @param floorFilter
 */
export function mapFloorFilter(floorFilter: FloorFilter): string {
	switch (floorFilter) {
		case FloorFilter.All:
			return "All";
		case FloorFilter.Current:
			return "Current";
	}
}
