import React, { useCallback, useMemo, useState } from "react";
import styles from "./map-list-selector.module.scss";
import { MapListDisplayType } from "./map-list-display-type";
import IconClose from "../../../../dynamicicons/icon-close";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import MapListDropdownSelector from "./map-list-selector-dropdown";
import MapListSelectorOption from "./map-list-selector-option";
import { classArr } from "../../../../helper/style-helper";

export interface MapListSelectorProps {
	displayType: MapListDisplayType;
	onChangeDisplayType: (value: MapListDisplayType) => void;

	onClose?: () => void;
}

export default function MapListSelector(props: MapListSelectorProps) {
	//Whether the dropdown is expanded or not, showing all available destinations
	const [isExpanded, setExpanded] = useState(false);

	const toggleExpanded = useCallback(() => {
		setExpanded((prev) => !prev);
	}, [setExpanded]);

	const selectableOptions = useMemo(() => {
		return [
			MapListDisplayType.Asset,
			MapListDisplayType.Site,
			MapListDisplayType.Geofence,
		].filter((option) => option !== props.displayType);
	}, [props.displayType]);

	const onChangeDisplayType = props.onChangeDisplayType;
	const selectOption = useCallback(
		(option: MapListDisplayType) => {
			onChangeDisplayType(option);
			setExpanded(false);
		},
		[onChangeDisplayType, setExpanded],
	);

	return (
		<div>
			<div className={styles.header}>
				<MapListDropdownSelector
					displayType={props.displayType}
					isOpen={isExpanded}
					onClick={toggleExpanded}
				/>

				<VelavuIconButton onClick={props.onClose}>
					<IconClose color="#A3B3CC" />
				</VelavuIconButton>
			</div>

			<div
				className={classArr(
					styles.options,
					isExpanded
						? styles.optionsExpanded
						: styles.optionsCollapsed,
				)}
			>
				<div className={styles.optionsContainer}>
					{selectableOptions.map((option) => (
						<MapListSelectorOption
							key={option}
							option={option}
							onClick={() => selectOption(option)}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
