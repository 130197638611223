import React, { useCallback, useContext, useEffect, useState } from "react";
import VelavuSwitchBlock from "../../elements/velavu-switch-block";
import { patchArray } from "../../helper/array-helper";
import { dirtyCheckArray, usePreference } from "../../helper/hook-helper";
import SignInContext from "../../sign-in-context";
import ComponentSaveBar from "./component-save-bar";
import styles from "./settings-alerts.module.scss";
import { alertEventCategories, EventCategory } from "velavu-js-api";
import { mapEventCategoryDescription } from "../../helper/language-helper";

const eventCategoryArray: [EventCategory, string][] = alertEventCategories
	.map((category): [EventCategory, string] => [
		category,
		mapEventCategoryDescription(category),
	])
	.sort(([, a], [, b]) => Number(a > b) - Number(a < b));
const eventCategoryArraySplit = Math.ceil(eventCategoryArray.length / 2);

export default function SettingsAlerts(props: {
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
}) {
	const userContext = useContext(SignInContext);
	const updateVelavuUser = userContext.updateVelavuUser;

	const [devicesSelection, setDevicesSelection, devicesSelectionDirty] =
		usePreference(
			userContext.preferences.alertsEventCategoryFilter,
			dirtyCheckArray,
		);

	const [isLoading, setLoading] = useState(false);
	const [lastSaveResult, setLastSaveResult] = useState<boolean | null>(null);

	const saveChanges = useCallback(() => {
		updateVelavuUser({
			settings: { alertsEventCategoryFilter: devicesSelection },
		})
			.then(() => {
				setLastSaveResult(true);
			})
			.catch((error) => {
				console.log(error);
				setLastSaveResult(false);
			})
			.finally(() => setLoading(false));
	}, [devicesSelection, updateVelavuUser]);

	const propsSetDirty = props.setDirty;
	useEffect(
		() => propsSetDirty(devicesSelectionDirty),
		[devicesSelectionDirty, propsSetDirty],
	);

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Device alerts</h1>
			<span className={styles.info}>
				Select the items you would like to receive alerts for.
			</span>

			<div className={styles.inputSection}>
				<div className={styles.inputColumn}>
					{eventCategoryArray
						.slice(0, eventCategoryArraySplit)
						.map(([category, label]) => {
							const isSelected =
								devicesSelection.includes(category);
							return (
								<VelavuSwitchBlock
									key={category}
									className={styles.switchBlock}
									toggled={isSelected}
									onChange={() =>
										patchArray(
											setDevicesSelection,
											category,
											!isSelected,
										)
									}
									label={label}
								/>
							);
						})}
				</div>
				<div className={styles.inputSpacer} />
				<div className={styles.inputColumn}>
					{eventCategoryArray
						.slice(eventCategoryArraySplit)
						.map(([category, label]) => {
							const isSelected =
								devicesSelection.includes(category);
							return (
								<VelavuSwitchBlock
									key={category}
									className={styles.switchBlock}
									toggled={isSelected}
									onChange={() =>
										patchArray(
											setDevicesSelection,
											category,
											!isSelected,
										)
									}
									label={label}
								/>
							);
						})}
				</div>
			</div>

			<ComponentSaveBar
				disabled={!props.isDirty || isLoading}
				onClick={saveChanges}
				labelOK="Alerts settings saved"
				labelError="Failed to save alerts settings"
				result={lastSaveResult}
			/>
		</div>
	);
}
