// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.b6IjpTKK6TtRzHThfThj{margin-top:20px}`, "",{"version":3,"sources":["webpack://./src/components/dashboard/dropdown/gateway-detail/gateway-properties.module.scss"],"names":[],"mappings":"AAAA,sBACC,eAAA","sourcesContent":[".button {\n\tmargin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `b6IjpTKK6TtRzHThfThj`
};
export default ___CSS_LOADER_EXPORT___;
