import React, { CSSProperties } from "react";
import { VelavuSwitchDisplay } from "./velavu-switch";
import styles from "./velavu-switch-block.module.scss";

export interface VelavuSwitchBlockProps {
	style?: CSSProperties;
	className?: string;

	toggled: boolean;
	onChange: (toggled: boolean) => void;
	label: string;

	children?: React.ReactNode;
}

export default function VelavuSwitchBlock(props: VelavuSwitchBlockProps) {
	return (
		<div
			style={props.style}
			className={`${styles.container} ${props.className ?? ""}`}
		>
			<button
				className={`${styles.button} ${
					props.children && props.toggled ? styles.buttonTop : ""
				}`}
				onClick={() => props.onChange(!props.toggled)}
			>
				<span
					className={`${styles.label} ${
						props.toggled
							? styles.labelChecked
							: styles.labelUnchecked
					}`}
				>
					{props.label}
				</span>
				<VelavuSwitchDisplay toggled={props.toggled} />
			</button>

			{props.children}
		</div>
	);
}
