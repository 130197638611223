import { Feature, FeatureCollection } from "geojson";
import mapboxgl, { ImageSource, ImageSourceOptions } from "mapbox-gl";

export const geoJSONBlank: Feature = {
	type: "Feature",
	geometry: { type: "Point", coordinates: [] },
	properties: [],
};

export const geoJSONBlankCollection: FeatureCollection = {
	type: "FeatureCollection",
	features: [],
};

export const geoJSONBlankLine: Feature = {
	type: "Feature",
	geometry: { type: "LineString", coordinates: [] },
	properties: {},
};

export const imageSourceBlank: ImageSourceOptions = {
	url: "https://docs.mapbox.com/mapbox-gl-js/assets/radar.gif",
	coordinates: [
		[0, 0],
		[0, 1],
		[1, 1],
		[1, 0],
	],
};
