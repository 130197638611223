import React from "react";
import { VelavuInventoryItem } from "velavu-js-api";
import IconCircleAdd from "../../../../dynamicicons/icon-circle-add";
import IconCircleDelete from "../../../../dynamicicons/icon-circle-delete";
import IconInventoryStatus from "../../../../dynamicicons/icon-inventory-status";
import { GroupTag } from "../../../../elements/categorization-tag";
import VelavuIconButton from "../../../../elements/velavu-icon-button";
import styles from "./asset-inventory-item.module.scss";

export default function AssetInventoryItem(props: {
	item: VelavuInventoryItem;
	fill: string;
	stroke: string;
	className?: string;
	action?: "add" | "delete" | undefined;
	onClick: VoidFunction;
	onMouseEnter: VoidFunction;
	onMouseLeave: VoidFunction;
	deleteInventory?: (item: VelavuInventoryItem) => void;
	addInventory?: (item: VelavuInventoryItem) => void;
	isOther?: boolean;
}) {
	return (
		<div className={`${styles.item} ${props.className}`}>
			<button
				className={styles.itemButton}
				onClick={props.onClick}
				onMouseEnter={props.onMouseEnter}
				onMouseLeave={props.onMouseLeave}
			>
				<div className={styles.titles}>
					<span className={styles.name}>
						{props.item.asset.name.length > 18
							? props.item.asset.name.slice(0, 17).concat("...")
							: props.item.asset.name}
					</span>
					<GroupTag
						categorization={{
							category: props.item.asset.category,
							group: props.item.asset.group,
						}}
					/>
				</div>
				<div className={styles.status}>
					<IconInventoryStatus
						fill={props.fill}
						stroke={props.stroke}
					/>
					<div className={styles.distance}>
						{props.item.proximity
							? `${props.item.proximity.distance}m away`
							: props.isOther
							? "Out of range"
							: "Missing"}
					</div>
				</div>
			</button>
			{props.action &&
				props.action === "add" &&
				!props.item.asset.parent_id && (
					<VelavuIconButton
						className={styles.actionButton}
						onClick={() => props.addInventory?.(props.item)}
					>
						<IconCircleAdd color={"#5F718C"} />
					</VelavuIconButton>
				)}
			{props.action && props.action === "delete" && (
				<VelavuIconButton
					className={styles.actionButton}
					onClick={() => props.deleteInventory?.(props.item)}
				>
					<IconCircleDelete color={"#5F718C"} />
				</VelavuIconButton>
			)}
		</div>
	);
}
