import { VelavuSite } from "velavu-js-api";
import {
	MapboxOverlayBox,
	MapHelperInstance,
	useStyleDependantMapEffect,
} from "../../helper/map-helper";
import { getFloorTarget } from "../../helper/floor-helper";
import { imageSourceBlank } from "./mapbox-injectable";

const mapboxIDSiteFloorplanPrefix: string = "site-floorplan-";
const mapboxIDSiteFloorplanImagePrefix: string = "site-floorplan-image-";

export interface MapboxInjectableSiteFloorplansProps {
	sites?: VelavuSite[] | undefined;
	siteCounts?: number[] | undefined;
	floorOverride?: { [key: string]: string | undefined };
	editingSiteId?: string;
	belowLayer?: string;
}

function drawFloorplan(
	box: MapboxOverlayBox,
	sites: VelavuSite[],
	editingSiteId: string | undefined,
	floorOverride?: { [key: string]: string | undefined },
	belowLayer?: string | undefined,
): string[] {
	const createdIDs: string[] = [];

	sites.forEach((site) => {
		const mapboxID = mapboxIDSiteFloorplanPrefix + site.id;
		createdIDs.push(mapboxID);

		box.addSource(mapboxID, {
			type: "geojson",
			data: getFloorTarget(site.floors!, floorOverride?.[site.id])!
				.floorplan,
		});

		box.addLayer(
			{
				id: mapboxID,
				source: mapboxID,
				type: "fill-extrusion",
				paint: {
					"fill-extrusion-color": "#3A58E2",
					"fill-extrusion-height": 3,
					"fill-extrusion-opacity":
						site.id !== editingSiteId ? 0.5 : 0,
				},
			},
			belowLayer,
		);
	});

	return createdIDs;
}

function drawFloorplanImages(
	box: MapboxOverlayBox,
	sites: VelavuSite[],
	editingSiteId: string | undefined,
	floorOverride?: { [key: string]: string | undefined },
	belowLayer?: string | undefined,
): string[] {
	const createdIDs: string[] = [];

	sites.forEach((site) => {
		const mapboxID = mapboxIDSiteFloorplanImagePrefix + site.id;
		createdIDs.push(mapboxID);

		let floorImageSource = { ...imageSourceBlank };
		if (site.id !== editingSiteId) {
			const floor = getFloorTarget(
				site.floors!,
				floorOverride?.[site.id],
			)!;
			if (floor?.image && floor?.image_coordinates) {
				floorImageSource = {
					url: floor.image,
					coordinates: floor.image_coordinates,
				};
			}
		}

		box.addSource(mapboxID, {
			type: "image",
			...floorImageSource,
		});

		box.addLayer(
			{
				id: mapboxID,
				source: mapboxID,
				type: "raster",
				paint: {
					"raster-opacity": 0.8,
				},
			},
			belowLayer,
		);
	});

	return createdIDs;
}

export default function useMapboxInjectableSiteFloorplans(
	mapInstance: MapHelperInstance,
	props: MapboxInjectableSiteFloorplansProps,
): string | undefined {
	const allSites = props.sites;

	useStyleDependantMapEffect(
		mapInstance,
		(_, box) => {
			if (allSites === undefined) return;

			//Filter out sites with no floors
			const floorSites = allSites.filter(
				(site) => site.floors !== undefined && site.floors.length > 0,
			);

			drawFloorplan(
				box,
				floorSites,
				props.editingSiteId,
				props.floorOverride,
				props.belowLayer,
			);

			drawFloorplanImages(
				box,
				floorSites,
				props.editingSiteId,
				props.floorOverride,
				props.belowLayer,
			);
		},
		[allSites, props.floorOverride, props.editingSiteId, props.belowLayer],
	);

	//Return the layer ID of the last site
	if (allSites !== undefined && allSites.length !== 0) {
		return (
			mapboxIDSiteFloorplanImagePrefix + allSites[allSites.length - 1].id
		);
	} else {
		return undefined;
	}
}
