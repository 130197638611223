import React, { CSSProperties } from "react";
import styles from "./bottom-tooltip.module.scss";
import { classArr } from "../helper/style-helper";

export type BottomTooltipEdge = "top" | "bottom" | "left" | "right";

export interface BottomTooltipProps {
	children?: string | React.ReactNode;
	className?: string;
	refPosition?: HTMLElement;
	edge?: BottomTooltipEdge;
}

export default function BottomTooltip(props: BottomTooltipProps) {
	const edge: BottomTooltipEdge = props.edge ?? "bottom";

	const content = (
		<>
			{edge === "bottom" && (
				<svg width="8" height="4" viewBox="0 0 2 1">
					<polygon points="0,1 1,0 2,1" fill="#00255D" />
				</svg>
			)}

			{edge === "right" && (
				<svg width="4" height="8" viewBox="0 0 1 2">
					<polygon points="1,0 1,2 0,1" fill="#00255D" />
				</svg>
			)}

			<span className={`${styles.tooltipContainer} ${props.className}`}>
				{props.children}
			</span>

			{edge === "top" && (
				<svg width="8" height="4" viewBox="0 0 2 1">
					<polygon points="0,0 1,1 2,0" fill="#00255D" />
				</svg>
			)}

			{edge === "left" && (
				<svg width="4" height="8" viewBox="0 0 1 2">
					<polygon points="0,0 0,2 1,1" fill="#00255D" />
				</svg>
			)}
		</>
	);

	const sharedStyles = [styles.tooltip];
	switch (edge) {
		case "bottom":
			sharedStyles.push(styles.bottom);
			break;
		case "top":
			sharedStyles.push(styles.top);
			break;
		case "left":
			sharedStyles.push(styles.left);
			break;
		case "right":
			sharedStyles.push(styles.right);
			break;
	}

	if (props.refPosition) {
		const positionRect = props.refPosition.getBoundingClientRect();

		let style: CSSProperties;
		switch (edge) {
			case "bottom":
				style = {
					left: positionRect.left + props.refPosition.offsetWidth / 2,
					top: positionRect.top + props.refPosition.offsetHeight,
				};
				break;
			case "top":
				style = {
					left: positionRect.left + props.refPosition.offsetWidth / 2,
					top: positionRect.top,
				};
				break;
			case "left":
				style = {
					left: positionRect.left,
					top: positionRect.top + props.refPosition.offsetHeight / 2,
				};
				break;
			case "right":
				style = {
					left: positionRect.left + props.refPosition.offsetWidth,
					top: positionRect.top + props.refPosition.offsetHeight / 2,
				};
				break;
		}

		return (
			<div
				className={classArr(...sharedStyles, styles.tooltipFixed)}
				style={style}
			>
				{content}
			</div>
		);
	} else {
		return (
			<div className={classArr(...sharedStyles, styles.tooltipAbsolute)}>
				{content}
			</div>
		);
	}
}
