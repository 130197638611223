import React from "react";
import styles from "./settings-billing-current.module.scss";
import { VelavuInvoice } from "velavu-js-api";
import SettingsBillingStatusText from "./settings-billing-status-text";
import SettingsBillingSummaryCard from "./settings-billing-summary-card";
import SettingsBillingUsageTable from "./settings-billing-usage-table";

export interface SettingsBillingCurrentProps {
	invoice?: VelavuInvoice | "error" | null | undefined;
}

export default function SettingsBillingCurrent(
	props: SettingsBillingCurrentProps,
) {
	return (
		<div className={styles.container}>
			<span className={styles.title}>Current Period</span>

			{!props.invoice ? (
				<SettingsBillingStatusText>
					Checking your billing usage...
				</SettingsBillingStatusText>
			) : props.invoice === "error" ? (
				<SettingsBillingStatusText type="error">
					There was a problem checking your billing usage. Please try
					again later.
				</SettingsBillingStatusText>
			) : (
				<React.Fragment>
					<SettingsBillingSummaryCard
						start={props.invoice.period_start}
						end={props.invoice.period_end}
						total={props.invoice.total}
					/>

					<span className={styles.subtitle}>Usage to-date</span>

					<SettingsBillingUsageTable usage={props.invoice.usage} />
				</React.Fragment>
			)}
		</div>
	);
}
