import React from "react";
import { VelavuModalProvider } from "../elements/velavu-modal";
import styles from "./screen-container.module.scss";

//A basic screen that has a blue border and a center area for content
export default function ScreenContainer(props: {
	children?: React.ReactNode;
	modals?: React.ReactNode;
}) {
	return (
		<VelavuModalProvider>
			<div className={styles.container}>
				{props.modals}
				<div className={styles.content}>{props.children}</div>
			</div>
		</VelavuModalProvider>
	);
}
