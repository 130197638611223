import React, { CSSProperties } from "react";

export default function IconViewGridPlus(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 20}
			height={props.size ?? 20}
			viewBox="0 0 20 20"
		>
			<mask
				id="mask0_140_5"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="20"
				height="20"
			>
				<rect width="20" height="20" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_140_5)">
				<path
					d="M9.23221 4H7C3.13401 4 0 7.13401 0 11C0 14.866 3.13401 18 7 18H11C14.209 18 16.9136 15.8407 17.7402 12.8959C17.0034 13.2389 16.1915 13.4479 15.336 13.4915C14.4727 14.9907 12.8542 16 11 16H7C4.23858 16 2 13.7614 2 11C2 8.23858 4.23858 6 7 6H8.57645C8.68628 5.28869 8.91144 4.61546 9.23221 4Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M13.0403 13.1994C12.505 13.6962 11.788 14 11 14H7C5.34315 14 4 12.6569 4 11C4 9.34315 5.34315 8 7 8H8.57645C8.68628 8.71131 8.91144 9.38454 9.23221 10H7C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12H10.8464C11.4857 12.5317 12.2286 12.943 13.0403 13.1994Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M15 12C17.7614 12 20 9.76142 20 7C20 4.23858 17.7614 2 15 2C12.2386 2 10 4.23858 10 7C10 9.76142 12.2386 12 15 12ZM14.25 4.75C14.25 4.33579 14.5858 4 15 4C15.4142 4 15.75 4.33579 15.75 4.75V6.25H17.25C17.6642 6.25 18 6.58579 18 7C18 7.41421 17.6642 7.75 17.25 7.75H15.75V9.25C15.75 9.66421 15.4142 10 15 10C14.5858 10 14.25 9.66421 14.25 9.25V7.75H12.75C12.3358 7.75 12 7.41421 12 7C12 6.58579 12.3358 6.25 12.75 6.25H14.25V4.75Z"
					fill={props.color ?? "#00255D"}
				/>
			</g>
		</svg>
	);
}
