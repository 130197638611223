import React, { useCallback, useMemo } from "react";
import styles from "./mapbox-injectable-place-image.module.scss";
import {
	MapHelperInstance,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import {
	EventData,
	GeoJSONSource,
	ImageSource,
	MapEventType,
	Marker,
} from "mapbox-gl";
import { geoJSONBlank, imageSourceBlank } from "./mapbox-injectable";
import { projectToPlane } from "../../helper/geo-helper";
import {
	degToRad,
	findLineIntersection,
	pointAngleLineToStandard,
	radToDeg,
} from "../../helper/math-helper";
import { ImagePoint } from "../../data/image-point";
import PlaceableFloorImage, {
	usePlaceableFloorImageURL,
} from "../../data/placeable-floor-image";
import PlaceImageState, {
	placeImageStateToTransformer,
} from "../../data/place-image-state";

const mapboxIDFloorPlanImage = "planImage";
const mapboxIDHandleOrigin = "origin";
const mapboxIDHandleRotate = "rotate";
const mapboxIDHandleBorder = "border";
const mapboxIDsHandleEdge = Array.from({ length: 4 }, (_, i) => `edge-${i}`);

const handleSize = 20;

export interface MapboxInjectablePlaceImageParams {
	mapInstance: MapHelperInstance;

	state: PlaceImageState;
	onChangeState: React.Dispatch<React.SetStateAction<PlaceImageState>>;

	image?: PlaceableFloorImage | null;
}

export default function useMapboxInjectablePlaceImage(
	params: MapboxInjectablePlaceImageParams,
) {
	const changeState = params.onChangeState;
	const setStateCenter = useCallback(
		(center: [number, number]) => {
			changeState((state) => ({
				...state,
				center: center,
			}));
		},
		[changeState],
	);
	const setStateScale = useCallback(
		(scale: number) => {
			changeState((state) => ({
				...state,
				scale: scale,
			}));
		},
		[changeState],
	);
	const setStateRotation = useCallback(
		(rotation: number) => {
			changeState((state) => ({
				...state,
				rotation: rotation,
			}));
		},
		[changeState],
	);

	//Markers
	const markerOrigin = useMemo(
		() =>
			new Marker(
				createMarkerElementLarge(`
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<!-- 4-arrow move -->
				<path d="M15.14 6.15c.32.31.1.85-.35.85H13v4h4V9.2a.5.5 0 01.85-.35l2.79 2.79c.2.2.2.51 0 .71l-2.79 2.79c-.31.32-.85.1-.85-.35V13h-4v4h1.8a.5.5 0 01.35.85l-2.79 2.79c-.2.2-.51.2-.71 0l-2.79-2.79c-.32-.31-.1-.85.35-.85H11v-4H7v1.8a.5.5 0 01-.85.35l-2.79-2.79c-.2-.2-.2-.51 0-.71l2.79-2.79c.31-.32.85-.1.85.35V11h4V7H9.2a.5.5 0 01-.35-.85l2.79-2.79c.2-.2.51-.2.71 0l2.79 2.79z" fill="#00255D" />
			</svg>
			`),
			),
		[],
	);

	const markerRotate = useMemo(
		() =>
			new Marker(
				createMarkerElementLarge(`
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
				<!-- Spin -->
				<path d="M16.24 16.243a6 6 0 00-8.485-8.486 1.016 1.016 0 01-.055.051L8.97 9.076a.495.495 0 01-.354.849H4.67a.495.495 0 01-.502-.502V5.477a.5.5 0 01.849-.354L6.29 6.398l.05-.055a8 8 0 11-2.107 7.59 1 1 0 111.942-.482 6 6 0 0010.066 2.792z" fill="#00255D" />
			</svg>
			`),
			),
		[],
	);

	const markersEdge = useMemo(
		() =>
			Array.from(
				{ length: 4 },
				() => new Marker(createMarkerElementSmall()),
			),
		[],
	);

	useStyleSetupMapEffect(params.mapInstance, (map, box) => {
		box.addSource(mapboxIDFloorPlanImage, {
			type: "image",
			...imageSourceBlank,
		});
		box.addLayer({
			id: mapboxIDFloorPlanImage,
			source: mapboxIDFloorPlanImage,
			type: "raster",
			paint: {
				"raster-opacity": 0.4,
			},
		});

		box.addSource(mapboxIDHandleOrigin, {
			type: "geojson",
			data: geoJSONBlank,
		});
		box.addLayer({
			id: mapboxIDHandleOrigin,
			type: "circle",
			source: mapboxIDHandleOrigin,
			paint: {
				"circle-radius": handleSize,
				"circle-opacity": 0,
			},
		});
		markerOrigin.setLngLat([0, 0]).addTo(map);

		box.addSource(mapboxIDHandleRotate, {
			type: "geojson",
			data: geoJSONBlank,
		});
		box.addLayer({
			id: mapboxIDHandleRotate,
			type: "circle",
			source: mapboxIDHandleRotate,
			paint: {
				"circle-radius": handleSize,
				"circle-opacity": 0,
			},
		});
		markerRotate.setLngLat([0, 0]).addTo(map);

		box.addSource(mapboxIDHandleBorder, {
			type: "geojson",
			data: geoJSONBlank,
		});
		box.addLayer({
			id: mapboxIDHandleBorder,
			type: "line",
			source: mapboxIDHandleBorder,
			paint: {
				"line-width": 1,
				"line-color": "#00255D",
			},
		});

		for (const id of mapboxIDsHandleEdge) {
			box.addSource(id, { type: "geojson", data: geoJSONBlank });
			box.addLayer({
				id: id,
				type: "circle",
				source: id,
				paint: {
					"circle-radius": handleSize,
					"circle-opacity": 0,
				},
			});
		}

		for (const marker of markersEdge) {
			marker.setLngLat([0, 0]).addTo(map);
		}
	});

	const imageSize = params.image?.dimensions;
	const edgePositions = useMemo(():
		| [ImagePoint, ImagePoint, ImagePoint, ImagePoint]
		| undefined => {
		if (imageSize === undefined) {
			return undefined;
		}

		const halfX = imageSize.width / 2;
		const halfY = imageSize.height / 2;

		return [
			{ x: -halfX, y: halfY },
			{ x: halfX, y: halfY },
			{ x: halfX, y: -halfY },
			{ x: -halfX, y: -halfY },
		];
	}, [imageSize]);
	const edgePositionsDistance = useMemo(():
		| [number, number, number, number]
		| undefined => {
		if (edgePositions === undefined) {
			return undefined;
		}

		return edgePositions.map(({ x, y }) =>
			Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2)),
		) as [number, number, number, number];
	}, [edgePositions]);

	const handlePositions = useMemo(() => {
		if (imageSize === undefined || edgePositions === undefined) {
			return undefined;
		}

		//Create the transformer
		const transformer = placeImageStateToTransformer(params.state);

		//Calculate the resize handle locations (clockwise)
		const resizeHandles = edgePositions.map((it) =>
			transformer.transform(it),
		);

		//Calculating the rotate handle location
		const rotateHandle = transformer.transform({
			x: 0,
			y: -imageSize.height / 4,
		});

		return {
			originHandle: params.state.center,
			resizeHandles: resizeHandles,
			rotateHandle: rotateHandle,
		};
	}, [
		imageSize,
		edgePositions,
		params.state.center,
		params.state.rotation,
		params.state.scale,
	]);

	//Update the origin handle
	const originHandlePosition = handlePositions?.originHandle;
	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			const handleOriginSource = map.getSource(
				mapboxIDHandleOrigin,
			) as GeoJSONSource;

			if (originHandlePosition !== undefined) {
				handleOriginSource.setData({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: originHandlePosition,
					},
					properties: {},
				});
				markerOrigin.setLngLat(originHandlePosition);
				markerOrigin.getElement().style.display = "";
			} else {
				handleOriginSource.setData(geoJSONBlank);
				markerOrigin.getElement().style.display = "none";
			}
		},
		[originHandlePosition],
	);

	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			const canvas = map.getCanvas();

			const onMove = (event: MapEventType["mousemove"] & EventData) => {
				//Set the cursor to grab
				canvas.style.cursor = "grabbing";

				//Update the location
				setStateCenter([event.lngLat.lng, event.lngLat.lat]);
			};
			const onUp = (event: MapEventType["mouseup"] & EventData) => {
				//Reset the cursor
				canvas.style.cursor = "";

				//Stop tracking move events
				map.off("mousemove", onMove);

				//Update the location
				setStateCenter([event.lngLat.lng, event.lngLat.lat]);
			};
			const onDown = (event: MapEventType["mousedown"] & EventData) => {
				//Prevent the default map drag behavior
				event.preventDefault();

				canvas.style.cursor = "grab";

				map.on("mousemove", onMove);
				map.once("mouseup", onUp);
			};
			map.on("mousedown", mapboxIDHandleOrigin, onDown);

			return () => {
				map.off("mousemove", onMove);
				map.off("mousedown", mapboxIDHandleOrigin, onDown);
			};
		},
		[setStateCenter],
	);

	//Update the rotation handle
	const rotateHandlePosition = handlePositions?.rotateHandle;
	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			const handleRotateSource = map.getSource(
				mapboxIDHandleRotate,
			) as GeoJSONSource;

			if (rotateHandlePosition !== undefined) {
				handleRotateSource.setData({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: rotateHandlePosition,
					},
					properties: {},
				});
				markerRotate.setLngLat(rotateHandlePosition);
				markerRotate.getElement().style.display = "";
			} else {
				handleRotateSource.setData(geoJSONBlank);
				markerRotate.getElement().style.display = "none";
			}
		},
		[rotateHandlePosition],
	);

	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			const canvas = map.getCanvas();

			function updateLocation(location: [number, number]) {
				//Convert to distance and rotation
				const centerPlane = projectToPlane(
					params.state.center[0],
					params.state.center[1],
				);
				const centerClick = projectToPlane(location[0], location[1]);

				const locationDelta = [
					centerClick[0] - centerPlane![0],
					centerClick[1] - centerPlane![1],
				];

				let angle = -radToDeg(
					Math.atan(locationDelta[0] / locationDelta[1]),
				);
				if (locationDelta[1] < 0) angle += 180;
				setStateRotation(angle + 180);
			}

			const onMove = (event: MapEventType["mousemove"] & EventData) => {
				//Set the cursor to grab
				canvas.style.cursor = "grabbing";

				//Update the location
				updateLocation([event.lngLat.lng, event.lngLat.lat]);
			};
			const onUp = (event: MapEventType["mouseup"] & EventData) => {
				//Reset the cursor
				canvas.style.cursor = "";

				//Stop tracking move events
				map.off("mousemove", onMove);

				//Update the location
				updateLocation([event.lngLat.lng, event.lngLat.lat]);
			};
			const onDown = (event: MapEventType["mousedown"] & EventData) => {
				//Prevent the default map drag behavior
				event.preventDefault();

				canvas.style.cursor = "grab";

				map.on("mousemove", onMove);
				map.once("mouseup", onUp);
			};
			map.on("mousedown", mapboxIDHandleRotate, onDown);

			return () => {
				map.off("mousemove", onMove);
				map.off("mousedown", mapboxIDHandleRotate, onDown);
			};
		},
		[params.state.center, setStateRotation],
	);

	//Update the edge handles
	const resizeHandlePositions = handlePositions?.resizeHandles;
	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			const borderSource = map.getSource(
				mapboxIDHandleBorder,
			) as GeoJSONSource;

			if (resizeHandlePositions !== undefined) {
				//Update the handles
				mapboxIDsHandleEdge.forEach((id, index) => {
					(map.getSource(id) as GeoJSONSource).setData({
						type: "Feature",
						geometry: {
							type: "Point",
							coordinates: resizeHandlePositions[index],
						},
						properties: {},
					});
				});
				markersEdge.forEach((marker, index) => {
					marker.setLngLat(resizeHandlePositions[index]);
					marker.getElement().style.display = "";
				});

				//Update the border
				borderSource.setData({
					type: "Feature",
					geometry: {
						type: "LineString",
						coordinates: resizeHandlePositions.concat([
							resizeHandlePositions[0],
						]),
					},
					properties: {},
				});
			} else {
				//Hide the handles
				for (const id of mapboxIDsHandleEdge) {
					(map.getSource(id) as GeoJSONSource).setData(geoJSONBlank);
				}
				for (const marker of markersEdge) {
					marker.getElement().style.display = "none";
				}

				//Hide the border
				borderSource.setData(geoJSONBlank);
			}
		},
		[resizeHandlePositions],
	);

	//Edge handles
	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			//Ignore if the map or data isn't initialized
			if (edgePositionsDistance === undefined) return;

			const localEdgePositionsDistance = edgePositionsDistance;
			const canvas = map.getCanvas();

			const cleanupCallbacks: (() => void)[] = [];
			for (let index = 0; index < 4; index++) {
				const id = mapboxIDsHandleEdge[index];

				function updateLocation(location: [number, number]) {
					const centerPlane = projectToPlane(
						params.state.center[0],
						params.state.center[1],
					);
					const clickPlane = projectToPlane(location[0], location[1]);

					const locationDelta = [
						clickPlane[0] - centerPlane![0],
						clickPlane[1] - centerPlane![1],
					];

					//Calculating the angle of a line between this handle and the plan origin
					const lineAngle =
						(index % 2 === 0 ? -45 : 45) + params.state.rotation;
					const lineSlope = Math.tan(degToRad(lineAngle));

					//A line between this handle and the plan origin
					const lineHandle = pointAngleLineToStandard(
						{ x: 0, y: 0 },
						lineSlope,
					);
					//A line perpendicular to the previous line that passes through the mouse cursor
					const lineCursor = pointAngleLineToStandard(
						{ x: locationDelta[0], y: locationDelta[1] },
						-1 / lineSlope,
					);

					const intersectPoint = findLineIntersection(
						lineHandle,
						lineCursor,
					)!;
					const cursorDistance = Math.sqrt(
						Math.pow(intersectPoint!.x, 2) +
							Math.pow(intersectPoint!.y, 2),
					);
					const handleOriginDistance =
						localEdgePositionsDistance[index];

					const scale = cursorDistance / handleOriginDistance;
					setStateScale(scale);
				}

				const onMove = (
					event: MapEventType["mousemove"] & EventData,
				) => {
					//Set the cursor to grab
					canvas.style.cursor = "grabbing";

					//Update the location
					updateLocation([event.lngLat.lng, event.lngLat.lat]);
				};
				const onUp = (event: MapEventType["mouseup"] & EventData) => {
					//Reset the cursor
					canvas.style.cursor = "";

					//Stop tracking move events
					map.off("mousemove", onMove);

					//Update the location
					updateLocation([event.lngLat.lng, event.lngLat.lat]);
				};
				const onDown = (
					event: MapEventType["mousedown"] & EventData,
				) => {
					//Prevent the default map drag behavior
					event.preventDefault();

					canvas.style.cursor = "grab";

					map.on("mousemove", onMove);
					map.once("mouseup", onUp);
				};
				map.on("mousedown", id, onDown);

				cleanupCallbacks.push(() => {
					map.off("mousemove", onMove);
					map.off("mousedown", id, onDown);
				});
			}

			return () => {
				for (const callback of cleanupCallbacks) {
					callback();
				}
			};
		},
		[
			params.state.center,
			params.state.rotation,
			setStateScale,
			edgePositionsDistance,
		],
	);

	const imageDataURL = usePlaceableFloorImageURL(params.image?.renderable);

	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			//Update the floor plan image
			const floorplanImageSource = map.getSource(
				mapboxIDFloorPlanImage,
			) as ImageSource;

			if (imageDataURL !== undefined) {
				floorplanImageSource.updateImage({
					url: imageDataURL,
					coordinates: resizeHandlePositions,
				});
			} else {
				floorplanImageSource.updateImage(imageSourceBlank);
			}
		},
		[imageDataURL, resizeHandlePositions],
	);
}

function createMarkerElementLarge(iconHTML: string) {
	const markerElement = document.createElement("div");
	markerElement.className = styles.markerLarge;
	markerElement.innerHTML = iconHTML;
	markerElement.style.display = "none";

	return markerElement;
}

function createMarkerElementSmall() {
	const markerElement = document.createElement("div");
	markerElement.className = styles.markerSmall;
	markerElement.style.display = "none";

	return markerElement;
}
