import { MeasurementSystem } from "velavu-js-api";

//Converts a speed from a speed unit to its measurement system
export function speedUnitToMeasurementSystem(
	speed: "mph" | "km/h",
): MeasurementSystem {
	if (speed === "mph") {
		return MeasurementSystem.Imperial;
	} else {
		return MeasurementSystem.Metric;
	}
}

//Guesses what measurement system to use depending on the user's locale
export function getDefaultMeasurementSystem(): MeasurementSystem {
	//Determining the user's region
	let userRegion: string | undefined = undefined;
	if (navigator.languages) {
		//Checking the first language with a region
		for (const lang of navigator.languages) {
			const locale = new Intl.Locale(lang);
			if (locale.region !== undefined) {
				userRegion = locale.region;
				break;
			}
		}
	} else {
		userRegion = new Intl.Locale(navigator.language).region;
	}

	return userRegion === "US"
		? MeasurementSystem.Imperial
		: MeasurementSystem.Metric;
}

/**
 * Converts a speed from m/s into the specified unit
 * @param speed The speed in meters per second
 * @param unit The measurement system to use
 */
export function convertSpeed(speed: number, unit: MeasurementSystem): number {
	if (unit === MeasurementSystem.Imperial) {
		return speed * 2.237;
	} else {
		return speed * 3.6;
	}
}

/**
 * Converts a speed from m/s into the user's preferred unit
 * @param speed The speed in meters per second
 * @param unit The measurement system to use
 */
export function formatSpeed(speed: number, unit: MeasurementSystem): string {
	if (unit === MeasurementSystem.Imperial) {
		return Math.round(speed * 2.237) + " mph";
	} else {
		return Math.round(speed * 3.6) + " km/h";
	}
}

/**
 * Converts a speed from meters into the user's preferred unit
 * @param distance The speed in meter
 * @param unit The measurement system to use
 */
export function formatDistance(
	distance: number,
	unit: MeasurementSystem,
): string {
	if (unit === MeasurementSystem.Imperial) {
		return (distance / 1609).toFixed(1) + " mi";
	} else {
		return (distance / 1000).toFixed(1) + " km";
	}
}

/**
 * Converts a speed from meters into the specified unit
 * @param distance The speed in meter
 * @param unit The measurement system to use
 */
export function convertDistance(
	distance: number,
	unit: MeasurementSystem,
): number {
	if (unit === MeasurementSystem.Imperial) {
		return distance / 1609;
	} else {
		return distance / 1000;
	}
}

/**
 * Formats a temperature reading based on specified unit
 * @param distance The speed in meter
 * @param unit The measurement system to use
 */
export function formatTemperature(
	temperature_c: number,
	unit: MeasurementSystem,
): number {
	if (unit === MeasurementSystem.Imperial) {
		return (temperature_c * 9) / 5 + 32;
	} else {
		return temperature_c;
	}
}

/**
 * Converts a speed from a human-readable speed unit to meters per second
 * @param speed The speed value
 * @param unit The speed unit
 */
export function normalizeSpeed(speed: number, unit: MeasurementSystem) {
	if (unit === MeasurementSystem.Imperial) {
		return speed / 2.237;
	} else if (unit === MeasurementSystem.Metric) {
		return speed / 3.6;
	} else {
		throw new Error("Unknown speed unit " + unit);
	}
}
