type Point = { x: number; y: number };
type LineStandard = { a: number; b: number; c: number };

export function degToRad(val: number): number {
	return (val * Math.PI) / 180;
}

export function radToDeg(val: number): number {
	return (val * 180) / Math.PI;
}

export function calculateBounds(values: { x: number; y: number }[]) {
	const pointsX = values.map((it) => it.x);
	const pointsY = values.map((it) => it.y);

	return {
		minX: Math.min(...pointsX),
		minY: Math.min(...pointsY),
		maxX: Math.max(...pointsX),
		maxY: Math.max(...pointsY),
	};
}

export function doBoundsIntersect(
	bounds1: [number, number, number, number],
	bounds2: [number, number, number, number],
) {
	const [minX1, minY1, maxX1, maxY1] = bounds1;
	const [minX2, minY2, maxX2, maxY2] = bounds2;

	return minX1 < maxX2 && maxX1 > minX2 && minY1 < maxY2 && maxY1 > minY2;
}

/**
 * Takes a point and a slope, and converts it to standard form
 */
export function pointAngleLineToStandard(
	point: Point,
	slope: number,
): LineStandard {
	const a = slope;
	const b = -1 / Math.abs(slope);
	const c = a * point.x + b * point.y;

	return { a: a, b: b, c: c };
}

/**
 * Calculates the intersection point between 2 lines
 */
export function findLineIntersection(
	line1: LineStandard,
	line2: LineStandard,
): Point | undefined {
	const delta = line1.a * line2.b - line2.a * line1.b;

	if (delta === 0) {
		//Lines are parallel
		return undefined;
	} else {
		return {
			x: (line2.b * line1.c - line1.b * line2.c) / delta,
			y: (line1.a * line2.c - line2.a * line1.c) / delta,
		};
	}
}

export function lerp(progress: number, start: number, end: number) {
	return start + (end - start) * progress;
}

export function inverseLerp(progress: number, start: number, end: number) {
	return (progress - start) / (end - start);
}

export function clamp(value: number, min: number, max: number) {
	return Math.min(Math.max(value, min), max);
}

export function lerpClamp(progress: number, start: number, end: number) {
	const lerpVal = lerp(progress, start, end);
	if (start < end) return clamp(lerpVal, start, end);
	else return clamp(lerpVal, end, start);
}

//https://gist.github.com/itsmrpeck/be41d72e9d4c72d2236de687f6f53974
export function lerpDegrees(progress: number, start: number, end: number) {
	//Normalize angles
	if (start < 0) start -= Math.ceil(start / 360) * 360;
	if (end < 0) end -= Math.ceil(end / 360) * 360;

	let result: number;
	const diff = end - start;
	if (diff < -180) {
		// lerp upwards past 360
		end += 360;
		result = lerp(progress, start, end);
		if (result >= 360) result -= 360;
	} else if (diff > 180) {
		// lerp downwards past 0
		end -= 360;
		result = lerp(progress, start, end);
		if (result < 0) result += 360;
	} else {
		// straight lerp
		result = lerp(progress, start, end);
	}

	return result;
}
