import React from "react";
import { getStatusColors, StatusType } from "../helper/status-helper";
import { classArr } from "../helper/style-helper";
import Divider from "./divider";
import styles from "./velavu-tab-switcher.module.scss";

interface VelavuTabSwitcherProps<Key extends string | number> {
	selectedKey: Key;
	onSelectKey: (key: Key) => void;
	labels: { [Entry in Key]: string | false | 0 | "" | null | undefined };
	indicators?: Partial<{ [Entry in Key]: StatusType | undefined }>;
	children?: React.ReactNode;
	className?: string;
}

/**
 * VelavuTabSwitcher is a horizontal variable-length tab bar that allows
 * the user to switch between multiple child views.
 *
 * VelavuTabSwitcher uses tab keys to separate tab ordering and
 * visibility from business logic.
 *
 * If a tab label is falsy, its tab will not be displayed.
 *
 * Tabs may optionally have a status indicator displayed
 * next to them, to call the user's attention.
 *
 * The children should be dynamically updated to reflect
 * the selected tab.
 *
 * @param props.selectedKey The active tab key
 * @param props.onSelectKey A callback invoked when a tab is clicked
 * @param props.labels A map of tab keys to human-readable labels.
 * @param props.indicators A map of indicators to be shown on the selected tab
 * @param props.children The child element to display beneath this tab switcher.
 */
export default function VelavuTabSwitcher<Key extends string | number>(
	props: VelavuTabSwitcherProps<Key>,
) {
	return (
		<>
			<div className={classArr(styles.container, props.className)}>
				{Object.entries(props.labels).map(([stringKey, label]) => {
					//Skip falsy values
					if (!label) return null;

					//Try to parse the key back to its original form
					const keyNumber = Number(stringKey);
					let key: Key;
					if (isNaN(keyNumber)) {
						key = stringKey as Key;
					} else {
						key = Number(stringKey) as Key;
					}

					const isSelected = key === props.selectedKey;
					const indicatorStatus = props.indicators?.[key];

					return (
						<div
							key={key}
							className={`${styles.option} ${
								isSelected
									? styles.optionSelected
									: styles.optionDeselected
							}`}
							onClick={() => props.onSelectKey(key)}
						>
							<span className={styles.label}>
								{label}
								{indicatorStatus !== undefined && (
									<span
										className={styles.indicatorDot}
										style={{
											backgroundColor:
												getStatusColors(indicatorStatus)
													.foreground,
										}}
									/>
								)}
							</span>
							<div
								className={classArr(
									styles.selectionIndicator,
									styles.hoverIndicator,
									isSelected && styles.hoverIndicatorActive,
								)}
							/>
						</div>
					);
				})}
			</div>

			<Divider />

			{props.children}
		</>
	);
}
