import React, { CSSProperties } from "react";
import styles from "./divider.module.scss";

export type DividerOrientation = "horizontal" | "vertical";

interface DividerProps {
	orientation?: DividerOrientation;

	edgePadding?: boolean; //Same-axis padding (on either edge of the divider)
	crossPadding?: boolean; //Cross-axis padding (between the divider and other elements)

	color?: string; //Color override
	style?: CSSProperties;
	className?: string;
}

export default function Divider(props: DividerProps) {
	const orientation: DividerOrientation = props.orientation ?? "horizontal";

	const classes = [styles.divider];
	if (props.edgePadding) classes.push(styles.edgePadding);
	if (props.crossPadding) classes.push(styles.crossPadding);
	if (props.className) classes.push(props.className);

	if (orientation === "horizontal") {
		classes.push(styles.horizontal);
	} else {
		classes.push(styles.vertical);
	}

	return (
		<div
			className={classes.join(" ")}
			style={{
				...props.style,
				backgroundColor: props.color,
			}}
		/>
	);
}
