export function isObject(item: unknown): item is Record<string, unknown> {
	return item !== null && typeof item === "object" && !Array.isArray(item);
}

export default function deepMerge<T extends Record<string, any>>(
	target: T,
	source: Partial<T> | undefined,
): T;
export default function deepMerge<
	T extends Record<string, any>,
	U extends Record<string, any> = T,
>(target: T, source: U): T & U;
export default function deepMerge(
	target: Record<string, unknown>,
	source: Record<string, unknown> | undefined,
): Record<string, unknown> {
	if (source === undefined) return target;

	const output = { ...target };

	for (const key of Object.keys(source)) {
		//Check if our output object already has this key
		const outputValue = output[key];
		const sourceValue = source[key];
		if (isObject(outputValue) && isObject(sourceValue)) {
			//Merge the objects and assign
			output[key] = deepMerge(outputValue, sourceValue);
		} else {
			//Assign the key directly
			output[key] = sourceValue;
		}
	}

	return output;
}

export interface DeepCompareOptions {
	//Whether to ignore default values, such as undefined, null, empty objects, and empty arrays
	collapseDefaultValues?: boolean;
	//An array of keys to explicitly ignore
	ignoreKeys?: string[];
}

/**
 * Gets if the value is undefined, null, or an empty object (with empty children) or array
 */
function isDefaultValue(value: unknown): boolean {
	//undefined and null are default values
	if (value === undefined || value === null) {
		return true;
	}

	//Empty arrays are default values
	if (Array.isArray(value)) {
		return value.length === 0;
	}

	//Objects are default values if they are empty,
	//or all their children are default values
	if (isObject(value)) {
		return Object.keys(value).every((key) => isDefaultValue(value[key]));
	}

	return false;
}

/**
 * Compares if 2 objects are equal
 * @param object1 The first object to compare
 * @param object2 The second object to compare
 * @param options Comparison options
 */
export function deepCompare(
	object1: unknown,
	object2: unknown,
	options: DeepCompareOptions = {},
): boolean {
	const collapseDefaultValues = options.collapseDefaultValues ?? true;
	const ignoreKeys = options.ignoreKeys ?? [];

	if (!isObject(object1) || !isObject(object2)) {
		return object1 === object2;
	}

	function keyFilter(object: Record<string, unknown>, key: string): boolean {
		if (ignoreKeys.includes(key)) return false;

		if (collapseDefaultValues) {
			const value = object[key];

			//Filter out undefined, null, and empty objects
			if (isDefaultValue(value)) {
				return false;
			}
		}

		return true;
	}

	const keys1 = Object.keys(object1).filter((key) => keyFilter(object1, key));
	const keys2 = Object.keys(object2).filter((key) => keyFilter(object2, key));

	if (keys1.length !== keys2.length) {
		return false;
	}

	for (const key of keys1) {
		const value1 = object1[key];
		const value2 = object2[key];

		if (isObject(value1) && isObject(value2)) {
			if (!deepCompare(value1, value2)) {
				return false;
			}
		} else if (Array.isArray(value1) && Array.isArray(value2)) {
			const length1 = value1.length;
			const length2 = value2.length;

			if (length1 !== length2) {
				return false;
			}

			for (let i = 0; i < length1; i++) {
				const entry1 = value1[i];
				const entry2 = value2[i];

				if (!deepCompare(entry1, entry2)) {
					return false;
				}
			}
		} else {
			if (value1 !== value2) {
				return false;
			}
		}
	}

	return true;
}

/**
 * Returns if an array consists of entirely unique values.
 * Array values must be hashable.
 */
export function arrayIsUnique(array: unknown[]): boolean {
	const set = new Set(array);
	return array.length === set.size;
}
