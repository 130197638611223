import React, { CSSProperties } from "react";

export default function IconSiteExited(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 18}
			height={props.size ?? 18}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.35575 3.03424C8.58985 2.32192 7.41015 2.32192 6.64425 3.03424L2.64425 6.75441C2.23432 7.13566 2 7.67308 2 8.23748V13.4827C2 14.6025 2.90108 15.5 4 15.5H12C12.5523 15.5 13 15.9477 13 16.5C13 17.0523 12.5523 17.5 12 17.5H4C1.78603 17.5 0 15.6965 0 13.4827V8.23748C0 7.11944 0.464002 6.05085 1.28219 5.2899L5.28219 1.56973C6.81106 0.147813 9.16966 0.143437 10.7036 1.55661L12.6542 3.24366C13.0719 3.60495 13.1176 4.23646 12.7563 4.65417C12.3951 5.07189 11.7635 5.11763 11.3458 4.75634L9.3826 3.05832C9.37351 3.05046 9.36456 3.04243 9.35575 3.03424Z"
				fill={props.color ?? "#00255D"}
			/>
			<path
				d="M6 9C5.44771 9 5 9.44771 5 10C5 10.5523 5.44772 11 6 11H13.6008V14L17.6247 10.7809C18.1251 10.3805 18.1251 9.61945 17.6247 9.21913L13.6008 6V9H6Z"
				fill={props.color ?? "#00255D"}
			/>
		</svg>
	);
}
