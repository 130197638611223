import React, { CSSProperties } from "react";

export default function IconCheckmark(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.71 16.2899C10.6175 16.3826 10.5076 16.4562 10.3866 16.5064C10.2657 16.5566 10.136 16.5824 10.005 16.5824C9.87404 16.5824 9.74435 16.5566 9.62338 16.5064C9.50241 16.4562 9.39252 16.3826 9.3 16.2899L5.71 12.6999C5.61742 12.6074 5.54398 12.4975 5.49388 12.3765C5.44377 12.2555 5.41798 12.1259 5.41798 11.9949C5.41798 11.864 5.44377 11.7344 5.49388 11.6134C5.54398 11.4924 5.61742 11.3825 5.71 11.2899C5.80259 11.1974 5.9125 11.1239 6.03346 11.0738C6.15443 11.0237 6.28407 10.9979 6.415 10.9979C6.54594 10.9979 6.67558 11.0237 6.79655 11.0738C6.91751 11.1239 7.02742 11.1974 7.12 11.2899L10 14.1699L16.88 7.28995C17.067 7.10297 17.3206 6.99792 17.585 6.99792C17.8494 6.99792 18.103 7.10297 18.29 7.28995C18.477 7.47692 18.582 7.73052 18.582 7.99495C18.582 8.25937 18.477 8.51297 18.29 8.69994L10.71 16.2899Z"
				fill={props.color ?? "white"}
			/>
		</svg>
	);
}
