import { subDays } from "date-fns";
import React, { useMemo, useState } from "react";
import { adjustDateEnd } from "../helper/date-helper";

export interface DateRange {
	start: Date;
	end: Date;
}

/**
 * A {@link useState} helper for a date range
 * @param defaultRange The default number of days to span
 */
export function useDateRange(
	defaultRange: number,
): [DateRange, React.Dispatch<React.SetStateAction<DateRange>>] {
	const mountTime = useMemo(() => {
		const date = new Date();
		date.setHours(0, 0, 0, 0);
		return date;
	}, []);

	const [dateRange, setDateRange] = useState<DateRange>({
		start: subDays(mountTime, defaultRange),
		end: adjustDateEnd(mountTime),
	});
	return [dateRange, setDateRange];
}
