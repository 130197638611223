import React, { CSSProperties } from "react";

export default function IconArda(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	const color = props.color ?? "#00255D";
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 7H10C7.23858 7 5 9.23858 5 12C5 14.7614 7.23858 17 10 17H14C16.7614 17 19 14.7614 19 12C19 9.23858 16.7614 7 14 7ZM10 5C6.13401 5 3 8.13401 3 12C3 15.866 6.13401 19 10 19H14C17.866 19 21 15.866 21 12C21 8.13401 17.866 5 14 5H10Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14 11H10C9.44772 11 9 11.4477 9 12C9 12.5523 9.44772 13 10 13H14C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11ZM10 9C8.34315 9 7 10.3431 7 12C7 13.6569 8.34315 15 10 15H14C15.6569 15 17 13.6569 17 12C17 10.3431 15.6569 9 14 9H10Z"
				fill={color}
			/>{" "}
		</svg>
	);
}
