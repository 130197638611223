import React from "react";
import { EventCategory } from "velavu-js-api";
import IconWifiAlt from "../dynamicicons/icon-wifi-alt";
import IconWifiOffAlt from "../dynamicicons/icon-wifi-off-alt";
import IconViewGridPlus from "../dynamicicons/icon-view-grid-plus";
import IconBatteryWarn from "../dynamicicons/icon-battery-warn";
import IconCar from "../dynamicicons/icon-car";
import IconShock from "../dynamicicons/icon-shock";
import IconVelavuAlerts from "../dynamicicons/icon-velavu-alerts";
import IconFound from "../dynamicicons/icon-found";
import IconMissing from "../dynamicicons/icon-missing";
import IconSiteEntered from "../dynamicicons/icon-site-entered";
import IconSiteExited from "../dynamicicons/icon-site-exited";
import IconRequestedAssistance from "../dynamicicons/icon-requested-assistance";
import IconGeofenceEntered from "../dynamicicons/icon-geofence-entered";
import IconGeofenceExited from "../dynamicicons/icon-geofence-exited";

/**
 * An icon that represents an event category
 */
export default function AlertIcon(props: {
	type: EventCategory;
	size?: number;
	color?: string;
}) {
	switch (props.type) {
		case EventCategory.DeviceConnect:
			return <IconWifiAlt size={props.size} color={props.color} />;
		case EventCategory.DeviceDisconnect:
			return <IconWifiOffAlt size={props.size} color={props.color} />;
		case EventCategory.DeviceProvision:
			return <IconViewGridPlus size={props.size} color={props.color} />;
		case EventCategory.LowBattery:
			return <IconBatteryWarn size={props.size} color={props.color} />;
		case EventCategory.DrivingRoute:
			return <IconCar size={props.size} color={props.color} />;
		case EventCategory.Shock:
			return <IconShock size={props.size} color={props.color} />;
		case EventCategory.InventoryAdded:
			return <IconFound size={props.size} color={props.color} />;
		case EventCategory.InventoryRemoved:
			return <IconMissing size={props.size} color={props.color} />;
		case EventCategory.SiteEntered:
			return <IconSiteEntered size={props.size} color={props.color} />;
		case EventCategory.SiteExited:
			return <IconSiteExited size={props.size} color={props.color} />;
		case EventCategory.GeofenceEntered:
			return (
				<IconGeofenceEntered size={props.size} color={props.color} />
			);
		case EventCategory.GeofenceExited:
			return <IconGeofenceExited size={props.size} color={props.color} />;
		case EventCategory.WearableAlert:
			return (
				<IconRequestedAssistance
					size={props.size}
					color={props.color}
				/>
			);
		default:
			return <IconVelavuAlerts size={props.size} color={props.color} />;
	}
}
