import React, { CSSProperties, useState } from "react";
import IconArrowDown from "../dynamicicons/icon-arrow-down";
import IconArrowRight from "../dynamicicons/icon-arrow-right";
import { StatusType } from "../helper/status-helper";
import styles from "./velavu-expand-menu.module.scss";
import VelavuIndicator from "./velavu-indicator";

export default function VelavuExpandMenu(props: {
	label: string;
	className?: string;
	style?: CSSProperties;
	children?: React.ReactNode;

	indicator?: StatusType;
}) {
	const [isExpanded, setExpanded] = useState(false);

	return (
		<div
			className={
				styles.container +
				(props.className ? " " + props.className : "")
			}
			style={props.style}
		>
			<div
				className={`${styles.titleRow} ${
					isExpanded && props.children ? styles.openMargin : ""
				}`}
				onClick={() => setExpanded(!isExpanded)}
			>
				{isExpanded ? (
					<IconArrowDown color="#5F718C" />
				) : (
					<IconArrowRight color="#5F718C" />
				)}

				<span className={styles.label}>{props.label}</span>

				{props.indicator !== undefined && (
					<VelavuIndicator type={props.indicator} />
				)}
			</div>

			{isExpanded && props.children}
		</div>
	);
}
