import React, { CSSProperties } from "react";

export default function IconVelavuSite(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.3557 6.03424C12.5899 5.32192 11.4101 5.32192 10.6442 6.03424L6.64425 9.75441C6.23431 10.1357 6 10.6731 6 11.2375V16.4827C6 17.6023 6.90085 18.5 8 18.5H16C17.0992 18.5 18 17.6023 18 16.4827V11.2375C18 10.6731 17.7657 10.1357 17.3557 9.75441L13.3557 6.03424ZM9.28219 4.56973C10.8158 3.14343 13.1842 3.14342 14.7178 4.56972L18.7178 8.28989C19.536 9.05086 20 10.1195 20 11.2375V16.4827C20 18.696 18.2146 20.5 16 20.5H8C5.78544 20.5 4 18.696 4 16.4827V11.2375C4 10.1195 4.46399 9.05086 5.28219 8.2899L9.28219 4.56973Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
