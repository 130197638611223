import React from "react";
import { HistoricalVelavuInvoice } from "velavu-js-api";
import VelavuSelect from "../../../elements/velavu-select";
import {
	formatBillingPeriod,
	mapInvoiceStatusText,
} from "./settings-billing-format";

export interface InvoiceSelectProps {
	invoices: HistoricalVelavuInvoice[];
	value: string;
	onChange: (invoiceID: string) => void;
}

export default function InvoiceSelect(props: InvoiceSelectProps) {
	return (
		<VelavuSelect
			titleText="Period"
			value={props.value}
			onChange={props.onChange}
			fullWidth
		>
			{props.invoices.map((invoice) => (
				<option key={invoice.id} value={invoice.id}>
					{/* Option doesn't support HTML ;( */}
					{/*<span className={styles.indicator} style={{backgroundColor: mapInvoiceStatusColors(invoice.status)[0]}} />*/}
					{/*<span>{formatBillingPeriod(invoice.period_start, invoice.period_end)}</span>*/}

					{`${formatBillingPeriod(
						invoice.period_start,
						invoice.period_end,
					)} (${mapInvoiceStatusText(invoice.status)})`}
				</option>
			))}
		</VelavuSelect>
	);
}
