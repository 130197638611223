import React, { CSSProperties } from "react";

export default function IconVelavuMapOutline(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M21 4.23678C21 3.53408 20.2943 3.05054 19.639 3.30422L15.3436 4.96703C15.1218 5.05288 14.8767 5.05689 14.6522 4.97833L9.32247 3.1129C9.11343 3.03974 8.88601 3.03804 8.6759 3.10808L3.68378 4.77209C3.27544 4.9082 3.00001 5.29034 3.00001 5.72077L3 19.7603C3 20.4627 3.70524 20.9463 4.36048 20.693L9.00001 18.9L14.6775 20.8872C14.8866 20.9603 15.114 20.962 15.3241 20.892L20.3162 19.2279C20.7246 19.0918 21 18.7097 21 18.2793L21 4.23678ZM10 5.47004L14 6.87004V18.53L10 17.13V5.47004ZM5.00001 6.46004L8.00001 5.45004V17.15L5.00001 18.31V6.46004ZM19 17.54L16 18.55V6.86004L19 5.70004V17.54Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
