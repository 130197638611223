import React from "react";
import OnboardingFrame from "./onboarding-frame";
import { OnboardingFrameProps } from "./onboarding-shared-data";

export default function OnboardingStepWelcome(
	props: Omit<OnboardingFrameProps, "navigateBackwards">,
) {
	return (
		<OnboardingFrame
			title="Welcome!"
			description="The next few steps will help you set up your dashboard."
			buttonPrimary={{ label: "Next", onClick: props.navigateForwards }}
		/>
	);
}
