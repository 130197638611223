// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.O1Fvk7fk7pIxeQD7V0bK{flex-grow:1;height:100%;position:relative;overflow:hidden}`, "",{"version":3,"sources":["webpack://./src/components/page/page.module.scss"],"names":[],"mappings":"AAMA,sBACC,WAAA,CACA,WAAA,CACA,iBAAA,CACA,eAAA","sourcesContent":["/*------------------------------*\n | @author  Cameron Slupeiks    |\n | @date    2020.07.15          |\n | Copyright Brash Inc. (2020)  |\n *------------------------------*/\n\n.container {\n\tflex-grow: 1;\n\theight: 100%;\n\tposition: relative;\n\toverflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `O1Fvk7fk7pIxeQD7V0bK`
};
export default ___CSS_LOADER_EXPORT___;
