import React, { CSSProperties } from "react";
import styles from "./geofence-list-item.module.scss";
import { VelavuGeofence } from "velavu-js-api";
import SelectableCard from "../../../../elements/selectable-card";
import { classArr } from "../../../../helper/style-helper";
import { useGeocoder } from "../../../../helper/api-helper";

export interface GeofenceListItemProps {
	style?: CSSProperties;
	className?: string;

	geofence: VelavuGeofence;
	onClick?: () => void;
}

export default function GeofenceListItem(props: GeofenceListItemProps) {
	const location = useGeocoder(props.geofence.center);

	return (
		<SelectableCard
			style={props.style}
			className={classArr(styles.container, props.className)}
			onClick={props.onClick}
		>
			<span className={styles.title}>{props.geofence.name}</span>
			<span className={styles.subtitle}>{location}</span>
		</SelectableCard>
	);
}
