import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { useResizeDetector } from "react-resize-detector";
import {
	LineSegment,
	VictoryAxis,
	VictoryChart,
	VictoryCursorContainer,
	VictoryGroup,
	VictoryLine,
	VictoryScatter,
} from "victory";
import { VictoryStyleObject } from "victory-core";
import AnalyticsStatType from "../../data/analytics-stat-type";
import {
	EventCategory,
	MeasurementSystem,
	VelavuAsset,
	VelavuDevice,
	VelavuEvent,
} from "velavu-js-api";
import { DateRange, useDateRange } from "../../data/date-range";
import IconCalendar from "../../dynamicicons/icon-calendar";
import DateRangeSelector from "../../elements/date-range-selector";
import FlatButton from "../../elements/flat-button";
import LoadingDots from "../../elements/loading-dots";
import VelavuSwitch from "../../elements/velavu-switch";
import { makeStyleable } from "../../helper/icon-helper";
import { formatDateRange } from "../../helper/language-helper";
import { inverseLerp, lerp } from "../../helper/math-helper";
import { formatTemperature } from "../../helper/unit-helper";
import globals from "../../styles/global.scss";
import AnalyticsCardHeader from "./analytics-card-header";
import styles from "./analytics-graph.module.scss";

const svgPadding = 12;
const svgTextHeight = 24;

enum Tabs {
	assets,
	anchors,
}

const graphPalette: string[] = ["#3A58E2", "#6681FF", "#B0BCF3", "#A3D9FF"];

interface PointData {
	x: Date;
	y: number;
}

interface GraphData {
	id: string;
	name: string;
	data: PointData[];
}

function mapStatName(stat: AnalyticsStatType): string | undefined {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			return "Temperature history";
		case AnalyticsStatType.Humidity:
			return "Humidity history";
		case AnalyticsStatType.Battery:
			return "Battery history";
		case AnalyticsStatType.Location:
			return "Location history";
	}
}

const makeStyles = <T extends { [key: string]: VictoryStyleObject }>(t: T) => t;
const victoryStyles = makeStyles({
	parent: {
		fontFamily: "Poppins, sans-serif",
	},
	mainAxis: {
		stroke: "#F5F8F9",
	},
	dependentAxis: {
		stroke: "none",
	},
	mainTickLabels: {
		fontFamily: "inherit",
		fill: globals.textTertiary,
	},
	dependentTickLabels: {
		fontFamily: "inherit",
		fill: globals.textSecondary,
	},
	grid: {
		fill: "none",
		stroke: "#F5F8F9",
	},
	ticks: {
		fill: "transparent",
		size: 12,
		stroke: "#F5F8F9",
		strokeWidth: 1,
	},
	line: {
		strokeWidth: 2,
	},
	flyout: {
		fill: "#00255D",
	},
	flyoutLabel: {
		fill: "#D5DCE5",
		fontFamily: "inherit",
	},
});

/*
 * All props are optional because Victory has a bad TypeScript API
 */
function CustomLabel(props: {
	x?: number;
	y?: number;
	datum?: {
		x: Date;
		y: number;
	};
	active?: boolean;
	text?: string;
	data: GraphData[];
	unitSuffix: string;
}) {
	type LabelData = { id: string; displayName: string; value: number };

	//Interpolate the data between the points
	const data = useMemo(():
		| { hoverDate: Date; labels: LabelData[] }
		| undefined => {
		if (props.data === undefined || props.datum === undefined)
			return undefined;

		//Get the date at the hovered point
		const hoverDate = props.datum.x;

		return {
			hoverDate,
			labels: props.data
				.map((graphEntry): LabelData | undefined => {
					//Find the first entry with a date greater than the hover date
					const sortedData = [...graphEntry.data];
					sortedData.sort(
						(d1, d2) => d1.x.getTime() - d2.x.getTime(),
					);
					const upperIndex = sortedData.findIndex(
						(graphPoint) => graphPoint.x > hoverDate,
					);

					//Ignoring if no entry was found, or the entry was the first entry (nothing to average)
					if (upperIndex === -1 || upperIndex === 0) return undefined;

					const lowerEntry = sortedData[upperIndex - 1];
					const upperEntry = sortedData[upperIndex];

					//Average between this entry and the previous one
					return {
						id: graphEntry.id,
						displayName: graphEntry.name,
						value: lerp(
							inverseLerp(
								hoverDate.getTime(),
								lowerEntry.x.getTime(),
								upperEntry.x.getTime(),
							),
							lowerEntry.y,
							upperEntry.y,
						),
					};
				})
				//Exclude non-applicable entries
				.filter((it) => it !== undefined) as LabelData[],
		};
	}, [props.data, props.datum]);

	if (data === undefined) return null;

	return (
		<g>
			<rect
				x={props.x!}
				y={props.y!}
				width={Math.max(
					115,
					...data.labels.map(
						(label) =>
							(label.displayName.length +
								(Math.round(label.value * 10) / 10)
									.toFixed(1)
									.toString().length +
								props.unitSuffix.length +
								4) *
							8,
					),
				)}
				height={svgTextHeight * (3 + data.labels.length) - svgPadding}
				rx="8"
				fill="#00255D"
			/>
			<text
				x={props.x! + svgPadding}
				y={props.y! + svgTextHeight}
				fontSize="14"
				fontWeight="500"
				fontFamily="inherit"
				fill="#FFFFFF"
			>
				{DateTime.fromJSDate(data.hoverDate).toLocaleString(
					DateTime.DATE_MED,
				)}
			</text>
			<text
				x={props.x! + svgPadding}
				y={props.y! + svgTextHeight * 2}
				fontSize="14"
				fontWeight="500"
				fontFamily="inherit"
				fill="#FFFFFF"
			>
				{DateTime.fromJSDate(data.hoverDate).toLocaleString(
					DateTime.TIME_SIMPLE,
				)}
			</text>
			{data.labels.map((label, i) => (
				<text
					key={label.id}
					x={props.x! + svgPadding}
					y={props.y! + svgTextHeight * (3 + i)}
					fontSize="14"
					fontFamily="inherit"
					fill="#D5DCE5"
				>
					{`${label.displayName}: ${(
						Math.round(label.value * 10) / 10
					).toFixed(1)}${props.unitSuffix}`}
				</text>
			))}
		</g>
	);
}

function Graph(props: {
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	unitRange: { min: number; max: number };
	data: GraphData[];
	unitSuffix: string;
	title: string;
}) {
	const [showPoints, setShowPoints] = useState(false);
	const { width, height, ref } = useResizeDetector();
	return (
		<>
			<AnalyticsCardHeader title={props.title}>
				<VelavuSwitch
					label="Show points"
					toggled={showPoints}
					onChange={setShowPoints}
				/>
				<div className={styles.rangePickerWrapper}>
					<FlatButton
						className={styles.rangePickerButton}
						icon={makeStyleable(IconCalendar)}
						label={formatDateRange(props.dateRange)}
					/>

					<div className={styles.rangePickerPopover}>
						<DateRangeSelector
							range={props.dateRange}
							onUpdate={props.setDateRange}
						/>
					</div>
				</div>
			</AnalyticsCardHeader>
			<div ref={ref} className={styles.graph}>
				<VictoryChart
					width={width}
					height={height}
					style={{ parent: victoryStyles.parent }}
					scale={{ x: "time", y: "linear" }}
					domain={{
						x: [props.dateRange.start, props.dateRange.end],
						y: [props.unitRange.min, props.unitRange.max],
					}}
					containerComponent={
						<VictoryCursorContainer
							cursorDimension="x"
							cursorComponent={
								<LineSegment style={{ stroke: "#00255D" }} />
							}
							cursorLabel={() => ""}
							cursorLabelComponent={
								<CustomLabel
									data={props.data}
									unitSuffix={props.unitSuffix}
								/>
							}
						/>
					}
				>
					<VictoryAxis
						style={{
							axis: victoryStyles.mainAxis,
							ticks: victoryStyles.ticks,
							tickLabels: victoryStyles.mainTickLabels,
						}}
						standalone={false}
					/>
					<VictoryAxis
						style={{
							axis: victoryStyles.dependentAxis,
							tickLabels: victoryStyles.dependentTickLabels,
							grid: victoryStyles.grid,
						}}
						dependentAxis
						standalone={false}
						tickFormat={(x) =>
							Math.round(x * 10) / 10 + props.unitSuffix
						}
						crossAxis={false}
					/>
					<VictoryGroup colorScale={graphPalette}>
						{props.data.map((data) => (
							<VictoryLine
								key={data.id}
								data={data.data}
								interpolation="monotoneX"
								style={{ data: victoryStyles.line }}
							/>
						))}
					</VictoryGroup>
					<VictoryGroup colorScale={graphPalette}>
						{showPoints &&
							props.data.map((data) => (
								<VictoryScatter
									key={data.id}
									style={{ data: { fill: globals.accent } }}
									size={3}
									data={data.data}
								/>
							))}
					</VictoryGroup>
				</VictoryChart>
			</div>
		</>
	);
}

export function LoadingGraph(props: { stat: AnalyticsStatType }) {
	const [dateRange, setDateRange] = useDateRange(7);

	return (
		<>
			<AnalyticsCardHeader title={mapStatName(props.stat)}>
				<div className={styles.rangePickerWrapper}>
					<FlatButton
						className={styles.rangePickerButton}
						icon={makeStyleable(IconCalendar)}
						label={formatDateRange(dateRange)}
					/>

					<div className={styles.rangePickerPopover}>
						<DateRangeSelector
							range={dateRange}
							onUpdate={setDateRange}
						/>
					</div>
				</div>
			</AnalyticsCardHeader>

			<div className={styles.empty}>
				<LoadingDots size={40} color={"#a3b3cc"} />
			</div>
		</>
	);
}

export function AssetsTemps(props: {
	entries: VelavuAsset[];
	ids: string[];
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	measurementSystem: MeasurementSystem;
	env: { [key: string]: VelavuEvent<EventCategory.Environment>[] };
}) {
	const graphData = useMemo(() => {
		return Object.entries(props.env)
			.map(([id, history]): GraphData => {
				return {
					id: id,
					name: props.entries.find((e) => e.id === id)?.name ?? id,
					data: history.map((env: any) => ({
						x: new Date(env.timestamp),
						y: formatTemperature(
							env.data.temperature_c,
							props.measurementSystem,
						),
					})),
				};
			})
			.filter(({ data }) => data.length > 0);
	}, [props.entries, props.env, props.measurementSystem]);

	const unitRange = useMemo(() => {
		const temps: number[] = [];
		const range = {
			min: formatTemperature(15, props.measurementSystem),
			max: formatTemperature(30, props.measurementSystem),
		};

		Object.entries(props.env).map(([_id, entry]) =>
			entry.forEach(
				(t) =>
					t.data.temperature_c !== undefined &&
					temps.push(t.data.temperature_c),
			),
		);

		if (temps.length) {
			range.min = formatTemperature(
				Math.round(Math.min(...temps)) - 2,
				props.measurementSystem,
			);
			range.max = formatTemperature(
				Math.round(Math.max(...temps)) + 2,
				props.measurementSystem,
			);
		}

		return range;
	}, [props.env, props.measurementSystem]);

	return (
		<Graph
			dateRange={props.dateRange}
			setDateRange={props.setDateRange}
			unitRange={unitRange}
			data={graphData}
			unitSuffix={"°"}
			title={"Temperature history"}
		/>
	);
}

export function AnchorsTemps(props: {
	entries: VelavuDevice[];
	ids: string[];
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	measurementSystem: MeasurementSystem;
	env: { [key: string]: VelavuEvent<EventCategory.Environment>[] };
}) {
	const graphData = useMemo(() => {
		return Object.entries(props.env)
			.map(([id, history]): GraphData => {
				return {
					id: id,
					name:
						props.entries.find((e) => e.id === id)?.asset?.name ??
						id,
					data: history.map((env: any) => ({
						x: new Date(env.timestamp),
						y: formatTemperature(
							env.data.temperature_c,
							props.measurementSystem,
						),
					})),
				};
			})
			.filter(({ data }) => data.length > 0);
	}, [props.entries, props.env, props.measurementSystem]);

	const unitRange = useMemo(() => {
		const temps: number[] = [];
		const range = {
			min: formatTemperature(15, props.measurementSystem),
			max: formatTemperature(30, props.measurementSystem),
		};

		Object.entries(props.env).map(([_id, entry]) =>
			entry.forEach(
				(t) =>
					t.data.temperature_c !== undefined &&
					temps.push(t.data.temperature_c),
			),
		);

		if (temps.length) {
			range.min = formatTemperature(
				Math.round(Math.min(...temps)) - 2,
				props.measurementSystem,
			);
			range.max = formatTemperature(
				Math.round(Math.max(...temps)) + 2,
				props.measurementSystem,
			);
		}

		return range;
	}, [props.env, props.measurementSystem]);

	return (
		<Graph
			dateRange={props.dateRange}
			setDateRange={props.setDateRange}
			unitRange={unitRange}
			data={graphData}
			unitSuffix={"°"}
			title={"Temperature history"}
		/>
	);
}

export function AssetsHums(props: {
	entries: VelavuAsset[];
	ids: string[];
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	env: { [key: string]: VelavuEvent<EventCategory.Environment>[] };
}) {
	const graphData = useMemo(() => {
		return Object.entries(props.env)
			.filter((e) => e[1].length && e[1][0].data.humidity)
			.map(([id, history]): GraphData => {
				return {
					id: id,
					name: props.entries.find((e) => e.id === id)?.name ?? id,
					data: history.map((env: any) => ({
						x: new Date(env.timestamp),
						y: env.data.humidity,
					})),
				};
			})
			.filter(({ data }) => data.length > 0);
	}, [props.entries, props.env]);

	const unitRange = useMemo(() => {
		const values: number[] = [];
		const range = { min: 0, max: 100 };

		Object.entries(props.env)
			.filter((e) => e[1].length && e[1][0].data.humidity)
			.map(([id, entry]) =>
				entry.forEach(
					(t) =>
						t.data.humidity !== undefined &&
						values.push(t.data.humidity),
				),
			);

		if (values.length) {
			range.min = Math.min(...values) - 2;
			range.max = Math.max(...values) + 2;
		}

		return range;
	}, [props.env]);

	return (
		<Graph
			dateRange={props.dateRange}
			setDateRange={props.setDateRange}
			unitRange={unitRange}
			data={graphData}
			unitSuffix={"%"}
			title={"Humidity history"}
		/>
	);
}

export function AnchorsHums(props: {
	entries: VelavuDevice[];
	ids: string[];
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	env: { [key: string]: VelavuEvent<EventCategory.Environment>[] };
}) {
	const graphData = useMemo(() => {
		return Object.entries(props.env)
			.map(([id, history]): GraphData => {
				return {
					id: id,
					name:
						props.entries.find((e) => e.id === id)?.asset?.name ??
						id,
					data: history.map((env: any) => ({
						x: new Date(env.timestamp),
						y: env.data.humidity,
					})),
				};
			})
			.filter(({ data }) => data.length > 0);
	}, [props.entries, props.env]);

	const unitRange = useMemo(() => {
		const values: number[] = [];
		const range = { min: 0, max: 100 };

		Object.entries(props.env).map(([id, entry]) =>
			entry.forEach(
				(t) =>
					t.data.humidity !== undefined &&
					values.push(t.data.humidity),
			),
		);

		if (values.length) {
			range.min = Math.min(...values) - 2;
			range.max = Math.max(...values) + 2;
		}

		return range;
	}, [props.env]);

	return (
		<Graph
			dateRange={props.dateRange}
			setDateRange={props.setDateRange}
			unitRange={unitRange}
			data={graphData}
			unitSuffix={"%"}
			title={"Humidity history"}
		/>
	);
}

export function AssetsBats(props: {
	entries: VelavuAsset[];
	ids: string[];
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	bat: { [key: string]: VelavuEvent<EventCategory.BatteryLevel>[] };
}) {
	const graphData = useMemo(() => {
		return Object.entries(props.bat)
			.map(([id, history]): GraphData => {
				return {
					id: id,
					name: props.entries.find((e) => e.id === id)?.name ?? id,
					data: history.map((bat: any) => ({
						x: new Date(bat.timestamp),
						y: bat.data.battery_level,
					})),
				};
			})
			.filter(({ data }) => data.length > 0);
	}, [props.entries, props.bat]);

	return (
		<Graph
			dateRange={props.dateRange}
			setDateRange={props.setDateRange}
			unitRange={{ min: 0, max: 100 }}
			data={graphData}
			unitSuffix={"%"}
			title={"Battery history"}
		/>
	);
}

export function AnchorsBats(props: {
	entries: VelavuDevice[];
	ids: string[];
	dateRange: DateRange;
	setDateRange: (range: DateRange) => void;
	bat: { [key: string]: VelavuEvent<EventCategory.BatteryLevel>[] };
}) {
	const graphData = useMemo(() => {
		return Object.entries(props.bat)
			.map(([id, history]): GraphData => {
				return {
					id: id,
					name:
						props.entries.find((e) => e.id === id)?.asset?.name ??
						id,
					data: history.map((bat: any) => ({
						x: new Date(bat.timestamp),
						y: bat.data.battery_level,
					})),
				};
			})
			.filter(({ data }) => data.length > 0);
	}, [props.entries, props.bat]);

	return (
		<Graph
			dateRange={props.dateRange}
			setDateRange={props.setDateRange}
			unitRange={{ min: 0, max: 100 }}
			data={graphData}
			unitSuffix={"%"}
			title={"Battery history"}
		/>
	);
}
