import React, { CSSProperties } from "react";

export default function IconVelavuGeofence(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M3 6C3 7.30622 3.83481 8.41746 5 8.82929V15.1707C3.83481 15.5825 3 16.6938 3 18C3 19.6569 4.34315 21 6 21C7.30622 21 8.41746 20.1652 8.82929 19H15.1707C15.5825 20.1652 16.6938 21 18 21C19.6569 21 21 19.6569 21 18C21 16.6938 20.1652 15.5825 19 15.1707V8.82929C20.1652 8.41746 21 7.30622 21 6C21 4.34315 19.6569 3 18 3C16.6938 3 15.5825 3.83481 15.1707 5H8.82929C8.41746 3.83481 7.30622 3 6 3C4.34315 3 3 4.34315 3 6ZM8.82929 17H15.1707C15.472 16.1476 16.1476 15.472 17 15.1707V8.82929C16.1476 8.52801 15.472 7.85241 15.1707 7H8.82929C8.52801 7.85241 7.85241 8.52801 7 8.82929V15.1707C7.85241 15.472 8.52801 16.1476 8.82929 17ZM7 6C7 6.55228 6.55228 7 6 7C5.44772 7 5 6.55228 5 6C5 5.44772 5.44772 5 6 5C6.55228 5 7 5.44772 7 6ZM18 7C18.5523 7 19 6.55228 19 6C19 5.44772 18.5523 5 18 5C17.4477 5 17 5.44772 17 6C17 6.55228 17.4477 7 18 7ZM19 18C19 18.5523 18.5523 19 18 19C17.4477 19 17 18.5523 17 18C17 17.4477 17.4477 17 18 17C18.5523 17 19 17.4477 19 18ZM6 19C6.55228 19 7 18.5523 7 18C7 17.4477 6.55228 17 6 17C5.44772 17 5 17.4477 5 18C5 18.5523 5.44772 19 6 19Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
