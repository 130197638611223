import React from "react";
import IconClose from "../../../dynamicicons/icon-close";
import VelavuIconButton from "../../../elements/velavu-icon-button";
import styles from "./pinned-list-header.module.scss";

export default function PinnedListHeader(props: {
	label?: string;
	onClose?: VoidFunction;
}) {
	return (
		<div className={styles.container}>
			<span className={styles.title}>{props.label ?? ""}</span>
			<VelavuIconButton onClick={props.onClose}>
				<IconClose color="#A3B3CC" />
			</VelavuIconButton>
		</div>
	);
}
