import React from "react";
import { VelavuDevice } from "velavu-js-api";
import styles from "./asset-environment.module.scss";

interface AssetEnvironmentProps {
	device?: VelavuDevice;
}

export default function AssetEnvironment(props: AssetEnvironmentProps) {
	return (
		<div className={styles.container}>
			<div className={styles.box} style={{ marginRight: 20 }}>
				<div className={styles.headers}>
					<span className={styles.ratingHeader}>
						{props.device?.environment
							? `${props.device?.environment.temperature_c}°`
							: "--"}
					</span>
					<span className={styles.textHeader}>Temperature</span>
				</div>
			</div>
			<div className={styles.box}>
				<div className={styles.headers}>
					<span className={styles.ratingHeader}>
						{props.device?.environment
							? `${Math.round(
									props.device?.environment.humidity,
							  )}%`
							: "--"}
					</span>
					<span className={styles.textHeader}>Humidity</span>
				</div>
			</div>
		</div>
	);
}
