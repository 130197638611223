import React, { CSSProperties } from "react";

export default function IconUnlink(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M14.3162,5.05134 C14.8402,5.22599 15.1233,5.79231 14.9487,6.31625 L10.9487,18.3163 C10.774,18.8402 10.2077,19.1234 9.68377,18.9487 C9.15983,18.7741 8.87667,18.2077 9.05132,17.6838 L13.0513,5.6838 C13.226,5.15986 13.7923,4.8767 14.3162,5.05134 Z M18,8.00003 C20.2091,8.00003 22,9.79089 22,12 C22,14.2092 20.2091,16 18,16 L15,16 C14.4477,16 14,15.5523 14,15 C14,14.4477 14.4477,14 15,14 L18,14 C19.1046,14 20,13.1046 20,12 C20,10.8955 19.1046,10 18,10 L17,10 C16.4477,10 16,9.55231 16,9.00003 C16,8.44774 16.4477,8.00003 17,8.00003 L18,8.00003 Z M9,8.00003 C9.55228,8.00003 10,8.44774 10,9.00003 C10,9.55231 9.55228,10 9,10 L9,10 L6,10 C4.89543,10 4,10.8955 4,12 C4,13.1046 4.89543,14 6,14 L6,14 L7,14 C7.55228,14 8,14.4477 8,15 C8,15.5523 7.55228,16 7,16 L7,16 L6,16 C3.79086,16 2,14.2092 2,12 C2,9.79089 3.79086,8.00003 6,8.00003 L6,8.00003 Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
