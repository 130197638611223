import { useCallback, useEffect, useMemo, useState } from "react";

export type RemoveRepo = () => void;
export type AddRepo<T> = (items: T[]) => RemoveRepo;

export interface MultiRepo<T> {
	items: T[];
	addRepo: AddRepo<T>;
}

export type RepoItemIDExtractor<T> = (item: T) => RepoItemID;
export type RepoItemID = unknown;

// Manages different sources of device markers and consolidates
// then into a single array
export default function useMultiRepo<T>(
	idExtractor: RepoItemIDExtractor<T>,
): MultiRepo<T> {
	const [repos, setRepos] = useState<T[][]>([]);

	const addRepo = useCallback(
		(repo: T[]): RemoveRepo => {
			setRepos((prevRepos) => [...prevRepos, repo]);

			return () => {
				setRepos((prevRepos) => {
					const index = prevRepos.indexOf(repo);
					if (index === -1) return prevRepos;

					const newRepos = [...prevRepos];
					newRepos.splice(index, 1);
					return newRepos;
				});
			};
		},
		[setRepos],
	);

	//Flatten and remove duplicate items
	const consolidatedItems = useMemo(() => {
		const itemMap = new Map<RepoItemID, T>();
		for (const itemArray of repos) {
			for (const item of itemArray) {
				itemMap.set(idExtractor(item), item);
			}
		}
		return Array.from(itemMap.values());
	}, [repos, idExtractor]);

	return useMemo(
		() => ({
			items: consolidatedItems,
			addRepo: addRepo,
		}),
		[consolidatedItems, addRepo],
	);
}

// Syncs the provided makers array into the repo,
// automatically updating the repo with any changes,
// and removing the markers on unmount
export function useSyncMultiRepoSource<T>(
	multiRepo: MultiRepo<T>,
	repo: T[] | undefined,
) {
	const addRepo = multiRepo.addRepo;

	useEffect(() => {
		if (repo === undefined || repo.length === 0) {
			return;
		}

		return addRepo(repo);
	}, [addRepo, repo]);
}

// Syncs the single provided device into the repo
export function useSyncMultiRepoSingleItem<T>(
	multiRepo: MultiRepo<T>,
	item: T | undefined,
) {
	//Needs to be memoized to avoid triggering updates
	const array = useMemo((): T[] => {
		if (item === undefined) {
			return [];
		} else {
			return [item];
		}
	}, [item]);

	useSyncMultiRepoSource(multiRepo, array);
}
