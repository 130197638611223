import React, { CSSProperties } from "react";

export default function IconVelavuAssets(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.78518 3.70682L4.70303 5.60779C2.80109 6.49349 2.49221 8.75465 3.77639 10.0871C2.74121 11.1612 2.7412 12.8388 3.77637 13.9129C2.49222 15.2453 2.8011 17.5065 4.70303 18.3922L8.78518 20.2931C10.809 21.2356 13.191 21.2356 15.2148 20.2931L19.297 18.3922C21.1989 17.5065 21.5078 15.2453 20.2236 13.9129C21.2588 12.8388 21.2588 11.1612 20.2236 10.0871C21.5078 8.75466 21.1989 6.49349 19.297 5.60779L15.2148 3.70682C13.191 2.76439 10.809 2.76439 8.78518 3.70682ZM18.4036 11.1566L15.2148 12.6416C13.191 13.584 10.809 13.584 8.78518 12.6416L5.59638 11.1566C4.87305 11.5154 4.87304 12.4846 5.59634 12.8434L5.59639 12.8433C5.60468 12.8474 5.61307 12.8515 5.62155 12.8554L7.89228 13.9129L7.89223 13.9129L9.7037 14.7564C11.1493 15.4296 12.8507 15.4296 14.2963 14.7564L16.1078 13.9129L16.1077 13.9129L18.3784 12.8554C18.3869 12.8515 18.3953 12.8474 18.4036 12.8433L18.4037 12.8434C19.127 12.4846 19.1269 11.5154 18.4036 11.1566ZM9.7037 5.41776L5.62155 7.31874C4.86465 7.67121 4.86465 8.6772 5.62155 9.02968L9.7037 10.9306C11.1493 11.6038 12.8507 11.6038 14.2963 10.9306L18.3784 9.02968C19.1354 8.6772 19.1354 7.67121 18.3784 7.31874L14.2963 5.41776C12.8507 4.7446 11.1493 4.7446 9.7037 5.41776ZM5.62155 16.6812C4.87315 16.3327 4.86474 15.3453 5.59634 14.9824L8.78518 16.4674C10.809 17.4098 13.191 17.4098 15.2148 16.4674L18.4037 14.9824C19.1353 15.3453 19.1269 16.3327 18.3784 16.6812L14.2963 18.5822C12.8507 19.2554 11.1493 19.2554 9.7037 18.5822L5.62155 16.6812Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
