import React from "react";
import styles from "./floor-create-import-prompt.module.scss";
import IconYesFile from "../../../../dynamicicons/icon-file-yes";
import IconNoFile from "../../../../dynamicicons/icon-file-no";
import PinnedList from "../pinned-list";
import PinnedListHeader from "../pinned-list-header";

export interface FloorCreateImportPromptProps {
	onSelectUpload?: () => void;
	onSelectSkip?: () => void;
	onClose?: () => void;
}

export default function FloorCreateImportPrompt(
	props: FloorCreateImportPromptProps,
) {
	return (
		<PinnedList>
			<PinnedListHeader
				label="Upload floor plan"
				onClose={props.onClose}
			/>

			<div className={styles.container}>
				<span className={styles.message}>
					Do you have a digital copy of your floor plan to import?
				</span>

				<div className={styles.buttonRow}>
					<button
						className={styles.buttonBox}
						onClick={props.onSelectUpload}
					>
						<IconYesFile />
						<span>Yes</span>
					</button>
					<div className={styles.buttonSpacer} />
					<button
						className={styles.buttonBox}
						onClick={props.onSelectSkip}
					>
						<IconNoFile />
						<span>No</span>
					</button>
				</div>
			</div>
		</PinnedList>
	);
}
