import React, { useCallback, useState, Dispatch, SetStateAction } from "react";
import { VelavuAsset } from "velavu-js-api";
import AssetCategorization from "../../data/asset-categorization";
import { AssetCategory } from "velavu-js-api";
import VelavuButton from "../../elements/velavu-button";
import CategorizationAutocomplete from "../../elements/velavu-categorization-autocomplete";
import VelavuInput from "../../elements/velavu-input";
import { useCategorizationSuggestions } from "../../helper/api-helper";
import TabAssetManageImage from "./tab-asset-manage-image";
import styles from "./tab-asset.module.scss";
import { VelavuAPI } from "velavu-js-api";

function Notes(props: {
	value: string;
	onChange: Dispatch<SetStateAction<string>>;
	disabled: boolean;
}) {
	const [height, setHeight] = useState(20);

	return (
		<>
			<span className={`${styles.p2m} ${styles.label}`}>Notes</span>
			<textarea
				disabled={props.disabled}
				value={props.value}
				onChange={(e) => {
					const t = e.target as HTMLTextAreaElement;
					props.onChange(t.value);
					if (t.scrollHeight > t.clientHeight)
						setHeight(t.scrollHeight);
				}}
				style={{ height: height }}
			></textarea>
		</>
	);
}

export default function TabAsset(props: {
	asset: VelavuAsset;
	onUpdate: (
		name: string,
		category: AssetCategory,
		group: string,
		notes: string,
	) => void;
	onUpdateImage: (url?: string) => void;
}) {
	const [originalName, setOriginalName] = useState(props.asset.name);
	const [originalCategory, setOriginalCategory] = useState(
		props.asset.category,
	);
	const [originalGroup, setOriginalGroup] = useState(props.asset.group);
	const [originalNotes, setOriginalNotes] = useState(props.asset.notes ?? "");

	const [assetName, setAssetName] = useState<string>(props.asset.name);
	const [assetCategorization, setAssetCategorization] = useState<
		AssetCategorization | undefined
	>({ category: props.asset.category, group: props.asset.group });
	const [assetNotes, setAssetNotes] = useState<string>(
		props.asset.notes ?? "",
	);

	const categorizationSuggestions = useCategorizationSuggestions();

	const [isSubmitting, setSubmitting] = useState(false);

	const propsOnUpdate = props.onUpdate;
	const saveChanges = useCallback(() => {
		setSubmitting(true);

		//Submitting the changes
		VelavuAPI.assets
			.modifySpecificAsset(props.asset.id, {
				name: assetName,
				category: assetCategorization!.category,
				group: assetCategorization!.group,
				notes: assetNotes,
			})
			.then(() => {
				setOriginalName(assetName);
				setOriginalCategory(assetCategorization!.category);
				setOriginalGroup(assetCategorization!.group);
				setOriginalNotes(assetNotes);

				propsOnUpdate(
					assetName,
					assetCategorization!.category,
					assetCategorization!.group,
					assetNotes,
				);
			})
			.catch((error) => console.log(error.response))
			.finally(() => setSubmitting(false));
	}, [
		assetName,
		assetCategorization,
		assetNotes,
		props.asset.id,
		propsOnUpdate,
	]);

	const dataChanged =
		assetName !== originalName ||
		assetCategorization?.category !== originalCategory ||
		assetCategorization?.group !== originalGroup ||
		assetNotes !== originalNotes;

	return (
		<div className={styles.container}>
			<TabAssetManageImage
				style={{ marginBottom: 20 }}
				id={props.asset.id}
				category={props.asset.category}
				image={props.asset.profile_img_url}
				updateImage={props.onUpdateImage}
			/>
			<VelavuInput
				style={{ marginBottom: 12 }}
				type="text"
				titleText="Name"
				placeholder="Ex. Ford Ranger"
				value={assetName}
				onChange={setAssetName}
				disabled={isSubmitting}
				fullWidth
			/>
			<CategorizationAutocomplete
				containerProps={{ style: { marginBottom: 12 } }}
				value={assetCategorization}
				onChange={setAssetCategorization}
				categorization={categorizationSuggestions}
			/>
			<Notes
				value={assetNotes}
				onChange={setAssetNotes}
				disabled={isSubmitting}
			/>

			<VelavuButton
				className={styles.button}
				label="Save changes"
				disabled={
					isSubmitting ||
					!(
						assetName.trim().length > 0 &&
						assetCategorization &&
						dataChanged
					)
				}
				onClick={saveChanges}
			/>
		</div>
	);
}
