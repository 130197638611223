import React, { CSSProperties } from "react";
import styles from "./velavu-notice.module.scss";

interface VelavuNoticeProps {
	type: "info" | "confirm" | "error";

	title?: string;
	body?: string;
	children?: React.ReactNode;

	className?: string;
	style?: CSSProperties;
}

export default function VelavuNotice(props: VelavuNoticeProps) {
	let style: string;
	switch (props.type) {
		case "info":
			style = styles.containerInfo;
			break;
		case "confirm":
			style = styles.containerConfirm;
			break;
		case "error":
			style = styles.containerError;
			break;
	}

	return (
		<div
			style={props.style}
			className={`${styles.container} ${style} ${
				props.className ? props.className : ""
			}`}
		>
			{props.title && <span className={styles.title}>{props.title}</span>}
			{props.body && (
				<span
					className={`${styles.body} ${
						props.title ? styles.bodyMargin : ""
					}`}
				>
					{props.body}
				</span>
			)}
			{props.children}
		</div>
	);
}
