import { LocationResource, VelavuFloor } from "velavu-js-api";

export const availableFloors = [-3, -2, -1, 1, 2, 3, 4, 5];

/**
 * Gets a floor to target with an optional override value, defaulting the first above-ground floor
 */
export function getFloorTarget(
	floors: VelavuFloor[] | undefined,
	id: string | undefined,
): VelavuFloor | undefined {
	if (floors === undefined) {
		return undefined;
	} else if (id === undefined) {
		return (
			floors
				.sort((f1, f2) => f1.level - f2.level) //ascending order
				.find((floor) => floor.level >= 0) ?? floors[0]
		);
	} else {
		return floors.find((floor) => floor.id == id)!;
	}
}

/**
 * Gets the default floor number to offer to the user from a list of floor numbers
 */
export function getDefaultFloor(floors: number[]): number {
	//Return first above-ground floor
	const aboveFloors = floors.filter((floor) => floor > 0);
	if (aboveFloors.length > 0) return Math.min(...aboveFloors);

	//Return highest below-ground floor
	return Math.max(...floors);
}

/**
 * Gets all floors that the user can pick from for an existing site
 */
export function getAvailableSiteFloors(
	existingFloors: VelavuFloor[],
): number[] {
	return availableFloors.filter(
		(floorNum) => !existingFloors.find((floor) => floor.level === floorNum),
	);
}

/**
 * Gets the floor_id attribute from an asset's location
 */
export function getLocationFloorID(
	location: LocationResource | undefined,
): string | undefined {
	if (location === undefined) {
		return undefined;
	} else if ("floor_id" in location) {
		return location.floor_id;
	} else {
		return undefined;
	}
}
