import React, { CSSProperties } from "react";
import styles from "./velavu-switch.module.scss";

export interface VelavuSwitchProps {
	style?: CSSProperties;
	className?: string;

	toggled: boolean;
	onChange: (toggled: boolean) => void;
	label?: string;
}

export default function VelavuSwitch(props: VelavuSwitchProps) {
	return (
		<label
			className={`${styles.label} ${
				props.className ? props.className : ""
			}`}
			style={props.style}
		>
			<input
				className={styles.input}
				type="checkbox"
				checked={props.toggled}
				onChange={(event) => props.onChange(event.target.checked)}
			/>
			<div className={`${styles.switch} ${styles.switchPadding}`}>
				<div className={styles.handle} />
			</div>

			{props.label}
		</label>
	);
}

export function VelavuSwitchDisplay(props: {
	style?: CSSProperties;
	className?: string;
	toggled?: boolean;
}) {
	return (
		<div
			className={`${styles.switch} ${styles.display} ${
				props.toggled ? styles.displayChecked : styles.displayUnchecked
			}`}
		>
			<div className={styles.handle} />
		</div>
	);
}
