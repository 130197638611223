import React, { CSSProperties } from "react";

export default function IconRequestedAssistance(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<mask
				id="mask0_140_5"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="24"
				height="24"
			>
				<rect width="24" height="24" fill="#D9D9D9" />
			</mask>
			<g mask="url(#mask0_140_5)">
				<path
					d="M11 16C11 15.4477 11.4477 15 12 15C12.5523 15 13 15.4477 13 16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11 2C10.4477 2 10 2.44771 10 3V3.41604C8.2341 4.1876 7 5.94968 7 8V16C7 18.7614 9.23858 21 12 21C14.7614 21 17 18.7614 17 16V8C17 5.94968 15.7659 4.1876 14 3.41604V3C14 2.44771 13.5523 2 13 2H11ZM12 5C10.3431 5 9 6.34315 9 8V16C9 17.6569 10.3431 19 12 19C13.6569 19 15 17.6569 15 16V8C15 6.34315 13.6569 5 12 5Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M19 16C19 15.4477 19.4477 15 20 15H23C23.5523 15 24 15.4477 24 16C24 16.5523 23.5523 17 23 17H20C19.4477 17 19 16.5523 19 16Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M5 16C5 15.4477 4.55228 15 4 15H1C0.447715 15 0 15.4477 0 16C0 16.5523 0.447715 17 1 17H4C4.55228 17 5 16.5523 5 16Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M18.2679 20.0002C18.5441 19.5219 19.1557 19.358 19.634 19.6341L21.366 20.6341C21.8443 20.9103 22.0082 21.5219 21.732 22.0002C21.4559 22.4785 20.8443 22.6423 20.366 22.3662L18.634 21.3662C18.1557 21.09 17.9918 20.4785 18.2679 20.0002Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M5.73203 20.0002C5.45589 19.5219 4.8443 19.358 4.36601 19.6341L2.63396 20.6341C2.15566 20.9103 1.99179 21.5219 2.26793 22.0002C2.54407 22.4785 3.15566 22.6423 3.63396 22.3662L5.36601 21.3662C5.8443 21.09 6.00817 20.4785 5.73203 20.0002Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M19.2679 12.134C18.9918 11.6557 19.1557 11.0441 19.634 10.7679L21.366 9.76793C21.8443 9.49179 22.4559 9.65567 22.732 10.134C23.0082 10.6122 22.8443 11.2238 22.366 11.5L20.634 12.5C20.1557 12.7761 19.5441 12.6123 19.2679 12.134Z"
					fill={props.color ?? "#00255D"}
				/>
				<path
					d="M4.73203 12.134C5.00817 11.6557 4.8443 11.0441 4.36601 10.7679L2.63396 9.76793C2.15566 9.49179 1.54407 9.65567 1.26793 10.134C0.991789 10.6122 1.15566 11.2238 1.63396 11.5L3.36601 12.5C3.8443 12.7761 4.45589 12.6123 4.73203 12.134Z"
					fill={props.color ?? "#00255D"}
				/>
			</g>
		</svg>
	);
}
