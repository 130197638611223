import { useContext, useEffect } from "react";
import { EventCategory } from "velavu-js-api";
import { MQTTContext, MQTTListener } from "../components/main/mqtt-provider";

export type DeviceOnlineCallback = (deviceID: string, online: boolean) => void;

/**
 * Sets up a callback function to be executed when a device connection status changes.
 *
 * @param {DeviceOnlineCallback} callback - The callback function to be executed
 * when a device connection status changes.
 * The function will receive two parameters:
 * the device ID and a boolean indicating the device's online status.
 * This callback function must be memoized.
 */
export default function useDeviceOnlineCallback(
	callback: DeviceOnlineCallback,
) {
	const mqttContext = useContext(MQTTContext);

	useEffect(() => {
		const listener: MQTTListener = {
			onAlertReceive: (alert) => {
				switch (alert.event_category) {
					case EventCategory.DeviceConnect:
						callback(alert.device.id, true);
						break;
					case EventCategory.DeviceDisconnect:
						callback(alert.device.id, false);
						break;
					default:
						break;
				}
			},
		};

		mqttContext.addListener(listener);
		return () => mqttContext.removeListener(listener);
	}, [mqttContext, callback]);
}
