import React, { CSSProperties } from "react";

export default function IconVelavuUp(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M12.4 3.4L17.6 8.6C18 9 17.7 9.5 17.3 9.5H13V20C13 20.6 12.6 21 12 21 11.4 21 11 20.6 11 20V9.5H6.7C6.3 9.5 6 9 6.4 8.6L11.6 3.4C11.8 3.2 12.2 3.2 12.4 3.4Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
