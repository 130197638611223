import {
	MapHelperInstance,
	MapReadyListenerPriority,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import iconTripEnd from "../../img/map-trip-end.svg";
import iconTripStart from "../../img/map-trip-start.svg";
import { useContext, useEffect, useMemo } from "react";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import styles from "./mapbox-injectable-route.module.scss";
import {
	Feature,
	FeatureCollection,
	LineString,
	MultiLineString,
	Point,
} from "geojson";
import {
	EventCategory,
	VelavuEvent,
	VelavuRoute,
	VelavuRouteDetailed,
} from "velavu-js-api";
import { buildRouteEventStrings } from "../../helper/language-helper";
import polyline from "@mapbox/polyline";
import SignInContext from "../../sign-in-context";
import { getSpeedingThreshold } from "../../helper/pref-helper";
import {
	normalizeSpeed,
	speedUnitToMeasurementSystem,
} from "../../helper/unit-helper";

const mapboxIDRoute = "route";
const mapboxIDSpeedingLine = "speedingLine";
const mapboxIDEventMarker = "eventMarker";
const mapboxLayerIDEventMarkerShadow = "eventMarkerShadow";

export enum RouteRenderType {
	Normal,
	Hover,
}

export interface MapboxInjectableRouteParams {
	mapInstance: MapHelperInstance;
	showLayerSpeeding: boolean;
	showLayerEvents: boolean;
	route: VelavuRoute | undefined;
	routeDetails: VelavuRouteDetailed | undefined;
	renderType: RouteRenderType | undefined;
}

export default function useMapboxInjectableRoute(
	params: MapboxInjectableRouteParams,
) {
	const userContext = useContext(SignInContext);

	const markerStart = useMemo(() => {
		const markerElement = document.createElement("img");
		markerElement.src = iconTripStart;
		markerElement.style.display = "none";
		return new mapboxgl.Marker(markerElement);
	}, []);

	const markerEnd = useMemo(() => {
		const markerElement = document.createElement("img");
		markerElement.src = iconTripEnd;
		markerElement.style.display = "none";
		return new mapboxgl.Marker(markerElement);
	}, []);

	//Set up the start and end markers
	useEffect(() => {
		const map = params.mapInstance.map;
		if (map !== undefined) {
			markerStart.setLngLat([0, 0]).addTo(map);
			markerEnd.setLngLat([0, 0]).addTo(map);

			return () => {
				markerStart.remove();
				markerEnd.remove();
			};
		}
	}, [params.mapInstance.map, markerStart, markerEnd]);

	//Show popup when hovering over an event
	const eventPopup = useMemo(
		() =>
			new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
			}),
		[],
	);
	useListenerSetupMapEffect(
		params.mapInstance.map,
		(map, box) => {
			box.on("mouseenter", mapboxIDEventMarker, (event) => {
				// Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = "pointer";

				const coordinates = (event.features![0].geometry as Point)
					.coordinates;
				const routeEvent = event.features![0]
					.properties! as VelavuEvent<EventCategory.DrivingEvent>;
				const [routeEventTitle, routeEventSubtitle] =
					buildRouteEventStrings(routeEvent);

				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
					coordinates[0] +=
						event.lngLat.lng > coordinates[0] ? 360 : -360;
				}

				// Populate the popup and set its coordinates
				// based on the feature found.
				const descriptionHTML = `
					<div class="${styles.eventPopupContainer}">
						<svg class="${styles.eventPopupIcon}" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
							<path d="M18.92,6.01C18.72,5.42,18.16,5,17.5,5h-11C5.84,5,5.29,5.42,5.08,6.01L3,12v7.5C3,20.33,3.67,21,4.5,21h0 C5.33,21,6,20.33,6,19.5V19h12v0.5c0,0.82,0.67,1.5,1.5,1.5h0c0.82,0,1.5-0.67,1.5-1.5V12L18.92,6.01z M7.5,16 C6.67,16,6,15.33,6,14.5S6.67,13,7.5,13S9,13.67,9,14.5S8.33,16,7.5,16z M16.5,16c-0.83,0-1.5-0.67-1.5-1.5s0.67-1.5,1.5-1.5 s1.5,0.67,1.5,1.5S17.33,16,16.5,16z M5.81,10l1.04-3h10.29l1.04,3H5.81z" fill="#00255D" />
						</svg>
						<div class="${styles.eventPopupText}">
							<span class="${styles.eventPopupTitle}">${routeEventTitle}</span>
							<span class="${styles.eventPopupSubtitle}">${routeEventSubtitle}</span>
						</div>
					</div>
					`;
				eventPopup
					.setLngLat(coordinates as [number, number])
					.setHTML(descriptionHTML)
					.addTo(map);
			});
			box.on("mouseleave", mapboxIDEventMarker, () => {
				map.getCanvas().style.cursor = "";
				eventPopup.remove();
			});
		},
		[eventPopup],
	);

	useStyleSetupMapEffect(params.mapInstance, (_, box) => {
		//Initialize blank route layer
		box.addSource(mapboxIDRoute, {
			type: "geojson",
			data: {
				type: "Feature",
				geometry: { type: "Point", coordinates: [] },
				properties: [],
			},
		});
		box.addLayer({
			id: mapboxIDRoute,
			type: "line",
			source: mapboxIDRoute,
			layout: {
				visibility: "visible",
				"line-join": "round",
				"line-cap": "round",
			},
			paint: {
				"line-width": 4,
				"line-color": "black",
			},
		});

		//Initialize blank speeding line layer
		box.addSource(mapboxIDSpeedingLine, {
			type: "geojson",
			data: {
				type: "Feature",
				geometry: { type: "Point", coordinates: [] },
				properties: [],
			},
		});
		box.addLayer({
			id: mapboxIDSpeedingLine,
			type: "line",
			source: mapboxIDSpeedingLine,
			layout: {
				visibility: "none",
			},
			paint: {
				"line-width": 4,
				//"line-color": "#FF6D00"
				"line-color": "red",
			},
		});

		box.addSource(mapboxIDEventMarker, {
			type: "geojson",
			data: {
				type: "Feature",
				geometry: { type: "Point", coordinates: [] },
				properties: [],
			},
		});
		box.addLayer({
			id: mapboxLayerIDEventMarkerShadow,
			type: "circle",
			source: mapboxIDEventMarker,
			layout: {
				visibility: "visible",
			},
			paint: {
				"circle-color": "#000000",
				"circle-radius": 8,
				"circle-blur": 1,
			},
		});
		box.addLayer({
			id: mapboxIDEventMarker,
			type: "circle",
			source: mapboxIDEventMarker,
			layout: {
				visibility: "none",
			},
			paint: {
				"circle-color": "#DA3E52",
				"circle-stroke-color": "#FFF",
				"circle-stroke-width": 2,
				"circle-radius": 4,
			},
		});
	});

	//Update visibility based on flags
	const showDetailLayers = params.routeDetails !== undefined;
	useStyleDependantMapEffect(
		params.mapInstance,
		(map) => {
			map.setLayoutProperty(
				mapboxIDSpeedingLine,
				"visibility",
				showDetailLayers && params.showLayerSpeeding
					? "visible"
					: "none",
			);
			map.setLayoutProperty(
				mapboxIDEventMarker,
				"visibility",
				showDetailLayers && params.showLayerEvents ? "visible" : "none",
			);
			map.setLayoutProperty(
				mapboxLayerIDEventMarkerShadow,
				"visibility",
				showDetailLayers && params.showLayerEvents ? "visible" : "none",
			);
		},
		[showDetailLayers, params.showLayerSpeeding, params.showLayerEvents],
	);

	//Render the specified route
	const targetRoute = params.route;
	const routeRenderType: RouteRenderType =
		params.renderType ?? RouteRenderType.Normal;
	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			const existingSource = map.getSource(
				mapboxIDRoute,
			) as GeoJSONSource;

			if (targetRoute !== undefined) {
				//Show the route
				map.setLayoutProperty(mapboxIDRoute, "visibility", "visible");

				//Decode the selected route to GeoJSON
				const lineString = polyline.toGeoJSON(
					targetRoute.data.geometry!,
				);
				const geoJSON: Feature<LineString> = {
					type: "Feature",
					geometry: lineString,
					id: targetRoute.id,
					properties: {},
				};

				//Update the source and paint data
				existingSource.setData(geoJSON);
				let lineColor: string;
				if (routeRenderType === RouteRenderType.Normal) {
					lineColor = "#3A58E2";
				} else {
					lineColor = "#5F718C";
				}
				map.setPaintProperty(mapboxIDRoute, "line-color", lineColor);

				//Only show the markers if the render type is normal
				if (routeRenderType === RouteRenderType.Normal) {
					//Show the route ends
					markerStart.setLngLat(
						lineString.coordinates[0] as [number, number],
					);

					//If route is finished, render stopped location marker
					if (targetRoute.data.location_stop) {
						markerEnd.setLngLat(
							lineString.coordinates[
								lineString.coordinates.length - 1
							] as [number, number],
						);
					}

					for (const marker of [markerStart, markerEnd]) {
						marker.getElement().style.display = "block";
					}
				}
			} else {
				//Hide the route
				map.setLayoutProperty("route", "visibility", "none");

				//Hide the route ends
				for (const marker of [markerStart, markerEnd])
					marker.getElement().style.display = "none";
			}
		},
		[targetRoute, routeRenderType, markerStart, markerEnd],
	);

	//Show route details on the map
	const selectedRouteDetails = params.routeDetails;
	useStyleDependantMapEffect(
		params.mapInstance,
		(map, box) => {
			const lineSource = map.getSource(
				mapboxIDSpeedingLine,
			) as GeoJSONSource;
			const eventSource = map.getSource(
				mapboxIDEventMarker,
			) as GeoJSONSource;

			if (selectedRouteDetails) {
				//Decoding the route geometry
				const sourceLine = polyline.toGeoJSON(
					selectedRouteDetails.data.geometry!,
				);

				//Index speeding sections
				const ranges: [number, number][] = [];
				if (userContext.preferences.eventsSpeedingVisibility) {
					const speedingThreshold = getSpeedingThreshold(
						userContext.preferences.eventsSpeedingThreshold,
						userContext.preferences.measurementSystem,
					);
					{
						let startPoint: number | undefined = undefined;
						for (const annotation of selectedRouteDetails.data
							.annotations) {
							//Ignoring annotations without a speed limit
							if (!annotation.speed_limit) continue;

							const isSpeeding =
								annotation.speed >
								normalizeSpeed(
									annotation.speed_limit.speed,
									speedUnitToMeasurementSystem(
										annotation.speed_limit.unit,
									),
								) +
									speedingThreshold;

							//Checking if we can complete a start point
							if (startPoint) {
								if (!isSpeeding) {
									//Completing this range
									ranges.push([startPoint, annotation.index]);
									startPoint = undefined;
								}
							} else {
								if (isSpeeding) {
									//Starting a new range
									startPoint = annotation.index;
								}
							}
						}
						//Completing a loose range (if someone drives a truck into a wall?)
						if (startPoint)
							ranges.push([
								startPoint,
								sourceLine.coordinates.length - 1,
							]);
					}
				}

				//Mapping the ranges to GeoJSON lines
				const speedingLine: MultiLineString = {
					type: "MultiLineString",
					coordinates: ranges.map(([iStart, iEnd]) =>
						sourceLine.coordinates.slice(iStart, iEnd + 1),
					),
				};

				//Rendering the line on the map
				lineSource.setData({
					type: "Feature",
					geometry: speedingLine,
					properties: [],
				});

				//Collecting the events into a feature collection
				const eventsGeoJSON: FeatureCollection<
					Point,
					VelavuEvent<EventCategory.DrivingEvent>
				> = {
					type: "FeatureCollection",
					features: selectedRouteDetails.data.annotations
						.filter((annotation) => annotation.events)
						.flatMap((annotation) =>
							annotation
								.events!.filter((event) =>
									userContext.preferences.eventsEventFilter.includes(
										event.data.direction,
									),
								)
								.map((event) => ({
									type: "Feature",
									geometry: {
										type: "Point",
										coordinates:
											sourceLine.coordinates[
												annotation.index
											],
									},
									properties: event,
								})),
						),
				};

				//Displaying the source
				eventSource.setData(eventsGeoJSON);
			}
		},
		[selectedRouteDetails, userContext.preferences],
		MapReadyListenerPriority.Medium,
	);
}
