import { useState, useEffect } from "react";
import { getBase64 } from "../helper/image-helper";

export interface ImageDimensions {
	width: number;
	height: number;
}

export default interface PlaceableFloorImage {
	name: string;
	renderable: Blob | string;
	dimensions: ImageDimensions;
}

export function usePlaceableFloorImageURL(renderable: Blob | string): string;
export function usePlaceableFloorImageURL(renderable: undefined): undefined;
export function usePlaceableFloorImageURL(
	renderable: Blob | string | undefined,
): string | undefined;
export function usePlaceableFloorImageURL(
	renderable: Blob | string | undefined,
): string | undefined {
	const [imageURL, setImageURL] = useState<string | undefined>(undefined);

	useEffect(() => {
		if (renderable === undefined) {
			setImageURL(undefined);
		} else if (typeof renderable === "string") {
			setImageURL(renderable);
		} else {
			const url = URL.createObjectURL(renderable);
			setImageURL(url);
			return () => {
				URL.revokeObjectURL(url);
			};
		}
	}, [renderable]);

	return imageURL;
}

export async function placeableFloorImageToBase64(
	renderable: Blob | string,
): Promise<string | undefined> {
	if (typeof renderable === "string") {
		return renderable;
	} else {
		return (await getBase64(renderable)) ?? undefined;
	}
}
