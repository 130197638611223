export type DevicePowerStatus = { level: number; charging: boolean };

//A type that can either accept a standard device power status, or a Velavu power resource
export type DevicePowerUnion =
	| { level?: number; charging?: boolean }
	| { resource: { battery_level?: number; usb_power?: boolean } | undefined };

/**
 * Reduces a {@link DevicePowerUnion} to a {@link DevicePowerStatus}
 */
export function useDevicePowerUnion(
	devicePowerUnion: DevicePowerUnion,
): DevicePowerStatus {
	if ("resource" in devicePowerUnion) {
		let level = devicePowerUnion.resource?.battery_level ?? 0;
		level /= 100;
		const charging = devicePowerUnion.resource?.usb_power ?? false;

		return { level, charging };
	} else {
		return {
			level: devicePowerUnion.level ?? 0,
			charging: devicePowerUnion.charging ?? false,
		};
	}
}
