import React, { CSSProperties, useCallback } from "react";
import IconAddFile from "../dynamicicons/icon-file-add";
import styles from "./site-modal-upload-button.module.scss";

interface SiteModalUploadButtonProps {
	style?: CSSProperties;
	className?: string;

	onReceive?: (file: File) => void;
}

export default function SiteModalUploadButton(
	props: SiteModalUploadButtonProps,
) {
	const propsOnReceive = props.onReceive;
	const onFileSelect = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			propsOnReceive?.(event.currentTarget.files![0]);
			event.currentTarget.value = "";
		},
		[propsOnReceive],
	);

	const eventPrevent = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
		e.preventDefault();
	}, []);

	const fileDrop = useCallback(
		(e: React.DragEvent<HTMLLabelElement>) => {
			e.preventDefault();
			propsOnReceive && propsOnReceive(e.dataTransfer.files![0]);
		},
		[propsOnReceive],
	);

	return (
		<label
			style={props.style}
			className={`${styles.container} ${props.className}`}
			onDragOver={eventPrevent}
			onDragEnter={eventPrevent}
			onDragLeave={eventPrevent}
			onDrop={fileDrop}
		>
			<IconAddFile />

			<span className={styles.title}>
				Drag file here or click to upload
			</span>
			<span className={styles.subtitle}>jpg, png and pdf accepted</span>

			<input
				className={styles.input}
				type="file"
				name="image"
				accept="image/png, image/jpeg, application/pdf"
				onChange={onFileSelect}
			/>
		</label>
	);
}
