import React, { CSSProperties } from "react";

export default function IconEdit(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M17.0824 5.77778C16.9327 5.77778 16.7845 5.80726 16.6462 5.86454C16.5079 5.92182 16.3822 6.00578 16.2764 6.11163L6.76026 15.6278L6.15577 17.8442L8.37224 17.2397L17.8884 7.7236C17.9942 7.61776 18.0782 7.4921 18.1355 7.35381C18.1927 7.21552 18.2222 7.0673 18.2222 6.91761C18.2222 6.76793 18.1927 6.61971 18.1355 6.48142C18.0782 6.34313 17.9942 6.21747 17.8884 6.11163C17.7825 6.00578 17.6569 5.92182 17.5186 5.86454C17.3803 5.80726 17.2321 5.77778 17.0824 5.77778ZM15.9659 4.22209C16.3198 4.07547 16.6992 4 17.0824 4C17.4655 4 17.8449 4.07547 18.1989 4.22209C18.5529 4.36871 18.8745 4.58362 19.1455 4.85455C19.4164 5.12547 19.6313 5.44711 19.7779 5.80109C19.9245 6.15508 20 6.53447 20 6.91761C20 7.30076 19.9245 7.68015 19.7779 8.03414C19.6313 8.38812 19.4164 8.70976 19.1455 8.98068L9.4624 18.6637C9.35301 18.7731 9.21698 18.8521 9.06774 18.8928L5.12279 19.9687C4.81505 20.0526 4.48593 19.9652 4.26037 19.7396C4.03481 19.5141 3.94741 19.185 4.03134 18.8772L5.10724 14.9323C5.14794 14.783 5.22688 14.647 5.33626 14.5376L15.0193 4.85455C15.2902 4.58362 15.6119 4.36871 15.9659 4.22209Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M17.0606 10.5641L13.4359 6.93937L14.6929 5.68229L18.3177 9.30707L17.0606 10.5641Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
