import React, { useState } from "react";
import { DateRange } from "../../../../data/date-range";
import IconCalendar from "../../../../dynamicicons/icon-calendar";
import IconVelavuDown from "../../../../dynamicicons/icon-velavu-down";
import IconVelavuUp from "../../../../dynamicicons/icon-velavu-up";
import DateRangeSelector from "../../../../elements/date-range-selector";
import Divider from "../../../../elements/divider";
import { formatDateRange } from "../../../../helper/language-helper";
import styles from "./date-picker.module.scss";

interface DatePickerProps {
	range: DateRange;
	onUpdate: (range: DateRange) => void;

	sortNew: boolean;
	setSortNew: (sortNew: boolean) => void;
}

export default function DatePicker(props: DatePickerProps) {
	const [showCalendar, setShowCalendar] = useState(false);

	const dateDisplay = formatDateRange(props.range);

	return (
		<div>
			<div className={styles.header}>
				<div
					className={styles.headerItem}
					onClick={() => setShowCalendar(!showCalendar)}
				>
					<IconCalendar size={16} color="unset" />
					<span
						style={{ marginLeft: 16 }}
						className={styles.headerLabel}
					>
						{dateDisplay}
					</span>
				</div>

				<div
					className={styles.headerItem}
					onClick={() => props.setSortNew(!props.sortNew)}
				>
					{props.sortNew ? (
						<IconVelavuUp size={16} color="unset" />
					) : (
						<IconVelavuDown size={16} color="unset" />
					)}
					<span
						style={{ marginLeft: 4 }}
						className={styles.headerLabel}
					>
						Sort
					</span>
				</div>
			</div>

			<Divider
				className={`${styles.calenderDivider} ${
					showCalendar
						? styles.calenderDividerVisible
						: styles.calenderDividerHidden
				}`}
			/>

			<div
				className={`${styles.calendar} ${
					showCalendar
						? styles.calenderExpanded
						: styles.calendarCollapsed
				}`}
			>
				<DateRangeSelector
					range={props.range}
					onUpdate={props.onUpdate}
					onClose={() => setShowCalendar(false)}
				/>
			</div>
		</div>
	);
}
