import React, { CSSProperties } from "react";

export default function IconRefresh(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 16}
			height={props.size ?? 16}
			viewBox="0 0 16 16"
		>
			<path
				d="M8.00002 3.99868V5.19201C8.00002 5.49201 8.36002 5.63868 8.56669 5.42534L10.4267 3.56534C10.56 3.43201 10.56 3.22534 10.4267 3.09201L8.56669 1.23201C8.51969 1.18594 8.46011 1.15482 8.39545 1.14255C8.33079 1.13029 8.26395 1.13745 8.20335 1.16311C8.14275 1.18878 8.0911 1.23181 8.05492 1.28678C8.01874 1.34175 7.99963 1.4062 8.00002 1.47201V2.66534C5.05335 2.66534 2.66669 5.05201 2.66669 7.99868C2.66669 8.69201 2.80002 9.35868 3.04669 9.96534C3.22669 10.412 3.80002 10.532 4.14002 10.192C4.32002 10.012 4.39335 9.73868 4.29335 9.49868C4.10002 9.03868 4.00002 8.52534 4.00002 7.99868C4.00002 5.79201 5.79335 3.99868 8.00002 3.99868ZM11.86 5.80534C11.68 5.98534 11.6067 6.26534 11.7067 6.49868C11.8934 6.96534 12 7.47201 12 7.99868C12 10.2053 10.2067 11.9987 8.00002 11.9987V10.8053C8.00002 10.5053 7.64002 10.3587 7.43335 10.572L5.57335 12.432C5.44002 12.5653 5.44002 12.772 5.57335 12.9053L7.43335 14.7653C7.47994 14.811 7.53892 14.842 7.60296 14.8545C7.667 14.867 7.7333 14.8604 7.79364 14.8356C7.85397 14.8107 7.90569 14.7687 7.94237 14.7148C7.97906 14.6608 7.9991 14.5973 8.00002 14.532V13.332C10.9467 13.332 13.3334 10.9453 13.3334 7.99868C13.3334 7.30534 13.2 6.63868 12.9534 6.03201C12.7734 5.58534 12.2 5.46534 11.86 5.80534Z"
				fill={props.color ?? "#5F718C"}
			/>
		</svg>
	);
}
