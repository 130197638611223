import React, { useCallback, useContext, useEffect, useState } from "react";
import {
	DeviceCategory,
	expandDeviceHardware,
	NormalizedDeviceHardware,
	OrganizationPreferences,
	ScannerMode,
	VelavuAPI,
	VelavuDevice,
	VelavuDeviceArda,
	VelavuDevicePavo,
	VelavuDeviceVesta,
	VelavuRule,
	VelavuRuleCreate,
	VelavuRuleUpdate,
} from "velavu-js-api";
import VelavuSwitchBlock from "../../elements/velavu-switch-block";
import IconAnchor from "../../dynamicicons/icon-anchor";
import IconBleTag from "../../dynamicicons/icon-ble-tag";
import IconGPSTag from "../../dynamicicons/icon-gps-tag";
import VelavuNotice from "../../elements/velavu-notice";
import VelavuSelect from "../../elements/velavu-select";
import SignInContext from "../../sign-in-context";
import ComponentSaveBar from "./component-save-bar";
import styles from "./settings-devices.module.scss";

interface DeviceFrequencies {
	positioning?: number;
	environment?: number;
}

export default function SettingsDevices(props: {
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
}) {
	const userContext = useContext(SignInContext);
	const organizationPreferences = userContext.organization?.settings;
	const updateOrganization = userContext.updateOrganization;

	const [originalGPSFrequencies, setOriginalGPSFrequencies] = useState<
		DeviceFrequencies | undefined
	>(undefined);
	const [gpsFrequencies, setGPSFrequencies] = useState<
		DeviceFrequencies | undefined
	>(undefined);

	const [originalBleFrequencies, setOriginalBleFrequencies] = useState<
		DeviceFrequencies | undefined
	>(undefined);
	const [bleFrequencies, setBleFrequencies] = useState<
		DeviceFrequencies | undefined
	>(undefined);

	const [originalAnchorFrequencies, setOriginalAnchorFrequencies] = useState<
		DeviceFrequencies | undefined
	>(undefined);
	const [anchorFrequencies, setAnchorFrequencies] = useState<
		DeviceFrequencies | undefined
	>(undefined);

	const [originalInventoryEnabled, setOriginalInventoryEnabled] =
		useState(false);
	const [inventoryEnabled, setInventoryEnabled] = useState(false);

	const [isLoading, setLoading] = useState(false);
	const [lastSaveResult, setLastSaveResult] = useState<boolean | null>(null);

	const propsSetDirty = props.setDirty;

	/**
	 * Gets a rule from the backend. If the rule doesn't exist, this function throws.
	 */
	const getRule = useCallback(
		async <Device extends VelavuDevice = VelavuDevice>(
			id: string,
		): Promise<VelavuRule<Device>> => {
			return VelavuAPI.rules.getSpecificRule(id);
		},
		[],
	);

	/**
	 * Registers a new rule with the backend.
	 */
	const createRule = useCallback(
		async <Device extends VelavuDevice = VelavuDevice>(
			rule: VelavuRuleCreate<Device>,
		): Promise<VelavuRule<Device>> => {
			return VelavuAPI.rules.createNewRule(rule);
		},
		[],
	);

	/**
	 * Updates an existing rule on the backend. If the rule doesn't exist, this function throws.
	 */
	const updateRule = useCallback(
		async <Device extends VelavuDevice = VelavuDevice>(
			rule: VelavuRuleUpdate<Device>,
			id: string,
		): Promise<VelavuRule<Device>> => {
			return VelavuAPI.rules.modifyExistingRule(id, rule);
		},
		[],
	);

	/**
	 * Compares two frequencies to see if they are not equivalent for the given device type
	 * @param hardware The device hardware to account for
	 * @param a The first frequency to compare
	 * @param b The second frequency to compare
	 * @return Whether the frequencies are effectively not equivalent for the given device type
	 */
	const compareFrequencies = useCallback(
		(
			hardware: NormalizedDeviceHardware,
			a?: DeviceFrequencies,
			b?: DeviceFrequencies,
		): boolean => {
			switch (hardware) {
				case NormalizedDeviceHardware.Argo:
					return !(a?.positioning === b?.positioning);
				case NormalizedDeviceHardware.Juno:
				case NormalizedDeviceHardware.Meridian:
					return !(
						a?.positioning === b?.positioning &&
						a?.environment === b?.environment
					);
				default:
					return false;
			}
		},
		[],
	);

	/**
	 * Uploads all changes to the backend and updates the UI
	 */
	const saveChanges = useCallback(async () => {
		const newPreferences: OrganizationPreferences = {
			rules: {},
		};

		const updateRuleAsync = async <
			Device extends VelavuDevice = VelavuDevice,
		>(
			rule: VelavuRuleCreate<Device>,
			ruleId: string | undefined,
		): Promise<string | undefined> => {
			if (ruleId) {
				try {
					await updateRule(rule, ruleId);
				} catch (error: any) {
					if (error.response.status === 404) {
						const newRule = await createRule(rule);
						return newRule.id;
					} else {
						throw Error(error);
					}
				}
			} else {
				const newRule = await createRule(rule);
				return newRule.id;
			}
		};

		try {
			// GPS tag frequencies have changed
			if (
				compareFrequencies(
					NormalizedDeviceHardware.Argo,
					originalGPSFrequencies,
					gpsFrequencies,
				)
			) {
				const rule: VelavuRuleCreate<VelavuDeviceVesta> = {
					condition: createDeviceHardwareCondition(
						NormalizedDeviceHardware.Argo,
					),
					action: {
						config: {
							positioning: {
								fixed_rate_s: gpsFrequencies!.positioning!,
								dynamic_mode: gpsFrequencies?.positioning === 0,
							},
						},
					},
				};

				const newRuleId = await updateRuleAsync(
					rule,
					organizationPreferences?.rules?.vesta,
				);
				if (newRuleId) {
					newPreferences.rules!.vesta = newRuleId;
				}
			}

			// BLE tag frequencies have changed
			if (
				compareFrequencies(
					NormalizedDeviceHardware.Juno,
					originalBleFrequencies,
					bleFrequencies,
				)
			) {
				const rule: VelavuRuleCreate<VelavuDeviceArda> = {
					condition: createDeviceHardwareCondition(
						NormalizedDeviceHardware.Juno,
					),
					action: {
						config: {
							env_sensor_rate_s: bleFrequencies!.environment!,
							positioning: {
								fixed_rate_s: bleFrequencies!.positioning!,
								dynamic_mode: bleFrequencies?.positioning === 0,
							},
						},
					},
				};

				const newRuleId = await updateRuleAsync(
					rule,
					organizationPreferences?.rules?.arda,
				);
				if (newRuleId) {
					newPreferences.rules!.arda = newRuleId;
				}
			}

			// Anchor frequencies have changed
			if (
				compareFrequencies(
					NormalizedDeviceHardware.Meridian,
					originalAnchorFrequencies,
					anchorFrequencies,
				)
			) {
				const rule: VelavuRuleCreate<VelavuDevicePavo> = {
					condition: createDeviceHardwareCondition(
						NormalizedDeviceHardware.Meridian,
					),
					action: {
						config: {
							env_sensor_rate_s: anchorFrequencies!.environment!,
							positioning: {
								fixed_rate_s: anchorFrequencies!.positioning!,
								dynamic_mode:
									anchorFrequencies?.positioning === 0,
							},
						},
					},
				};

				const newRuleId = await updateRuleAsync(
					rule,
					organizationPreferences?.rules?.pavo,
				);
				if (newRuleId) {
					newPreferences.rules!.pavo = newRuleId;
				}
			}

			// Update enabling inventory
			if (originalInventoryEnabled !== inventoryEnabled) {
				const rules: VelavuRuleCreate<VelavuDevice>[] = [
					{
						condition: createDeviceHardwareCondition(
							NormalizedDeviceHardware.Argo,
						),
						action: {
							config: {
								scanner: {
									mode: inventoryEnabled
										? ScannerMode.Continuous
										: ScannerMode.Disabled,
									fixed_rate_s: inventoryEnabled ? 1800 : 0,
								},
							},
						},
					},
					{
						condition: `${createDeviceHardwareCondition(
							NormalizedDeviceHardware.Juno,
						)} OR ${createDeviceHardwareCondition(
							NormalizedDeviceHardware.Meridian,
						)} AND category == ${DeviceCategory.Tag}`,
						action: {
							config: {
								beacon: {
									interval_ms: inventoryEnabled ? 1000 : 0,
									tx_power: 0,
								},
							},
						},
					},
				];

				const newRuleIds = await Promise.all([
					updateRuleAsync(
						rules[0],
						organizationPreferences?.rules?.inventory?.[0],
					),
					updateRuleAsync(
						rules[1],
						organizationPreferences?.rules?.inventory?.[1],
					),
				]);
				if (newRuleIds[0] && newRuleIds[1]) {
					newPreferences.rules!.inventory = [
						newRuleIds[0],
						newRuleIds[1],
					];
				}
			}

			await updateOrganization({ settings: newPreferences });

			setLastSaveResult(true);
		} catch (error) {
			console.log(error);
			setLastSaveResult(false);
		} finally {
			propsSetDirty(false);
			setLoading(false);
		}
	}, [
		compareFrequencies,
		gpsFrequencies,
		originalGPSFrequencies,
		bleFrequencies,
		originalBleFrequencies,
		anchorFrequencies,
		originalAnchorFrequencies,
		organizationPreferences?.rules,
		originalInventoryEnabled,
		inventoryEnabled,
		updateOrganization,
		propsSetDirty,
		updateRule,
		createRule,
	]);

	/**
	 * Callback to update local values when the user makes changes
	 */
	const onChange = useCallback(
		(name: string, positioning?: number, environment?: number) => {
			switch (name) {
				case "Vesta":
					setGPSFrequencies({ positioning: positioning });
					break;
				case "Arda":
					if (positioning !== undefined) {
						setBleFrequencies((bleFrequencies) => ({
							positioning: positioning,
							environment: bleFrequencies?.environment,
						}));
					}
					if (environment !== undefined) {
						setBleFrequencies((bleFrequencies) => ({
							positioning: bleFrequencies?.positioning,
							environment: environment,
						}));
					}
					break;
				case "Pavo":
					if (positioning !== undefined) {
						setAnchorFrequencies((anchorFrequencies) => ({
							positioning: positioning,
							environment: anchorFrequencies?.environment,
						}));
					}
					if (environment !== undefined) {
						setAnchorFrequencies((anchorFrequencies) => ({
							positioning: anchorFrequencies?.positioning,
							environment: environment,
						}));
					}
					break;
			}
		},
		[setGPSFrequencies, setBleFrequencies, setAnchorFrequencies],
	);

	/**
	 * Update the dirty state when the user makes changes
	 */
	useEffect(() => {
		propsSetDirty(
			originalInventoryEnabled !== inventoryEnabled ||
				compareFrequencies(
					NormalizedDeviceHardware.Argo,
					originalGPSFrequencies,
					gpsFrequencies,
				) ||
				compareFrequencies(
					NormalizedDeviceHardware.Juno,
					originalBleFrequencies,
					bleFrequencies,
				) ||
				compareFrequencies(
					NormalizedDeviceHardware.Meridian,
					originalAnchorFrequencies,
					anchorFrequencies,
				),
		);
	}, [
		propsSetDirty,
		gpsFrequencies,
		originalGPSFrequencies,
		anchorFrequencies,
		originalAnchorFrequencies,
		bleFrequencies,
		originalBleFrequencies,
		compareFrequencies,
		inventoryEnabled,
		originalInventoryEnabled,
	]);

	/**
	 * Updates the state to the provided frequencies
	 * for the provided device type
	 */
	const setFrequencies = useCallback(
		(name: string, environment?: number, positioning?: number) => {
			switch (name) {
				case "Vesta":
					setOriginalGPSFrequencies({
						positioning: positioning,
						environment: environment,
					});
					setGPSFrequencies({
						positioning: positioning,
						environment: environment,
					});
					break;
				case "Arda":
					setOriginalBleFrequencies({
						positioning: positioning,
						environment: environment,
					});
					setBleFrequencies({
						positioning: positioning,
						environment: environment,
					});
					break;
				case "Pavo":
					setOriginalAnchorFrequencies({
						positioning: positioning,
						environment: environment,
					});
					setAnchorFrequencies({
						positioning: positioning,
						environment: environment,
					});
					break;
			}
		},
		[
			setOriginalGPSFrequencies,
			setGPSFrequencies,
			setOriginalBleFrequencies,
			setBleFrequencies,
			setOriginalAnchorFrequencies,
			setAnchorFrequencies,
		],
	);

	/**
	 * Loads initial state from the backend
	 */
	useEffect(() => {
		if (!organizationPreferences?.rules?.vesta) {
			setFrequencies("Vesta", 600, 600);
		} else {
			getRule<VelavuDeviceVesta>(organizationPreferences.rules.vesta)
				.then((rule) =>
					setFrequencies(
						"Vesta",
						undefined,
						rule.action.config?.positioning?.fixed_rate_s,
					),
				)
				.catch((error) => {
					console.log(error);
					setFrequencies("Vesta", 600, 600);
				});
		}

		if (!organizationPreferences?.rules?.arda) {
			setFrequencies("Arda", 600, 600);
		} else {
			getRule<VelavuDeviceArda>(organizationPreferences.rules.arda)
				.then((rule) =>
					setFrequencies(
						"Arda",
						rule.action.config?.env_sensor_rate_s,
						rule.action.config?.positioning?.fixed_rate_s,
					),
				)
				.catch((error) => {
					console.log(error);
					setFrequencies("Arda", 600, 600);
				});
		}

		if (!organizationPreferences?.rules?.pavo) {
			setFrequencies("Pavo", 600, 600);
		} else {
			getRule<VelavuDevicePavo>(organizationPreferences.rules.pavo)
				.then((rule) =>
					setFrequencies(
						"Pavo",
						rule.action.config?.env_sensor_rate_s,
						rule.action.config?.positioning?.fixed_rate_s,
					),
				)
				.catch((error) => {
					console.log(error);
					setFrequencies("Pavo", 600, 600);
				});
		}

		if (!organizationPreferences?.rules?.inventory) {
			setOriginalInventoryEnabled(false);
			setInventoryEnabled(false);
		} else {
			Promise.all([
				getRule<VelavuDevice>(
					organizationPreferences.rules.inventory[0],
				),
				getRule<VelavuDevice>(
					organizationPreferences.rules.inventory[1],
				),
			])
				.then((rules) => {
					if (rules[0].action.config?.scanner?.mode !== "DISABLED") {
						setOriginalInventoryEnabled(true);
						setInventoryEnabled(true);
					} else {
						setOriginalInventoryEnabled(false);
						setInventoryEnabled(false);
					}
				})
				.catch((error) => {
					console.log(error);
					setOriginalInventoryEnabled(false);
					setInventoryEnabled(false);
				});
		}
	}, [organizationPreferences, getRule, setFrequencies]);

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Update Frequency</h1>
			<span className={styles.info}>
				Define how often velavu devices will send information.
			</span>

			<VelavuNotice
				className={styles.notice}
				type="info"
				body="Higher frequency updates will result in shorter battery life. Selecting lower frequency updates will prolong the lifetime of your devices."
			/>

			<Device
				name="Vesta"
				frequencies={gpsFrequencies}
				onChange={onChange}
			/>

			<Device
				name="Arda"
				frequencies={bleFrequencies}
				onChange={onChange}
			/>

			<Device
				name="Pavo"
				frequencies={anchorFrequencies}
				onChange={onChange}
			/>

			<h1 className={styles.title}>Inventory</h1>
			<VelavuSwitchBlock
				toggled={inventoryEnabled}
				onChange={() => setInventoryEnabled(!inventoryEnabled)}
				label={"Enable Inventory on devices"}
			/>
			<VelavuNotice
				className={styles.notice}
				type="info"
				body="Enabling inventory will result in slightly shorter battery life of devices. Only enable if you intend to use this feature."
			/>

			<ComponentSaveBar
				disabled={!props.isDirty || isLoading}
				result={lastSaveResult}
				labelOK="Device settings saved"
				labelError="Failed to save device settings"
				onClick={() => {
					setLoading(true);
					saveChanges();
				}}
			/>
		</div>
	);
}

function Device(props: {
	name: string;
	frequencies?: DeviceFrequencies;
	onChange: (
		name: string,
		positioning?: number,
		environment?: number,
	) => void;
}) {
	return (
		<>
			<div className={styles.section}>
				<DeviceIcon name={props.name} />
				<h2 className={styles.sectionHeader}>{props.name}</h2>
			</div>

			<div className={styles.grid}>
				{(props.name === "Arda" || props.name === "Pavo") && (
					<div className={styles.environment}>
						<h2 className={styles.columnHeader}>
							Environment data
						</h2>
						<VelavuSelect
							className={styles.select}
							value={props.frequencies?.environment}
							onChange={(value) =>
								props.onChange(props.name, undefined, +value)
							}
							fullWidth
						>
							<option value={0}>Off</option>
							<option value={60}>1 min</option>
							<option value={300}>5 min</option>
							<option value={600}>10 min</option>
							<option value={900}>15 min</option>
							<option value={1800}>30 min</option>
							<option value={3600}>1 hr</option>
							<option value={10800}>3 hr</option>
							<option value={21600}>6 hr</option>
							<option value={43200}>12 hr</option>
							<option value={86400}>24 hr</option>
						</VelavuSelect>
					</div>
				)}
				<div className={styles.positioning}>
					<h2 className={styles.columnHeader}>Positioning data</h2>
					<VelavuSelect
						className={styles.select}
						value={props.frequencies?.positioning}
						onChange={(value) => {
							props.onChange(props.name, +value, undefined);
						}}
						fullWidth
					>
						<option value={60}>1 min</option>
						<option value={300}>5 min</option>
						<option value={600}>10 min</option>
						<option value={900}>15 min</option>
						<option value={1800}>30 min</option>
						<option value={3600}>1 hr</option>
						<option value={10800}>3 hr</option>
						<option value={21600}>6 hr</option>
						<option value={43200}>12 hr</option>
						<option value={86400}>24 hr</option>
						<option value={0}>Dynamic (based on movement)</option>
					</VelavuSelect>
				</div>
			</div>
		</>
	);
}

/**
 * Gets an icon to represent a specific device hardware
 */
function DeviceIcon(props: { name: string; color?: string }) {
	const defaultColor = "#3A58E2";

	switch (props.name) {
		case "Vesta":
			return <IconGPSTag color={props.color ?? defaultColor} />;
		case "Arda":
			return <IconBleTag color={props.color ?? defaultColor} />;
		case "Pavo":
			return <IconAnchor color={props.color ?? defaultColor} />;
		default:
			return null;
	}
}

function createDeviceHardwareCondition(
	hardware: NormalizedDeviceHardware,
): string {
	return expandDeviceHardware(hardware)
		.map((it) => `hardware == ${it}`)
		.join(" OR ");
}
