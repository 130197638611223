import React, { CSSProperties } from "react";
import globals from "../../src/styles/global.scss";
import { StyleableIcon } from "../helper/icon-helper";
import { classArr } from "../helper/style-helper";
import styles from "./flat-button.module.scss";

/**
 * A small, narrow button that blends in with the background
 */
export default function FlatButton(props: {
	style?: CSSProperties;
	className?: string;

	icon?: StyleableIcon;
	label: string;
	onClick?: VoidFunction;
}) {
	return (
		<button
			style={props.style}
			className={classArr(styles.button, props.className)}
			onClick={props.onClick}
		>
			{props.icon?.(16, globals.textSecondary)}
			<span
				className={classArr(
					styles.label,
					props.icon !== undefined && styles.labelIcon,
				)}
			>
				{props.label}
			</span>
		</button>
	);
}
