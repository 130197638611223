import React, { CSSProperties } from "react";

export default function IconAnchor(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16 10H8C6.89543 10 6 10.8954 6 12C6 13.1046 6.89543 14 8 14H16C17.1046 14 18 13.1046 18 12C18 10.8954 17.1046 10 16 10ZM8 8C5.79086 8 4 9.79086 4 12C4 14.2091 5.79086 16 8 16H16C18.2091 16 20 14.2091 20 12C20 9.79086 18.2091 8 16 8H8Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
