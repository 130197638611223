import { updatePassword } from "aws-amplify/auth";
import React, {
	FormEvent,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";
import { minPasswordLength, passwordRequirementsNotice } from "../../constants";
import IconDone from "../../dynamicicons/icon-done";
import VelavuButton from "../../elements/velavu-button";
import VelavuInput from "../../elements/velavu-input";
import VelavuNotice from "../../elements/velavu-notice";
import SignInContext from "../../sign-in-context";
import styles from "./change-password-modal.module.scss";

export default function ChangePasswordModal(props: { onClose: VoidFunction }) {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

	const [newPasswordError, setNewPasswordError] = useState(false);
	const [newPasswordConfirmError, setNewPasswordConfirmError] =
		useState(false);

	const [isLoading, setLoading] = useState(false);
	const [result, setResult] = useState<
		{ success: boolean; description: string } | undefined
	>(undefined);

	const currentPasswordOK = currentPassword.length > 0;
	const newPasswordOK = newPassword.length >= minPasswordLength;
	const newPasswordOKDisplay = newPassword.length === 0 || newPasswordOK;
	const newPasswordConfirmOK =
		newPasswordConfirm.length > 0 && newPassword === newPasswordConfirm;
	const newPasswordConfirmOKDisplay =
		newPasswordConfirm.length === 0 || newPasswordConfirmOK;

	const submitPassword = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			//Ignoring if the state is invalid
			if (
				!(currentPasswordOK && newPasswordOK && newPasswordConfirmOK) ||
				isLoading
			)
				return;

			//Setting the view as loading
			setLoading(true);

			//Submitting the password change
			updatePassword({
				oldPassword: currentPassword,
				newPassword: newPassword,
			})
				.then(() => {
					setResult({
						success: true,
						description: "Your password has been changed",
					});
				})
				.catch((error) => {
					console.log(error);
					setResult({ success: false, description: error.message });
				})
				.finally(() => setLoading(false));
		},
		[
			currentPasswordOK,
			newPasswordOK,
			newPasswordConfirmOK,
			isLoading,
			currentPassword,
			newPassword,
		],
	);

	const checkNewPasswordError = useCallback(() => {
		//Set new password error on blur
		if (!newPasswordOKDisplay) setNewPasswordError(true);
	}, [newPasswordOKDisplay, setNewPasswordError]);

	useEffect(() => {
		//Clear new password error on edit
		if (newPasswordError && newPasswordOKDisplay) {
			setNewPasswordError(false);
		}
	}, [newPasswordError, newPasswordOKDisplay, setNewPasswordError]);

	const checkNewPasswordConfirmError = useCallback(() => {
		//Set new password confirm error on blur
		if (!newPasswordConfirmOKDisplay) setNewPasswordConfirmError(true);
	}, [newPasswordConfirmOKDisplay, setNewPasswordConfirmError]);

	useEffect(() => {
		//Clear new password confirm error on edit
		if (newPasswordConfirmError && newPasswordConfirmOKDisplay) {
			setNewPasswordConfirmError(false);
		}
	}, [
		newPasswordConfirmError,
		newPasswordConfirmOKDisplay,
		setNewPasswordConfirmError,
	]);

	return (
		<form className={styles.container} onSubmit={submitPassword}>
			<span className={styles.title}>Change password</span>
			<VelavuInput
				value={currentPassword}
				onChange={setCurrentPassword}
				type="password"
				titleText="Current password"
				placeholder="Type current password"
				autoComplete="current-password"
				disabled={isLoading}
				fullWidth
				bottomPadding
			/>

			<VelavuInput
				value={newPassword}
				onChange={setNewPassword}
				onBlur={checkNewPasswordError}
				type="password"
				titleText="New password"
				placeholder="Type new password"
				autoComplete="new-password"
				disabled={isLoading}
				error={newPasswordError}
				trailingIcon={
					newPasswordOK ? (
						<IconDone color="#9DE29C" size={16} />
					) : undefined
				}
				fullWidth
				bottomPadding
			/>

			<VelavuInput
				value={newPasswordConfirm}
				onChange={setNewPasswordConfirm}
				onBlur={checkNewPasswordConfirmError}
				type="password"
				titleText="Confirm new password"
				placeholder="Retype new password"
				autoComplete="new-password"
				disabled={isLoading}
				error={newPasswordConfirmError}
				trailingIcon={
					newPasswordConfirmOK ? (
						<IconDone color="#9DE29C" size={16} />
					) : undefined
				}
				fullWidth
				bottomPadding
			/>

			<VelavuNotice type="info" body={passwordRequirementsNotice} />

			<div className={styles.buttonBar}>
				<VelavuButton
					label="Cancel"
					onClick={props.onClose}
					outlined
					fullWidth
					submit={false}
				/>

				<div className={styles.buttonBarSpacer} />

				<VelavuButton
					label="Save password"
					disabled={
						!(
							currentPasswordOK &&
							newPasswordOK &&
							newPasswordConfirmOK
						) || isLoading
					}
					fullWidth
					submit
				/>
			</div>

			{result && (
				<VelavuNotice
					className={styles.statusNotice}
					type={result.success ? "confirm" : "error"}
					title={
						result.success
							? "Password changed"
							: "Password change failed"
					}
					body={result.description}
				/>
			)}
		</form>
	);
}
