import React, { ChangeEvent, CSSProperties, useCallback } from "react";
import styles from "./velavu-slider.module.scss";
import { classArr } from "../helper/style-helper";

type VelavuSliderProps = {
	style?: CSSProperties;
	className?: string;

	min: number;
	max: number;
	value: number;
	step?: number | string;
	onChange?: (value: number) => void;
} & (
	| {
			variant?: undefined | "plain";
	  }
	| {
			variant: "marked";
	  }
	| {
			variant: "segmented";
			segments: number[];
	  }
);

const backgroundColorPlain = "#E2E4E5";
const backgroundColorSegmented = "#CCD6E5";
const fillColor = "#3A58E2";

export default function VelavuSlider(props: VelavuSliderProps) {
	const propsOnChange = props.onChange;
	const updateValue = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			const value = parseInt(event.currentTarget.value);
			if (propsOnChange) propsOnChange(value);
		},
		[propsOnChange],
	);

	const backgroundColor =
		props.variant === "segmented"
			? backgroundColorSegmented
			: backgroundColorPlain;
	const valuePercent =
		((props.value - props.min) / (props.max - props.min)) * 100;
	return (
		<div className={classArr(styles.container, props.className)}>
			<input
				style={{
					background: `linear-gradient(to right, ${fillColor} 0%, ${fillColor} ${valuePercent}%, ${backgroundColor} ${valuePercent}%, ${backgroundColor} 100%)`,
				}}
				className={classArr(
					styles.slider,
					props.variant === "segmented"
						? styles.sliderTall
						: styles.sliderRound,
				)}
				type="range"
				min={props.min}
				max={props.max}
				step={props.step}
				value={props.value}
				onChange={updateValue}
			></input>

			{props.variant === "marked" && (
				<div className={styles.markContainer}>
					{[...Array(props.max - props.min + 1)].map(
						(element, index) => {
							let classStyle: string;

							if (index > props.value) {
								classStyle = styles.markAfter;
							} else if (index < props.value) {
								classStyle = styles.markBefore;
							} else {
								classStyle = styles.markSelected;
							}

							return (
								<span
									key={index}
									className={classArr(
										styles.mark,
										classStyle,
									)}
								/>
							);
						},
					)}
				</div>
			)}

			{props.variant === "segmented" && (
				<div className={styles.segmentContainer}>
					{props.segments.map((segment, index) => {
						let classStyle: string;

						if (segment > props.value) {
							classStyle = styles.segmentBefore;
						} else {
							classStyle = styles.segmentAfter;
						}

						return (
							<span
								key={index}
								style={{
									left: `${
										((segment - props.min) /
											(props.max - props.min)) *
										100
									}%`,
								}}
								className={classArr(styles.segment, classStyle)}
							/>
						);
					})}
				</div>
			)}
		</div>
	);
}
