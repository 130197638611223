import React, { CSSProperties } from "react";

export default function IconVelavuMap(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M19.5556 4L19.4133 4.02667L14.6667 5.86667L9.33333 4L4.32 5.68889C4.13333 5.75111 4 5.91111 4 6.11556V19.5556C4 19.8044 4.19556 20 4.44444 20L4.58667 19.9733L9.33333 18.1333L14.6667 20L19.68 18.3111C19.8667 18.2489 20 18.0889 20 17.8844V4.44444C20 4.19556 19.8044 4 19.5556 4ZM14.6667 18.2222L9.33333 16.3467V5.77778L14.6667 7.65333V18.2222Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
