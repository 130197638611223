import React, {
	CSSProperties,
	useCallback,
	useEffect,
	useRef,
	useState,
} from "react";
import IconAddImage from "../../../dynamicicons/icon-add-image";
import VelavuItemIcon from "../../../elements/velavu-item-icon";
import styles from "./asset-image-preview.module.scss";
import { AssetCategory } from "velavu-js-api";

interface AssetImagePreviewProps {
	style?: CSSProperties;
	category?: string;
	image?: string;
	setImage?: (url: string) => void;
}

export default function AssetImagePreview(props: AssetImagePreviewProps) {
	const [image, setImage] = useState<string | undefined>(undefined);
	const imageInput = useRef<HTMLInputElement>(null);

	const onChangeImage = useCallback(
		(event: React.FormEvent<HTMLInputElement>) => {
			const image = event.currentTarget.files![0];
			const imageURL = URL.createObjectURL(image);

			props.setImage?.(imageURL);
		},
		[props],
	);

	const renderImage = useCallback(() => {
		if (image) {
			return <img className={styles.image} src={image} alt="" />;
		} else if (!image && props.category) {
			if (props.category === "VEHICLE") {
				return <VelavuItemIcon data={AssetCategory.Vehicle} />;
			} else {
				return <VelavuItemIcon data={AssetCategory.Equipment} />;
			}
		} else {
			return (
				<button onClick={() => imageInput.current?.click()}>
					<IconAddImage
						className={styles.iconAddImage}
						color="#A3B3CC"
					/>
				</button>
			);
		}
	}, [image, props.category]);

	useEffect(() => {
		if (!props.image) return;

		setImage(props.image);
	}, [props.image]);

	return (
		<div className={styles.container} style={props.style}>
			<div className={styles.content}>{renderImage()}</div>
			<button
				style={{ marginLeft: 16 }}
				className={styles.imageButton}
				onClick={() => imageInput.current?.click()}
			>
				{image ? "Upload new photo" : "Upload photo"}
			</button>
			{image && (
				<div>
					<span className={styles.bulletPoint} />
					<button
						className={styles.imageButton}
						onClick={() => setImage(undefined)}
					>
						Remove photo
					</button>
				</div>
			)}
			<input
				className={styles.imageInput}
				ref={imageInput}
				onChange={onChangeImage}
				type="file"
				name="image"
				accept="image/png, image/jpeg"
			/>
		</div>
	);
}
