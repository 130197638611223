import React from "react";
import styles from "./velavu-radio.module.scss";

//VelavuRadio is a simple non-interactive radio component for visibility purposes
export default function VelavuRadio(props: { checked: boolean }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			viewBox="0 0 16 16"
		>
			<circle
				cx="8"
				cy="8"
				r="6"
				stroke="#5F718C"
				strokeWidth="1.5"
				fill="none"
			/>
			<circle
				className={`${styles.circle} ${
					props.checked
						? styles.circleChecked
						: styles.circleUnchecked
				}`}
				cx="8"
				cy="8"
				r="3"
				fill="#5F718C"
			/>
		</svg>
	);
}
