import React, { CSSProperties } from "react";
import globals from "../../../src/styles/global.scss";
import { VelavuSite } from "velavu-js-api";
import IconPlace from "../../dynamicicons/icon-place";
import SelectableCard from "../../elements/selectable-card";
import { classArr } from "../../helper/style-helper";
import SiteSwitcherListItem from "./site-switcher-list-item";
import styles from "./site-switcher-list.module.scss";
import { NoSitesIcon } from "../../img/icons/icons";

function NoSites() {
	return (
		<div className={styles.emptyContent}>
			<NoSitesIcon />
			<span className={styles.heading}>No sites yet</span>
			<span className={styles.info}>
				Once you create a site, it will show up here.
			</span>
		</div>
	);
}

type SiteSwitcherListProps = {
	style?: CSSProperties;
	className?: string;

	sites: VelavuSite[];
} & (
	| {
			showGlobal: true;
			onSelectSite: (id: string | undefined) => void;
	  }
	| {
			showGlobal?: false;
			onSelectSite: (id: string) => void;
	  }
);

export default function SiteSwitcherList(props: SiteSwitcherListProps) {
	let result;
	if (!props.sites.length) {
		result = <NoSites />;
	} else {
		result = (
			<>
				{props.showGlobal && (
					<SelectableCard
						className={`${styles.item} ${styles.globalCard}`}
						onClick={() => props.onSelectSite(undefined)}
					>
						<IconPlace
							color={globals.accent}
							style={{ marginRight: 16 }}
						/>
						<span className={styles.globalLabel}>Global</span>
					</SelectableCard>
				)}

				{props.sites.map((site, index) => (
					<SiteSwitcherListItem
						key={site.id}
						className={styles.item}
						site={site}
						onClick={() => props.onSelectSite(site.id)}
					/>
				))}
			</>
		);
	}

	return (
		<div
			style={props.style}
			className={classArr(styles.list, props.className)}
		>
			{result}
		</div>
	);
}
