import mapboxgl, {
	Expression,
	GeoJSONSource,
	MapLayerMouseEvent,
} from "mapbox-gl";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AssetDetailTab } from "../../components/dashboard/dropdown/asset-detail/asset-detail";
import {
	LocationResourceProximity,
	LocationType,
	NormalizedDeviceHardware,
} from "velavu-js-api";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import DeviceMarker, {
	DeviceMarkerType,
	resolveDeviceMarkerCoordinates,
} from "../../data/device-marker";
import { Feature, Geometry } from "geojson";
import {
	buildDeviceEffectExpression,
	buildDeviceSymbolExpression,
	DeviceSymbolEffect,
} from "../../helper/map-device-symbols";
import { createVelavuMapboxPopup } from "../../mapbox-component/velavu-mapbox-popup";

const mapboxIDDevice = "asset";
const mapboxIDDeviceMarker = "asset-marker";
const mapboxIDDeviceOuter = "asset-outer"; //hover or focus
const mapboxIDDeviceInventoryIndicator = "asset-inventory-indicator";
const mapboxIDDeviceCluster = "asset-cluster";
const mapboxIDDeviceClusterText = "asset-cluster-text";
const mapboxIDDeviceRadius = "asset-radius";

const iconSizeDefault = 0.7;
const iconSizeLarge = 0.7;

const featureStateKeyHoverInventory: string = "hover-inventory";

const notCluster: Expression = ["!", ["has", "cluster_count"]];
const isCluster: Expression = [
	"all",
	["has", "cluster_count"],
	[">", ["get", "cluster_count"], 0],
];

const zoomFilter: Expression = [
	"any",
	["!", ["has", "displayZoomThreshold"]],
	[">=", ["zoom"], ["get", "displayZoomThreshold"]],
];

interface MapboxInjectableDevicesParams {
	mapInstance: MapHelperInstance;
	devicesSource?: DeviceMarker[];
	hoveredDeviceID?: string;
	selectedDeviceID?: string;
	onSelectAsset?: (id: string, tab: AssetDetailTab) => void;
	onSelectAnchor?: (id: string) => void;
	animateMovement?: boolean;
	prioritizedDeviceIDs?: string[];
	belowLayer?: string;
}

interface InternalDeviceMarker extends DeviceMarker {
	coordinates: [number, number];
}

interface MarkerProperties {
	markerType: DeviceMarkerType;
	deviceID: string;
	inSite: boolean;
	online: boolean | undefined;
	hardware: NormalizedDeviceHardware | undefined;
	name: string;
	coordinates: [number, number];
	lat: number;
	type: LocationType;
	hasInventoryActions: boolean;
	distance: number;
	prioritized: number;
	displayZoomThreshold: number | undefined;
}

export default function useMapboxInjectableDevices(
	params: MapboxInjectableDevicesParams,
): string {
	const {
		animateMovement,
		devicesSource,
		hoveredDeviceID,
		mapInstance,
		onSelectAnchor,
		onSelectAsset,
		selectedDeviceID,
		prioritizedDeviceIDs,
	} = params;

	const [devices, setDevices] = useState<
		InternalDeviceMarker[] | undefined
	>();

	const [hoverID, setHoverID] = useState<string | undefined>(undefined);
	const focusIDs = useRef<string[]>([]); // save hoveredDeviceID & selectedDeviceID in ref
	const hoverCountBubbleId = useRef<string | number | undefined>(undefined);

	const hiddenDevices = useMemo((): Set<string> => {
		const hiddenDevices = new Set<string>();

		if (devicesSource === undefined) {
			return hiddenDevices;
		}

		for (const device of devicesSource) {
			const location = device.location;
			if (location.location_type !== LocationType.Proximity) {
				continue;
			}

			//If this device is selected, hide the corresponding device
			if (selectedDeviceID === device.deviceID) {
				hiddenDevices.add(location.device_id);
			}
			//Otherwise if the corresponding device exists, hide this device
			else if (
				devicesSource.some((it) => it.deviceID === location.device_id)
			) {
				hiddenDevices.add(device.deviceID);
			}
		}

		return hiddenDevices;
	}, [devicesSource, selectedDeviceID]);

	//Sync the devices state with the devicesSource parameter
	const updateDevices = useCallback(
		(time: number) => {
			const newDevices = devicesSource
				?.filter((device) => !hiddenDevices.has(device.deviceID))
				?.map((device): InternalDeviceMarker => {
					let coordinates: [number, number];
					if (animateMovement) {
						coordinates = resolveDeviceMarkerCoordinates(
							device,
							time,
						);
					} else {
						coordinates = device.location.coordinates;
					}

					return {
						...device,
						coordinates: coordinates,
					};
				});
			setDevices(newDevices);
		},
		[animateMovement, devicesSource, setDevices, hiddenDevices],
	);

	useEffect(() => {
		updateDevices(performance.now());
	}, [devicesSource, updateDevices]);

	//Resolve whether we have any devices that are in an active animation state
	const needsAnimationLoop = useMemo(() => {
		const currentTime = performance.now();

		return (
			devices?.some((device) => {
				const anim = device.anim;
				return anim !== undefined && currentTime < anim.endDate;
			}) ?? false
		);
	}, [devices]);

	//Call updateDevices() every frame while we have devices that are animating
	useEffect(() => {
		if (!animateMovement || !needsAnimationLoop) {
			return;
		}

		let requestID: number;

		const loop = (time: number) => {
			updateDevices(time);
			requestID = requestAnimationFrame(loop);
		};
		requestID = requestAnimationFrame(loop);

		return () => {
			cancelAnimationFrame(requestID);
		};
	}, [animateMovement, needsAnimationLoop, updateDevices]);

	useStyleSetupMapEffect(mapInstance, (_, box) => {
		const metersToPixelsAtMaxZoom = (meters: any) => [
			"/",
			meters,
			[
				"/",
				78271.484 / 2 ** 23,
				["cos", ["*", ["get", "lat"], Math.PI / 180]],
			],
		];

		box.addSource(mapboxIDDevice, {
			type: "geojson",
			data: geoJSONBlankCollection,
			generateId: true,
			// cluster: true,
			clusterMaxZoom: 14,
			clusterRadius: 100,
			clusterProperties: {
				cluster_count: ["+", ["case", ["get", "inSite"], 1, 0]],
			},
		});

		box.addLayer(
			{
				id: mapboxIDDeviceMarker,
				type: "symbol",
				source: mapboxIDDevice,
				layout: {
					"icon-image": buildDeviceSymbolExpression(
						["get", "hardware"],
						["get", "online"],
						["==", ["get", "type"], LocationType.Fixed],
					),
					"icon-allow-overlap": true,
					"icon-size": iconSizeDefault,
					"symbol-sort-key": [
						"match",
						["get", "type"],
						LocationType.GPS,
						1,
						0,
					],
				},
				filter: zoomFilter,
			},
			params.belowLayer,
		);
		box.addLayer(
			{
				id: mapboxIDDeviceOuter,
				type: "symbol",
				source: mapboxIDDevice,
				layout: {
					"icon-image": buildDeviceEffectExpression(
						["get", "hardware"],
						DeviceSymbolEffect.Hover,
					),
					"icon-allow-overlap": true,
					"icon-size": iconSizeLarge,
					"symbol-sort-key": [
						"match",
						["get", "type"],
						LocationType.GPS,
						1,
						0,
					],
				},
				filter: zoomFilter,
			},
			mapboxIDDeviceMarker,
		);
		box.addLayer(
			{
				id: mapboxIDDeviceRadius,
				type: "circle",
				source: mapboxIDDevice,
				paint: {
					"circle-radius": [
						"interpolate",
						["exponential", 2],
						["zoom"],
						0,
						0,
						23,
						metersToPixelsAtMaxZoom(["get", "distance"]),
					],
					"circle-color": "#3a58e2",
					"circle-opacity": 0.15,
					"circle-stroke-width": 2,
					"circle-stroke-color": "#3a58e2",
					"circle-stroke-opacity": 0.3,
					"circle-pitch-alignment": "map",
				},
				filter: zoomFilter,
			},
			mapboxIDDeviceOuter,
		);
		box.addLayer({
			id: mapboxIDDeviceInventoryIndicator,
			type: "symbol",
			source: mapboxIDDevice,
			layout: {
				"icon-image": "icon-inventory",
				"icon-offset": ["literal", [0, -56]],
				"icon-size": 0.43,
				"icon-allow-overlap": true,
			},
			paint: {
				"icon-opacity": [
					"case",
					[
						"boolean",
						["feature-state", featureStateKeyHoverInventory],
						false,
					],
					0.5,
					0.25,
				],
			},
		});
		box.addLayer({
			id: mapboxIDDeviceCluster,
			type: "symbol",
			source: mapboxIDDevice,
			filter: isCluster,
			layout: {
				"icon-image": "cluster",
				"icon-allow-overlap": true,
				"icon-size": 1,
			},
		});
		box.addLayer({
			id: mapboxIDDeviceClusterText,
			type: "symbol",
			source: mapboxIDDevice,
			filter: isCluster,
			layout: {
				"text-field": ["get", "cluster_count"],
				"text-size": 14,
				"text-allow-overlap": true,
			},
			paint: {
				"text-color": "white",
			},
		});
	});

	const tooltipPopup = useMemo(() => createVelavuMapboxPopup(), []);

	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			box.on(
				"mousemove",
				mapboxIDDeviceMarker,
				(event: MapLayerMouseEvent) => {
					// sort by icon overlap order
					const features = event.features
						? event.features.slice().sort((a, b) => {
								const aProperties =
									a.properties as MarkerProperties;
								const bProperties =
									b.properties as MarkerProperties;

								const compareFocus: number =
									Number(
										focusIDs.current.some(
											(x) => x === bProperties?.deviceID,
										),
									) -
									Number(
										focusIDs.current.some(
											(x) => x === aProperties?.deviceID,
										),
									);
								if (compareFocus !== 0) {
									return compareFocus;
								}
								if (
									aProperties?.prioritized !==
									bProperties?.prioritized
								) {
									return (
										bProperties?.prioritized -
										aProperties?.prioritized
									);
								} else if (
									aProperties?.type === LocationType.GPS ||
									bProperties?.type === LocationType.GPS
								) {
									return (
										Number(
											bProperties?.type ===
												LocationType.GPS,
										) -
										Number(
											aProperties?.type ===
												LocationType.GPS,
										)
									);
								}
								return (
									Number(
										bProperties?.markerType ===
											DeviceMarkerType.Asset,
									) -
									Number(
										aProperties?.markerType ===
											DeviceMarkerType.Asset,
									)
								);
						  })
						: [];
					if (features[0].id != undefined) {
						const properties = features[0]
							.properties as MarkerProperties;

						if (
							properties.displayZoomThreshold !== undefined &&
							map.getZoom() < properties.displayZoomThreshold
						) {
							return;
						}
						setHoverID(properties.deviceID);
						// show popup
						const descriptionHTML = `<span>${properties.name}</span>`;
						const coordinates = JSON.parse(
							//Gets converted to string through Mapbox
							properties.coordinates as unknown as string,
						);
						tooltipPopup
							.setLngLat({
								lng: coordinates[0],
								lat: coordinates[1],
							})
							.setHTML(descriptionHTML);
						if (!tooltipPopup.isOpen()) tooltipPopup.addTo(map);
					}
				},
			);
			box.on("mouseleave", mapboxIDDeviceMarker, () => {
				setHoverID(undefined);
				// hide popup
				tooltipPopup.remove();
			});
			box.on(
				"mousemove",
				mapboxIDDeviceInventoryIndicator,
				(event: MapLayerMouseEvent) => {
					const id = event.features?.[0].id;
					if (id === undefined) return;

					if (hoverCountBubbleId.current !== undefined) {
						map.setFeatureState(
							{
								source: mapboxIDDevice,
								id: hoverCountBubbleId.current,
							},
							{
								[featureStateKeyHoverInventory]: false,
							},
						);
					}

					map.setFeatureState(
						{
							source: mapboxIDDevice,
							id,
						},
						{
							[featureStateKeyHoverInventory]: true,
						},
					);

					hoverCountBubbleId.current = id;
				},
			);

			box.on("mouseleave", mapboxIDDeviceInventoryIndicator, () => {
				if (hoverCountBubbleId.current !== undefined) {
					map.setFeatureState(
						{
							source: mapboxIDDevice,
							id: hoverCountBubbleId.current,
						},
						{
							[featureStateKeyHoverInventory]: false,
						},
					);
					hoverCountBubbleId.current = undefined;
				}
			});
		},
		[tooltipPopup],
	);

	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			const onClick = (
				event: MapLayerMouseEvent,
				tab?: AssetDetailTab,
			) => {
				// sort by icon overlap order
				const features = event.features
					? event.features.slice().sort((a, b) => {
							const compareFocus =
								Number(
									focusIDs.current.some(
										(x) => x === b.properties?.deviceID,
									),
								) -
								Number(
									focusIDs.current.some(
										(x) => x === a.properties?.deviceID,
									),
								);
							if (compareFocus !== 0) {
								return compareFocus;
							}
							return (
								Number(b.properties?.type == LocationType.GPS) -
								Number(a.properties?.type == LocationType.GPS)
							);
					  })
					: [];
				const properties = features[0].properties as MarkerProperties;

				if (properties.markerType === DeviceMarkerType.Anchor) {
					onSelectAnchor?.(properties.deviceID);
				} else if (properties.markerType === DeviceMarkerType.Asset) {
					onSelectAsset?.(properties.deviceID, tab!);
				}
			};

			const onClickInventoryIndicator = (event: MapLayerMouseEvent) =>
				onClick(event, AssetDetailTab.Inventory);

			box.on("click", mapboxIDDeviceMarker, onClick);
			box.on(
				"click",
				mapboxIDDeviceInventoryIndicator,
				onClickInventoryIndicator,
			);
		},
		[mapInstance, onSelectAsset, onSelectAnchor],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			focusIDs.current = [hoveredDeviceID, selectedDeviceID]
				.filter((value) => value !== undefined)
				.map((value) => String(value));
			const matchArray = [
				hoverID,
				hoveredDeviceID,
				selectedDeviceID,
			].filter(
				(value, index) =>
					value !== undefined &&
					[hoverID, hoveredDeviceID, selectedDeviceID].indexOf(
						value,
					) === index,
			);

			// if a BLEDevice is in focus, hide the GPSDevice it is paired to
			let hiddenID: string | undefined;
			if (matchArray.length > 0) {
				const focusedDevice = devices?.find(
					(device) =>
						device?.location?.location_type ===
							LocationType.Proximity &&
						matchArray.some((x) => x === device.deviceID),
				);
				if (
					focusedDevice?.location?.location_type ===
					LocationType.Proximity
				) {
					const location: LocationResourceProximity =
						focusedDevice.location;
					hiddenID = devices?.find(
						(device) =>
							device?.location?.location_type ===
								LocationType.GPS &&
							device.deviceID === location.device_id,
					)?.deviceID;
				}
			}

			const sortKey = [
				"match",
				["get", "deviceID"],
				hoverID === undefined ? "" : hoverID,
				5,
				matchArray.length > 0
					? [
							"match",
							["get", "deviceID"],
							matchArray,
							4,
							[
								"case",
								["==", ["get", "prioritized"], 1],
								3,
								["==", ["get", "type"], LocationType.GPS],
								2,
								[
									"==",
									["get", "markerType"],
									DeviceMarkerType.Asset,
								],
								1,
								0,
							],
					  ]
					: [
							"case",
							["==", ["get", "prioritized"], 1],
							3,
							["==", ["get", "type"], LocationType.GPS],
							2,
							[
								"==",
								["get", "markerType"],
								DeviceMarkerType.Asset,
							],
							1,
							0,
					  ],
			];

			map.setFilter(mapboxIDDeviceMarker, [
				"all",
				notCluster,
				["!=", ["get", "deviceID"], hiddenID ?? ""],
				zoomFilter,
			]);
			map.setFilter(mapboxIDDeviceOuter, [
				"all",
				["!", ["has", "cluster_count"]],
				["!=", ["get", "deviceID"], hiddenID ?? ""],
				matchArray.length > 0
					? [
							"match",
							["get", "deviceID"],
							matchArray,
							["literal", true],
							["literal", false],
					  ]
					: ["literal", false],
				zoomFilter,
			]);
			map.setLayoutProperty(
				mapboxIDDeviceMarker,
				"symbol-sort-key",
				sortKey,
			);
			map.setLayoutProperty(
				mapboxIDDeviceOuter,
				"symbol-sort-key",
				sortKey,
			);
			map.setLayoutProperty(
				mapboxIDDeviceMarker,
				"icon-size",
				matchArray.length > 0
					? [
							"match",
							["get", "deviceID"],
							matchArray,
							iconSizeLarge,
							iconSizeDefault,
					  ]
					: iconSizeDefault,
			);
			map.setLayoutProperty(
				mapboxIDDeviceOuter,
				"icon-image",
				buildDeviceEffectExpression(
					["get", "hardware"],
					[
						"match",
						["get", "deviceID"],
						selectedDeviceID ?? "",
						DeviceSymbolEffect.Focus,
						DeviceSymbolEffect.Hover,
					],
				),
			);
			map.setFilter(mapboxIDDeviceRadius, [
				"all",
				["!", ["has", "cluster_count"]],
				[
					"in",
					["get", "type"],
					[
						"literal",
						[LocationType.Proximity, LocationType.Cellular],
					],
				],
				matchArray.length > 0
					? [
							"match",
							["get", "deviceID"],
							matchArray,
							["to-boolean", true],
							["to-boolean", false],
					  ]
					: ["to-boolean", false],
				zoomFilter,
			]);
			map.setFilter(mapboxIDDeviceInventoryIndicator, [
				"all",
				notCluster,
				[
					"any",
					["==", ["get", "type"], LocationType.GPS],
					["==", ["get", "type"], LocationType.Fixed],
				],
				["get", "hasInventoryActions"],
				["!=", ["get", "deviceID"], hiddenID ?? ""],
				zoomFilter,
			]);
		},
		[hoverID, hoveredDeviceID, selectedDeviceID, devices],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			const assetSource = map.getSource(mapboxIDDevice) as GeoJSONSource;
			assetSource.setData({
				type: "FeatureCollection",
				features: (devices ?? []).map(
					(device): Feature<Geometry, MarkerProperties> => {
						return {
							type: "Feature",
							id: device.deviceID,
							geometry: {
								type: "Point",
								coordinates: device.coordinates,
							},
							properties: {
								markerType: device.markerType,
								deviceID: device.deviceID,
								inSite: device.inSite,
								online: device.online,
								hardware: device.hardware,
								name: device.name ?? device.deviceID,
								coordinates: device.coordinates,
								lat: device.coordinates[1],
								type: device.location?.location_type ?? "",
								hasInventoryActions: device.hasInventoryActions,
								distance:
									"distance" in device.location
										? device.location.distance
										: 0,
								prioritized: prioritizedDeviceIDs?.includes(
									device.deviceID,
								)
									? 1
									: 0,
								displayZoomThreshold:
									device.displayZoomThreshold,
							},
						};
					},
				),
			});
		},
		[devices],
	);

	return mapboxIDDeviceRadius;
}
