import React, { useCallback, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { VelavuAPI, normalizeDeviceHardware } from "velavu-js-api";
import IconAdd from "../../../dynamicicons/icon-add";
import AssetAddWizard from "../../../elements/asset-add-wizard";
import wizardStyles from "../../../elements/asset-add-wizard.module.scss";
import { CategoryTag, GroupTag } from "../../../elements/categorization-tag";
import DynamicBattery from "../../../elements/dynamic-battery";
import StatusIndicator from "../../../elements/status-indicator";
import VelavuArrowButton from "../../../elements/velavu-arrow-button";
import VelavuButton from "../../../elements/velavu-button";
import VelavuItemIcon from "../../../elements/velavu-item-icon";
import VelavuSimpleTable, {
	CenteredTD,
	MultilineTD,
} from "../../../elements/velavu-simple-table";
import { getBase64FromUrl } from "../../../helper/image-helper";
import { mapConnectionStatus } from "../../../helper/language-helper";
import { getDeviceStatus } from "../../../helper/status-helper";
import { AssetAddData } from "./asset-add-data";
import styles from "./asset-new-confirm.module.scss";

export default function AssetNewConfirm(props: { location?: any }) {
	const wizardData: AssetAddData | undefined =
		props.location?.state?.wizardData;
	const [isSubmitting, setSubmitting] = useState(false);
	const history = useHistory();

	const status = useMemo(() => {
		return getDeviceStatus(wizardData?.tag);
	}, [wizardData?.tag]);

	const submitAssetImage = useCallback((base64Data: string, id: string) => {
		VelavuAPI.assets
			.uploadProfilePicture(id, base64Data)
			.catch((error) => console.log(error));
	}, []);

	const submitAsset = useCallback(() => {
		if (isSubmitting) return;

		setSubmitting(true);

		VelavuAPI.assets
			.createNewAsset({
				name: wizardData!.asset.assetName,
				category: wizardData!.asset.assetCategorization.category,
				group: wizardData!.asset.assetCategorization.group,
				notes: wizardData!.asset.assetNotes,
				device_id: wizardData!.tag?.id,
			})
			.then((response) => {
				wizardData!.asset.assetImage &&
					getBase64FromUrl(wizardData!.asset.assetImage).then(
						(base64Data) =>
							submitAssetImage(base64Data!, response.id),
					);
			})
			.finally(() => history.push("/assets"))
			.catch((error) => {
				console.log(error);
				setSubmitting(false);
			});
	}, [isSubmitting, wizardData, submitAssetImage, history]);

	return (
		<AssetAddWizard
			previous={
				<Link
					className={wizardStyles.navButton}
					to={{
						pathname: "/assets/create/tag",
						state: { wizardData: wizardData },
					}}
				>
					<VelavuArrowButton direction="left" />
				</Link>
			}
			next={
				<div className={wizardStyles.navButton}>
					<VelavuArrowButton
						onClick={submitAsset}
						direction="confirm"
					/>
				</div>
			}
			stageCount={3}
			currentStage={2}
		>
			<div style={{ padding: 20 }}>
				<h1>Overview</h1>
				<div className={styles.container}>
					{wizardData ? (
						<ResultsTile
							type="Asset"
							editPath="/assets/create"
							editState={{ wizardData: wizardData }}
							icon={
								wizardData.asset.assetImage ? (
									<img
										className={styles.assetImagePreview}
										src={wizardData.asset.assetImage}
										alt=""
									/>
								) : (
									<VelavuItemIcon
										data={
											wizardData.asset.assetCategorization
												.category
										}
										status={status}
									/>
								)
							}
							title={wizardData.asset.assetName}
						>
							<VelavuSimpleTable>
								<tbody>
									<tr>
										<td>Category</td>
										<td>
											<CategoryTag
												category={
													wizardData.asset
														.assetCategorization
														.category
												}
											/>
										</td>
									</tr>
									<tr>
										<td>Group</td>
										<td>
											<GroupTag
												categorization={
													wizardData.asset
														.assetCategorization
												}
											/>
										</td>
									</tr>
									<tr>
										<td>Notes</td>
										<MultilineTD>
											{wizardData.asset.assetNotes
												? wizardData.asset.assetNotes
												: "None"}
										</MultilineTD>
									</tr>
								</tbody>
							</VelavuSimpleTable>
						</ResultsTile>
					) : (
						<AbsentTile
							message="No asset paired"
							action="Pair asset"
							editPath="/assets/create"
							editState={{ wizardData: wizardData }}
						/>
					)}
					<div className={styles.tileSeparator} />

					{wizardData?.tag ? (
						<ResultsTile
							type="Tag"
							editPath="/assets/create/tag"
							editState={{ wizardData: wizardData }}
							icon={
								<VelavuItemIcon
									data={normalizeDeviceHardware(
										wizardData.tag.hardware,
									)}
								/>
							}
							title={wizardData.tag.id}
						>
							<VelavuSimpleTable>
								<tbody>
									<tr>
										<td>Status</td>
										<CenteredTD>
											<StatusIndicator status={status} />
											<span>
												{mapConnectionStatus(status)}
											</span>
										</CenteredTD>
									</tr>
									<tr>
										<td>Battery</td>
										<CenteredTD>
											<span style={{ marginRight: 4 }}>
												{wizardData.tag.state?.power
													?.battery_level !==
												undefined
													? `${wizardData.tag.state.power.battery_level}%`
													: "N/A"}
											</span>
											<DynamicBattery
												resource={
													wizardData.tag.state?.power
												}
											/>
										</CenteredTD>
									</tr>
								</tbody>
							</VelavuSimpleTable>
						</ResultsTile>
					) : (
						<AbsentTile
							message="No tag paired"
							action="Pair tag"
							editPath="/assets/create/tag"
							editState={{ wizardData: wizardData }}
						/>
					)}
				</div>
			</div>
		</AssetAddWizard>
	);
}

function AbsentTile(props: {
	message: string;
	action: string;
	editPath: string;
	editState?: Record<string, unknown>;
}) {
	return (
		<div className={`${styles.tile} ${styles.absentTile}`}>
			<h3>{props.message}</h3>
			<Link
				style={{ textDecoration: "none" }}
				to={{ pathname: props.editPath, state: props.editState }}
			>
				<VelavuButton
					icon={(size, color, className) => (
						<IconAdd
							size={size}
							color={color}
							className={className}
						/>
					)}
					label={props.action}
				/>
			</Link>
		</div>
	);
}

interface ResultsTileProps {
	type: string;
	editPath: string;
	editState?: Record<string, unknown>;
	icon: React.ReactNode;
	title: string;
	children?: React.ReactNode;
}

function ResultsTile(props: ResultsTileProps) {
	return (
		<div className={styles.tile}>
			<div className={styles.tileTitle}>
				<h3>{props.type}</h3>

				<Link
					style={{ textDecoration: "none" }}
					to={{ pathname: props.editPath, state: props.editState }}
				>
					<VelavuButton label="Edit" size="small" outlined />
				</Link>
			</div>

			<div className={styles.tileHeader}>
				{props.icon}
				<h3 className={styles.headerTitle}>{props.title}</h3>
			</div>

			{props.children}
		</div>
	);
}
