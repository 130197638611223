import { DateTime } from "luxon";
import React, { useRef } from "react";
import { VelavuRoute, VelavuRouteDetailed } from "velavu-js-api";
import IconArrowThinRight from "../../../../dynamicicons/icon-arrow-thin-right";
import IconCar from "../../../../dynamicicons/icon-car";
import BottomTooltip from "../../../../elements/bottom-tooltip";
import { useToggleable } from "../../../../helper/hook-helper";
import routeIcon from "../../../../img/route_icon.svg";
import styles from "./asset-routes-item.module.scss";

interface AssetRouteItemProps {
	route: VelavuRoute;
	details?: VelavuRouteDetailed;

	isHovered: boolean;
	onHover: VoidFunction;
	onHoverExit: VoidFunction;

	isSelected: boolean;
	onSelect: (route: VelavuRoute | undefined) => void;
}

export default function AssetRoutesItem(props: AssetRouteItemProps) {
	const dateStart = DateTime.fromISO(props.route.data.time_start);
	const dateEnd = props.route.data.time_stop
		? DateTime.fromISO(props.route.data.time_stop)
		: undefined;

	const [hoverDate, enterHoverDate, exitHoverDate] = useToggleable();
	const tileRef = useRef<HTMLSpanElement>(null);

	let selectionIndicatorStyle;
	if (props.isSelected)
		selectionIndicatorStyle = styles.selectionIndicatorVisible;
	else if (props.isHovered)
		selectionIndicatorStyle = styles.selectionIndicatorHover;
	else selectionIndicatorStyle = "";

	return (
		<div
			className={styles.container}
			onClick={() => {
				if (props.isSelected) {
					props.onSelect(undefined);
				} else {
					props.route.data.location_start &&
						props.onSelect(props.route);
				}
			}}
			onMouseEnter={() =>
				props.route.data.location_start && props.onHover()
			}
			onMouseLeave={props.onHoverExit}
		>
			<div
				className={`${styles.selectionIndicator} ${selectionIndicatorStyle}`}
			/>

			<span className={styles.routeIcon}>
				<img src={routeIcon} />
			</span>
			<div className={`${styles.routeTwoLevel} ${styles.routePoints}`}>
				<span>{props.route.data.location_start}</span>
				{props.route.data.location_stop ? (
					<span>{props.route.data.location_stop}</span>
				) : (
					<span className={styles.inProgress}>In progress</span>
				)}
			</div>
			<div className={`${styles.routeTwoLevel} ${styles.routeTime}`}>
				<span
					ref={tileRef}
					onMouseEnter={enterHoverDate}
					onMouseLeave={exitHoverDate}
				>
					{dateStart.toLocaleString(DateTime.TIME_SIMPLE)}

					{hoverDate && (
						<BottomTooltip
							refPosition={tileRef.current ?? undefined}
						>
							{!dateEnd &&
								dateStart.toLocaleString(DateTime.DATETIME_MED)}
							{dateEnd && (
								<div className={styles.routeTimeTooltip}>
									<span
										className={styles.routeTimeTooltipDate}
									>
										{dateStart.toLocaleString(
											DateTime.DATE_FULL,
										)}
									</span>

									<span>
										{dateStart.toLocaleString(
											DateTime.TIME_SIMPLE,
										)}
									</span>
									<IconArrowThinRight color="white" />
									<span>
										{dateEnd.toLocaleString(
											DateTime.TIME_SIMPLE,
										)}
									</span>
								</div>
							)}
						</BottomTooltip>
					)}
				</span>
				<span className={styles.eventCount}>
					{props.isSelected ? (
						dateEnd?.toLocaleString(DateTime.TIME_SIMPLE)
					) : props.route.data.event_cnt > 0 ? (
						<>
							<IconCar size={16} color="#5F718C" />
							<span className={styles.eventCountText}>
								{props.route.data.event_cnt}
							</span>
						</>
					) : null}
				</span>
			</div>
		</div>
	);
}
