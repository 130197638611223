import React, { CSSProperties } from "react";
import AnalyticsStatType from "../../data/analytics-stat-type";
import { MeasurementSystem, VelavuDevice } from "velavu-js-api";
import { classArr } from "../../helper/style-helper";
import { Anchor, Device } from "./analytics-sidebar-device";
import styles from "./analytics-sidebar-devices.module.scss";

// Guarantees that only assets linked to a tag/device are filtered them maps
// them to a device component
export function TagList({
	className,
	assets,
	selectedAssets,
	setSelectedAssets,
	statType,
	measurementSystem,
}: {
	style?: CSSProperties;
	className?: string;
	assets: VelavuDevice[];
	selectedAssets: string[];
	setSelectedAssets: React.Dispatch<React.SetStateAction<string[]>>;
	statType: AnalyticsStatType;
	measurementSystem: MeasurementSystem;
}) {
	let result: React.ReactNode | React.ReactNode[] = assets.map((asset) => (
		<Device
			key={asset.id}
			className={styles.item}
			measurementSystem={measurementSystem}
			asset={asset.asset!}
			device={asset}
			isSelected={selectedAssets.includes(asset.asset!.id)}
			isNonApplicable={
				!checkStatApplicability(asset ?? undefined, statType)
			}
			onClick={() => {
				const filtered = selectedAssets.filter(
					(a) => a !== asset.asset!.id,
				);
				if (filtered.length === selectedAssets.length) {
					setSelectedAssets(filtered.concat(asset.asset!.id));
				} else {
					setSelectedAssets(filtered);
				}
			}}
		/>
	));

	if (!assets.length)
		result = <div className={styles.emptyLabel}>No results</div>;

	return <div className={classArr(styles.list, className)}>{result}</div>;
}

export function AnchorList({
	className,
	selectedAnchors,
	setSelectedAnchors,
	anchors,
	statType,
	measurementSystem,
}: {
	className?: string;
	selectedAnchors: string[];
	setSelectedAnchors: React.Dispatch<React.SetStateAction<string[]>>;
	anchors: VelavuDevice[];
	statType: AnalyticsStatType;
	measurementSystem: MeasurementSystem;
}) {
	let result: React.ReactNode | React.ReactNode[] = anchors.map((anchor) => (
		<Anchor
			key={anchor.id}
			className={styles.item}
			measurementSystem={measurementSystem}
			anchor={anchor}
			asset={anchor.asset!}
			isSelected={selectedAnchors.includes(anchor.id)}
			isNonApplicable={!checkStatApplicability(anchor, statType)}
			onClick={() =>
				setSelectedAnchors?.((selection) => {
					const selectionIndex = selection.indexOf(anchor.id);
					if (selectionIndex === -1) {
						return selection.concat(anchor.id);
					} else {
						const newSelection = [...selection];
						newSelection.splice(selectionIndex, 1);
						return newSelection;
					}
				})
			}
		/>
	));

	if (!anchors.length)
		result = <div className={styles.emptyLabel}>No results</div>;

	return <div className={classArr(styles.list, className)}>{result}</div>;
}

/**
 * Checks if a given statistic can apply to a device
 */
function checkStatApplicability(
	device: VelavuDevice | undefined,
	stat: AnalyticsStatType,
): boolean {
	switch (stat) {
		case AnalyticsStatType.Temperature:
			return device?.environment?.temperature_c !== undefined;
		case AnalyticsStatType.Humidity:
			return device?.environment?.humidity !== undefined;
		case AnalyticsStatType.Battery:
			return device?.state?.power?.battery_level !== undefined;
		case AnalyticsStatType.Location:
			return true;
	}
}
