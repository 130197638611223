import React, { CSSProperties } from "react";

export default function IconDelete(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.571 6V5c0-.75.254-1.495.743-2.065C8.807 2.36 9.512 2 10.286 2h3.428c.774 0 1.479.36 1.972.935A3.18 3.18 0 0116.429 5v1H20a1 1 0 110 2h-1v11a3.18 3.18 0 01-.743 2.065c-.493.575-1.197.935-1.971.935H7.714c-.774 0-1.478-.36-1.971-.935A3.18 3.18 0 015 19V8H4a1 1 0 010-2h3.571zm2.262-1.763c.15-.176.317-.237.453-.237h3.428c.136 0 .303.061.453.237.154.18.262.452.262.763v1H9.57V5c0-.31.108-.584.262-.763zM7 8v11c0 .31.107.584.261.763.15.175.318.237.453.237h8.572c.135 0 .302-.061.453-.237.154-.18.261-.452.261-.763V8H7z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
