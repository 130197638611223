import React, { CSSProperties } from "react";

export default function IconSpeed(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M12.4 14C13.2 13.8 13.6 13.1 14.1 12.4L19.1 4.9 11.6 9.9C10.9 10.4 10.2 10.8 10 11.6 9.9 12.2 10.1 12.9 10.6 13.4 11.1 13.9 11.8 14.1 12.4 14Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M20 12C20 16.4 16.4 20 12 20 7.6 20 4 16.4 4 12 4 7.6 7.6 4 12 4 13.3 4 14.5 4.3 15.6 4.8L17.4 3.6C15.9 2.6 14 2 12 2 6.5 2 2 6.5 2 12 2 17.5 6.5 22 12 22 17.5 22 22 17.5 22 12 22 10 21.4 8.1 20.4 6.6L19.2 8.4C19.7 9.5 20 10.7 20 12Z"
				fill={props.color ?? "black"}
			/>
			<path
				d="M17.9 6.6L19.1 4.9 17.4 6.1C17.6 6.2 17.8 6.4 17.9 6.6Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
