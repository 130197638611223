import React, {
	useCallback,
	useContext,
	useMemo,
	useRef,
	useState,
} from "react";
import IconClose from "../../../../dynamicicons/icon-close";
import IconPlace from "../../../../dynamicicons/icon-place";
import VelavuAutocomplete, {
	AutocompleteDivider,
} from "../../../../elements/velavu-autocomplete";
import { useDebouncedEffect } from "../../../../helper/hook-helper";
import SignInContext from "../../../../sign-in-context";
import styles from "./site-create-search.module.scss";

interface GeocoderResult {
	attribution: string;
	features?: {
		id: string;
		center: [number, number];
		place_name: string;
	}[];
	query: string[];
	type: "FeatureCollection";
}

export interface SiteCreateAddressResult {
	id: string;
	position: [number, number];
	name: string;
}

interface SiteCreateSearchProps {
	address: SiteCreateAddressResult | undefined;
	onSetAddress: (address: SiteCreateAddressResult | undefined) => void;
	from: string;
}

export default function SiteCreateSearch(props: SiteCreateSearchProps) {
	const [inputSiteAddress, setInputSiteAddress] = useState("");
	const [searchResults, setSearchResults] = useState<
		SiteCreateAddressResult[] | undefined
	>(undefined);
	const user = useContext(SignInContext);
	const refInput = useRef<HTMLInputElement | null>(null);

	const updateSearch = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value;
			setInputSiteAddress(value);
		},
		[setInputSiteAddress],
	);

	const mapboxToken = useMemo(
		() => user.velavuUser?.mapbox_token,
		[user.velavuUser],
	);
	useDebouncedEffect(
		() => {
			//Ignoring if there is no query
			if (inputSiteAddress.trim() === "") {
				setSearchResults(undefined);
				return;
			}

			//Getting the mapbox token
			if (mapboxToken === undefined) {
				console.warn("Geocoder failed; no Mapbox token");
				return;
			}

			//API.get("Mapbox-API", `/geocoding/v5/mapbox.places/${encodeURIComponent(query)}.json`, {queryStringParameters: {access_token: "pk.eyJ1IjoiYnJhc2gtaW5jIiwiYSI6ImNrand6dmphdTA3NDQydHBlY2RwdzQ4dDMifQ.MiuxkhWnwljDZJRuiiGzeg"}})
			const req = new XMLHttpRequest();
			req.responseType = "json";
			req.addEventListener("load", () => {
				const response: GeocoderResult = req.response;
				setSearchResults(
					response.features?.map(
						(feature) =>
							({
								id: feature.id,
								position: feature.center,
								name: feature.place_name,
							}) as SiteCreateAddressResult,
					) ?? [],
				);
			});
			req.open(
				"GET",
				`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
					inputSiteAddress,
				)}.json?language=en&types=address&access_token=${mapboxToken}&proximity=${
					props.from
				}`,
			);
			req.send();
		},
		300,
		false,
		[inputSiteAddress, setSearchResults, mapboxToken],
	);

	return (
		<VelavuAutocomplete
			ref={refInput}
			inputProps={{ value: inputSiteAddress, onChange: updateSearch }}
			contentOverride={
				!props.address ? undefined : (
					<div
						className={styles.selectionDisplay}
						onClick={() => {
							props.onSetAddress(undefined);
							setTimeout(() => refInput.current!.focus());
						}}
					>
						<span className={styles.selectionDisplayLabel}>
							{props.address.name}
						</span>
						<IconClose color="#A3B3CC" size={16} />
					</div>
				)
			}
			disableSuggestions={props.address !== undefined}
		>
			{searchResults !== undefined && searchResults.length > 0 && (
				<>
					<AutocompleteDivider />
					{searchResults.map((result) => (
						<AddressEntry
							key={result.id}
							address={result}
							onClick={() => {
								props.onSetAddress(result);
								setSearchResults(undefined);
								setInputSiteAddress("");
							}}
						/>
					))}
				</>
			)}
		</VelavuAutocomplete>
	);
}

function AddressEntry(props: {
	address: SiteCreateAddressResult;
	onClick?: VoidFunction;
}) {
	return (
		<button className={styles.addressEntry} onClick={props.onClick}>
			<IconPlace
				className={styles.addressEntryIcon}
				color="#607898"
				size={16}
			/>
			<span className={styles.addressEntryLabel}>
				{props.address.name}
			</span>
		</button>
	);
}
