// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.em3ouhUuB6FINrpR7CLo{display:inline-flex}`, "",{"version":3,"sources":["webpack://./src/elements/status-indicator.module.scss"],"names":[],"mappings":"AAAA,sBACC,mBAAA","sourcesContent":[".container {\n\tdisplay: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `em3ouhUuB6FINrpR7CLo`
};
export default ___CSS_LOADER_EXPORT___;
