import React, { CSSProperties } from "react";

export default function IconCircleCheck(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM15.88 8.29L10 14.17L8.12 12.29C7.93302 12.103 7.67943 11.998 7.415 11.998C7.15057 11.998 6.89698 12.103 6.71 12.29C6.52302 12.477 6.41798 12.7306 6.41798 12.995C6.41798 13.2594 6.52302 13.513 6.71 13.7L9.3 16.29C9.69 16.68 10.32 16.68 10.71 16.29L17.3 9.7C17.3927 9.60749 17.4663 9.4976 17.5164 9.37662C17.5666 9.25565 17.5924 9.12597 17.5924 8.995C17.5924 8.86403 17.5666 8.73435 17.5164 8.61338C17.4663 8.4924 17.3927 8.38251 17.3 8.29C16.91 7.9 16.27 7.9 15.88 8.29Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
