import { VelavuSite } from "velavu-js-api";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";
import { useMemo } from "react";
import mapboxgl, { GeoJSONSource } from "mapbox-gl";
import { Feature, Point } from "geojson";
import { geoJSONBlankCollection } from "./mapbox-injectable";
import {
	fallbackSiteZoomThreshold,
	resolveSiteZoomLevel,
} from "../../data/device-marker";
import { createVelavuMapboxPopup } from "../../mapbox-component/velavu-mapbox-popup";

export const mapboxIDSiteIcon = "site-icon";

export interface MapboxInjectableSiteIndicatorsProps {
	sites?: VelavuSite[] | undefined;
	onSelectSite?: (siteID: string) => void;
	belowLayer?: string;
	mapDimensions: [number, number];
}

export interface MapSiteIndicatorGeoJSONProperties {
	id: string;
	name: string;
	displayZoomThreshold: number;
}

export default function useMapboxInjectableSiteIndicators(
	mapInstance: MapHelperInstance,
	props: MapboxInjectableSiteIndicatorsProps,
): string {
	const popup = useMemo(() => createVelavuMapboxPopup(), []);

	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			box.on(
				"mouseenter",
				mapboxIDSiteIcon,
				(
					event: mapboxgl.MapMouseEvent & {
						features?: mapboxgl.MapboxGeoJSONFeature[];
					} & mapboxgl.EventData,
				) => {
					//Get the first feature
					const feature = event.features?.[0];
					if (feature === undefined) {
						return;
					}

					const properties =
						feature.properties as MapSiteIndicatorGeoJSONProperties;

					const coordinates = (
						feature.geometry as Point
					).coordinates.slice() as [number, number];
					const label = properties.name;

					// Ensure that if the map is zoomed out such that multiple
					// copies of the feature are visible, the popup appears
					// over the copy being pointed to.
					while (Math.abs(event.lngLat.lng - coordinates[0]) > 180) {
						coordinates[0] +=
							event.lngLat.lng > coordinates[0] ? 360 : -360;
					}

					popup
						.setLngLat(coordinates)
						.setHTML(`<span>${label}</span>`)
						.addTo(map);
				},
			);

			box.on("mouseleave", mapboxIDSiteIcon, () => {
				popup.remove();
			});
		},
		[popup],
	);

	useStyleSetupMapEffect(mapInstance, (_, box) => {
		//Create indicator layer
		box.addSource(mapboxIDSiteIcon, {
			type: "geojson",
			data: geoJSONBlankCollection,
		});
		box.addLayer(
			{
				id: mapboxIDSiteIcon,
				source: mapboxIDSiteIcon,
				type: "symbol",
				layout: {
					"icon-image": "velavu-site",
					"icon-allow-overlap": true,
				},
				filter: ["<", ["zoom"], ["get", "displayZoomThreshold"]],
			},
			props.belowLayer,
		);
	});

	useStyleDependantMapEffect(
		mapInstance,
		(map, box) => {
			const sites = props.sites ?? [];

			const siteIconSource = map.getSource(
				mapboxIDSiteIcon,
			) as GeoJSONSource;
			siteIconSource.setData({
				type: "FeatureCollection",
				features: sites.map<
					Feature<Point, MapSiteIndicatorGeoJSONProperties>
				>((site) => ({
					type: "Feature",
					id: site.id,
					properties: {
						id: site.id,
						name: site.name,
						displayZoomThreshold:
							resolveSiteZoomLevel(site, props.mapDimensions) ??
							fallbackSiteZoomThreshold,
					},
					geometry: {
						type: "Point",
						coordinates: site.coordinates,
					},
				})),
			});
		},
		[props.sites, props.mapDimensions],
	);

	//Register click listener
	const clickListener = props.onSelectSite;
	useStyleDependantMapEffect(
		mapInstance,
		(_, box) => {
			if (clickListener === undefined) return;

			const onClick = (
				event: mapboxgl.MapMouseEvent & {
					features?: mapboxgl.MapboxGeoJSONFeature[];
				} & mapboxgl.EventData,
			) => {
				const feature = event.features![0];
				const id = feature.properties!.id;
				clickListener(id);
			};
			box.on("click", mapboxIDSiteIcon, onClick);
		},
		[clickListener],
	);

	return mapboxIDSiteIcon;
}
