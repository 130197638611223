import { VelavuAPI, VelavuDevice, VelavuRule } from "velavu-js-api";

/**
 * Check if user's inventory setting is on/off
 */
export async function getInventorySetting(
	ruleIds: [string, string],
): Promise<boolean | undefined> {
	const getRule = async <Device extends VelavuDevice = VelavuDevice>(
		id: string,
	): Promise<VelavuRule<Device>> => {
		return VelavuAPI.rules.getSpecificRule(id);
	};

	try {
		const rules = await Promise.all([
			getRule<VelavuDevice>(ruleIds[0]),
			getRule<VelavuDevice>(ruleIds[1]),
		]);
		if (
			rules[0].action.config?.scanner?.mode === "CONTINUOUS" &&
			rules[0].action.config?.scanner?.fixed_rate_s === 1800 &&
			rules[1].action.config?.beacon?.interval_ms === 1000 &&
			rules[1].action.config?.beacon?.tx_power === 0
		) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		console.log(error);
	}
}
