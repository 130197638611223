import React, { useState } from "react";
import { VelavuSite } from "velavu-js-api";
import IconAdd from "../../../../dynamicicons/icon-add";
import Divider from "../../../../elements/divider";
import VelavuButton from "../../../../elements/velavu-button";
import SiteSwitcherList from "../../../shared/site-switcher-list";
import styles from "./site-list.module.scss";
import IconSearch from "../../../../dynamicicons/icon-search";
import VelavuInput from "../../../../elements/velavu-input";

interface SiteListProps {
	sites: VelavuSite[];
	onSelectSite: (id: string) => void;
	onCreateSite: VoidFunction;
}

export default function SiteList(props: SiteListProps) {
	const [searchText, setSearchText] = useState("");

	return (
		<React.Fragment>
			<div className={styles.header}>
				<VelavuInput
					className={styles.search}
					leadingIcon={<IconSearch size={16} color="#00255D" />}
					placeholder="Search sites"
					value={searchText}
					onChange={setSearchText}
					fullWidth
				/>

				<VelavuButton
					icon={(size, color, className) => (
						<IconAdd
							size={size}
							color={color}
							className={className}
						/>
					)}
					label="New site"
					onClick={props.onCreateSite}
				/>
			</div>

			<Divider />

			<SiteSwitcherList
				className={styles.list}
				sites={props.sites}
				onSelectSite={props.onSelectSite}
			/>
		</React.Fragment>
	);
}
