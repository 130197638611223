/*------------------------------*
 | @author  Cameron Slupeiks    |
 | @date    2022.12.21          |
 | Copyright Brash Inc. (2020)  |
 *------------------------------*/

// React DayPicker styles
import "react-day-picker/dist/style.css";
import "./app.scss";

import mapboxgl from "mapbox-gl";
import { Hub } from "aws-amplify/utils";
import { fetchUserAttributes, getCurrentUser } from "aws-amplify/auth";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import AuthRoute from "./components/authentication/auth-route";
import Authentication from "./components/authentication/authentication";
import Content from "./components/main/content";
import MQTTProvider from "./components/main/mqtt-provider";
import { VelavuModalProvider } from "./elements/velavu-modal";
import deepMerge from "./helper/object-helper";
import SignInContext from "./sign-in-context";
import {
	OrganizationPreferences,
	resolveDefaultUserPreferences,
	UserPreferences,
	VelavuAPI,
	VelavuOrganization,
	VelavuUser,
} from "velavu-js-api";

export default function App() {
	const [isLoading, setLoading] = useState(true);
	const [isSignedIn, setIsSignedIn] = useState(false);

	//Get the current authentication state
	useEffect(() => {
		//Get the currently authenticated user
		getCurrentUser()
			.then(() => {
				setIsSignedIn(true);
			})
			.catch(() => {
				setIsSignedIn(false);
			})
			.finally(() => setLoading(false));
	}, [setIsSignedIn]);

	//Subscribe to authentication state changes
	useEffect(() => {
		const unsubscribe = Hub.listen("auth", ({ payload }) => {
			switch (payload.event) {
				case "signedIn":
					setIsSignedIn(true);
					console.log("Triggered sign-in event");
					break;
				case "signedOut":
					setIsSignedIn(false);
					console.log("Triggered sign-out event");
					break;
			}
		});

		return () => {
			unsubscribe();
		};
	}, [setIsSignedIn]);

	//User and organization information
	const [velavuUser, setVelavuUser] = useState<VelavuUser | undefined>(
		undefined,
	);
	const [organization, setOrganization] = useState<
		VelavuOrganization | undefined
	>(undefined);

	//MQTT state
	const [isMQTTRegistered, setMQTTRegistered] = useState(false);

	useEffect(() => {
		if (isSignedIn) {
			//Registering the user with MQTT
			VelavuAPI.authorizeMQTT
				.authorizeMQTT()
				.then(() => {
					setMQTTRegistered(true);
					console.log("Registered user with MQTT policy");
				})
				.catch(console.warn);
		} else {
			//Clearing the user's MQTT registration status
			setMQTTRegistered(false);
		}
	}, [isSignedIn]);

	// Updates the client information from the server whenever some user information changes.
	useEffect(() => {
		if (!isSignedIn) return;

		(async () => {
			//Get the user details
			const userAttributes = await fetchUserAttributes();
			const userEmail = userAttributes.email;
			setVelavuUser(await VelavuAPI.users.getSpecificUser(userEmail!));
		})();

		//Get the user's organization information
		VelavuAPI.organizations.getOrganization().then(setOrganization);
	}, [isSignedIn, setVelavuUser, setOrganization]);

	const updateVelavuUser = useCallback(
		async (updatedUser: Partial<VelavuUser>): Promise<VelavuUser> => {
			if (velavuUser === undefined) {
				throw new Error("User is not yet loaded!");
			}

			//Merge the preferences
			const mergedUser: Partial<VelavuUser> = {
				...updatedUser,
				settings: deepMerge<Partial<UserPreferences>>(
					velavuUser.settings,
					updatedUser?.settings,
				),
			};

			//Submit the new user
			const newUser = await VelavuAPI.users.updateSpecificUserSettings(
				velavuUser.email,
				mergedUser,
			);

			setVelavuUser({
				...velavuUser,
				name: newUser.name,
				settings: newUser.settings,
			});

			return newUser;
		},
		[velavuUser, setVelavuUser],
	);

	const updateVelavuUserImage = useCallback(
		async (imageData: string | undefined) => {
			if (velavuUser === undefined) {
				throw new Error("User is not yet loaded!");
			}

			if (imageData !== undefined) {
				const updatedUser = await VelavuAPI.users.uploadProfilePicture(
					velavuUser.email,
					imageData,
				);
				setVelavuUser(updatedUser);
			} else {
				await VelavuAPI.users.deleteProfilePicture(velavuUser.email);

				setVelavuUser((user) =>
					user === undefined
						? undefined
						: { ...user, profile_img_url: undefined },
				);
			}
		},
		[velavuUser, setVelavuUser],
	);

	const updateOrganization = useCallback(
		async (
			updatedOrganization: Partial<VelavuOrganization>,
		): Promise<VelavuOrganization> => {
			if (organization === undefined) {
				throw new Error("Organization is not yet loaded!");
			}

			//Merge the preferences
			const mergedOrganization: Partial<VelavuOrganization> = {
				...updatedOrganization,
				settings: deepMerge<OrganizationPreferences>(
					organization.settings,
					updatedOrganization?.settings,
				),
			};

			//Submit the new organization
			const newOrganization =
				await VelavuAPI.organizations.updateOrganization(
					mergedOrganization,
				);
			setOrganization(newOrganization);
			return newOrganization;
		},
		[organization, setOrganization],
	);

	//Update the mapbox token with the user's token
	const [isMapboxRegistered, setIsMapboxRegistered] = useState(false);
	useEffect(() => {
		if (velavuUser === undefined) return;
		mapboxgl.accessToken = velavuUser.mapbox_token;
		setIsMapboxRegistered(true);
	}, [velavuUser, setIsMapboxRegistered]);

	const resolvedPreferences = useMemo(() => {
		return resolveDefaultUserPreferences(velavuUser?.settings);
	}, [velavuUser?.settings]);

	//Wait until we finish loading to render content
	if (isLoading) return null;

	return (
		<SignInContext.Provider
			value={{
				velavuUser,
				updateVelavuUser,
				updateVelavuUserImage,
				preferences: resolvedPreferences,
				organization,
				updateOrganization,
				isMapboxRegistered,
				isMQTTRegistered,
			}}
		>
			<Router>
				<Switch>
					<AuthRoute
						path="/login"
						type="guest"
						isAuthUser={isSignedIn}
					>
						<Authentication />
					</AuthRoute>
					<AuthRoute path="/" type="private" isAuthUser={isSignedIn}>
						<MQTTProvider>
							<Router>
								<VelavuModalProvider zIndex={100}>
									<Content />
								</VelavuModalProvider>
							</Router>
						</MQTTProvider>
					</AuthRoute>
				</Switch>
			</Router>
		</SignInContext.Provider>
	);
}
