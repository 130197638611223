import React, { useMemo } from "react";
import styles from "./channel-topic-table.module.scss";
import EditableChannelTopic from "./editable-channel-topic";
import ChannelTopicRow from "./channel-topic-row";
import InputButton from "../../../elements/input-button";
import { makeStyleable } from "../../../helper/icon-helper";
import IconCircleAdd from "../../../dynamicicons/icon-circle-add";

interface ChannelTopicTableProps {
	className?: string;
	title: string;
	channelTopics: EditableChannelTopic[];
	setChannelTopics?: (channelTopics: EditableChannelTopic[]) => void;
}

export default function ChannelTopicTable(props: ChannelTopicTableProps) {
	const channelTopicErrors = useMemo(() => {
		const errorMap = new Map<number, string>();

		props.channelTopics.forEach((channelTopic, i) => {
			//Check the validity of the channel
			const wirepasChannelString = channelTopic.wirepas_channel;
			if (wirepasChannelString.length > 0) {
				//Check the channel number
				const wirepasChannel = parseInt(wirepasChannelString);
				if (
					isNaN(wirepasChannel) ||
					wirepasChannel < 1 ||
					wirepasChannel > 255
				) {
					errorMap.set(i, "Must be value between 1-255");
					return;
				}

				//Check if the number is already taken
				if (
					props.channelTopics.some((otherChannelTopic) => {
						return (
							channelTopic !== otherChannelTopic &&
							parseInt(otherChannelTopic.wirepas_channel) ===
								wirepasChannel
						);
					})
				) {
					errorMap.set(i, "This endpoint is already taken");
					return;
				}
			}
		});

		return errorMap;
	}, [props.channelTopics]);

	return (
		<div className={props.className}>
			<span className={styles.title}>{props.title}</span>

			{props.channelTopics.length > 0 && (
				<table className={styles.table}>
					<thead>
						<tr>
							<th className={styles.wirepasEndpointHeader}>
								Wirepas endpoint
							</th>
							<th className={styles.mqttTopicHeader}>
								MQTT topic
							</th>
						</tr>
					</thead>

					<tbody>
						{props.channelTopics.map((channelTopic, i) => (
							<ChannelTopicRow
								key={i}
								channelTopic={channelTopic}
								errorMessage={channelTopicErrors.get(i)}
								onChange={(newChannelTopic) => {
									const newChannelTopics = [
										...props.channelTopics,
									];
									newChannelTopics[i] = newChannelTopic;
									props.setChannelTopics?.(newChannelTopics);
								}}
								onRemove={() => {
									const newChannelTopics = [
										...props.channelTopics,
									];
									newChannelTopics.splice(i, 1);
									props.setChannelTopics?.(newChannelTopics);
								}}
							/>
						))}
					</tbody>
				</table>
			)}

			<InputButton
				className={styles.addButton}
				icon={makeStyleable(IconCircleAdd)}
				label="Add new endpoint"
				fullWidth
				onClick={() => {
					const newChannelTopics = [...props.channelTopics];
					newChannelTopics.push({
						wirepas_channel: "",
						mqtt_topic: "",
					});
					props.setChannelTopics?.(newChannelTopics);
				}}
			/>
		</div>
	);
}
