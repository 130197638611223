import React, { CSSProperties } from "react";

export default function IconErase(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				d="M12.9497 18.6066C11.7782 19.7782 9.87868 19.7782 8.70711 18.6066L5.87868 15.7782C4.70711 14.6066 4.70711 12.7071 5.87868 11.5355L11.5355 5.87868C12.7071 4.70711 14.6066 4.70711 15.7782 5.87868L18.6066 8.70711C19.7782 9.87868 19.7782 11.7782 18.6066 12.9497L12.9497 18.6066ZM12.9497 15.7782L8.70711 11.5355L7.29289 12.9497C6.90237 13.3403 6.90237 13.9734 7.29289 14.364L10.1213 17.1924C10.5118 17.5829 11.145 17.5829 11.5355 17.1924L12.9497 15.7782Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
