import React, { useCallback, useContext, useMemo, useState } from "react";
import styles from "./settings-advanced.module.scss";
import VelavuSelect from "../../elements/velavu-select";
import VelavuInput from "../../elements/velavu-input";
import FileInputButton from "../../elements/file-input-button";
import ChannelTopicTable from "./settings-advanced/channel-topic-table";
import EditableChannelTopic from "./settings-advanced/editable-channel-topic";
import VelavuButton from "../../elements/velavu-button";
import SignInContext from "../../sign-in-context";
import {
	VelavuAPI,
	VelavuBackend,
	VelavuBackendCertificates,
	VelavuBackendChannelTopic,
} from "velavu-js-api";
import { useCancellableEffect } from "../../helper/hook-helper";
import { useBase64FileState } from "../../data/base64-file-data";
import { arrayIsUnique, deepCompare } from "../../helper/object-helper";
import BackendDeviceTable from "./settings-advanced/backend-device-table";
import VelavuNotice from "../../elements/velavu-notice";
import VelavuSwitchBlock from "../../elements/velavu-switch-block";

enum BackendType {
	Velavu = "velavu",
	ThirdParty = "thirdParty",
}

enum BrokerAuthType {
	None = "none",
	UsernamePassword = "usernamePassword",
	CertificateKey = "certificateKey",
}

export default function SettingsAdvanced(props: {
	isDirty: boolean;
	setDirty: (dirty: boolean) => void;
}) {
	//Fetch organization settings
	const userContext = useContext(SignInContext);
	const updateOrganization = userContext.updateOrganization;
	const organizationSettings = userContext.organization?.settings;
	const backendID = organizationSettings?.backend?.id;

	//Initialize input state
	const [backendType, setBackendType] = useState<BackendType>(
		backendID !== undefined ? BackendType.ThirdParty : BackendType.Velavu,
	);

	const [brokerHostName, setBrokerHostName] = useState("");
	const [brokerPort, setBrokerPort] = useState("");
	const [brokerAuthType, setBrokerAuthType] = useState<BrokerAuthType>(
		BrokerAuthType.None,
	);

	const [brokerUsername, setBrokerUsername] = useState("");
	const [brokerPassword, setBrokerPassword] = useState("");

	const [brokerRootCertificate, setBrokerRootCertificate] =
		useBase64FileState();
	const [brokerClientCertificate, setBrokerClientCertificate] =
		useBase64FileState();
	const [brokerPrivateKey, setBrokerPrivateKey] = useBase64FileState();

	const [upstreamChannelTopicInput, setUpstreamChannelTopicInput] = useState<
		EditableChannelTopic[]
	>([]);

	const [downstreamChannelTopicInput, setDownstreamChannelTopicInput] =
		useState<EditableChannelTopic[]>([]);

	const [wirepasNetworkAddress, setWirepasNetworkAddress] =
		useState<string>("");
	const [wirepasDiagnosticInterval, setWirepasDiagnosticInterval] =
		useState<number>(0);
	const [wirepasNetworkChannel, setWirepasNetworkChannel] =
		useState<string>("");
	const [wirepasAuthenticationKey, setWirepasAuthenticationKey] =
		useState<string>("");
	const [wirepasEncryptionKey, setWirepasEncryptionKey] =
		useState<string>("");
	const [useBackendAPI, setUseBackendAPI] = useState(false);

	//Fetch backend settings
	const [savedBackend, setSavedBackend] = useState<VelavuBackend | undefined>(
		undefined,
	);
	useCancellableEffect(
		(addPromise) => {
			if (backendID === undefined) {
				setSavedBackend(undefined);
			} else {
				addPromise(
					VelavuAPI.backends.getSpecificBackend(backendID),
				).then((backend: VelavuBackend) => {
					setSavedBackend(backend);

					//Update input state
					setBrokerHostName(backend.config.host);
					setBrokerPort(backend.config.port.toString());

					if (
						typeof backend.config.username === "string" &&
						typeof backend.config.password === "string"
					) {
						setBrokerAuthType(BrokerAuthType.UsernamePassword);
					} else if (
						typeof backend.certificates?.ca_cert === "string" &&
						typeof backend.certificates?.server_cert === "string" &&
						typeof backend.certificates?.private_key === "string"
					) {
						setBrokerAuthType(BrokerAuthType.CertificateKey);
					} else {
						setBrokerAuthType(BrokerAuthType.None);
					}

					setBrokerUsername(backend.config.username ?? "");
					setBrokerPassword(backend.config.password ?? "");

					setBrokerRootCertificate(
						backend.certificates?.ca_cert === undefined ||
							backend.certificates?.ca_cert === null
							? undefined
							: {
									base64: backend.certificates.ca_cert,
									name:
										organizationSettings?.backend?.fileName
											?.rootCertificate ?? "",
							  },
					);
					setBrokerClientCertificate(
						backend.certificates?.server_cert === undefined ||
							backend.certificates?.server_cert === null
							? undefined
							: {
									base64: backend.certificates.server_cert,
									name:
										organizationSettings?.backend?.fileName
											?.clientCertificate ?? "",
							  },
					);
					setBrokerPrivateKey(
						backend.certificates?.private_key === undefined ||
							backend.certificates?.private_key === null
							? undefined
							: {
									base64: backend.certificates.private_key,
									name:
										organizationSettings?.backend?.fileName
											?.privateKey ?? "",
							  },
					);

					setUpstreamChannelTopicInput(
						(backend.config.upstream_eps ?? []).map((ep) => ({
							wirepas_channel: ep.wirepas_channel.toString(),
							mqtt_topic: ep.mqtt_topic,
						})),
					);

					setDownstreamChannelTopicInput(
						(backend.config.downstream_eps ?? []).map((ep) => ({
							wirepas_channel: ep.wirepas_channel.toString(),
							mqtt_topic: ep.mqtt_topic,
						})),
					);

					setWirepasNetworkAddress(
						backend.config.wirepas_config.network_addr.toString(),
					);
					setWirepasDiagnosticInterval(
						backend.config.wirepas_config.diag_interval_s,
					);
					setWirepasNetworkChannel(
						backend.config.wirepas_config.network_chan.toString(),
					);
					setWirepasAuthenticationKey(
						backend.config.wirepas_config.auth_key ?? "",
					);
					setWirepasEncryptionKey(
						backend.config.wirepas_config.encr_key ?? "",
					);
					setUseBackendAPI(backend.config.use_backend_api);
				});
			}
		},
		[/*backendID, */ setSavedBackend],
	);

	const savableBackendState = useMemo(():
		| Omit<VelavuBackend, "id" | "devices">
		| undefined => {
		if (backendType !== BackendType.ThirdParty) return undefined;

		const host = brokerHostName.trim();
		if (host.length === 0) return undefined;
		const port = parseInt(brokerPort);
		if (isNaN(port)) return undefined;

		let username: string | null;
		let password: string | null;
		if (brokerAuthType === BrokerAuthType.UsernamePassword) {
			username = brokerUsername.trim();
			if (username.length === 0) return undefined;
			password = brokerPassword.trim();
			if (password.length === 0) return undefined;
		} else {
			username = null;
			password = null;
		}

		let certificates: VelavuBackendCertificates | null;
		if (brokerAuthType === BrokerAuthType.CertificateKey) {
			const ca_cert = brokerRootCertificate?.base64;
			if (ca_cert === undefined) return undefined;
			const server_cert = brokerClientCertificate?.base64;
			if (server_cert === undefined) return undefined;
			const private_key = brokerPrivateKey?.base64;
			if (private_key === undefined) return undefined;
			certificates = { ca_cert, server_cert, private_key };
		} else {
			certificates = {
				ca_cert: null,
				server_cert: null,
				private_key: null,
			};
		}

		const network_addr = parseInt(wirepasNetworkAddress);
		if (isNaN(network_addr)) return undefined;
		const diag_interval_s = wirepasDiagnosticInterval;
		const network_chan = parseInt(wirepasNetworkChannel);
		if (isNaN(network_chan) || network_chan < 1 || network_chan > 255)
			return undefined;

		const auth_key = wirepasAuthenticationKey.trim();
		const encr_key = wirepasEncryptionKey.trim();

		const upstream_eps: VelavuBackendChannelTopic[] = [];
		for (const endpoint of upstreamChannelTopicInput) {
			const wirepas_channel = parseInt(endpoint.wirepas_channel);
			if (isNaN(wirepas_channel)) return undefined;
			const mqtt_topic = endpoint.mqtt_topic.trim();
			if (mqtt_topic.length === 0) return undefined;
			upstream_eps.push({ wirepas_channel, mqtt_topic });
		}
		if (
			!arrayIsUnique(
				upstream_eps.map((endpoint) => endpoint.wirepas_channel),
			)
		) {
			return undefined;
		}

		const downstream_eps: VelavuBackendChannelTopic[] = [];
		for (const endpoint of downstreamChannelTopicInput) {
			const wirepas_channel = parseInt(endpoint.wirepas_channel);
			if (isNaN(wirepas_channel)) return undefined;
			const mqtt_topic = endpoint.mqtt_topic.trim();
			if (mqtt_topic.length === 0) return undefined;
			downstream_eps.push({ wirepas_channel, mqtt_topic });
		}
		if (
			!arrayIsUnique(
				downstream_eps.map((endpoint) => endpoint.wirepas_channel),
			)
		) {
			return undefined;
		}
		const use_backend_api = useBackendAPI;

		return {
			name: "backend",
			config: {
				host,
				port,
				username,
				password,
				wirepas_config: {
					network_addr,
					network_chan,
					diag_interval_s,
					auth_key,
					encr_key,
				},
				upstream_eps,
				downstream_eps,
				use_backend_api,
			},
			certificates,
		};
	}, [
		backendType,
		brokerAuthType,
		brokerClientCertificate?.base64,
		brokerHostName,
		brokerPassword,
		brokerPort,
		brokerPrivateKey?.base64,
		brokerRootCertificate?.base64,
		brokerUsername,
		downstreamChannelTopicInput,
		upstreamChannelTopicInput,
		wirepasAuthenticationKey,
		wirepasEncryptionKey,
		wirepasNetworkAddress,
		wirepasDiagnosticInterval,
		wirepasNetworkChannel,
		useBackendAPI,
	]);

	const hasDifferingChanges = useMemo(() => {
		return !deepCompare(savedBackend, savableBackendState, {
			collapseDefaultValues: true,
			ignoreKeys: ["id"],
		});
	}, [savedBackend, savableBackendState]);

	const [isSubmitting, setIsSubmitting] = useState(false);

	const saveChanges = useCallback(async () => {
		setIsSubmitting(true);

		try {
			if (backendType === BackendType.Velavu) {
				if (backendID !== undefined) {
					//Delete the backend
					await VelavuAPI.backends.deleteBackend(backendID);
					setSavedBackend(undefined);
					await updateOrganization({
						settings: {
							backend: {
								id: undefined,
								fileName: {
									clientCertificate: undefined,
									privateKey: undefined,
									rootCertificate: undefined,
								},
							},
						},
					});
				}
			} else {
				//Make sure we have savable state
				if (savableBackendState === undefined) return;

				if (backendID === undefined) {
					//Create a new backend
					const backend =
						await VelavuAPI.backends.createNewBackend(
							savableBackendState,
						);
					setSavedBackend(backend);
					await updateOrganization({
						settings: {
							backend: {
								id: backend.id,
								fileName: {
									rootCertificate:
										brokerRootCertificate?.name,
									clientCertificate:
										brokerClientCertificate?.name,
									privateKey: brokerPrivateKey?.name,
								},
							},
						},
					});
				} else {
					//Update the backend
					const backend =
						await VelavuAPI.backends.modifyExistingBackend(
							backendID,
							savableBackendState,
						);
					setSavedBackend(backend);
					await updateOrganization({
						settings: {
							backend: {
								fileName: {
									rootCertificate:
										brokerRootCertificate?.name,
									clientCertificate:
										brokerClientCertificate?.name,
									privateKey: brokerPrivateKey?.name,
								},
							},
						},
					});
				}
			}
		} finally {
			setIsSubmitting(false);
		}
	}, [
		backendType,
		backendID,
		updateOrganization,
		savableBackendState,
		brokerRootCertificate?.name,
		brokerClientCertificate?.name,
		brokerPrivateKey?.name,
		setIsSubmitting,
	]);

	const configureDevice = useCallback(
		async (deviceID: string) => {
			if (backendID === undefined) return;

			//Register the device on the backend
			const updatedBackend = await VelavuAPI.backends.addDevice(
				backendID,
				deviceID,
			);

			//Update the local backend instance
			setSavedBackend(updatedBackend);
		},
		[backendID, setSavedBackend],
	);

	const removeDevice = useCallback(
		async (deviceID: string) => {
			if (backendID === undefined) return;

			//Unregister the device on the backend
			const updatedBackend = await VelavuAPI.backends.removeDevice(
				backendID,
				deviceID,
			);

			//Update the local backend instance
			setSavedBackend(updatedBackend);
		},
		[backendID, setSavedBackend],
	);

	return (
		<div className={styles.container}>
			<h1 className={styles.title}>Broker</h1>

			<div className={styles.optionGrid}>
				<VelavuSelect
					titleText="Broker type"
					fullWidth
					value={backendType}
					onChange={setBackendType}
				>
					<option value={BackendType.Velavu}>Velavu</option>
					<option value={BackendType.ThirdParty}>Third party</option>
				</VelavuSelect>

				{backendType === BackendType.ThirdParty && (
					<>
						<VelavuInput
							style={{ gridColumnStart: 1 }}
							titleText="Broker host name"
							fullWidth
							placeholder="Host name"
							value={brokerHostName}
							onChange={setBrokerHostName}
						/>

						<VelavuInput
							style={{ gridColumnStart: 2 }}
							titleText="Broker port"
							fullWidth
							placeholder="Port number"
							value={brokerPort}
							onChange={setBrokerPort}
						/>

						<VelavuSelect
							titleText="Broker authentication"
							fullWidth
							value={brokerAuthType}
							onChange={setBrokerAuthType}
						>
							<option value={BrokerAuthType.None}>None</option>
							<option value={BrokerAuthType.UsernamePassword}>
								Username + password
							</option>
							<option value={BrokerAuthType.CertificateKey}>
								Certificate + key
							</option>
						</VelavuSelect>

						{/* Username + password authentication */}
						{brokerAuthType === BrokerAuthType.UsernamePassword && (
							<>
								<VelavuInput
									style={{ gridColumnStart: 1 }}
									titleText="Username"
									fullWidth
									placeholder="Enter username"
									value={brokerUsername}
									onChange={setBrokerUsername}
								/>

								<VelavuInput
									style={{ gridColumnStart: 2 }}
									titleText="Password"
									fullWidth
									type="password"
									placeholder="Enter password"
									value={brokerPassword}
									onChange={setBrokerPassword}
								/>
							</>
						)}

						{/* Certificate + key authentication */}
						{brokerAuthType === BrokerAuthType.CertificateKey && (
							<>
								<div
									style={{ gridColumnStart: "span 2" }}
									className={styles.inputRow}
								>
									<FileInputButton
										titleText="Root certificate"
										fullWidth
										fileName={brokerRootCertificate?.name}
										onInput={setBrokerRootCertificate}
									/>
									<FileInputButton
										titleText="Client certificate"
										fullWidth
										fileName={brokerClientCertificate?.name}
										onInput={setBrokerClientCertificate}
									/>
									<FileInputButton
										titleText="Key"
										fullWidth
										fileName={brokerPrivateKey?.name}
										onInput={setBrokerPrivateKey}
									/>
								</div>
							</>
						)}
					</>
				)}
			</div>

			{backendType === BackendType.ThirdParty && (
				<>
					{/*	Devices */}
					<h1 className={styles.title}>Devices</h1>

					<BackendDeviceTable
						className={styles.formBlock}
						devices={savedBackend?.devices}
						configureDevice={configureDevice}
						removeDevice={removeDevice}
						disabled={backendID === undefined}
					/>

					{backendID === undefined && (
						<VelavuNotice
							className={styles.formBlock}
							type="error"
							body="Save your changes to configure devices to this backend"
						/>
					)}

					{/*	Channel topic configuration */}
					<h1 className={styles.title}>
						Channel topic configuration
					</h1>

					<ChannelTopicTable
						className={styles.formBlock}
						title="Upstream"
						channelTopics={upstreamChannelTopicInput}
						setChannelTopics={setUpstreamChannelTopicInput}
					/>

					<ChannelTopicTable
						className={styles.formBlock}
						title="Downstream"
						channelTopics={downstreamChannelTopicInput}
						setChannelTopics={setDownstreamChannelTopicInput}
					/>

					{/* Wirepas network */}
					<h1 className={styles.title}>Wirepas network</h1>

					<div className={styles.optionGrid}>
						<VelavuInput
							style={{ gridColumnStart: 1 }}
							titleText="Network address"
							fullWidth
							placeholder="Network address"
							value={wirepasNetworkAddress}
							onChange={setWirepasNetworkAddress}
						/>

						<VelavuSelect
							style={{ gridColumnStart: 2 }}
							titleText="Diagnostic interval"
							fullWidth
							value={wirepasDiagnosticInterval}
							onChange={setWirepasDiagnosticInterval}
						>
							{[0, 30, 60, 120, 300, 600, 1800].map((value) => (
								<option key={value} value={value}>
									{value}
								</option>
							))}
						</VelavuSelect>

						<VelavuInput
							style={{ gridColumnStart: 1 }}
							titleText="Network channel"
							fullWidth
							placeholder="Network channel"
							value={wirepasNetworkChannel}
							onChange={setWirepasNetworkChannel}
						/>

						<VelavuInput
							style={{ gridColumnStart: 1 }}
							titleText="Authentication key"
							fullWidth
							placeholder="32-character key"
							value={wirepasAuthenticationKey}
							onChange={setWirepasAuthenticationKey}
						/>

						<VelavuInput
							style={{ gridColumnStart: 2 }}
							titleText="Encryption key"
							fullWidth
							placeholder="32-character key"
							value={wirepasEncryptionKey}
							onChange={setWirepasEncryptionKey}
						/>
					</div>

					<VelavuSwitchBlock
						className={styles.formBlock}
						toggled={useBackendAPI}
						onChange={() => setUseBackendAPI(!useBackendAPI)}
						label="Wirepas backend API"
					/>
				</>
			)}

			<div className={styles.footer}>
				<VelavuButton
					label="Save changes"
					disabled={
						(backendType === BackendType.ThirdParty &&
							savableBackendState === undefined) ||
						isSubmitting ||
						!hasDifferingChanges
					}
					onClick={saveChanges}
				/>
			</div>
		</div>
	);
}
