import React from "react";

export function NoAssetsIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="128"
			height="129"
			fill="none"
			viewBox="0 0 128 129"
		>
			<g filter="url(#filter0_dd_8574_211524)">
				<circle cx="64" cy="48.5" r="40" fill="#001A41"></circle>
				<path
					fill="#3A58E2"
					d="M60.14 31.957a7.7 7.7 0 015.72 0l12.665 5.071a3.3 3.3 0 012.075
		  3.06V56.91a3.3 3.3 0 01-2.075 3.064L65.86 65.041a7.7 7.7 0 01-5.72
		  0l-12.665-5.067A3.3 3.3 0 0145.4 56.91V40.088a3.301 3.301 0
		  012.075-3.062l12.665-5.069zm4.902 2.046a5.5 5.5 0 00-4.086 0l-11.495
		  4.598 5.014 2.002 13.539-5.412-2.97-1.188h-.002zm5.933 2.37l-13.539
		  5.414L63 44.015 76.539 38.6l-5.564-2.224v-.002zm-9.075
		  9.57l-14.3-5.72v16.684a1.1 1.1 0 00.69 1.023l12.668
		  5.067c.308.12.623.215.942.282V45.942z"
				></path>
				<g filter="url(#filter1_b_8574_211524)">
					<circle
						cx="79"
						cy="57.5"
						r="12"
						fill="url(#paint0_linear_8574_211524)"
						fillOpacity="0.2"
					></circle>
					<circle
						cx="79"
						cy="57.5"
						r="11.75"
						stroke="url(#paint1_linear_8574_211524)"
						strokeWidth="0.5"
					></circle>
				</g>
				<path
					fill="#fff"
					d="M80.25 58.25a1.25 1.25 0 11-2.5 0v-5.5a1.25 1.25 0 112.5
		  0v5.5zM77.75 62.25a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_dd_8574_211524"
					width="128"
					height="128"
					x="0"
					y="0.5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="16"></feOffset>
					<feGaussianBlur stdDeviation="12"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0
		  0.439216 0 0 0 0.16 0"
					></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_8574_211524"
					></feBlend>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="2"></feOffset>
					<feGaussianBlur stdDeviation="4"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0
		  0.239216 0 0 0 0.04 0"
					></feColorMatrix>
					<feBlend
						in2="effect1_dropShadow_8574_211524"
						result="effect2_dropShadow_8574_211524"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect2_dropShadow_8574_211524"
						result="shape"
					></feBlend>
				</filter>
				<filter
					id="filter1_b_8574_211524"
					width="36"
					height="36"
					x="61"
					y="39.5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feGaussianBlur
						in="BackgroundImage"
						stdDeviation="3"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_8574_211524"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_8574_211524"
						result="shape"
					></feBlend>
				</filter>
				<linearGradient
					id="paint0_linear_8574_211524"
					x1="79"
					x2="79"
					y1="45.5"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0.25"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_8574_211524"
					x1="91"
					x2="67"
					y1="45.5"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
}

export function NoDevicesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="128"
			height="129"
			fill="none"
			viewBox="0 0 128 129"
		>
			<g filter="url(#filter0_dd_8746_19846)">
				<circle cx="64" cy="48.5" r="40" fill="#001A41"></circle>
				<g clipPath="url(#clip0_8746_19846)">
					<path
						fill="#3A58E2"
						d="M64.019
			32.86h-2.433c-1.33.003-2.66.088-3.982.254-1.838.242-3.62.808-5.269
			1.674-3.397 1.828-5.975 4.922-7.199 8.64-1.224 3.717-1 7.773.625
			11.327 1.642 3.567 4.572 6.348 8.176 7.76 1.608.601 3.292.961 5 1.07
			1.7.134 3.377.12 5.063.12s3.362 0 5.058-.12a17.434 17.434 0
			005.005-1.07c3.604-1.412 6.534-4.193 8.176-7.76a15.542 15.542 0
			00.633-11.33c-1.224-3.72-3.806-6.813-7.207-8.637a15.766 15.766 0
			00-5.27-1.674 32.632 32.632 0 00-3.981-.255h-2.395z"
					></path>
					<path
						stroke="#00255D"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M64.019 61.077h-2.41a20.4 20.4 0 01-6.104-.786 12.337 12.337 0
			01-6.184-4.574 12.768 12.768 0
			01-2.358-7.417c0-1.204.169-2.403.498-3.559a12.683 12.683 0
			012.759-4.977 12.338 12.338 0 014.628-3.22 13.66 13.66 0 013.404-.83
			28.995 28.995 0 013.02-.187h5.513c1.009.01 2.017.072 3.02.187
			1.164.13 2.307.409 3.404.83a12.338 12.338 0 014.627 3.22 12.683
			12.683 0 012.76 4.977 12.95 12.95 0 01-.062 7.32 12.652 12.652 0
			01-3.01 5.107 12.296 12.296 0 01-4.973
			3.123c-1.985.57-4.043.835-6.104.786h-2.428z"
					></path>
					<path
						stroke="#00255D"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M64.019 57.484H61.2a15.576 15.576 0 01-4.729-.638 8.82 8.82 0
			01-3.49-2.246 9.071 9.071 0 01-2.112-3.62 9.298 9.298 0 01.136-5.798
			9.103 9.103 0 011.89-3.099 8.881 8.881 0 012.933-2.086 9.594 9.594 0
			012.348-.653 23.578 23.578 0 013.386-.235h4.945a23.491 23.491 0
			013.385.235 9.571 9.571 0 012.348.653c.69.3 1.338.69 1.93 1.16a9.036
			9.036 0 013.002 4.434 9.216 9.216 0 01-.006 5.39 9.071 9.071 0
			01-2.11 3.602 8.82 8.82 0 01-3.478
			2.24c-1.54.455-3.14.67-4.743.637-.939.024-1.878.024-2.817.024z"
					></path>
					<path
						stroke="#00255D"
						strokeLinecap="round"
						strokeLinejoin="round"
						d="M64.019 53.858h-2.513a19.542 19.542 0 01-2.845-.201 6.483 6.483 0
			01-.94-.216 4.13 4.13 0 01-.511-.192 5.01 5.01 0 01-.756-.408 5.424
			5.424 0 01-1.653-1.717 4.99 4.99 0 01-.357-.71 5.844 5.844 0
			01-.24-.7c0-.096-.047-.192-.065-.288a5.412 5.412 0
			01-.113-1.031v-.096c-.001-.24.015-.481.047-.72.026-.218.067-.434.122-.647v-.048a6.84
			6.84 0 01.188-.575c.09-.233.197-.46.32-.677l.088-.158a4.6 4.6 0
			01.273-.403c.074-.105.154-.206.24-.302l.182-.206c.132-.135.263-.264.404-.384l.136-.115c.141-.115.287-.216.437-.317a4.898
			4.898 0 01.958-.484 5.46 5.46 0 01.827-.245c.366-.077.737-.13
			1.103-.168a25.35 25.35 0 011.587-.096c.662 0 1.33-.023
			1.991-.023h2.16c.663 0 1.325 0 1.992.023.526 0 1.056.044
			1.587.096.366.039.737.091 1.103.168a5.629 5.629 0
			011.433.518l.352.211c.15.101.296.202.437.317l.136.115c.14.12.273.248.399.384.066.067.127.134.188.206.085.096.165.197.24.302.097.13.188.264.272.403l.089.158a5.205
			5.205 0 01.502 1.252v.048a5.829 5.829 0 01.17 1.367v.091a5.404 5.404
			0 01-.419 2.02 5.26 5.26 0 01-1.024 1.592 5.33 5.33 0
			01-.985.834c-.24.158-.494.294-.757.408-.166.075-.337.14-.511.192a6.484
			6.484 0
			01-.94.216c-.942.136-1.893.203-2.845.201-.831.005-1.672.005-2.49.005z"
					></path>
				</g>
				<g filter="url(#filter1_b_8746_19846)">
					<circle
						cx="79"
						cy="57.5"
						r="12"
						fill="url(#paint0_linear_8746_19846)"
						fillOpacity="0.2"
					></circle>
					<circle
						cx="79"
						cy="57.5"
						r="11.75"
						stroke="url(#paint1_linear_8746_19846)"
						strokeWidth="0.5"
					></circle>
				</g>
				<path
					fill="#fff"
					d="M80.25 58.25a1.25 1.25 0 11-2.5 0v-5.5a1.25 1.25 0 112.5
		  0v5.5zM77.75 62.25a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_dd_8746_19846"
					width="128"
					height="128"
					x="0"
					y="0.5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="16"></feOffset>
					<feGaussianBlur stdDeviation="12"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0
		  0.439216 0 0 0 0.16 0"
					></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_8746_19846"
					></feBlend>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="2"></feOffset>
					<feGaussianBlur stdDeviation="4"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0
		  0.239216 0 0 0 0.04 0"
					></feColorMatrix>
					<feBlend
						in2="effect1_dropShadow_8746_19846"
						result="effect2_dropShadow_8746_19846"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect2_dropShadow_8746_19846"
						result="shape"
					></feBlend>
				</filter>
				<filter
					id="filter1_b_8746_19846"
					width="36"
					height="36"
					x="61"
					y="39.5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feGaussianBlur
						in="BackgroundImage"
						stdDeviation="3"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_8746_19846"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_8746_19846"
						result="shape"
					></feBlend>
				</filter>
				<linearGradient
					id="paint0_linear_8746_19846"
					x1="79"
					x2="79"
					y1="45.5"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0.25"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_8746_19846"
					x1="91"
					x2="67"
					y1="45.5"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
				</linearGradient>
				<clipPath id="clip0_8746_19846">
					<path
						fill="#fff"
						d="M0 0H40V31.579H0z"
						transform="translate(44 32.5)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export function NoAlertsIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="128"
			height="129"
			fill="none"
			viewBox="0 0 128 129"
		>
			<g filter="url(#filter0_dd_8718_12311)">
				<circle cx="64" cy="48.5" r="40" fill="#001A41"></circle>
				<path
					fill="#3A58E2"
					d="M77.459
					57.805l-2.382-2.382v-9.23c0-5.668-3.028-10.413-8.308-11.668v-1.256A2.765
					2.765 0 0064 30.5a2.765 2.765 0 00-2.769 2.77v1.255c-5.299
					1.255-8.308 5.981-8.308 11.667v9.231l-2.381 2.382c-1.163
					1.163-.351 3.157 1.292 3.157h24.314c1.661 0 2.474-1.994
					1.31-3.157zM58.46 55.793c0 .815-.66 1.476-1.476 1.476a.37.37
					0 01-.37-.369V46.192c0-4.578 2.788-8.307 7.385-8.307-2.769
					0-5.539 3.729-5.539 8.307v9.6zm5.54 10.707a3.703 3.703 0
					003.691-3.692h-7.384A3.692 3.692 0 0064 66.5z"
				></path>
				<g filter="url(#filter1_b_8718_12311)">
					<circle
						cx="79"
						cy="57.5"
						r="12"
						fill="url(#paint0_linear_8718_12311)"
						fillOpacity="0.2"
					></circle>
					<circle
						cx="79"
						cy="57.5"
						r="11.75"
						stroke="url(#paint1_linear_8718_12311)"
						strokeWidth="0.5"
					></circle>
				</g>
				<path
					fill="#fff"
					d="M82.88 53.789L77 59.669l-1.88-1.88a.997.997 0 00-1.41
					1.41l2.59 2.59c.39.39 1.02.39 1.41 0l6.59-6.59a.996.996 0
					000-1.41c-.39-.39-1.03-.39-1.42 0z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_dd_8718_12311"
					width="128"
					height="128"
					x="0"
					y="0.5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="16"></feOffset>
					<feGaussianBlur stdDeviation="12"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0
					0 0 0.439216 0 0 0 0.16 0"
					></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_8718_12311"
					></feBlend>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="2"></feOffset>
					<feGaussianBlur stdDeviation="4"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0
					0 0 0.239216 0 0 0 0.04 0"
					></feColorMatrix>
					<feBlend
						in2="effect1_dropShadow_8718_12311"
						result="effect2_dropShadow_8718_12311"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect2_dropShadow_8718_12311"
						result="shape"
					></feBlend>
				</filter>
				<filter
					id="filter1_b_8718_12311"
					width="36"
					height="36"
					x="61"
					y="39.5"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feGaussianBlur
						in="BackgroundImage"
						stdDeviation="3"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_8718_12311"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_8718_12311"
						result="shape"
					></feBlend>
				</filter>
				<linearGradient
					id="paint0_linear_8718_12311"
					x1="79"
					x2="79"
					y1="45.5"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0.25"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_8718_12311"
					x1="91"
					x2="67"
					y1="45.5"
					y2="69.5"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
				</linearGradient>
			</defs>
		</svg>
	);
}

export function NoSitesIcon() {
	return (
		<svg
			width="80"
			height="80"
			viewBox="0 0 80 80"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="40" cy="40" r="40" fill="#001A41" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M40 58.3346C40 58.3346 55 48.3346 55 36.668C55 32.6897 53.4196 28.8744 50.6066 26.0614C47.7936 23.2483 43.9782 21.668 40 21.668C36.0218 21.668 32.2064 23.2483 29.3934 26.0614C26.5804 28.8744 25 32.6897 25 36.668C25 48.3346 40 58.3346 40 58.3346ZM45 36.668C45 39.4294 42.7614 41.668 40 41.668C37.2386 41.668 35 39.4294 35 36.668C35 33.9065 37.2386 31.668 40 31.668C42.7614 31.668 45 33.9065 45 36.668Z"
				fill="#3A58E2"
			/>
			<g filter="url(#filter0_b_8877_13590)">
				<circle
					cx="55"
					cy="49"
					r="12"
					fill="url(#paint0_linear_8877_13590)"
					fillOpacity="0.2"
				/>
				<circle
					cx="55"
					cy="49"
					r="11.75"
					stroke="url(#paint1_linear_8877_13590)"
					strokeWidth="0.5"
				/>
			</g>
			<path
				d="M56.25 49.75C56.25 50.4404 55.6904 51 55 51C54.3096 51 53.75 50.4404 53.75 49.75V44.25C53.75 43.5596 54.3096 43 55 43C55.6904 43 56.25 43.5596 56.25 44.25V49.75Z"
				fill="white"
			/>
			<path
				d="M53.75 53.75C53.75 53.0596 54.3096 52.5 55 52.5C55.6904 52.5 56.25 53.0596 56.25 53.75C56.25 54.4404 55.6904 55 55 55C54.3096 55 53.75 54.4404 53.75 53.75Z"
				fill="white"
			/>
			<defs>
				<filter
					id="filter0_b_8877_13590"
					x="37"
					y="31"
					width="36"
					height="36"
					filterUnits="userSpaceOnUse"
					colorInterpolationFilters="sRGB"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix" />
					<feGaussianBlur in="BackgroundImage" stdDeviation="3" />
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_8877_13590"
					/>
					<feBlend
						mode="normal"
						in="SourceGraphic"
						in2="effect1_backgroundBlur_8877_13590"
						result="shape"
					/>
				</filter>
				<linearGradient
					id="paint0_linear_8877_13590"
					x1="55"
					y1="37"
					x2="55"
					y2="61"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0.25" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_8877_13590"
					x1="67"
					y1="37"
					x2="43"
					y2="61"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="white" stopOpacity="0" />
				</linearGradient>
			</defs>
		</svg>
	);
}

export function NoFloorsIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="128"
			height="128"
			fill="none"
			viewBox="0 0 128 128"
		>
			<g filter="url(#filter0_dd_8718_14156)">
				<circle cx="64" cy="48" r="40" fill="#001A41"></circle>
				<g clipPath="url(#clip0_8718_14156)">
					<rect
						width="32"
						height="32"
						x="41"
						y="48"
						fill="#3A58E2"
						rx="8"
						transform="rotate(-45 41 48)"
					></rect>
					<path
						stroke="#001A41"
						strokeLinecap="round"
						strokeWidth="1.5"
						d="M74.94 60.727l-7.07-7.071 12.02-12.021M55.142 31.028l8.486
			8.485m2.828-2.828l-5.657 5.656M53.02 50.123l-5.303 5.303M53.021
			50.121v-8.485M68.577 52.948l-12.02 12.02"
					></path>
				</g>
				<g filter="url(#filter1_b_8718_14156)">
					<circle
						cx="79"
						cy="57"
						r="12"
						fill="url(#paint0_linear_8718_14156)"
						fillOpacity="0.2"
					></circle>
					<circle
						cx="79"
						cy="57"
						r="11.75"
						stroke="url(#paint1_linear_8718_14156)"
						strokeWidth="0.5"
					></circle>
				</g>
				<path
					fill="#fff"
					d="M80.25 57.75a1.25 1.25 0 11-2.5 0v-5.5a1.25 1.25 0 112.5
		  0v5.5zM77.75 61.75a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_dd_8718_14156"
					width="128"
					height="128"
					x="0"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="16"></feOffset>
					<feGaussianBlur stdDeviation="12"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0
		  0.439216 0 0 0 0.16 0"
					></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_8718_14156"
					></feBlend>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="2"></feOffset>
					<feGaussianBlur stdDeviation="4"></feGaussianBlur>
					<feColorMatrix
						values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0
		  0.239216 0 0 0 0.04 0"
					></feColorMatrix>
					<feBlend
						in2="effect1_dropShadow_8718_14156"
						result="effect2_dropShadow_8718_14156"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect2_dropShadow_8718_14156"
						result="shape"
					></feBlend>
				</filter>
				<filter
					id="filter1_b_8718_14156"
					width="36"
					height="36"
					x="61"
					y="39"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feGaussianBlur
						in="BackgroundImage"
						stdDeviation="3"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_8718_14156"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_8718_14156"
						result="shape"
					></feBlend>
				</filter>
				<linearGradient
					id="paint0_linear_8718_14156"
					x1="79"
					x2="79"
					y1="45"
					y2="69"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0.25"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_8718_14156"
					x1="91"
					x2="67"
					y1="45"
					y2="69"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
				</linearGradient>
				<clipPath id="clip0_8718_14156">
					<rect
						width="32"
						height="32"
						x="41"
						y="48"
						fill="#fff"
						rx="8"
						transform="rotate(-45 41 48)"
					></rect>
				</clipPath>
			</defs>
		</svg>
	);
}

export function NoTripIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="128"
			height="128"
			fill="none"
			viewBox="0 0 128 128"
		>
			<g filter="url(#filter0_dd_8746_20742)">
				<circle cx="64" cy="48" r="40" fill="#001A41"></circle>
				<g clipPath="url(#clip0_8746_20742)">
					<path
						fill="#3A58E2"
						fillRule="evenodd"
						d="M88.003 49.316c.823-.736.82-2.008.04-2.788l-9.9-9.9c-.781-.781-2.042-.77-2.905-.08a18.002 18.002 0 01-22.476 0c-.862-.691-2.123-.7-2.904.08l-9.9 9.9c-.781.782-.785 2.053.038 2.79a36 36 0 0048.007-.002zm-39.386-4.24a.507.507 0 00-.15-.695c-.347-.23-.688-.468-1.024-.714a.507.507 0 00-.703.1l-.606.794a.494.494 0 00.098.698c.38.28.767.55 1.16.81.23.152.54.083.688-.15l.537-.844zm8.395 3.559a.508.508 0 00-.37-.607 27.019 27.019 0 01-3.32-1.092.506.506 0 00-.656.27l-.39.92a.494.494 0 00.267.652 29 29 0 003.635 1.195.493.493 0 00.601-.366l.233-.972zm6.803.248c-.583.009-1.165 0-1.747-.03a.508.508 0 00-.534.47l-.068.998a.494.494 0 00.466.528c1.275.065 2.553.045 3.825-.058a.493.493 0 00.45-.543l-.1-.995a.508.508 0 00-.547-.453 26.97 26.97 0 01-1.745.083zm11.092-2.02a.507.507 0 00-.665-.25 26.985 26.985 0 01-3.285 1.195.506.506 0 00-.35.617l.263.965a.494.494 0 00.612.347 29 29 0 003.596-1.306.494.494 0 00.248-.66l-.419-.908zm5.818-3.62a.508.508 0 00-.707-.078c-.329.257-.663.506-1.001.746a.507.507 0 00-.129.698l.564.826a.494.494 0 00.693.128c.384-.271.762-.553 1.133-.843a.494.494 0 00.076-.701l-.629-.776z"
						clipRule="evenodd"
					></path>
				</g>
				<g filter="url(#filter1_b_8746_20742)">
					<circle
						cx="79"
						cy="57"
						r="12"
						fill="url(#paint0_linear_8746_20742)"
						fillOpacity="0.2"
					></circle>
					<circle
						cx="79"
						cy="57"
						r="11.75"
						stroke="url(#paint1_linear_8746_20742)"
						strokeWidth="0.5"
					></circle>
				</g>
				<path
					fill="#fff"
					d="M80.25 57.75a1.25 1.25 0 11-2.5 0v-5.5a1.25 1.25 0 112.5 0v5.5zM77.75 61.75a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0z"
				></path>
			</g>
			<defs>
				<filter
					id="filter0_dd_8746_20742"
					width="128"
					height="128"
					x="0"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="16"></feOffset>
					<feGaussianBlur stdDeviation="12"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0.376471 0 0 0 0 0.380392 0 0 0 0 0.439216 0 0 0 0.16 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_8746_20742"
					></feBlend>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dy="2"></feOffset>
					<feGaussianBlur stdDeviation="4"></feGaussianBlur>
					<feColorMatrix values="0 0 0 0 0.156863 0 0 0 0 0.160784 0 0 0 0 0.239216 0 0 0 0.04 0"></feColorMatrix>
					<feBlend
						in2="effect1_dropShadow_8746_20742"
						result="effect2_dropShadow_8746_20742"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect2_dropShadow_8746_20742"
						result="shape"
					></feBlend>
				</filter>
				<filter
					id="filter1_b_8746_20742"
					width="36"
					height="36"
					x="61"
					y="39"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood
						floodOpacity="0"
						result="BackgroundImageFix"
					></feFlood>
					<feGaussianBlur
						in="BackgroundImage"
						stdDeviation="3"
					></feGaussianBlur>
					<feComposite
						in2="SourceAlpha"
						operator="in"
						result="effect1_backgroundBlur_8746_20742"
					></feComposite>
					<feBlend
						in="SourceGraphic"
						in2="effect1_backgroundBlur_8746_20742"
						result="shape"
					></feBlend>
				</filter>
				<linearGradient
					id="paint0_linear_8746_20742"
					x1="79"
					x2="79"
					y1="45"
					y2="69"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0.25"></stop>
				</linearGradient>
				<linearGradient
					id="paint1_linear_8746_20742"
					x1="91"
					x2="67"
					y1="45"
					y2="69"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#fff"></stop>
					<stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
				</linearGradient>
				<clipPath id="clip0_8746_20742">
					<path
						fill="#fff"
						d="M0 0H48V48H0z"
						transform="rotate(-135 48.958 27.687)"
					></path>
				</clipPath>
			</defs>
		</svg>
	);
}
