//A type that contains an asset's class and type
import { AssetCategory } from "velavu-js-api";

export default interface AssetCategorization {
	category: AssetCategory;
	group: string;
}

export function mapCategoryName(category: AssetCategory): string {
	switch (category) {
		case AssetCategory.Vehicle:
			return "Vehicle";
		case AssetCategory.Equipment:
			return "Equipment";
		default:
			return "Category";
	}
}

export function mapCategoryColor(category: AssetCategory): [string, string] {
	switch (category) {
		case AssetCategory.Vehicle:
			return ["#E5FAFF", "#00819C"];
		case AssetCategory.Equipment:
			return ["#F6EDFF", "#4D0099"];
		default:
			return ["#EEEEEE", "black"];
	}
}

const serializationSplitter = "-";

export function serializeAssetCategorization(
	categorization: AssetCategorization,
): string {
	return (
		categorization.category + serializationSplitter + categorization.group
	);
}

export function deserializeAssetCategorization(
	serialized: string,
): AssetCategorization | undefined {
	const splitIndex = serialized.indexOf(serializationSplitter);
	if (splitIndex === -1) {
		console.warn(
			`Failed to deserialize categorization "${serialized}": no split index`,
		);
		return undefined;
	}

	const category = serialized.slice(0, splitIndex);
	const group = serialized.slice(splitIndex + 1);

	if (
		!Object.values(AssetCategory as Record<string, string>).includes(
			category,
		)
	) {
		console.warn(
			`Failed to deserialize categorization "${serialized}": illegal category`,
		);
		return undefined;
	}

	return { category: category as AssetCategory, group: group };
}

/**
 * Converts a record of categories to an array of their groups into a flat
 * array of {@link AssetCategorization}.
 */
export function convertCategoryGroupsToAssetCategorizations(
	categoryGroups: Record<AssetCategory, string[]>,
): AssetCategorization[] {
	return Object.entries(categoryGroups).flatMap<AssetCategorization>(
		([category, group]) => {
			return group.map<AssetCategorization>((group) => {
				return {
					category: category as AssetCategory,
					group: group,
				};
			});
		},
	);
}

///Returns a de-duplicated array of asset categorizations, sorted by category, then by occurrence in descending order
export function splitReduceAssetCategorizations(
	input: AssetCategorization[],
): AssetCategorization[] {
	const equipment = input.filter(
		(item) => item.category === AssetCategory.Equipment,
	);
	const vehicles = input.filter(
		(item) => item.category === AssetCategory.Vehicle,
	);

	const sortedEquipment = reduceAssetCategorizations(equipment);
	const sortedVehicles = reduceAssetCategorizations(vehicles);

	return [...sortedEquipment, ...sortedVehicles];
}

///Returns a de-duplicated array of asset categorizations, sorted by occurrence in descending order
export function reduceAssetCategorizations(
	input: AssetCategorization[],
): AssetCategorization[] {
	const occurrencesMap: Record<string, number> = {};
	const uniqueObjects: AssetCategorization[] = [];

	// Count occurrences and create an array of unique objects
	for (const obj of input) {
		const key = serializeAssetCategorization(obj);
		if (!occurrencesMap[key]) {
			occurrencesMap[key] = 1;
			uniqueObjects.push(obj);
		} else {
			occurrencesMap[key]++;
		}
	}

	// Sort unique objects by occurrence in descending order
	uniqueObjects.sort(
		(a, b) =>
			occurrencesMap[serializeAssetCategorization(b)] -
			occurrencesMap[serializeAssetCategorization(a)],
	);

	return uniqueObjects;
}
