import { makeStyleable, StyleableIcon } from "../../../../helper/icon-helper";
import IconVelavuAssets from "../../../../dynamicicons/icon-velavu-assets";
import IconVelavuSite from "../../../../dynamicicons/icon-velavu-site";
import IconVelavuGeofence from "../../../../dynamicicons/icon-velavu-geofence";

export enum MapListDisplayType {
	Asset,
	Site,
	Geofence,
}

/**
 * Gets a StyleableIcon used to represent a given MapListDisplayType
 */
export function mapListDisplayTypeToIcon(
	displayType: MapListDisplayType,
): StyleableIcon {
	switch (displayType) {
		case MapListDisplayType.Asset:
			return makeStyleable(IconVelavuAssets);
		case MapListDisplayType.Site:
			return makeStyleable(IconVelavuSite);
		case MapListDisplayType.Geofence:
			return makeStyleable(IconVelavuGeofence);
	}
}
