import React, { CSSProperties } from "react";

export default function IconError(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2Z"
				fill={props.color}
			/>
			<path
				d="M13.25 12.75C13.25 13.4404 12.6904 14 12 14C11.3096 14 10.75 13.4404 10.75 12.75V7.25C10.75 6.55964 11.3096 6 12 6C12.6904 6 13.25 6.55964 13.25 7.25V12.75Z"
				fill={props.color}
			/>
			<path
				d="M10.75 16.75C10.75 16.0596 11.3096 15.5 12 15.5C12.6904 15.5 13.25 16.0596 13.25 16.75C13.25 17.4404 12.6904 18 12 18C11.3096 18 10.75 17.4404 10.75 16.75Z"
				fill={props.color}
			/>
		</svg>
	);
}
