import React, { CSSProperties } from "react";
import styles from "./velavu-select.module.scss";

interface VelavuSelectProps<T> {
	className?: string;
	style?: CSSProperties;
	children?: React.ReactNode;

	value?: T;
	onChange?: (value: T) => void;
	disabled?: boolean;

	titleText?: string;

	fullWidth?: boolean;
	bottomPadding?: boolean;
}

export default function VelavuSelect<T extends string>(
	props: VelavuSelectProps<T>,
): React.JSX.Element;
export default function VelavuSelect<T extends number>(
	props: VelavuSelectProps<T>,
): React.JSX.Element;
export default function VelavuSelect<T extends string | number>(
	props: VelavuSelectProps<T>,
) {
	return (
		<div
			style={props.style}
			className={[
				styles.container,
				props.className,
				props.bottomPadding ? styles.bottomPadding : undefined,
			]
				.filter((value) => value)
				.join(" ")}
		>
			{props.titleText && (
				<span className={styles.titleText}>{props.titleText}</span>
			)}
			<select
				className={`${styles.select} ${
					props.fullWidth ? styles.fullWidth : undefined
				}`}
				value={props.value}
				onChange={(event) =>
					props.onChange &&
					props.onChange(
						(typeof props.value === "number"
							? Number(event.target.value)
							: event.target.value) as T,
					)
				}
				disabled={props.disabled}
			>
				{props.children}
			</select>
		</div>
	);
}
