import React from "react";
import { Link } from "react-router-dom";
import IconAdd from "../../dynamicicons/icon-add";
import imageAsset from "../../img/asset.svg";
import OnboardingFrame from "./onboarding-frame";
import { OnboardingFrameProps } from "./onboarding-shared-data";
import styles from "./onboarding-step-assets.module.scss";

export default function OnboardingStepAssets(props: OnboardingFrameProps) {
	return (
		<OnboardingFrame
			title="Add assets"
			description="Get started by adding your first asset"
			buttonSecondary={{
				label: "Back",
				onClick: props.navigateBackwards,
			}}
			buttonPrimary={{ label: "Finish", onClick: props.navigateForwards }}
			centerContent
			notice="Assets can be added at any point on the Assets page."
		>
			<Link
				className={styles.content}
				to="/assets/create"
				onClick={props.navigateForwards}
			>
				<img src={imageAsset} alt="" />
				<div className={styles.button}>
					<IconAdd size={16} color="#00255D" />
					<span className={styles.buttonLabel}>Add asset</span>
				</div>
			</Link>
		</OnboardingFrame>
	);
}
