import React from "react";

const signalColors = ["#CCD6E5", "#DA3E52", "#FFD338", "#9CE2BF", "#00B283"];

//A signal that can change its colors to represent different signal strengths
export default function DynamicSignal(props: { strength: number | undefined }) {
	return (
		<svg
			width="24px"
			height="24px"
			viewBox="0 0 24 24"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 15C3 14.1716 3.67157 13.5 4.5 13.5C5.32843 13.5 6 14.1716 6 15V18C6 18.8284 5.32843 19.5 4.5 19.5C3.67157 19.5 3 18.8284 3 18V15Z"
				fill={
					props.strength && props.strength >= 1
						? signalColors[props.strength]
						: signalColors[0]
				}
			/>
			<path
				d="M8 12C8 11.1716 8.67157 10.5 9.5 10.5C10.3284 10.5 11 11.1716 11 12V18C11 18.8284 10.3284 19.5 9.5 19.5C8.67157 19.5 8 18.8284 8 18V12Z"
				fill={
					props.strength && props.strength >= 2
						? signalColors[props.strength]
						: signalColors[0]
				}
			/>
			<path
				d="M13 9C13 8.17157 13.6716 7.5 14.5 7.5C15.3284 7.5 16 8.17157 16 9V18C16 18.8284 15.3284 19.5 14.5 19.5C13.6716 19.5 13 18.8284 13 18V9Z"
				fill={
					props.strength && props.strength >= 3
						? signalColors[props.strength]
						: signalColors[0]
				}
			/>
			<path
				d="M18 6C18 5.17157 18.6716 4.5 19.5 4.5C20.3284 4.5 21 5.17157 21 6V18C21 18.8284 20.3284 19.5 19.5 19.5C18.6716 19.5 18 18.8284 18 18V6Z"
				fill={
					props.strength && props.strength === 4
						? signalColors[props.strength]
						: signalColors[0]
				}
			/>
			{!props.strength && (
				<path
					d="M5.99431 4.35266L7.06338 3.29202C7.45274 2.90266 8.08401 2.90266 8.47337 3.29202C8.86274 3.68138 8.86274 4.31266 8.47337 4.70202L7.40431 5.76266L8.47338 6.82755C8.86274 7.21691 8.86274 7.84819 8.47338 8.23755C8.08402 8.62691 7.45274 8.62691 7.06338 8.23755L5.99431 7.17266L4.93362 8.23756C4.54426 8.62692 3.91298 8.62692 3.52362 8.23756C3.13426 7.8482 3.13426 7.21692 3.52362 6.82756L4.58431 5.76266L3.52783 4.70203C3.13847 4.31267 3.13847 3.68139 3.52783 3.29203C3.91719 2.90267 4.54847 2.90267 4.93783 3.29203L5.99431 4.35266Z"
					fill="#00255D"
				/>
			)}
		</svg>
	);
}

export function DynamicSignalLabel(props: { strength: number | undefined }) {
	let signalLabel: string;
	if (props.strength === 0) signalLabel = "Unknown";
	else if (props.strength === 1) signalLabel = "Weak";
	else if (props.strength === 2) signalLabel = "Fair";
	else if (props.strength === 3) signalLabel = "Good";
	else signalLabel = "Excellent";

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
			}}
		>
			<DynamicSignal strength={props.strength} />
			<span style={{ marginLeft: 4 }}>{signalLabel}</span>
		</div>
	);
}

export function mapStrengthRSRP(value: number | undefined): number | undefined {
	if (!value) return 0;
	else if (value < 20) return 1;
	else if (value < 40) return 2;
	else if (value < 60) return 3;
	else return 4;
}

export function mapStrengthSNR(value: number | undefined): number | undefined {
	if (!value) return 0;
	else if (value < 20) return 1;
	else if (value < 25) return 2;
	else if (value < 30) return 3;
	else return 4;
}
