import React, { CSSProperties } from "react";

export default function IconWall(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.8995 10.1213C16.9758 11.045 15.5998 11.2404 14.4846 10.7077L10.7077 14.4847C11.2404 15.5998 11.045 16.9759 10.1213 17.8995C8.94975 19.0711 7.05025 19.0711 5.87868 17.8995C4.70711 16.7279 4.70711 14.8285 5.87868 13.6569C6.8023 12.7333 8.17835 12.5378 9.29347 13.0705L13.0704 9.29349C12.5377 8.17836 12.7332 6.80231 13.6568 5.87868C14.8284 4.70711 16.7279 4.70711 17.8995 5.87868C19.0711 7.05025 19.0711 8.94975 17.8995 10.1213ZM15.0711 8.70711C15.4616 9.09763 16.0948 9.09763 16.4853 8.70711C16.8758 8.31658 16.8758 7.68342 16.4853 7.29289C16.0948 6.90237 15.4616 6.90237 15.0711 7.29289C14.6805 7.68342 14.6805 8.31658 15.0711 8.70711ZM8.70711 16.4853C8.31658 16.8758 7.68342 16.8758 7.29289 16.4853C6.90237 16.0948 6.90237 15.4616 7.29289 15.0711C7.68342 14.6806 8.31658 14.6806 8.70711 15.0711C9.09763 15.4616 9.09763 16.0948 8.70711 16.4853Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
