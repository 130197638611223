import React, { CSSProperties } from "react";

export default function IconYesFile(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			width={props.size ?? "42"}
			height={props.size ?? "58"}
			style={props.style}
			viewBox="0 0 42 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.2566 0.5C30.4584 0.5 31.6102 0.980704 32.4555 1.83499L40.1989 9.66121C41.0324 10.5037 41.5 11.641 41.5 12.8262L41.5 53C41.5 55.4853 39.4853 57.5 37 57.5L5 57.5C2.51472 57.5 0.500003 55.4853 0.500003 53L0.5 5C0.5 2.51472 2.51472 0.500003 5 0.500003L29.2566 0.5ZM29.5 1.50847V10C29.5 11.3807 30.6193 12.5 32 12.5H40.4848C40.4097 11.6977 40.0594 10.9421 39.488 10.3645L31.7446 2.53833C31.1445 1.9318 30.3457 1.56739 29.5 1.50847ZM40.5 13.5H32C30.067 13.5 28.5 11.933 28.5 10V1.5L5 1.5C3.067 1.5 1.5 3.06701 1.5 5L1.5 53C1.5 54.933 3.06701 56.5 5 56.5L37 56.5C38.933 56.5 40.5 54.933 40.5 53L40.5 13.5Z"
				fill={props.color ?? "#3A58E2"}
			/>
			<path
				d="M21.5 20C15.98 20 11.5 24.48 11.5 30C11.5 35.52 15.98 40 21.5 40C27.02 40 31.5 35.52 31.5 30C31.5 24.48 27.02 20 21.5 20ZM21.5 38C17.09 38 13.5 34.41 13.5 30C13.5 25.59 17.09 22 21.5 22C25.91 22 29.5 25.59 29.5 30C29.5 34.41 25.91 38 21.5 38ZM25.38 26.29L19.5 32.17L17.62 30.29C17.433 30.103 17.1794 29.998 16.915 29.998C16.6506 29.998 16.397 30.103 16.21 30.29C16.023 30.477 15.918 30.7306 15.918 30.995C15.918 31.2594 16.023 31.513 16.21 31.7L18.8 34.29C19.19 34.68 19.82 34.68 20.21 34.29L26.8 27.7C26.8927 27.6075 26.9663 27.4976 27.0164 27.3766C27.0666 27.2557 27.0924 27.126 27.0924 26.995C27.0924 26.864 27.0666 26.7343 27.0164 26.6134C26.9663 26.4924 26.8927 26.3825 26.8 26.29C26.41 25.9 25.77 25.9 25.38 26.29Z"
				fill={props.color ?? "#3A58E2"}
			/>
		</svg>
	);
}
