import React, { CSSProperties } from "react";

export default function IconVelavuTags(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.6794 9.67198C18.4284 9.25676 17.9949 8.8025 16.5962 7.4038C15.1975 6.00511 14.7432 5.57156 14.328 5.32055C12.8966 4.45524 11.1034 4.45524 9.67198 5.32055C9.25676 5.57156 8.8025 6.00511 7.4038 7.4038C6.00511 8.8025 5.57156 9.25677 5.32055 9.67198C4.45524 11.1034 4.45524 12.8966 5.32055 14.328C5.57156 14.7432 6.00511 15.1975 7.4038 16.5962C8.8025 17.9949 9.25677 18.4284 9.67198 18.6794C11.1034 19.5448 12.8966 19.5448 14.328 18.6794C14.7432 18.4284 15.1975 17.9949 16.5962 16.5962C17.9949 15.1975 18.4284 14.7432 18.6794 14.328C19.5448 12.8966 19.5448 11.1034 18.6794 9.67198ZM15.3627 20.391C16.0381 19.9827 16.6955 19.3253 18.0104 18.0104C19.3253 16.6955 19.9827 16.0381 20.391 15.3627C21.6409 13.2951 21.6409 10.7049 20.391 8.6373C19.9827 7.96188 19.3253 7.30445 18.0104 5.98959C16.6955 4.67473 16.0381 4.0173 15.3627 3.60899C13.2951 2.3591 10.7049 2.3591 8.6373 3.60899C7.96188 4.0173 7.30445 4.67473 5.98959 5.98959C4.67473 7.30445 4.0173 7.96188 3.60899 8.6373C2.3591 10.7049 2.3591 13.2951 3.60899 15.3627C4.0173 16.0381 4.67473 16.6955 5.98959 18.0104C7.30445 19.3253 7.96188 19.9827 8.6373 20.391C10.7049 21.6409 13.2951 21.6409 15.3627 20.391Z"
				fill={props.color ?? "black"}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.1438 13.6794L10.3206 13.8562C10.9707 14.5063 11.3536 14.8857 11.6598 15.1353C11.8103 15.258 11.8923 15.3065 11.9235 15.3225C11.9742 15.3304 12.0258 15.3304 12.0765 15.3225C12.1077 15.3065 12.1897 15.258 12.3402 15.1353C12.6464 14.8857 13.0293 14.5063 13.6794 13.8562L13.8562 13.6794C14.5063 13.0293 14.8857 12.6464 15.1353 12.3402C15.258 12.1897 15.3065 12.1077 15.3225 12.0765C15.3304 12.0258 15.3304 11.9742 15.3225 11.9235C15.3065 11.8923 15.258 11.8103 15.1353 11.6598C14.8857 11.3536 14.5063 10.9707 13.8562 10.3206L13.6794 10.1438C13.0293 9.49372 12.6464 9.11428 12.3402 8.86466C12.1897 8.74195 12.1077 8.69352 12.0765 8.67745C12.0258 8.66961 11.9742 8.66961 11.9235 8.67745C11.8923 8.69352 11.8103 8.74195 11.6598 8.86466C11.3536 9.11428 10.9707 9.49372 10.3206 10.1438L10.1438 10.3206C9.49372 10.9707 9.11429 11.3536 8.86466 11.6598C8.74196 11.8103 8.69353 11.8923 8.67746 11.9235C8.66961 11.9742 8.66961 12.0258 8.67746 12.0765C8.69353 12.1077 8.74196 12.1897 8.86466 12.3402C9.11429 12.6464 9.49372 13.0293 10.1438 13.6794ZM12.4877 17.2804C13.2315 17.1324 13.8522 16.5117 15.0936 15.2704L15.2704 15.0936C16.5118 13.8522 17.1324 13.2315 17.2804 12.4877C17.3444 12.1657 17.3444 11.8343 17.2804 11.5123C17.1324 10.7685 16.5118 10.1478 15.2704 8.9064L15.0936 8.72963C13.8522 7.48825 13.2315 6.86756 12.4877 6.71961C12.1657 6.65556 11.8343 6.65556 11.5123 6.71961C10.7685 6.86756 10.1478 7.48825 8.90641 8.72963L8.72963 8.9064C7.48825 10.1478 6.86756 10.7685 6.71961 11.5123C6.65556 11.8343 6.65556 12.1657 6.71961 12.4877C6.86756 13.2315 7.48825 13.8522 8.72963 15.0936L8.90641 15.2704C10.1478 16.5117 10.7685 17.1324 11.5123 17.2804C11.8343 17.3444 12.1657 17.3444 12.4877 17.2804Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
