import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GetAllDevicesParams, VelavuAPI, VelavuDevice } from "velavu-js-api";
import useDeviceOnlineCallback from "../hook/use-device-online-callback";

interface InternalListDevicesState {
	devices: VelavuDevice[];
	isLoading: boolean;
	error: unknown | null;
	continuationToken: string | null;
}

export interface ListDevicesState {
	devices: VelavuDevice[];
	isLoading: boolean;
	error: unknown | null;
	hasMore: boolean;
}

function internalListDevicesStateToSuccessState(
	state: InternalListDevicesState,
): ListDevicesState {
	return {
		devices: state.devices,
		isLoading: state.isLoading,
		error: state.error,
		hasMore: state.continuationToken !== null,
	};
}

export interface ListDevicesActions {
	loadDevices: (params?: GetAllDevicesParams) => void;
	loadMore: () => void;
	registerDevice: (device: VelavuDevice) => void;
	unregisterDevice: (deviceID: string) => void;
	updateDevice: (
		deviceID: string,
		updater: (device: VelavuDevice) => VelavuDevice,
	) => void;
}

export function useListDevicesState(): [ListDevicesState, ListDevicesActions] {
	const [devicesState, setDevicesState] = useState<InternalListDevicesState>({
		devices: [],
		isLoading: false,
		error: null,
		continuationToken: null,
	});

	const activeRequestID = useRef(0);
	const initialRequestParams = useRef<GetAllDevicesParams | undefined | null>(
		null,
	);

	const loadDevices = useCallback(
		async (params?: GetAllDevicesParams) => {
			//Get a request ID
			activeRequestID.current += 1;
			const requestID = activeRequestID.current;

			//Reset the devices state
			setDevicesState((state) => ({
				...state,
				isLoading: true,
			}));

			//Store the params
			initialRequestParams.current = params;

			//Load the devices
			try {
				const allDevices =
					await VelavuAPI.devices.getAllDevices(params);

				//Make sure our request is still active
				if (activeRequestID.current === requestID) {
					setDevicesState({
						devices: allDevices.data,
						isLoading: false,
						error: null,
						continuationToken: allDevices.continuationToken ?? null,
					});
				}
			} catch (error) {
				//Make sure our request is still active
				if (activeRequestID.current === requestID) {
					setDevicesState({
						devices: [],
						isLoading: false,
						error,
						continuationToken: null,
					});
				}
			}
		},
		[setDevicesState],
	);

	const loadMore = useCallback(async () => {
		//Check state
		if (devicesState.isLoading) {
			console.warn(
				"Attempted to load more devices while already loading",
			);
			return;
		}
		if (devicesState.continuationToken === null) {
			console.warn(
				"Attempted to load more devices without a continuation token",
			);
			return;
		}
		if (initialRequestParams.current === null) {
			console.warn(
				"Attempted to load more devices without initial request params",
			);
			return;
		}

		//Reset the devices state
		const newDevicesState: Readonly<InternalListDevicesState> = {
			...devicesState,
			isLoading: true,
			error: null,
		};
		setDevicesState(newDevicesState);

		//Load more devices
		try {
			const moreDevices = await VelavuAPI.devices.getAllDevices(
				initialRequestParams.current,
				{ continuationToken: devicesState.continuationToken },
			);

			setDevicesState({
				...newDevicesState,
				isLoading: false,
				devices: [...newDevicesState.devices, ...moreDevices.data],
				continuationToken: moreDevices.continuationToken ?? null,
			});
		} catch (error) {
			setDevicesState({
				...newDevicesState,
				isLoading: false,
				error: error,
			});
		}
	}, [devicesState, setDevicesState]);

	const updateDevice = useCallback(
		(deviceID: string, updater: (device: VelavuDevice) => VelavuDevice) => {
			setDevicesState((devicesState) => {
				//Copy the current state
				const updatedDevicesState = { ...devicesState };
				const updatedDevices = [...updatedDevicesState.devices];

				//Update the device with the matching ID
				const deviceIndex = updatedDevices.findIndex(
					(device) => device.id === deviceID,
				);
				if (deviceIndex !== -1) {
					updatedDevices[deviceIndex] = updater(
						updatedDevices[deviceIndex],
					);
				}

				//Return the updated state
				updatedDevicesState.devices = updatedDevices;
				return updatedDevicesState;
			});
		},
		[setDevicesState],
	);

	const registerDevice = useCallback(
		(device: VelavuDevice) => {
			setDevicesState((devicesState) => {
				//Copy the current state
				const updatedDevicesState = { ...devicesState };
				const updatedDevices = [...updatedDevicesState.devices];

				//Check if the device is already in the list
				const deviceIndex = updatedDevices.findIndex(
					(device) => device.id === device.id,
				);
				if (deviceIndex !== -1) {
					//Update the device
					updatedDevices[deviceIndex] = device;
				} else {
					//Add the new device
					updatedDevices.push(device);
				}

				//Return the updated state
				updatedDevicesState.devices = updatedDevices;
				return updatedDevicesState;
			});
		},
		[setDevicesState],
	);

	const unregisterDevice = useCallback(
		async (deviceID: string) => {
			try {
				await VelavuAPI.devices.deleteSpecificDevice(deviceID);
				setDevicesState((devicesState) => {
					//Copy the current state
					const updatedDevicesState = { ...devicesState };
					const updatedDevices = [...updatedDevicesState.devices];

					//Remove the device with the matching ID
					const deviceIndex = updatedDevices.findIndex(
						(device) => device.id === deviceID,
					);
					if (deviceIndex !== -1) {
						updatedDevices.splice(deviceIndex, 1);
					}

					//Return the updated state
					updatedDevicesState.devices = updatedDevices;
					return updatedDevicesState;
				});
			} catch (error) {
				console.log(error);
			}
		},
		[setDevicesState],
	);

	//Subscribe to device online callbacks
	useDeviceOnlineCallback(
		useCallback(
			(deviceID, online) => {
				setDevicesState((devicesState) => {
					//Copy the current state
					const updatedDevicesState = { ...devicesState };
					const updatedDevices = [...updatedDevicesState.devices];

					//Replace the device with the matching ID's online state
					const deviceIndex = updatedDevices.findIndex(
						(device) => device.id === deviceID,
					);
					if (deviceIndex !== -1) {
						updatedDevices[deviceIndex] = {
							...updatedDevices[deviceIndex],
							online: online,
						};
					}

					//Return the updated state
					updatedDevicesState.devices = updatedDevices;
					return updatedDevicesState;
				});
			},
			[setDevicesState],
		),
	);

	useEffect(() => {
		//Load the initial devices
		loadDevices();
	}, []);

	//Convert the internal state to public state
	const publicDevicesState = useMemo(() => {
		return internalListDevicesStateToSuccessState(devicesState);
	}, [devicesState]);

	//Memoize the actions
	const actions: ListDevicesActions = useMemo(() => {
		return {
			loadDevices,
			loadMore,
			updateDevice,
			unregisterDevice,
			registerDevice,
		};
	}, [loadDevices, loadMore, registerDevice, unregisterDevice, updateDevice]);

	return [publicDevicesState, actions];
}
