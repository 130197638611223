import React from "react";
import { AssetCategory } from "velavu-js-api";
import { CategoryTag } from "../../../../elements/categorization-tag";
import { VelavuMenuFilterItem } from "../../../../elements/velavu-menu-filter";
import styles from "./asset-list-filter.module.scss";

interface AssetListFilterProps {
	filterCategory: AssetCategory | undefined;
	selectCategory: (filter: AssetCategory | undefined) => void;

	filterStatus: boolean | undefined;
	selectStatus: (filter: boolean | undefined) => void;
}

export default function AssetListFilter(props: AssetListFilterProps) {
	return (
		<div className={styles.container}>
			<div className={styles.column}>
				<div className={styles.title}>Status</div>
				<VelavuMenuFilterItem
					checked={props.filterStatus === undefined}
					onClick={() => props.selectStatus(undefined)}
				>
					All
				</VelavuMenuFilterItem>
				<VelavuMenuFilterItem
					checked={props.filterStatus === true}
					onClick={() => props.selectStatus(true)}
				>
					Active
				</VelavuMenuFilterItem>
				<VelavuMenuFilterItem
					checked={props.filterStatus === false}
					onClick={() => props.selectStatus(false)}
				>
					Offline
				</VelavuMenuFilterItem>
			</div>

			<div className={styles.column}>
				<div className={styles.title}>Category</div>
				<VelavuMenuFilterItem
					checked={props.filterCategory === undefined}
					onClick={() => props.selectCategory(undefined)}
				>
					All
				</VelavuMenuFilterItem>
				{Object.values(AssetCategory).map(
					(assetCategory: AssetCategory) => (
						<VelavuMenuFilterItem
							key={assetCategory}
							checked={props.filterCategory === assetCategory}
							onClick={() => props.selectCategory(assetCategory)}
						>
							<CategoryTag category={assetCategory} />
						</VelavuMenuFilterItem>
					),
				)}
			</div>
		</div>
	);
}
