import React, { useState } from "react";
import {
	VelavuDevice,
	VelavuSite,
	normalizeDeviceHardware,
} from "velavu-js-api";
import DropdownPanelToolbar from "../../../../elements/dropdown-panel-toolbar";
import VelavuItemIcon from "../../../../elements/velavu-item-icon";
import VelavuTabSwitcher from "../../../../elements/velavu-tab-switcher";
import styles from "./anchor-detail.module.scss";
import AnchorEnvironment from "./anchor-environment";
import AnchorProperties from "./anchor-properties";

interface AnchorDetailProps {
	site: VelavuSite;
	anchor: VelavuDevice;
	onBack: () => void;
	onClose: () => void;
}

enum AnchorDetailTab {
	Info,
	Environment,
}

export default function AnchorDetail(props: AnchorDetailProps) {
	const [selectedTab, setSelectedTab] = useState(AnchorDetailTab.Info);

	return (
		<div className={styles.container}>
			<DropdownPanelToolbar
				onBack={props.onBack}
				onClose={props.onClose}
			/>
			<div className={styles.header}>
				<VelavuItemIcon
					data={normalizeDeviceHardware(props.anchor.hardware)}
				/>
				<div className={styles.headerText}>
					<span className={styles.headerID}>{props.anchor.id}</span>
					<span className={styles.headerLocation}>
						{props.site.name}
					</span>
				</div>
			</div>
			<VelavuTabSwitcher
				selectedKey={selectedTab}
				onSelectKey={setSelectedTab}
				labels={{
					[AnchorDetailTab.Info]: "Info",
					[AnchorDetailTab.Environment]: "Environment",
				}}
			>
				{selectedTab === AnchorDetailTab.Info ? (
					<div className={styles.tables}>
						<AnchorProperties
							anchor={props.anchor}
							site={props.site}
						/>
					</div>
				) : (
					<div className={styles.tables}>
						<AnchorEnvironment anchor={props.anchor} />
					</div>
				)}
			</VelavuTabSwitcher>
		</div>
	);
}
