export type SimpleSubscriber<T> = (value: T) => void;

export default class SimpleObservable<T> {
	private readonly subscribers: SimpleSubscriber<T>[] = [];

	public subscribe(subscriber: SimpleSubscriber<T>) {
		this.subscribers.push(subscriber);
	}

	public unsubscribe(subscriber: SimpleSubscriber<T>) {
		const index = this.subscribers.indexOf(subscriber);
		if (index === -1) return;
		this.subscribers.splice(index, 1);
	}

	public emit(event: T) {
		for (const subscriber of this.subscribers) {
			subscriber(event);
		}
	}
}
