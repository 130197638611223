import styles from "./mapbox-injectable-location-history.module.scss";
import { Feature, LineString, Point } from "geojson";
import mapboxgl, { GeoJSONSource, MapLayerMouseEvent } from "mapbox-gl";
import { useMemo } from "react";
import { geoJSONBlank, geoJSONBlankLine } from "./mapbox-injectable";
import { EventCategory, VelavuDevice, VelavuEvent } from "velavu-js-api";
import distance from "@turf/distance";
import { DateTime } from "luxon";
import {
	cutWeightedLine,
	WeightedLineInterpolationResult,
	WeightedLineInterpolationResultType,
} from "../../helper/geo-helper";
import {
	MapHelperInstance,
	useListenerSetupMapEffect,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";

const mapboxIDHistoryLineBase = "history-line-base";
const mapboxIDHistoryLineHighlight = "history-line-highlight";
const mapboxIDHistoryLinePoint = "history-line-point";

const colorLineBase = "#A3B3CC";
const colorLineHighlight = "#3A58E2";

const colorPointBaseInner = "#CCD6E5";
const colorPointBaseOuter = "#A3B3CC";

const colorPointHighlightInner = "#6681FF";
const colorPointHighlightOuter = "#3A58E2";

enum HistoryLinesGeoPropertyType {
	Exact,
	Lines,
}

type HistoryLinesGeoProperty =
	| {
			type: HistoryLinesGeoPropertyType.Exact;
			coordinates: string; //[number, number]
			timestamp: string;
	  }
	| {
			type: HistoryLinesGeoPropertyType.Lines;
			points: string; //HistoryLinesGeoPropertyEntry
	  };

interface HistoryLinesGeoPropertyEntry {
	coordinates: [number, number];
	timestamp: string;
}

interface HistoryLinesPointEntry {
	highlight: boolean;
}

export interface InterpolatedLocationHistoryEntry {
	device: VelavuDevice;
	locationArray: VelavuEvent<EventCategory.Location>[];
	interpolationResult: WeightedLineInterpolationResult<string>;
}

export default function useMapboxInjectableLocationHistory(
	mapInstance: MapHelperInstance,
	historyLines: InterpolatedLocationHistoryEntry[],
	showPoints: boolean = false,
) {
	useStyleSetupMapEffect(mapInstance, (_, box) => {
		box.addSource(mapboxIDHistoryLineBase, {
			type: "geojson",
			data: geoJSONBlankLine,
		});
		box.addSource(mapboxIDHistoryLineHighlight, {
			type: "geojson",
			data: geoJSONBlankLine,
		});
		box.addSource(mapboxIDHistoryLinePoint, {
			type: "geojson",
			data: geoJSONBlank,
		});

		box.addLayer({
			id: mapboxIDHistoryLineBase,
			type: "line",
			source: mapboxIDHistoryLineBase,
			layout: {
				"line-join": "round",
				"line-cap": "round",
			},
			paint: {
				"line-color": colorLineBase,
				"line-width": 3,
			},
		});

		box.addLayer({
			id: mapboxIDHistoryLineHighlight,
			type: "line",
			source: mapboxIDHistoryLineHighlight,
			layout: {
				"line-join": "round",
				"line-cap": "round",
			},
			paint: {
				"line-color": colorLineHighlight,
				"line-width": 3,
			},
		});

		box.addLayer({
			id: mapboxIDHistoryLinePoint,
			type: "circle",
			source: mapboxIDHistoryLinePoint,
			paint: {
				"circle-color": [
					"case",
					["get", "highlight"],
					colorPointHighlightInner,
					colorPointBaseInner,
				],
				"circle-radius": 2,
				"circle-stroke-color": [
					"case",
					["get", "highlight"],
					colorPointHighlightOuter,
					colorPointBaseOuter,
				],
				"circle-stroke-width": 2,
			},
		});
	});

	const pointPopup = useMemo(
		() =>
			new mapboxgl.Popup({
				closeButton: false,
				closeOnClick: false,
				className: styles.tooltipContainer,
			}),
		[],
	);

	useListenerSetupMapEffect(
		mapInstance.map,
		(map, box) => {
			const showPopup = (event: MapLayerMouseEvent) => {
				const properties = event.features![0]
					.properties as HistoryLinesGeoProperty;

				//Find the target point
				let popupCoordinates: [number, number];
				let popupTimestamp: string;
				if (properties.type === HistoryLinesGeoPropertyType.Exact) {
					popupCoordinates = JSON.parse(properties.coordinates);
					popupTimestamp = properties.timestamp;
				} else {
					const mouseCoordinates: [number, number] = [
						event.lngLat.lng,
						event.lngLat.lat,
					];

					const historyLines = JSON.parse(
						properties.points,
					) as HistoryLinesGeoPropertyEntry[];
					const closestResource = historyLines.reduce(
						(closestPoint, point) =>
							distance(
								closestPoint.coordinates,
								mouseCoordinates,
							) < distance(point.coordinates, mouseCoordinates)
								? closestPoint
								: point,
					);
					if (closestResource === undefined) return;

					popupCoordinates = mouseCoordinates;
					popupTimestamp = closestResource.timestamp;
				}

				// Ensure that if the map is zoomed out such that multiple
				// copies of the feature are visible, the popup appears
				// over the copy being pointed to.
				while (Math.abs(event.lngLat.lng - popupCoordinates[0]) > 180) {
					popupCoordinates[0] +=
						event.lngLat.lng > popupCoordinates[0] ? 360 : -360;
				}

				//Change the cursor style as a UI indicator.
				map.getCanvas().style.cursor = "pointer";

				const dateDisplay =
					DateTime.fromISO(popupTimestamp).toFormat("MMM d t");

				// Populate the popup and set its coordinates
				// based on the feature found.
				let descriptionHTML = `<span class="primary">${dateDisplay}</span>`;
				if (properties.type === HistoryLinesGeoPropertyType.Lines) {
					descriptionHTML += `<span class="secondary">approximation</span>`;
				}
				pointPopup.setLngLat(popupCoordinates).setHTML(descriptionHTML);
				if (!pointPopup.isOpen()) pointPopup.addTo(map);
			};

			const hidePopup = () => {
				map.getCanvas().style.cursor = "";
				pointPopup.remove();
			};

			box.on("mouseenter", mapboxIDHistoryLineBase, showPopup);
			box.on("mousemove", mapboxIDHistoryLineBase, showPopup);
			box.on("mouseleave", mapboxIDHistoryLineBase, hidePopup);

			box.on("mouseenter", mapboxIDHistoryLinePoint, showPopup);
			box.on("mousemove", mapboxIDHistoryLinePoint, showPopup);
			box.on("mouseleave", mapboxIDHistoryLinePoint, hidePopup);
		},
		[pointPopup],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			if (!historyLines) return;

			const baseLineSource = map.getSource(
				mapboxIDHistoryLineBase,
			) as GeoJSONSource;
			const highlightLineSource = map.getSource(
				mapboxIDHistoryLineHighlight,
			) as GeoJSONSource;
			const pointSource = map.getSource(
				mapboxIDHistoryLinePoint,
			) as GeoJSONSource;

			baseLineSource.setData({
				type: "FeatureCollection",
				features: historyLines.map<
					Feature<LineString, HistoryLinesGeoProperty>
				>((historyEntry) => ({
					type: "Feature",
					id: historyEntry.device.id,
					properties: {
						type: HistoryLinesGeoPropertyType.Lines,
						points: JSON.stringify(
							historyEntry.locationArray.map<HistoryLinesGeoPropertyEntry>(
								(locationEntry) => ({
									coordinates: locationEntry.data.coordinates,
									timestamp: locationEntry.timestamp,
								}),
							),
						),
					},
					geometry: {
						type: "LineString",
						coordinates: historyEntry.locationArray.map(
							(locationEntry) => locationEntry.data.coordinates,
						),
					},
				})),
			});

			highlightLineSource.setData({
				type: "FeatureCollection",
				features: historyLines.flatMap<Feature<LineString, null>>(
					(historyEntry) => ({
						type: "Feature",
						id: historyEntry.device.id,
						properties: null,
						geometry: {
							type: "LineString",
							coordinates: cutWeightedLine(
								historyEntry.locationArray.map(
									(locationEntry) =>
										locationEntry.data.coordinates,
								),
								historyEntry.interpolationResult,
							),
						},
					}),
				),
			});

			pointSource.setData({
				type: "FeatureCollection",
				features: historyLines.flatMap((historyEntry) => {
					let highlightThreshold: number;
					if (
						historyEntry.interpolationResult.type ===
						WeightedLineInterpolationResultType.Exact
					) {
						highlightThreshold =
							historyEntry.interpolationResult.index;
					} else {
						highlightThreshold =
							historyEntry.interpolationResult.indexUpper;
					}

					return historyEntry.locationArray.map<
						Feature<
							Point,
							HistoryLinesPointEntry & HistoryLinesGeoProperty
						>
					>((location, locationIndex) => ({
						type: "Feature",
						id: historyEntry.device.id,
						properties: {
							highlight: locationIndex < highlightThreshold,
							type: HistoryLinesGeoPropertyType.Exact,
							coordinates: JSON.stringify(
								location.data.coordinates,
							),
							timestamp: location.timestamp,
						},
						geometry: {
							type: "Point",
							coordinates: location.data.coordinates,
						},
					}));
				}),
			});
		},
		[historyLines],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			if (!map) return;

			map.setLayoutProperty(
				mapboxIDHistoryLinePoint,
				"visibility",
				showPoints ? "visible" : "none",
			);
		},
		[showPoints],
	);
}
