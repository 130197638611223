export const minPasswordLength = 8;
export const passwordRequirementsNotice =
	"Passwords must be at least " + minPasswordLength + " characters long";

export const mapboxUsername = "brash-inc";

export const mapboxStyleID = "cknyjh5v13nql17nupxtb9zrt";
export const mapboxStyle = `mapbox://styles/${mapboxUsername}/${mapboxStyleID}`;

export const mapboxStyleSatelliteID = "clb5beops000d14nv8rtide2k";
export const mapboxStyleSatellite = `mapbox://styles/${mapboxUsername}/${mapboxStyleSatelliteID}`;

export const hardwareSetupSupportURL = "https://support.velavu.com";
