import React, { CSSProperties } from "react";

export default function IconMissing(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			width={props.size ?? 12}
			height={props.size ?? 12}
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.33335 4.66667C4.33335 4.29848 4.03488 4 3.66669 4H3.33335C1.86059 4 0.666687 5.19391 0.666687 6.66667V9.33333C0.666687 10.8061 1.86059 12 3.33335 12H8.66669C10.1394 12 11.3334 10.8061 11.3334 9.33333V6.66667C11.3334 5.19391 10.1394 4 8.66669 4H8.33335C7.96516 4 7.66669 4.29848 7.66669 4.66667C7.66669 5.03486 7.96516 5.33333 8.33335 5.33333H8.66669C9.40307 5.33333 10 5.93029 10 6.66667V9.33333C10 10.0697 9.40307 10.6667 8.66669 10.6667L3.33335 10.6667C2.59697 10.6667 2.00002 10.0697 2.00002 9.33333L2.00002 6.66667C2.00002 5.93029 2.59697 5.33333 3.33335 5.33333H3.66669C4.03488 5.33333 4.33335 5.03486 4.33335 4.66667Z"
				fill={props.color ?? "#00255D"}
			/>
			<path
				d="M5.33335 8C5.33335 8.36819 5.63183 8.66667 6.00002 8.66667C6.36821 8.66667 6.66669 8.36819 6.66669 8V2.93281H8.66669L6.5206 0.250203C6.25372 -0.0834008 5.74632 -0.0834015 5.47944 0.250203L3.33335 2.93281L5.33335 2.93281V8Z"
				fill={props.color ?? "#00255D"}
			/>
		</svg>
	);
}
