import React, { CSSProperties } from "react";

export default function IconFloors(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15 6C15 5.44772 15.4477 5 16 5H20C20.5523 5 21 5.44772 21 6C21 6.55228 20.5523 7 20 7H17V10C17 10.5523 16.5523 11 16 11H13V14C13 14.5523 12.5523 15 12 15H9V18C9 18.5523 8.55228 19 8 19H4C3.44772 19 3 18.5523 3 18C3 17.4477 3.44772 17 4 17H7V14C7 13.4477 7.44772 13 8 13H11V10C11 9.44772 11.4477 9 12 9H15V6Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
