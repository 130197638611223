import { GeoJSONSource } from "mapbox-gl";
import { geoJSONBlank } from "./mapbox-injectable";
import {
	MapHelperInstance,
	useStyleDependantMapEffect,
	useStyleSetupMapEffect,
} from "../../helper/map-helper";

export const mapboxIDHotspot = "hostspot";

export type HotspotPoint = { location: [number, number]; strength: number };

/**
 * A mapbox injectable that displays point layers on the map
 * @param mapInstance The map to render on
 * @param points The points to render
 * @param spectrum The color spectrum to interpolate between 0 and 1
 */
export default function useMapboxInjectableHotspot(
	mapInstance: MapHelperInstance,
	points: HotspotPoint[] | undefined,
	spectrum: string[],
) {
	useStyleSetupMapEffect(mapInstance, (_, box) => {
		box.addSource(mapboxIDHotspot, {
			type: "geojson",
			data: geoJSONBlank,
		});
		box.addLayer({
			id: mapboxIDHotspot,
			type: "circle",
			source: mapboxIDHotspot,
			paint: {
				"circle-radius": [
					"interpolate",
					["linear"],
					["get", "strength"],
					0,
					25,
					1,
					100,
				],
				"circle-blur": 1,
			},
		});
	});

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			if (spectrum.length === 0) return;

			map.setPaintProperty(mapboxIDHotspot, "circle-color", [
				"interpolate",
				["linear"],
				["get", "strength"],
				...spectrum
					.map((color, index) => [
						index / (spectrum.length - 1),
						color,
					])
					.flat(),
			]);
		},
		[spectrum],
	);

	useStyleDependantMapEffect(
		mapInstance,
		(map) => {
			const hotspotSource = map.getSource(
				mapboxIDHotspot,
			) as GeoJSONSource;
			hotspotSource.setData({
				type: "FeatureCollection",
				features: (points ?? []).map((point) => ({
					type: "Feature",
					geometry: {
						type: "Point",
						coordinates: point.location,
					},
					properties: {
						strength: point.strength,
					},
				})),
			});
		},
		[points],
	);
}
