import React, { CSSProperties } from "react";

export default function IconPlace(props: {
	className?: string;
	style?: CSSProperties;
	color?: string;
	size?: number;
}) {
	return (
		<svg
			className={props.className}
			style={props.style}
			xmlns="http://www.w3.org/2000/svg"
			width={props.size ?? 24}
			height={props.size ?? 24}
			viewBox="0 0 24 24"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22.9999C12 22.9999 21 16.9999 21 9.99994C21 7.61299 20.0518 5.3238 18.364 3.63598C16.6761 1.94815 14.3869 0.999939 12 0.999939C9.61305 0.999939 7.32387 1.94815 5.63604 3.63598C3.94821 5.3238 3 7.61299 3 9.99994C3 16.9999 12 22.9999 12 22.9999ZM15 9.99994C15 11.6568 13.6569 12.9999 12 12.9999C10.3431 12.9999 9 11.6568 9 9.99994C9 8.34309 10.3431 6.99994 12 6.99994C13.6569 6.99994 15 8.34309 15 9.99994Z"
				fill={props.color ?? "black"}
			/>
		</svg>
	);
}
